<template>
  <div>
    <b-modal :id="modal" size="md" :title="title" no-close-on-esc no-close-on-backdrop hide-header-close>
      <div class="row">
        <div class="form-group col-md-12">
          <label>Nombre*</label>
          <input type="text" name="" class="form-control" v-model="formTipo.nombre" v-touppercase>
        </div>
      </div>
      <template #modal-footer="{ }">
        <button class="button button--secondary" @click="closeModal();">
          <span class="button__text">Cancelar</span>
          <font-awesome-icon :icon="['fas', 'times-circle']" class="button__icon" />
        </button>
        <button class="button button--success" v-if="!edit" @click="createTipoArticulo()">
          <span class="button__text">Guardar</span>
          <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
        </button>
        <button class="button button--success" v-if="edit" @click="updateTipoArticulo()">
          <span class="button__text">Actualizar</span>
          <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
        </button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
const TipoArticulo = resource('/base/api/gestor_tipos_articulos/', {
    update: (id, params) => axios.put(`/base/api/gestor_tipos_articulos/${id}/`, { params })
}, axios)

export default {
    name: 'ModalTipoArticulo',
    props: ['modal', 'tipo', 'edit', 'title'],
    components: {
    },
    data () {
        return {
            formTipo: {
                nombre: null
            }
        }
    },
    methods: {
        openModalCreate () {
            this.formTipo = { nombre: null }
            this.$root.$emit('bv::show::modal', 'modal-create-tipo-articulo')
        },
        openModalEdit () {
            if (this.tipo) this.formTipo = this.tipo
            this.$root.$emit('bv::show::modal', 'modal-edit-tipo-articulo')
        },
        closeModal () {
            this.$emit('accept')
            this.$root.$emit('bv::hide::modal', this.modal)
        },
        createTipoArticulo () {
            this.formTipo.username = this.formTipo.email
            if (this.formTipo.rol === 'Administrador') this.formTipo.empresas = this.empresas__id
            TipoArticulo.save(this.formTipo).then((res) => {
                this.closeModal()
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Tipo Artículo creado con exito'
                })
                this.formTipo = {
                    nombre: null
                }
            })
                .catch((error) => {
                    this.$emit('accept')
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al crear el tipo de artículo'
                    })
                })
        },
        updateTipoArticulo () {
            TipoArticulo.update(this.formTipo.id, this.formTipo).then((res) => {
                this.closeModal()
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Tipo de Artículo editado con exito'
                })
            })
                .catch((error) => {
                    this.$emit('accept')
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al editar el tipo de artículo'
                    })
                })

        }
    },
    mounted () {
    }
}
</script>
