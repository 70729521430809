<template>
  <div>
    <HeaderGeneral></HeaderGeneral>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <h4 class="bienvenido mb-3">Resumen Comercial</h4>
          <div class="card">
            <div class="card__body">
              <label>Filtrar por año de ingreso</label>
              <v-select :options="anios" v-model="form.anio" label="fecha_ingreso__year" :reduce="fecha_ingreso__year => fecha_ingreso__year.fecha_ingreso__year">        
              </v-select>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="card">
            <div class="card__body">
              <h6 class="reporte__title">Propuestas abiertas</h6>
              <table class="reporte">
                <thead>
                  <tr v-if="cotizacionesAbiertas">
                    <th class="reporte__th">Dripsa</th>
                    <th class="reporte__th">Tagle</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="cotizacionesAbiertas">                        
                    <td class="reporte__td reporte__n--success" v-for="cotizacion in cotizacionesAbiertas" v-bind:key="cotizacion.empresa">{{cotizacion.total}}</td>
                  </tr>
                  <tr>
                    <td class="reporte__text">.</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card">
            <div class="card__body">
              <h6 class="reporte__title">Propuestas cerradas</h6>
              <table class="reporte">
                <thead>
                  <tr>
                    <th class="reporte__th">Dripsa</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="reporte__td reporte__n--success" v-if="cotizacionesCerradas.dripsa">{{cotizacionesCerradas.dripsa[0]['aprobadas']}}
                    </td>
                    <td class="reporte__td reporte__n--danger" v-if="cotizacionesCerradas.dripsa">{{cotizacionesCerradas.dripsa[0]['rechazadas']}}
                    </td>
                    <td class="reporte__td reporte__n--secondary" v-if="cotizacionesCerradas.dripsa">{{cotizacionesCerradas.dripsa[0]['descartadas']}}
                    </td>
                  </tr>
                  <tr>
                    <td class="reporte__text">Aprob.</td>
                    <td class="reporte__text">Rech.</td>
                    <td class="reporte__text">Desc.</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card">
            <div class="card__body">
              <h6 class="reporte__title">Propuestas cerradas</h6>
              <table class="reporte">
                <thead>
                  <tr>
                    <th class="reporte__th">Tagle</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="reporte__td reporte__n--success" v-if="cotizacionesCerradas.tagle">{{cotizacionesCerradas.tagle[0]['aprobadas']}}</td>
                    <td class="reporte__td reporte__n--danger" v-if="cotizacionesCerradas.tagle">{{cotizacionesCerradas.tagle[0]['rechazadas']}}</td>
                    <td class="reporte__td reporte__n--secondary" v-if="cotizacionesCerradas.tagle">{{cotizacionesCerradas.tagle[0]['descartadas']}}</td>
                  </tr>
                  <tr>
                    <td class="reporte__text">Aprob.</td>
                    <td class="reporte__text">Rech.</td>
                    <td class="reporte__text">Desc.</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="card">
            <div class="card__body">
              <h6 class="reporte__title">Monto total cotizado</h6>
              <table class="reporte">
                <thead>
                  <tr>
                    <th class="reporte__th" style="width:100px">Dripsa</th>
                    <th class="reporte__th" style="width:100px">Tagle</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="reporte__td reporte__n--success" v-for="total in totalCotizado" v-bind:key="total.empresa">
                      <vue-numeric style="width:100%" currency="$" separator="," v-model="total.total" disabled v-if="total.total > 0" v-bind:precision="3"></vue-numeric>
                      <span v-if="total.total===0">$ 0</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="reporte__text" v-for="total in totalCotizado" v-bind:key="total.empresa">$ CLP</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card__body">
              <h6 class="reporte__title">Monto total vendido</h6>
              <table class="reporte" v-if="totalAprobado">
                <thead>
                  <tr>
                    <th class="reporte__th" style="width:100px" v-for="total in totalAprobado" v-bind:key="total.empresa">{{total.empresa}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="reporte__td reporte__n--success" v-for="total in totalAprobado" v-bind:key="total.empresa">
                      <vue-numeric style="width:100%" currency="$" separator="," v-model="total.total" disabled v-if="total.total > 0" v-bind:precision="3"></vue-numeric>
                      <span v-if="total.total===0">$ 0</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="reporte__text" v-for="total in totalAprobado" v-bind:key="total.empresa">$ CLP</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <div class="card">
            <div class="card__body">
              <h6 class="reporte__title">Monto total rechazado</h6>
              <table class="reporte" v-if="totalRechazado">
                <thead>
                  <tr>
                    <th class="reporte__th" style="width:100px" v-for="total in totalRechazado" v-bind:key="total.empresa">{{total.empresa}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="reporte__td reporte__n--danger" v-for="total in totalRechazado" v-bind:key="total.empresa">
                      <vue-numeric style="width:100%" currency="$" separator="," v-model="total.total" disabled v-if="total.total > 0" v-bind:precision="3"></vue-numeric>
                      <span v-if="total.total===0">$ 0</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="reporte__text" v-for="total in totalRechazado" v-bind:key="total.empresa">$ CLP</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card__body">
              <h6 class="reporte__title">Monto total descartado</h6>
              <table class="reporte" v-if="totalDescartado">
                <thead>
                  <tr>
                    <th class="reporte__th" style="width:100px" v-for="total in totalDescartado" v-bind:key="total.empresa">{{total.empresa}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="reporte__td reporte__n--secondary" v-for="total in totalDescartado" v-bind:key="total.empresa">
                      <vue-numeric style="width:100%" currency="$" separator="," v-model="total.total" disabled v-if="total.total>0" v-bind:precision="3"></vue-numeric>
                      <span v-if="total.total===0">$ 0</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="reporte__text" v-for="total in totalDescartado" v-bind:key="total.empresa">$ CLP</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="vendedores">
        <div class="col-md-12">
          <div class="card">
            <div class="card__header">
              <h4 class="card__title">Vendedores</h4>
            </div>
            <div class="card__body">
              <div class="table-responsive">
                <table class="table">
                  <thead class="thead">
                    <tr>
                      <th class="thead__th" style="min-width:100px">Nombre</th>
                      <th class="thead__th" style="min-width:150px">Cotizado</th>
                      <th class="thead__th"></th>
                      <th class="thead__th" style="min-width:150px">Vendido</th>
                      <th class="thead__th"></th>
                      <th class="thead__th" style="min-width:150px">Rechazado</th>
                      <th class="thead__th"></th>
                      <th class="thead__th" style="min-width:150px">Descartado</th>
                      <th class="thead__th"></th>
                    </tr>
                  </thead>
                  <tbody class="tbody">
                    <tr v-for="vendedor in vendedores" v-bind:key="vendedor.nombre">
                      <td class="table__td">{{vendedor.vendedor}}</td>
                      <td class="table__td">
                          <vue-numeric style="width:100%" currency="$" separator="," v-model="vendedor.monto_cotizadas" disabled v-bind:precision="3"></vue-numeric>
                      </td>
                      <td class="table__td">
                        <span class="badge badge--primary">{{vendedor.cantidad_cotizadas}}</span>
                      </td>
                      <td class="table__td">
                          <vue-numeric style="width:100%" currency="$" separator="," v-model="vendedor.monto_aprobadas" disabled v-if="vendedor.monto_aprobadas > 0" v-bind:precision="3"></vue-numeric>
                          <span v-if="vendedor.monto_aprobadas ==0">$ 0 </span>
                      </td>
                      <td class="table__td">
                        <span class="badge badge--success">{{vendedor.cantidad_aprobadas}}</span>
                      </td>
                      <td class="table__td">
                          <vue-numeric style="width:100%" currency="$" separator="," v-model="vendedor.monto_rechazadas" disabled v-if="vendedor.monto_rechazadas > 0" v-bind:precision="3"></vue-numeric>
                          <span v-if="vendedor.monto_rechazadas ==0">$ 0 </span>
                      </td>
                      <td class="table__td">
                        <span class="badge badge--danger">{{vendedor.cantidad_rechazadas}}</span>
                      </td>
                      <td class="table__td">
                          <vue-numeric style="width:100%" currency="$" separator="," v-model="vendedor.monto_descartadas" disabled v-if="vendedor.monto_descartadas > 0" v-bind:precision="3"></vue-numeric>
                          <span v-if="vendedor.monto_descartadas ==0">$ 0 </span>
                      </td>
                      <td class="table__td">
                        <span class="badge badge--secondary">{{vendedor.cantidad_descartadas}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
import HeaderGeneral from '@/components/HeaderGeneral.vue'

const Cotizacion = resource('/cotizacion/api/reportes', {
    cotizaciones_abiertas:(params) => axios.get(`/cotizacion/api/reportes/cotizaciones_abiertas/`, { params }),
    cotizaciones_cerradas:(params) => axios.get(`/cotizacion/api/reportes/cotizaciones_cerradas/`, { params }),
    total_cotizado:(params) => axios.get(`/cotizacion/api/reportes/total_cotizado/`, { params }),
    total_aprobado:(params) => axios.get(`/cotizacion/api/reportes/total_aprobado/`, { params }),
    total_rechazado:(params) => axios.get(`/cotizacion/api/reportes/total_rechazado/`, { params }),
    total_descartado:(params) => axios.get(`/cotizacion/api/reportes/total_descartado/`, { params }),
    vendedores:(params) => axios.get(`/cotizacion/api/reportes/vendedores/`, { params }),
    anios:() => axios.get(`/cotizacion/api/reportes/anios/`),
}, axios)

export default {
    name: 'Reportes',
    components: {
        HeaderGeneral
    },
    data () {
        return {
            anios: [],
            cotizacionesAbiertas: [],
            cotizacionesCerradas: [],
            totalCotizado: [],
            totalAprobado: [],
            totalRechazado: [],
            totalDescartado: [],
            vendedores: [],
            form: { anio: new Date().getFullYear() }
        }
    },
    methods: {
        getData () {
            Cotizacion.cotizaciones_abiertas({ fecha_ingreso__year: this.form.anio }).then((res) => {
                this.cotizacionesAbiertas = res.data
            })
            Cotizacion.cotizaciones_cerradas({ fecha_ingreso__year: this.form.anio }).then((res) => {
                this.cotizacionesCerradas = res.data
            })
            Cotizacion.total_cotizado({ fecha_ingreso__year: this.form.anio }).then((res) => {
                this.totalCotizado = res.data
            })
            Cotizacion.total_aprobado({ fecha_ingreso__year: this.form.anio }).then((res) => {
                this.totalAprobado = res.data
            })
            Cotizacion.total_rechazado({ fecha_ingreso__year: this.form.anio }).then((res) => {
                this.totalRechazado = res.data
            })
            Cotizacion.total_descartado({ fecha_ingreso__year: this.form.anio }).then((res) => {
                this.totalDescartado = res.data
            })
            Cotizacion.vendedores({ fecha_ingreso__year: this.form.anio }).then((res) => {
                this.vendedores = res.data
            })
        }
    },
    mounted () {
        this.getData()        
        Cotizacion.anios({ }).then((res) => {
            this.anios = res.data
        })
        this.$watch('form.anio', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null) {
                this.getData()
            }
        }, { immediate: true })
    },
    computed: {
    }
}
</script>
