<!--COTIZACIONES - CONFIRMAR COTIZACION-->

<template>
  <div>
    <modal-direccion-despacho :cliente="modalAddressData" ref="modalAddress" :title="modalAddressData.numero" @accept="getDireccion();modalAddressData=null;created=true;" v-if="modalAddressData"></modal-direccion-despacho>
    <b-modal id="modal-confirmar-cotizacion" size="lg" title="Confirmar Cotización" no-close-on-esc no-close-on-backdrop hide-header-close>
      <div class="row">
        <div class="col-md-6">
          <label>Método de Despacho</label>
          <v-select :options="despachos" v-model="formCotizacion.despacho" label="despacho">
          </v-select>
        </div>
        <div class="form-group col-md-6">
            <label>
                Órden de Compra
            </label>
            <input class="form-control" type="text" v-model="formCotizacion.oc"/>
        </div>
        <div class="col-md-12" v-if="formCotizacion.cliente">
          <label>Dirección de Despacho</label>
          <v-select :options="direcciones" v-model="formCotizacion.direccion" label="direccion" :filter-by="myFilterDireccion" v-if="direcciones.length > 0">
            <template slot="option" slot-scope="option">
                {{option.tipo}} - {{option.id_embarque}}. {{option.direccion}}. {{option.comuna}}, {{option.region}}.
            </template>
            <template slot="selected-option" slot-scope="option">
                {{option.tipo}} - {{option.id_embarque}}. {{option.direccion}}. {{option.comuna}}, {{option.region}}.
            </template> 
            <div class="alert alert--warning" role="alert" slot="no-options">
              <font-awesome-icon :icon="['fas', 'exclamation-circle']" class="alert__icon" />
              <span class="alert__text">No se encontraron resultados.</span>
            </div>
          </v-select>
          <div>
            <a @click="setDataAddress(cliente)" class="button--sm button--primary">
              Agregar Dirección
              <font-awesome-icon class="button__icon--sm" :icon="['fas', 'map-marker-alt']" />
            </a>
          </div>
        </div>
      </div>
      <template #modal-footer="{ }">
        <button class="button button--secondary" @click="closeModal();">
          <span class="button__text">Cancelar</span>
          <font-awesome-icon :icon="['fas', 'times-circle']" class="button__icon" />
        </button>
        <button class="button button--success" @click="cerrarCotizacion(formCotizacion.cerrado)" v-if="confirmarCotizacion()">
          <span class="button__text">Confirmar Venta</span>
          <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
        </button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
import ModalDireccionDespacho from '@/modals/ModalDireccionDespacho.vue'

const Cliente = resource('/base/api/listar_clientes/', axios)

const Cotizacion = resource('/cotizacion/api/gestor_cotizaciones', {
    update: (id, params) => axios.put(`/cotizacion/api/gestor_cotizaciones/${id}/`, { params }),
    cerrar:(id, params) => axios.put(`/cotizacion/api/gestor_cotizaciones/${id}/cerrar/`, { params }),
    actualizar_cambio:(id, params) => axios.put(`/cotizacion/api/gestor_cotizaciones/${id}/actualizar_cambio/`, { params }),
}, axios)

export default {
    name: 'ModalConfirmarCotizacion',
    props: ['cotizacion', 'cambios'],
    components: {
        ModalDireccionDespacho
    },
    data () {
        return {
            clientes: [],
            formCotizacion: {},
            direcciones: [],
            error: null,
            despachos: [
                'TRANSPORTE INTERNO',
                'TRANSPORTE EXTERNO ACC',
                'TRANSPORTE EXTERNO ACI',
                'RETIRO VENDEDOR',
                'RETIRO CLIENTE',
                'DESPACHO DIRECTO',
                'INSTALACION',
                'NO APLICA'
            ],
            modalAddressData: null,
            formCambio: {},
            cliente: {},
            created: false
        }
    },
    methods: {
        getDireccion () {
            Cliente.query({ id: this.cotizacion.cliente }).then((res) => {
                this.cliente = res.data[0]
                this.direcciones = res.data[0]['direccion']
                this.direcciones = this.direcciones.filter(data => { return data.tipo === 'DESPACHO'})
                if (this.created) {
                    this.formCotizacion.direccion = this.direcciones[this.direcciones.length-1]
                    this.created = false
                }
            })
        },
        openModal () {
            this.formCotizacion = this.cotizacion
            this.getDireccion()
            this.$root.$emit('bv::show::modal', 'modal-confirmar-cotizacion')
        },
        closeModal () {
            this.$root.$emit('bv::hide::modal', 'modal-confirmar-cotizacion')
        },
        cerrarCotizacion (cerrado) {
            this.formCotizacion.cerrado = !cerrado
            this.formCotizacion.por_revisar = false
            this.formCotizacion.revisado = false
            this.formCotizacion.aprobado = false
            this.formCotizacion.por_confirmar = false
            if (!this.formCotizacion.cerrado)
                this.formCotizacion.usuario_rechazo = null
            delete this.formCotizacion.fecha_ingreso
            delete this.formCotizacion.fechahora_actualizacion
            delete this.formCotizacion.fecha_cierre
            // this.actualizarCambio(this.cambios)
            Cotizacion.cerrar(this.formCotizacion.id, this.formCotizacion).then((res) => {
                this.$emit('accept')
                this.$root.$emit('bv::hide::modal', 'modal-confirmar-cotizacion')
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Cotización actualizada con éxito'
                })
            })
                .catch((error) => {
                    this.$emit('accept')
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al actualizar la cotización'
                    })
                })

        },
        openModalAddress () {
            this.$refs.modalAddress.openModal()
        },
        setDataAddress (item) {
            this.modalAddressData = item
        },
        getClienteAddress (value) {
            this.setDataAddress(value)
        },
        myFilterDireccion: (option, label, search) => {
            let temp = search.toUpperCase().toString()
            return option.region.toUpperCase().indexOf(temp) > -1 || 
            option.comuna.toUpperCase().indexOf(temp) > -1 || 
            option.direccion.toUpperCase().indexOf(temp) > -1 
            // || 
            // option.id_embarque.toUpperCase().indexOf(temp) > -1 || 
            // option.tipo.toUpperCase().indexOf(temp) > -1
        },
        // actualizarCambio (cambios) {
        //     this.formCambio = cambios.map((c) => { return {'moneda': c.moneda, 'tasa_cambio': c.tasa_cambio} })
        //     Cotizacion.actualizar_cambio(this.cotizacion.id, this.formCambio).then((res) => {
        //         this.$notify({
        //             group: 'foo',
        //             type: 'success',
        //             title: 'Cotizacion actualizada con éxito'
        //         })
        //     })
        //         .catch((error) => {
        //             this.$notify({
        //                 group: 'foo',
        //                 type: 'error',
        //                 title: 'Error al actualizar la cotización'
        //             })
        //         })

        // },
        confirmarCotizacion() {
            if (this.formCotizacion.despacho === 'TRANSPORTE INTERNO') {
                if (Object.keys(this.formCotizacion.direccion).length > 0 && this.formCotizacion.oc) {
                    return true
                }
            } else {
                if (this.formCotizacion.oc) {
                    return true
                }
            }
            return false
        }
    },
    mounted () {
        this.$watch('modalAddressData', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null) {
                this.openModalAddress()
            }
        }, { immediate: true })
    }
}
</script>
