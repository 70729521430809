import axios from 'axios'
import _ from 'lodash'

export const utils = {
    data () {
        return {
            seleccionGrupo: false,
            seleccionadosGrupal: [],
            cambioUnElementoTd: false
        }
    },
    methods: {
        async getPermisos () {
            if (this.$store.state.permiso_editar_bodega === undefined || this.$store.state.permiso_editar_logistica === undefined) {
                return axios.get('/base/api/usuario_actual').then(res => {
                    const permisos = res.data[0].permisos
        
                    let ver_bodega = false
                    let ver_logistica = false
                    let editar_bodega = false
                    let editar_logistica = false
        
                    permisos.forEach(permiso => {
                        if (permiso['tipo'] === 'ver_bodega' && permiso['id'].length > 0)
                            ver_bodega = true
                        if (permiso['tipo'] === 'editar_bodega' && permiso['id'].length > 0)
                            editar_bodega = true
                        if (permiso['tipo'] === 'ver_logistica' && permiso['id'].length > 0)
                            ver_logistica = true
                        if (permiso['tipo'] === 'editar_logistica' && permiso['id'].length > 0)
                            editar_logistica = true
                    })
        
                    this.$store.commit('set_permiso_ver_bodega', ver_bodega)
                    this.$store.commit('set_permiso_editar_bodega', editar_bodega)
                    this.$store.commit('set_permiso_ver_logistica', ver_logistica)
                    this.$store.commit('set_permiso_editar_logistica', editar_logistica)
                }).catch(error => {
                    console.log(error)
                })
            }
            return
        },
        getClassStockOrden (orden) {
            if (orden.estados.sin_stock && !orden.estados.en_orden) {
                return ''
            } else if (orden.estados.sin_stock && orden.estados.en_orden) {
                return ''
            } else if (!orden.estados.sin_stock && orden.estados.en_orden) {
                return 'calendar__ov__circle--success'
            } else if (!orden.estados.sin_stock && !orden.estados.en_orden) {
                return ''
            }

            return ''
        },
        getOrdenesByRegion (ordenes) {
            return _.groupBy(ordenes,'region')
        },
        getOrdenesByDespacho (ordenes) {
            return _.groupBy(ordenes,'despacho')
        },
        getParents(el, parentSelector, className = null, includeChild = false) {

            const parents = []
            if (el === null) {
                return parents
            }
            let p = el.parentNode
            if (includeChild) {
                p = el
            }

            while (p !== parentSelector && p !== document && (p.nodeName === 'svg' || p.nodeName === 'path' || !p.className?.includes(className))) {
                const o = p
                parents.push(o)
                p = o.parentNode
                // Si se cambia de pagina se pierde el nodo padre, quedando este como nulo
                if (!p) {
                    return parents
                }

            }
            if (p === parentSelector){
                parents.push(parentSelector)
            } else if (p.nodeName !== 'svg' && p.nodeName !== 'path' && p.className?.includes(className)) {
                parents.push(p)
            }
            
            return parents
        },
        nodoIsChildOf (el, parentSelector) {
            const parents = this.getParents(el, parentSelector, null, true)
            const encontrado = parents.find(parent => parent.isEqualNode(parentSelector))

            return encontrado !== undefined
        },
        parentNodeAccordingToCertainClassName (el, parentType) {
            const parents = this.getParents(el, document, parentType, true)
            const encontrado = parents.find(parent => parent.nodeName !== 'svg' && parent.nodeName !== 'path' && parent.className?.includes(parentType))
            return encontrado
        },
        buscarOrdenEnConjunto ({ idOrden, conjunto=[] }) {
            let orden = undefined
            for (let i = 0; i < conjunto.length; i++) {
                orden = conjunto[i].find(o => o.id === idOrden)
                if (orden !== undefined) break
            }
            return orden
        },
        getNodoPadreDelTarget ({ nodoTarget, padresPosibles=[], classNameLastOptionParent=null }) {
            for (let i = 0; i < padresPosibles.length; i++) {
                if (this.nodoIsChildOf(nodoTarget, padresPosibles[i])){
                    return padresPosibles[i]
                }
            }

            let contenedorTD = null
            if (classNameLastOptionParent) {
                contenedorTD = this.parentNodeAccordingToCertainClassName(nodoTarget, classNameLastOptionParent)
            }
        
            return contenedorTD ? contenedorTD: nodoTarget.parentNode
        },
        handleSingleClick (event, isDraggable=false) {
            const elementoClickeado = event.target

            // NOTA EA: No se deberia asignar este evento a los elementos que son Chofer
            //          Esta pensado solo para los elementos OV
            //  LO DE LOS CAMIONEROS ES SOLO EN LOGISTICA PERO NO AFECTA AL DE BODEGA
            if (!this.seleccionGrupo || !isDraggable || elementoClickeado.dataset.camioneroId !== undefined) {
                return
            }
            event.preventDefault()

            const BtnOvCalendar = this.parentNodeAccordingToCertainClassName(elementoClickeado, 'calendar__ov')
            const id = +BtnOvCalendar.dataset.ovId
            if (BtnOvCalendar.classList.contains("seleccionadoGrupal")) {
                //Nota EA: Remueve nodo del listado de Seleccionados
                const index = this.seleccionadosGrupal.map(e => e.id).indexOf(id);
                if (index > -1) { // only splice array when item is found
                    this.seleccionadosGrupal.splice(index, 1); // 2nd parameter means remove one item only
                }
                BtnOvCalendar.classList.remove("seleccionadoGrupal");
            } else {
                //Nota EA: Agrega nodo al listado de Seleccionados
                this.seleccionadosGrupal.push({id: id, nodo: BtnOvCalendar, tipoElementoArrastrado: 'ov'})
                BtnOvCalendar.classList.add("seleccionadoGrupal");
            }
            event.stopImmediatePropagation()

        },
        handleTargetClick (event, functionMover) {
            // functionMover debe tener los argumentos nodoTarget (node) y ovs (array)
            if (!this.seleccionGrupo || !this.hayElementosSeleccionados()) {
                return
            }
            event.preventDefault()
            
            const elementoClickeado = event.target
            const contenedorOVs = document.getElementById('contenedor-ovs')
            const contenedorTD = this.parentNodeAccordingToCertainClassName(elementoClickeado, 'contenedor')
            // El siguiente contenedor solo esta en el calendario Bodega
            const contenedorOVsSinConfirmar = document.getElementById('contenedor-ovs-sinconfirmar')
            
            // if (elementoClickeado.nodeName === 'TD') {
            if (elementoClickeado.nodeName === 'TD') {
                functionMover({ nodoTarget: elementoClickeado, ovs: this.seleccionadosGrupal })
            } else if (this.nodoIsChildOf(elementoClickeado, contenedorOVs)) {
                functionMover({ nodoTarget: contenedorOVs, ovs: this.seleccionadosGrupal })
            } else if (this.$options.name === 'CalendarioBodega' && this.nodoIsChildOf(elementoClickeado, contenedorOVsSinConfirmar)) {
                functionMover({ nodoTarget: contenedorOVsSinConfirmar, ovs: this.seleccionadosGrupal })
            } else if (contenedorTD.nodeName === 'TD') {
                functionMover({ nodoTarget: contenedorTD, ovs: this.seleccionadosGrupal })
            }
            event.stopImmediatePropagation()

        },
        limpiarSeleccionadasGrupal () {
            if (!this.seleccionGrupo && this.seleccionadosGrupal.length > 0) {
                this.seleccionadosGrupal.forEach(seleccionado => {
                    seleccionado.nodo?.classList.remove("seleccionadoGrupal")
                })
                this.seleccionadosGrupal = []
            }
        },
        hayElementosSeleccionados () {
            return this.seleccionadosGrupal.length > 0
        },
        overParaMultipleSelecccion (event){
            const elementoOver = event.target
            const contenedorTD = this.parentNodeAccordingToCertainClassName(elementoOver, 'contenedor')

            const contenedorOVs = document.getElementById('contenedor-ovs')
            const isChildContenedorOVs = this.nodoIsChildOf(elementoOver, contenedorOVs)
            
            // El siguiente contenedor solo esta en el calendario Bodega
            let isChildContenedorOVsSinConfirmar = false
            const contenedorOVsSinConfirmar = document.getElementById('contenedor-ovs-sinconfirmar')
            if (contenedorOVsSinConfirmar) {
                isChildContenedorOVsSinConfirmar = this.nodoIsChildOf(elementoOver, contenedorOVsSinConfirmar)
            }
            
            if (!this.seleccionGrupo || !this.hayElementosSeleccionados() || (!contenedorTD && !isChildContenedorOVs && !isChildContenedorOVsSinConfirmar) ) {
                return
            }
            
            const BtnOvCalendar = this.parentNodeAccordingToCertainClassName(elementoOver, 'calendar__ov')
            if (elementoOver.classList.contains("calendar__ov") || (BtnOvCalendar && BtnOvCalendar.classList.contains("calendar__ov"))) {
                
                if (isChildContenedorOVs) {
                    contenedorOVs.classList.remove('over')
                } else if (contenedorTD) {
                    contenedorTD.classList.remove('over')
                } else if (isChildContenedorOVsSinConfirmar) {
                    contenedorOVsSinConfirmar.classList.remove('over')
                }
                return
            }

            if (isChildContenedorOVs) {
                contenedorOVs.classList.add('over')
            } else if (contenedorTD) {
                contenedorTD.classList.add('over')
            } else if (isChildContenedorOVsSinConfirmar) {
                contenedorOVsSinConfirmar.classList.add('over')
            }
            
        },
        leaveParaMultipleSelecccion (event){
            const elementoLeave = event.target
            const contenedorTD = this.parentNodeAccordingToCertainClassName(elementoLeave, 'contenedor')

            const contenedorOVs = document.getElementById('contenedor-ovs')
            const isChildContenedorOVs = this.nodoIsChildOf(elementoLeave, contenedorOVs)
            
            // El siguiente contenedor solo esta en el calendario Bodega
            let isChildContenedorOVsSinConfirmar = false
            const contenedorOVsSinConfirmar = document.getElementById('contenedor-ovs-sinconfirmar')
            if (contenedorOVsSinConfirmar) {
                isChildContenedorOVsSinConfirmar = this.nodoIsChildOf(elementoLeave, contenedorOVsSinConfirmar)
            }

            if (!this.seleccionGrupo || !this.hayElementosSeleccionados() || (!contenedorTD && !isChildContenedorOVs && !isChildContenedorOVsSinConfirmar)) {
                return
            }

            if (isChildContenedorOVs) {
                contenedorOVs.classList.remove('over')
            } else if (contenedorTD) {
                contenedorTD.classList.remove('over')
            }  else if (isChildContenedorOVsSinConfirmar) {
                contenedorOVsSinConfirmar.classList.remove('over')
            }
        },
        mostrarOVOcultas (td) {
            if (!Array.isArray(td) || this.hayElementosSeleccionados()) {
                return
            }
            if (!td[0]) { return }
            // Activamos todosElementos del dataset del td
            td[0].dataset.todosElementos = 'true'
            // Lo siguiente es solo para activar el re-renderizado de los td
            this.cambioUnElementoTd = !this.cambioUnElementoTd
        },
        canSeeElement (td, index, cambioUnElementoTd) {
            const verTodosElementos = (td[0]?.dataset.todosElementos === 'true')
            
            return verTodosElementos || index <= 2
        },
        canSeeButton(td, arrayOvs) {
            if (!Array.isArray(td)) {
                return false
            }
            const verTodosElementos = (td[0]?.dataset.todosElementos === 'true')
            return !verTodosElementos && arrayOvs.length > 3
        },
        updateClassNameMultipleOvs ({ calendarId }) {
            if (this.seleccionGrupo && this.hayElementosSeleccionados()) {
                const cardCalendario = document.getElementById(calendarId)
                const ordenesVenta = [...cardCalendario.getElementsByClassName('calendar__ov')]

                this.seleccionadosGrupal.forEach( (s, idx) => {
                    const index = ordenesVenta.map(e => +e.dataset.ovId).indexOf(s.id)
                    if (index > -1) {
                        const ov = ordenesVenta[index] 
                        if (!ov.classList.contains("seleccionadoGrupal")) {
                            ov.classList.add("seleccionadoGrupal")
                            this.seleccionadosGrupal[idx].nodo = ov
                        }
                    }
                })
            }
        }

    }
}