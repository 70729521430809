<template>
  <div>
    <modal-cliente modal="modal-create-cliente" :edit="false" ref="modalCreate" title="Crear Cliente" @address="getCliente" @accept="getData();getRegistros();"></modal-cliente>
    <modal-cliente modal="modal-edit-cliente" :cliente="modalEditData" :edit="true" ref="modalEdit" title="Editar Cliente" @accept="getData();modalEditData=null;getRegistros();" v-if="modalEditData"></modal-cliente>
    <modal-direccion-despacho :cliente="modalAddressData" ref="modalAddress" :title="modalAddressData.numero" @accept="getData();modalAddressData=null;getRegistros();" v-if="modalAddressData"></modal-direccion-despacho>
    <modal-respaldo v-if='empresaId' :empresa="empresaId" ref="modalRespaldo" :cliente="true" @accept="getData();"></modal-respaldo>
    <Header :empresa-nombre="empresaNombre" :empresa-id="empresaId"></Header>
    <div class="container-fluid">
      <div class="card mb-3">
        <div class="card__header">                                  
          <h2 class="card__title">Clientes</h2>
          <div class="card__buttons">
            <a @click="openModalRespaldo()" class="button button--line-primary">
              <span class="button__text">Cargar Clientes para {{empresaNombre | capitalize}} (XLS)</span>
              <font-awesome-icon :icon="['fas', 'upload']" class="button__icon" />
            </a>
            <a @click="openModalCreate()" class="button button--primary">
              <span class="button__text">Crear Cliente</span>
              <font-awesome-icon :icon="['fas', 'plus-circle']" class="button__icon" />
            </a>
          </div>
        </div>
        <div class="card__body">
          <v-app v-if="clientes">
            <v-main>
              <v-data-table
              :headers="headers"
              :items="filteredRows"
              :items-per-page="3"
              v-bind:options.sync="pagination"
              item-key="codigo"
              class="elevation-1 table-striped"
              loading-text="Cargando Clientes"
              :footer-props="footerProps"
              mobile-breakpoint="0">
                <template v-slot:no-data>
                  <div class="alert alert--warning" role="alert">
                    <font-awesome-icon :icon="['fas', 'exclamation-circle']" class="alert__icon" />
                    <span class="alert__text">No existen clientes</span>
                  </div>
                </template>
                <template v-slot:header="{ props }">
                  <tr class="grey lighten-3">
                    <th v-for="header in props.headers" :key="header.text">
                      <div v-if="filters.hasOwnProperty(header.value)">
                        <v-autocomplete
                            flat
                            hide-details
                            multiple
                            attach
                            chips
                            dense
                            clearable
                            :items="columnValueList(header.value)"
                            v-model="filters[header.value]"
                            header-fixed
                            no-data-text="No hay información"
                        >
                            <template v-slot:selection="{ item, index }">
                                <v-chip v-if="index === 0">
                                    <span>
                                        +{{ filters[header.value].length }}
                                    </span>
                                </v-chip>
                            </template>
                        </v-autocomplete>
                      </div>
                    </th>
                  </tr>
                </template>
                <template v-slot:item.accion="{ item }">
                  <div class="btn-group" role="group" aria-label="Second group">
                    <a @click="setDataEdit(item)" class="button--sm button--primary" v-b-tooltip.hover title="Editar">
                      <font-awesome-icon :icon="['fas', 'pencil-alt']" />
                    </a>
                    <a @click="setDataAddress(item)" class="button--sm button--success" v-b-tooltip.hover title="Agregar Direcciones">
                      <font-awesome-icon :icon="['fas', 'map-marker-alt']" />
                    </a>
                    <a @click="deleteAlert(item)" class="button--sm button--danger" v-b-tooltip.hover title="Eliminar">
                      <font-awesome-icon :icon="['fas', 'trash-alt']" />
                    </a>
                  </div>
                </template>
              </v-data-table>
            </v-main>
          </v-app>
        </div>
      </div>
      <div class="card" v-if="isAdmin">
        <div class="card__header">                                  
          <h2 class="card__title">Registros</h2>
        </div>
        <div class="card__body">
          <v-app v-if="registros">
            <v-main>
              <v-data-table
                :headers="headersRegistro"
                :items="registros"
                :items-per-page="3"
                v-bind:options.sync="paginationRegistro"
                item-key="index"
                class="elevation-1 table-striped"
                loading-text="Cargando Registros"
                :footer-props="footerPropsRegistro"
                mobile-breakpoint="0">
                  <template v-slot:no-data>
                      <div class="alert alert--warning" role="alert">
                        <font-awesome-icon :icon="['fas', 'exclamation-circle']" class="alert__icon" />
                        <span class="alert__text">No existen registros</span>
                      </div>
                  </template>
                </v-data-table>
            </v-main>
          </v-app>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
import Header from '@/components/Header.vue'
import ModalCliente from '@/modals/ModalCliente.vue'
import ModalDireccionDespacho from '@/modals/ModalDireccionDespacho.vue'
import ModalRespaldo from '@/modals/ModalRespaldo.vue'


const Cliente = resource('/base/api/gestor_clientes', axios)
const Registro = resource('/base/api/ver_registros', axios)

export default {
    name: 'GestorClientes',
    components: {
        Header,
        ModalCliente,
        ModalDireccionDespacho,
        ModalRespaldo
    },
    data () {
        return {
            empresaId: this.$route.params.empresaId,
            empresaNombre: this.$route.params.empresaNombre,
            clientes: [],
            registros: [],
            currentPage: 1,
            perPage: null,
            rows: null,
            page: null,
            formProducto: {},
            pagination: {
                page: 1
            },
            paginationRegistro: {
                page: 1
            },
            footerProps: {
                'disable-items-per-page': false,
                'items-per-page-options': [10, 20, 30, -1],
                'items-per-page-text': 'Clientes por página'
            },
            footerPropsRegistro: {
                'disable-items-per-page': false,
                'items-per-page-options': [10, 20, 30, -1],
                'items-per-page-text': 'Registros por página'
            },
            filters: {
                numero: [],
                nombre: [],
                rut: [],
                razon_social: [],
                tipo_cliente: [],
                telefono: [],
                email: [],
                // region: [],
                // comuna: []
                // direccion: []
            },
            modalEditData: null,
            modalAddressData: null
        }
    },
    methods: {
        getData () {
            Cliente.query({ empresa: this.empresaId }).then((res) => {
                this.clientes = res.data
            })
        },
        getRegistros () {
            if (this.isAdmin) {
                Registro.query({ empresa: this.empresaId }).then((res) => {
                    this.registros = res.data
                })
            }
        },
        columnValueList (val) {
            var dict = []
            dict = this.clientes.map((d) => d[val])
            return dict.sort()
        },
        setDataEdit (item) {
            this.modalEditData = item
        },
        openModalEdit () {
            this.$refs.modalEdit.openModalEdit()
        },
        openModalCreate () {
            this.modalCreateVisible = true
            this.$refs.modalCreate.openModalCreate()
        },
        deleteAlert (cliente) {
            this.$swal({
                title: 'Vas a eliminar el cliente',
                text: '¿Estás seguro? Se perderá toda la información asociada',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteCliente(cliente)
                }
            })
        },
        deleteCliente(cliente) {
            Cliente.delete(cliente.id).then((res) => {
                this.getData()
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Cliente eliminado con exito'
                })
            })
                .catch((error) => {
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al eliminar el cliente'
                    })
                })
        },
        openModalAddress () {
            this.$refs.modalAddress.openModal()
        },
        setDataAddress (item) {
            this.modalAddressData = item
        },
        getCliente (value) {
            this.setDataAddress(value)
        },
        openModalRespaldo () {
            this.$refs.modalRespaldo.openModal()
        }
    },
    mounted () {
        this.getData()
        this.getRegistros()
        this.$watch('modalEditData', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null) {
                this.openModalEdit()
            }
        }, { immediate: true })
        this.$watch('modalAddressData', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null) {
                this.openModalAddress()
            }
        }, { immediate: true })
        this.$watch('isAdmin', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null) {
                this.getRegistros()
            }
        }, { immediate: true })
    },
    computed: {
        headers () {
            return [
                { text: 'Cliente', value: 'numero' },
                { text: 'Nombre Cliente', value: 'nombre' },
                { text: 'R.U.T.', value: 'rut' },
                { text: 'Razón Social', value: 'razon_social' },
                { text: 'Tipo de Cliente', value: 'tipo_cliente' },
                { text: 'Teléfono', value: 'telefono' },
                { text: 'Correo Electrónico', value: 'email' },
                // { text: 'Región', value: 'region' },
                // { text: 'Comuna', value: 'comuna' },
                // { text: 'Dirección', value: 'direccion' },
                { text: 'Acción', value: 'accion', sortable: false, align: 'center'}
            ]
        }, 
        headersRegistro () {
            return [
                { text: 'R.U.T. Cliente', value: 'cliente__rut', sortable: false },
                { text: 'Usuario', value: 'user__username', sortable: false },
                { text: 'Columna', value: 'column', sortable: false },
                { text: 'Valor Anterior', value: 'old', sortable: false },
                { text: 'Valor Actual', value: 'new', sortable: false },
                { text: 'Fecha Actualización', value: 'fechahora', sortable: false }
            ]
        },        
        filteredRows () {
            if (this.clientes) {
                return this.clientes.filter((d) => {
                    return Object.keys(this.filters).every((f) => {
                        return this.filters[f].length < 1 || this.filters[f].includes(d[f])
                    })
                })
            }
            return null
        },
        isAdmin() {
            return this.$store.state.admin
        }
    }
}
</script>
