<template>
  <div>
    <b-modal id="modal-create-mail" size="lg" title="Enviar Correo" no-close-on-esc no-close-on-backdrop hide-header-close>
      <div class="row">
        <div class="form-group col-md-12">
          <label>Observaciones (Si Aplica)</label>
          <textarea class="form-control" v-model="mail.observaciones"></textarea> 
        </div>
        <div class="form-group col-md-12">
          <label>URL de seguimiento (Si Aplica)</label>
          <input type="text" name="" class="form-control" v-model="mail.url">
        </div>
      </div>
      <template #modal-footer>
        <button class="button button--secondary" @click="closeModal()">
          <span class="button__text">Cancelar</span>
          <font-awesome-icon :icon="['fas', 'times-circle']" class="button__icon" />
        </button>
        <button class="button button--success"  @click="$emit('accept', mail);closeModal();">
          <span class="button__text">Enviar</span>
          <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
        </button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios'

export default {
    name: 'ModalMail',
    props: [],
    data () {
        return {
            mail: { observaciones: null, url: null }
        }
    },
    methods: {
        openModal () {
            this.mail = { observaciones: null, url: null }
            this.$root.$emit('bv::show::modal', 'modal-create-mail')
        },
        closeModal () {
            this.$emit('close')
            this.$root.$emit('bv::hide::modal', 'modal-create-mail')
        }
    },
    mounted () {
    }
}
</script>
