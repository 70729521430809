<!--TABLA PRESUPUESTO-->
<template>
  <div>
    <div v-if="detalles">
      <div class="table-responsive" v-for="data in detalles" v-bind:key="data.moneda">
        <p class="table__title" :id="'table_article-' + empresaNombre">Artículos en {{data.moneda}}</p>
        <table class="table">
          <thead class="thead">
            <tr id="table_title">
              <th class="thead__th">Artículo</th>
              <th class="thead__th">Cant.</th>
              <th class="thead__th" v-if="empresaNombre==='tagle'">Dcto.</th>
              <th class="thead__th">Valor Unitario</th>
              <th class="thead__th">Valor Total</th>
              <th class="thead__th" v-if="empresaNombre==='dripsa' || (empresaNombre==='tagle' && permisoAprobar)">Costo</th>
              <th class="thead__th" v-if="empresaNombre==='dripsa' || (empresaNombre==='tagle' && permisoAprobar)">Margen</th>
              <th class="thead__th" v-if="!$parent.aprobado && !$parent.$parent.cotizacion.rechazado && !$parent.$parent.cotizacion.descartado && !$parent.$parent.cotizacion.cerrado">Acción</th>
            </tr>
          </thead>
          <!-- <thead v-for="(detalle, tipo) in data.data" v-bind:key="tipo">
            TIPO DE ARTICULO--><!--text-left
            <tr id="table_typearticle">
              <td class="table__td--typearticle" colspan="8">{{tipo}}</td>
            </tr>
          </thead> -->
          <tbody class="tbody" v-for="(detalle, tipo) in data.data" v-bind:key="detalle.id">
            <tr id="table_typearticle">
              <td class="table__td--typearticle" colspan="8">{{tipo}}</td>
            </tr>
            <tr v-for="d in detalle" v-bind:key="d.id" :class="{ 'sin-codigo': d.articulo__codigo.includes('00000-') || d.por_aprobar }">
              <td class="table__td">{{d.articulo__codigo}} - {{d.articulo__descripcion}}</td>
              <td class="table__td">{{d.cantidad}} {{d.articulo__unidad}}</td>
              <td class="table__td" v-if="empresaNombre=='tagle'">
                <span v-if="d.descuento">{{d.descuento}} % </span>
                <span v-if="!d.descuento">N/A</span>
                <a @click="aprobarArticulo(d.id)" class="button--sm button--primary" v-b-tooltip.hover title="Aprobar Artículo" v-if="d.por_aprobar && permisoAprobar">
                  <font-awesome-icon :icon="['fas', 'check']" />
                </a>
              </td>
              <td class="table__td">
                <vue-numeric currency="$" separator="." v-model="d.precio_pesos" disabled size="8rem" v-if="d.precio_pesos && d.moneda !== 'CLP' && ($parent.porConfirmar || $parent.$parent.cotizacion.cerrado)" v-bind:precision="3"></vue-numeric>
                <vue-numeric currency="$" separator="." v-model="d.precio" disabled size="8rem" v-if="d.precio && (d.moneda === 'CLP' || d.moneda !== 'CLP' && !$parent.porConfirmar && !$parent.$parent.cotizacion.cerrado)" v-bind:precision="3"></vue-numeric>
                <vue-numeric currency="$" class="form-control" separator="." v-model="d.precio" :min="d.precio_lista" size="8rem" v-if="empresaNombre === 'dripsa' && d.precio_lista" v-bind:precision="3"></vue-numeric>
              </td>
              <td class="table__td">
                <vue-numeric currency="$" separator="." v-model="d.total_pesos" disabled size="8rem" v-if="d.total_pesos && d.moneda !== 'CLP' && ($parent.porConfirmar || $parent.$parent.cotizacion.cerrado)" v-bind:precision="3"></vue-numeric>
                <vue-numeric currency="$" separator="." v-model="d.total" disabled size="8rem" v-if="d.total && (d.moneda === 'CLP' || d.moneda !== 'CLP' && !$parent.porConfirmar && !$parent.$parent.cotizacion.cerrado)" v-bind:precision="3"></vue-numeric>
              </td>
              <td class="table__td" v-if="empresaNombre==='dripsa' || (empresaNombre==='tagle' && permisoAprobar)">
                <vue-numeric v-if="d.costos_pesos && d.moneda !== 'CLP' && $parent.porConfirmar" currency="$" separator="." v-model="d.costos_pesos" disabled size="8rem" v-bind:precision="3"></vue-numeric>
                <vue-numeric currency="$" separator="." v-model="d.costos" disabled size="8rem" v-else-if="d.costos && (d.moneda === 'CLP' || d.moneda !== 'CLP' && !$parent.porConfirmar)" v-bind:precision="3"></vue-numeric>
                <span v-else>-</span>
              </td>
              <td class="table__td" v-if="empresaNombre==='dripsa' || (empresaNombre==='tagle' && permisoAprobar)">
                <span v-if="d.get_margen || d.get_margen === 0">{{d.get_margen}} %</span>
                <span v-else>-</span>
              </td>
              <td class="table__td">
                <div class="btn-group" style="float:right">
                  <a class="button--sm button--secondary" @click="setDataUpload(d.articulo_data)" v-b-tooltip.hover title="Cargar documentos" v-if="d.articulo__codigo.includes('00000-') || d.articulo__variable">
                    <font-awesome-icon :icon="['fas', 'file-upload']" />
                  </a>
                  <a class="button--sm button--primary" @click="setEditData(d, true)" v-b-tooltip.hover title="Editar" v-if="!$parent.$parent.cotizacion.rechazado && !$parent.$parent.cotizacion.descartado && !$parent.$parent.cotizacion.cerrado">
                    <font-awesome-icon :icon="['fas', 'pencil-alt']" />
                  </a>
                  <a class="button--sm button--danger" @click="deleteAlert(d)" v-b-tooltip.hover title="Eliminar" v-if="!$parent.$parent.cotizacion.rechazado && !$parent.$parent.cotizacion.descartado && !$parent.$parent.cotizacion.cerrado">
                    <font-awesome-icon :icon="['fas', 'trash-alt']" />
                  </a>
                </div>
              </td>
            </tr>
        </tbody>
        </table>
        </div>
    </div>
    <div class="card--outline" v-if="!$parent.$parent.cotizacion.rechazado && !$parent.$parent.cotizacion.descartado && !$parent.$parent.cotizacion.cerrado">
        <modal-precio ref="modalCreate" @accept="getPrecio" :articulo="modalEditData" :cliente="presupuesto.tipo_cliente" v-if="modalEditData" @cancel="formDetalle.precio=null;modalEditData=null" :edit="editInfo" @acceptUpdate="setPrecio"></modal-precio>
        <div class="card__body">
            <div class="cotizacion__legend">
                <div>
                    <span class="badge badge--primary" v-if="empresaNombre === 'tagle'">Con Inventario</span>
                    <span class="badge badge--danger" v-if="empresaNombre === 'tagle'">Sin Inventario</span>
                    <span class="badge badge--success">Con Precio</span>
                    <span class="badge badge--secondary">Sin Precio</span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-7">
                    <label>Artículo*</label>
                    <multiselect class="w-100 mb-3" v-model="articuloSearch" :options="articulos" placeholder="Seleccione" label="codigo" track-by="codigo" :custom-label="labelArticulo">
                    <template slot="option" slot-scope="props">
                        <div v-if="empresaNombre === 'tagle'" class="table-legend__color" :class="{ 'table-legend__color--blue': props.option.stock_disponible, 'table-legend__color--pink': !props.option.stock_disponible }"></div>
                            <div class="table-legend__color" :class="{ 'table-legend__color--green': props.option.precio_disponible, 'table-legend__color--grey': !props.option.precio_disponible }"></div>
                            {{ props.option.codigo }} - {{ props.option.descripcion }}
                    </template>
                    </multiselect>
                </div>
                <div class="col-md-2" v-if="orderedPrecios && 'precio' in orderedPrecios">
                    <label>Cant.*</label>
                    <input type="number" min="1" name="" class="form-control" v-model="formDetalle.cantidad">
                </div>
                <div class="col-md-3">
                    <div class="alert--sm alert--primary m-0" role="alert" v-if="formDetalle.articulo && empresaNombre==='tagle'">
                        <font-awesome-icon :icon="['fas', 'info-circle']" class="alert__icon--sm" />
                        <span class="alert__text--sm">
                            Inventario Disponible: <b>{{formDetalle.articulo.stock_disponible}}</b> <br>
                            Moneda: <b>{{formDetalle.articulo.moneda}}</b> <br>
                            Tipo: <b>{{formDetalle.articulo.unidad__abreviatura}}</b> <br></span>
                            <!-- {{formDetalle.articulo.codigo}} - {{formDetalle.articulo.descripcion}}  -->
                    </div>
                </div>
                <div class="col-md-7" v-if="empresaNombre === 'tagle'">
                    <div v-if="formDetalle.articulo && orderedPrecios && !formDetalle.articulo.variable">
                        <input class="mr-2" type="radio" id="precio" :value="true" v-model="precioDescuento" />
                        <label for="precio">Precio</label>
                        <input class="ml-5 mr-2" type="radio" id="Descuento" :value="false" v-model="precioDescuento" />
                        <label for="Descuento">Descuento</label>
                    </div>
                    <label>Precio</label>
                    <span v-if="orderedPrecios && formDetalle.articulo && !formDetalle.articulo.variable"> lista *
                        <vue-numeric style="font-weight:700" currency="$" disabled separator="." v-model="orderedPrecios.precio" size="5rem" v-if="orderedPrecios" v-bind:precision="3"></vue-numeric>
                    </span>
                    <span v-if="presupuesto.tipo_cliente === 'Agrícola' && orderedPrecios && orderedPrecios.dcto_agricola">
                        Dcto. tope: <strong>{{orderedPrecios.dcto_agricola}} %</strong>
                    </span>
                    <span v-if="presupuesto.tipo_cliente === 'Distribuidor' && orderedPrecios && orderedPrecios.dcto_distribuidor">
                        Dcto. tope: <strong>{{orderedPrecios.dcto_distribuidor}} %</strong>
                    </span>
                    <a @click="setEditData(formDetalle.articulo, false)" class="btn btn-primary btn-sm" v-show="(formDetalle.articulo && !orderedPrecios) || (formDetalle.articulo && formDetalle.articulo.variable)">
                        Agregar Precio<font-awesome-icon class="button__icon--sm" :icon="['fas', 'plus-circle']" />
                    </a>
                    <percent-input :key="keyPercent" v-model="descuento" class="form-control" v-bind="{ integerPlaces: 2, decimalPlaces: 2, suffix: true }" v-if="formDetalle && formDetalle.articulo && !formDetalle.articulo.variable && orderedPrecios && !precioDescuento"></percent-input>
                    <label v-if="formDetalle && formDetalle.articulo && !formDetalle.articulo.variable && orderedPrecios && !precioDescuento">
                        <small>
                            <vue-numeric currency="$" separator="." v-model="formDetalle.precio" size="8rem" disabled v-bind:precision="3"></vue-numeric>
                        </small>
                    </label>
                    <vue-numeric currency="$" separator="." v-model="precio" size="8rem" v-if="precioDescuento" v-bind:precision="3" class="form-control"></vue-numeric>
                    <percent-input :key="keyDescuento" v-model="descuento" disabled v-bind="{ integerPlaces: 2, decimalPlaces: 2, suffix: true }" v-if="precioDescuento"></percent-input>
                    <br v-if="error">
                    <input v-if="orderedCostos && orderedCostos.costos" v-model="orderedCostos.costos" disabled hidden>
                </div>
                <div class="col-md-5" v-if="empresaNombre === 'tagle'">
                    <div class="alert--sm alert--danger m-0" role="alert" v-if="error">
                        <font-awesome-icon :icon="['fas', 'exclamation-circle']" class="alert__icon--sm" />
                        <span class="alert__text--sm">{{error}}</span>
                    </div>
                </div>
                <div class="col-md-7" v-if="empresaNombre === 'dripsa'">
                    <label>Precio</label>
                    <span v-if="orderedPrecios && formDetalle.articulo && !formDetalle.articulo.variable">mínimo *
                        <vue-numeric style="font-weight:700" currency="$" disabled separator="." v-model="orderedPrecios.precio" size="5rem" v-bind:precision="3"></vue-numeric>
                    </span>
                    <a @click="setEditData(formDetalle.articulo)" class="btn btn-primary btn-sm" v-show="(formDetalle.articulo && !orderedPrecios) || (formDetalle.articulo && formDetalle.articulo.variable) && !formDetalle.precio">
                        Agregar Precio<font-awesome-icon class="button__icon--sm" :icon="['fas', 'plus-circle']" />
                    </a>
                    <vue-numeric v-if="orderedPrecios && !formDetalle.articulo.variable" currency="$" class="form-control" separator="." v-model="formDetalle.precio" size="8rem" :min="orderedPrecios.precio" v-bind:precision="3"></vue-numeric>
                    <!-- <vue-numeric currency="$" separator="." v-model="formDetalle.precio" size="8rem" disabled></vue-numeric> -->
                    <input v-if="orderedCostos && orderedCostos.costos" v-model="orderedCostos.costos" disabled hidden>
                </div>
                <div class="col-md-5" v-if="empresaNombre === 'dripsa'">
                    <div class="alert--sm alert--danger m-0" role="alert" v-if="error">
                        <font-awesome-icon :icon="['fas', 'exclamation-circle']" class="alert__icon--sm" />
                        <span class="alert__text--sm">{{error}}</span>
                    </div>
                </div>
                <div class="col-md-12">
                    <button @click="alertaStock()" class="button button--primary w-100 m-0" :disabled="formDetalle.precio===undefined || !formDetalle.precio">
                        <span class="button__text">Agregar artículo a presupuesto</span>
                        <font-awesome-icon class="button__icon" :icon="['fas', 'plus-circle']" /> 
                    </button>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import resource from 'resource-axios'
import _ from 'lodash'
import ModalPrecio from '@/modals/ModalPrecio.vue'

const Articulo = resource('/cotizacion/api/gestor_articulos', axios)
const DetallePresupuesto = resource('/cotizacion/api/gestor_detalle_presupuestos/', {
    update: (id, params) => axios.put(`/cotizacion/api/gestor_detalle_presupuestos/${id}/`, { params }),
    delete: (id) => axios.delete(`/cotizacion/api/gestor_detalle_presupuestos/${id}/`),
    aprobar: (id) => axios.put(`/cotizacion/api/gestor_detalle_presupuestos/${id}/aprobar_articulo/`)
}, axios)

export default {
    name: 'DetallePresupuesto',
    props: ['presupuesto', 'articulos'],
    components: {
        ModalPrecio
    },
    data () {
        return {
            empresaNombre: this.$route.params.empresaNombre,
            detalles: [],
            // articulos: [],
            precios: [],
            formDetalle: {},
            modalEditData: null,
            monedas: [],
            error: null,
            detalleAnterior: null,
            keyPercent: 0,
            discountUp: false,
            articuloSearch: null,
            key: 0,
            descuento: null,
            precioDescuento: false,
            precio: null,
            keyDescuento: 0,
            editInfo: false
        }
    },
    methods: {
        getData () {
            DetallePresupuesto.query({ presupuesto: this.presupuesto.id }).then((res) => {
                var monedas = _.groupBy(res.data,'moneda')
                this.detalles = []
                for (var moneda in monedas) {
                    var dictmoneda = {}
                    dictmoneda['moneda'] = moneda
                    dictmoneda['data'] = _.groupBy(monedas[moneda], 'articulo__tipo')
                    this.detalles.push(dictmoneda)
                }
            })
        },
        // getArticulo () {
        //     Articulo.query({ empresa: this.$parent.$parent.empresaId, moneda: this.presupuesto.moneda }).then((res) => {
        //         this.articulos = res.data
        //     })
        // },
        alertaStock (modal) {
            var stock = this.formDetalle.articulo.stock_disponible
            var cantidad = parseInt(this.formDetalle.cantidad)
            if (stock === 0 || stock === 'N/A' || !stock || cantidad > stock){
                this.$swal({
                    title: 'La cantidad seleccionada es mayor al stock disponible',
                    text: '¿Desea continuar?',
                    showCancelButton: true,
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Aceptar'
                    
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.createDetallePresupuesto(modal)
                    }
                })
            } else {
                this.createDetallePresupuesto(modal)
            }
        },
        createDetallePresupuesto (modal) {
            if (this.error) {
                this.formDetalle.por_aprobar = true
                if (!this.formDetalle.datos) {
                    this.formDetalle.datos = {}
                }
                this.formDetalle.datos.por_aprobar = true
                this.error = null
            }
            if (this.orderedPrecios && 'precio' in this.orderedPrecios) {
                this.formDetalle.precio_lista = this.orderedPrecios.precio
                if (this.presupuesto.tipo_cliente === 'Agrícola' && this.orderedPrecios.dcto_agricola)
                    this.formDetalle.descuento_tope = this.orderedPrecios.dcto_agricola
                else if (this.presupuesto.tipo_cliente === 'Distribuidor' && this.orderedPrecios.dcto_distribuidor)
                    this.formDetalle.descuento_tope = this.orderedPrecios.dcto_distribuidor
            }

            this.formDetalle.presupuesto = this.presupuesto.id
            this.formDetalle.moneda = this.formDetalle.articulo.moneda
            this.formDetalle.articulo = this.formDetalle.articulo.id
            if (!modal) {
                if (this.formDetalle.moneda !== 'CLP') {
                    if (typeof(this.formDetalle.precio) != 'string')
                        this.formDetalle.precio = this.formDetalle.precio.toFixed(3)
                    if (typeof(this.formDetalle.precio_lista) != 'string')
                        this.formDetalle.precio_lista = this.formDetalle.precio_lista.toFixed(3)
                    if (typeof(this.formDetalle.costos) != 'string')
                        this.formDetalle.costos = this.formDetalle.costos.toFixed(3) 
                }
                else {
                    this.formDetalle.precio = Math.round(this.formDetalle.precio)
                    this.formDetalle.precio_lista = Math.round(this.formDetalle.precio_lista)
                    this.formDetalle.costos = Math.round(this.formDetalle.costos)
                }
            }
            DetallePresupuesto.save(this.formDetalle).then((res) => {
                this.formDetalle = {}
                this.getData()
                this.$emit('accept')
                this.$parent.$emit('accept')
                this.articuloSearch = null
                this.forceRerenderDescuento()
                this.precio = null
                this.descuento = null
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Detalle creado con exito'
                })
            })
                .catch((error) => {
                    this.formDetalle = {}
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al crear el detalle'
                    })
                })
        },
        deleteAlert (detalle) {
            this.$swal({
                title: 'Vas a eliminar el Artículo',
                text: '¿Estás seguro? Se perderá toda la información asociada',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteDetalle(detalle)
                }
            })
        },
        deleteDetalle(detalle) {
            DetallePresupuesto.delete(detalle.id).then((res) => {
                this.getData()
                this.$emit('accept')
                this.$parent.$emit('accept')
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: ' Artículo eliminado con exito'
                })
            })
                .catch((error) => {
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al eliminar el  artículo'
                    })
                })
        },
        editDetalle (detalle) {
            DetallePresupuesto.update(detalle.id, {
                id: detalle.id,
                cantidad: detalle.cantidad,
                articulo: detalle.articulo,
                moneda: detalle.moneda,
                precio: detalle.precio,
                costos: detalle.costos,
                presupuesto: detalle.presupuesto,
                descuento: detalle.descuento,
                por_aprobar: detalle.por_aprobar
            }).then((res) => {
                this.getData()
                this.$emit('accept')
                this.$parent.$emit('accept')
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Detalle editado con exito'
                })
            })
                .catch((error) => {
                    this.formDetalle = {}
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al editar el detalle'
                    })
                })
        },
        setEditData (item, edit) {
            this.editInfo = edit
            this.modalEditData = item
        },
        openModalCreate () {
            this.$refs.modalCreate.openModalCreate()
        },
        getPrecio (value) {
            this.formDetalle.articulo = this.modalEditData
            this.formDetalle.precio = value.precio
            this.formDetalle.costos = value.costo
            this.formDetalle.precio_lista = value.precio_lista
            this.formDetalle.descuento_tope = value.descuento_tope
            this.formDetalle.descuento = value.descuento
            this.formDetalle.cantidad = value.cantidad
            this.error = value.error
            this.modalEditData = null
            if (this.formDetalle.cantidad && this.formDetalle.precio)
                this.createDetallePresupuesto(true)
            this.getData()
        },
        setPrecio (value) {
            var articuloEditar = {}
            articuloEditar.id = value.id
            articuloEditar.articulo = value.articulo
            articuloEditar.moneda = value.moneda
            articuloEditar.presupuesto = value.presupuesto
            articuloEditar.precio = value.precio
            articuloEditar.descuento = value.descuento
            articuloEditar.cantidad = value.cantidad
            articuloEditar.descuento_tope = value.descuento_tope
            articuloEditar.precio_lista = value.precio_lista
            this.modalEditData = null
            this.editDetalle(articuloEditar)
        },
        // myFilter: (option, label, search) => {
        //     // console.log('aaaaa')
        //     // console.log(option)
        //     let temp = search.toUpperCase()
        //     console.log(option.descripcion.toUpperCase().indexOf(temp))
        //     return option.descripcion.toUpperCase().indexOf(temp) > -1 || 
        //     option.codigo.toUpperCase().indexOf(temp) > -1
        // },
        labelArticulo (obj) {
            return `${obj.codigo} - ${obj.descripcion}`
        },
        setDataUpload (item) {
            this.$parent.setDataUpload(item)
        },
        downloadPdf (moneda) {
            axios({
                url: '/cotizacion/api/gestor_cotizaciones/' + this.presupuesto.cotizacion +'/pdf_cotizacion/?moneda=' + moneda + '&presupuesto=' + this.presupuesto.id,
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))
                var fileLink = document.createElement('a')

                fileLink.href = fileURL
                window.open(fileURL)
            })
                .catch((error) => {
                    if (error.response.status === 401) {
                        this.getData()
                        this.downloadPdf(moneda)
                    }
                })
        },
        downloadXls (moneda) {
            axios({
                url: '/cotizacion/api/gestor_cotizaciones/' + this.presupuesto.cotizacion +'/xls_cotizacion/?moneda=' + moneda + '&presupuesto=' + this.presupuesto.id,
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}))
                var fileLink = document.createElement('a')

                fileLink.href = fileURL
                window.open(fileURL)
            })
                .catch((error) => {
                    if (error.response.status === 401) {
                        this.getData()
                        this.downloadXls(moneda)
                    }
                })
        },
        aprobarArticulo (detalle) {
            DetallePresupuesto.aprobar(detalle).then((res) => {
                this.getData()
                this.$emit('reset')
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Detalle Aprobado'
                })
            })
                .catch((error) => {
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al aprobar el detalle'
                    })
                })
        },
        forceRerenderPercent() {
            this.keyPercent += 1;
        },
        forceRerenderDescuento() {
            this.keyDescuento += 1;
        }
    },
    watch: {
        descuento () {
            this.formDetalle.descuento = this.descuento
            if (this.orderedPrecios) {
                if ((this.presupuesto.tipo_cliente === 'Agrícola' && 'dcto_agricola' in this.orderedPrecios) && this.orderedPrecios.dcto_agricola < this.formDetalle.descuento) {
                    this.error = 'ATENCIÓN: el descuento es mayor al tope y necesita aprobación.'
                } else if ((this.presupuesto.tipo_cliente === 'Distribuidor' && 'dcto_distribuidor' in this.orderedPrecios) && this.orderedPrecios.dcto_distribuidor < this.formDetalle.descuento) {
                    this.error = 'ATENCIÓN: el descuento es mayor al tope y necesita aprobación.'
                }
                else {
                    this.error = null
                }
                if (!this.precio) {
                    var precioCalculado = this.orderedPrecios.precio - (this.orderedPrecios.precio * this.formDetalle.descuento/100)
                    this.formDetalle.precio = precioCalculado
                }
            }
        },
        precio () {
            this.formDetalle.precio = this.precio
            if (this.orderedPrecios) {
                var precio_lista = this.orderedPrecios.precio
                var dctoCalculado = Math.round((100 - ((100*this.formDetalle.precio)/precio_lista)))
                this.descuento = dctoCalculado
                this.forceRerenderDescuento()
            }
        },

        precioDescuento () {
            this.error = null
            this.formDetalle.precio = null
            this.precio = null
            this.formDetalle.descuento = null
            this.descuento = null
            this.forceRerenderDescuento()
            this.forceRerenderPercent()
        }
    },
    mounted () {
        this.getData()
        this.$watch('modalEditData', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null && this.modalEditData !== null) {
                this.openModalCreate()
            }
        }, { immediate: true })
        this.$watch('orderedCostos.costo', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== undefined && newLocale !== null && this.formDetalle) {
                this.formDetalle.costos = newLocale
            }
        }, { immediate: true })
        // this.$watch('formDetalle.articulo', (newLocale, oldLocale) => {
        //     this.discountUp = false
        //     if (newLocale !== oldLocale && newLocale !== undefined && newLocale !== null && oldLocale !== undefined) {
        //         this.formDetalle.descuento = 0.0
        //         this.forceRerenderPercent()
        //         this.formDetalle.cantidad = 1
        //         if (this.orderedPrecios)
        //             this.formDetalle.precio = this.orderedPrecios.precio
        //         else
        //             this.formDetalle.precio = 0
        //     }
        // }, { immediate: true })
        // this.$watch('discountUp', (newLocale, oldLocale) => {
        //     if (newLocale !== oldLocale && newLocale !== undefined && newLocale !== null && oldLocale !== undefined && !this.editar) {
        //         if (newLocale) {
        //             this.formDetalle.precio = this.orderedPrecios.precio + (this.orderedPrecios.precio * this.formDetalle.descuento/100)
        //         } else {
        //             this.formDetalle.precio = this.orderedPrecios.precio - (this.orderedPrecios.precio * this.formDetalle.descuento/100)
        //         }
        //     }
        // }, { immediate: true })
        this.$watch('articuloSearch', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== undefined && newLocale !== null && oldLocale !== undefined) {
                if (newLocale) {
                    this.precioDescuento = false
                    this.discountUp = false
                    Articulo.query({ id: newLocale.id }).then((res)=> {
                        this.formDetalle.articulo = res.data.items[0]
                        this.key++
                        this.formDetalle.descuento = 0.0
                        this.forceRerenderPercent()
                        this.formDetalle.cantidad = 1
                        if (this.orderedPrecios)
                            this.formDetalle.precio = this.orderedPrecios.precio
                        else
                            this.formDetalle.precio = 0
                    })
                } else {
                    this.formDetalle.articulo = null
                }
            }
        }, { immediate: true })
    },
    computed: {
        orderedPrecios: function () {
            this.key
            var today = new Date()
            var month = today.getMonth() + 1
            var date = today.getDate() + '/' + month + '/' + today.getFullYear()
            var sortedPrecio = {}
            if (this.formDetalle.articulo && this.formDetalle.articulo.precio) {
                // var newPrecio = this.formDetalle.articulo.precio.filter((item) => {
                //     return item['tipocliente'] === this.presupuesto.tipo_cliente
                // })
                sortedPrecio = _.sortBy(this.formDetalle.articulo.precio, o => {
                    let date = o.fechaactualizacion.split('/')
                    new Date(date[2], date[1], date[0])
                }).reverse()[0]
                if (this.formDetalle.articulo.variable && sortedPrecio) {
                    if (('agregado' in sortedPrecio && !sortedPrecio['agregado']) || !('agregado' in sortedPrecio)) {
                        return {}
                    }
                }
                let formatedPrecio = sortedPrecio ? parseFloat(sortedPrecio.precio) : 0
                if (sortedPrecio === undefined) {
                    sortedPrecio = {}
                }
                sortedPrecio.precio = formatedPrecio
                return sortedPrecio
            }
            return null
        },
        orderedCostos: function () {
            this.key
            var today = new Date()
            var month = today.getMonth() + 1
            var date = today.getDate() + '/' + month + '/' + today.getFullYear()
            var sortedCosto = {}
            if (this.formDetalle.articulo && this.formDetalle.articulo.costos) {
                sortedCosto = _.sortBy(this.formDetalle.articulo.costos, o => {
                    let date = o.fechaactualizacion.split('/')
                    new Date(date[2], date[1], date[0])
                }).reverse()[0]
                if (this.formDetalle.articulo.variable && sortedCosto) {
                    if (('agregado' in sortedCosto && !sortedCosto['agregado']) || !('agregado' in sortedCosto)) {
                        return {}
                    }
                }
                let formatedCosto = sortedCosto ? parseFloat(sortedCosto.costo) : 0
                if (sortedCosto === undefined) {
                    sortedCosto = {}
                }
                sortedCosto.costo = formatedCosto 
                return sortedCosto
            }
            return {}
        },
        permisoAprobar () {
            return this.$store.state.permiso_aprobar
        }
    }
}
</script>
