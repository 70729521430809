<template>
    <nav class="navbar navbar-expand-lg">
        <modal-password :user="user" ref="modalPassword"></modal-password>
        <div class="container-fluid">
            <router-link class="navbar-brand" :to="baseUrl">
                <img class="navbar-brand__img" :src="'/img/' + empresaNombre + '.png'">
            </router-link>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <font-awesome-icon :icon="['fas', 'bars']" />
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav ml-auto d-none d-md-flex">
                    <li class="nav-item">
                        <router-link class="nav-link" :to="'/'">
                            <font-awesome-icon :icon="['fas', 'home']" class="button__icon--left" />Inicio
                        </router-link>
                    </li>
                    <li class="nav-item" v-if="!isBodega && !isLogistica">
                        <router-link :class="[currentPage === '/' + empresaId + '/' + empresaNombre ? activeClass: '', 'nav-link']" :to="baseUrl">
                            <font-awesome-icon :icon="['fas', 'file-invoice-dollar']" class="button__icon--left" />Cotizaciones {{empresaNombre | capitalize}}
                        </router-link>
                    </li>
                    <div class="d-flex" v-if="isAdmin || isDigitador || permisoBodega() || permisoLogistica()">
                        <li class="nav-item" v-if="isAdmin || isDigitador">
                            <router-link class="nav-link" :to="baseUrl + '/gestor_articulos'" :class="empresaNombre">
                                <font-awesome-icon :icon="['fas', 'tools']" class="button__icon--left" />Artículos
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="isAdmin || isDigitador">
                            <router-link class="nav-link" :to="baseUrl + '/gestor_clientes'" :class="empresaNombre">
                                <font-awesome-icon :icon="['fas', 'users']" class="button__icon--left" />Clientes
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="permisoBodega()">
                            <router-link class="nav-link" :to="baseUrl + '/bodega'" :class="empresaNombre">
                                <font-awesome-icon :icon="['fas', 'warehouse']" class="button__icon--left" />Bodega
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="permisoLogistica()">
                            <router-link class="nav-link" :to="baseUrl + '/logistica'" :class="empresaNombre">
                                <font-awesome-icon :icon="['fas', 'shipping-fast']" class="button__icon--left" />Logística
                            </router-link>
                        </li>
                    </div>
                    <li class="nav-item dropdown" >
                        <a data-toggle="dropdown" class="nav-link dropdown-toggle" style="cursor: pointer;" aria-haspopup="true" aria-expanded="false">
                            <font-awesome-icon :icon="['fas', 'user']" class="button__icon--left" />
                            <span v-if="user">{{user.get_nombre_completo}}</span>
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <li class="nav-item">
                                <a class="nav-link" @click="cambiarPassword()" style="cursor: pointer;">Cambiar Contraseña</a>
                                <router-link to='/login?logout' class="nav-link nav-link-exit" style="cursor: pointer;">Cerrar Sesión</router-link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>
<script>

import ModalPassword from '@/modals/ModalPassword.vue'

export default {
    name: 'Header',
    props: ['empresaNombre', 'empresaId'],
    components: {
        ModalPassword
    },
    data () {
        return {
            activeClass: 'nav-link--active'
        }
    },
    computed: {
        currentPage () {
            return this.$route.path
        },
        user () {
            return this.$store.state.user
        },
        baseUrl () {
            return `/${this.empresaId}/${this.empresaNombre}`
        },
        isAdmin () {
            return this.$store.state.admin
        },
        isDigitador () {
            return this.$store.state.digitador
        },
        isBodega () {
            return this.$store.state.bodega
        },
        isLogistica () {
            return this.$store.state.logistica
        }
    },
    methods: {
        cambiarPassword() {     
            this.$refs.modalPassword.openModal()
        },
        permisoBodega () {
            return this.$store.state.permiso_ver_bodega
        },
        permisoLogistica () {
            return this.$store.state.permiso_ver_logistica
        }
    },
    mounted () {
    }
}
</script>
