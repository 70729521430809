<template>
  <div>
    <Header :empresa-nombre="empresaNombre" :empresa-id="empresaId"></Header>
    <div class="container-fluid">
      <div class="card" id="card-calendario-logistico">
        <div class="card__header">
          <h4 class="card__title">Camiones (vista completa)</h4>
        </div>
        <div class="card__body">
          <span class="calendar__legend">
            <span class="calendar__legend--span">
              <h6>Despachada</h6>
              <font-awesome-icon class="despachada" :icon="['fa', 'check-circle']" />
            </span>
            <span class="calendar__legend--span">
              <h6>Entregada</h6>
              <font-awesome-icon class="entregada" :icon="['fa', 'check-circle']" />
            </span>
            <span class="calendar__legend--span">
              <h6>No entregada</h6>
              <font-awesome-icon class="no-entregada" :icon="['fa', 'times-circle']" />
            </span>
            <span class="calendar__legend--span">
              <h6>Entregada con Errores</h6>
              <font-awesome-icon class="entregada-error" :icon="['fa', 'times-circle']" />
            </span>
            <span class="calendar__legend--span">
              <h6>Noficado próximo a despacho</h6>
              <font-awesome-icon class="proximo-despacho" :icon="['fa', 'info-circle']" />
            </span>
            <span class="calendar__legend--span">
              <h6>No Entregada Cliente</h6>
              <font-awesome-icon class="no-entregada-cliente" :icon="['fa', 'minus-circle']" />
            </span>
            <span class="calendar__legend--span">
              <h6>Entregada con Errores Cliente</h6>
              <font-awesome-icon class="entregada-error-cliente" :icon="['fa', 'minus-circle']" />
            </span>
          </span>
          <div class="table-responsive">
            <table class="table table-bordered" v-if="semana">
              <thead class="thead">
                <tr>
                  <th class="thead__th" style="padding:5px !important;" scope="col" colspan="3">
                    <div class="calendar__th--view">
                      <button class="button button--primary m-0" @click="cambiarSemana('anterior')" type="button">
                      <font-awesome-icon :icon="['fas', 'arrow-left']" />
                    </button>
                    <button class="button button--primary m-0" @click="cambiarSemana('proxima')" type="button">
                      <font-awesome-icon :icon="['fas', 'arrow-right']" />
                    </button>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="dia in Object.keys(semana)" :key="dia">
                  <td style="min-width:100px; width:100px; max-width:150px !important; padding:5px">
                    <span class="calendar__td--log-view">{{semana[dia].date | formatDatePersonalized}}</span>
                  </td>
                  <td class="d-fle"  style=" padding:5px;" :class="getBgColor(semana[dia].date)" :data-fecha="semana[dia].formattedDate">
                    <button class="calendar__ov mx-1" v-for="orden in getOVParaDia(semana[dia].formattedDate)" :key="orden.id" :class="getClassRegion(orden.region, orden.estados)" :data-ov-id="orden.id">
                      
                      <font-awesome-icon v-if="orden.estados.despachado && !orden.estados.entregado && !orden.estados.entregado_con_errores && !orden.estados.no_entregado" class="despachada" :icon="['fa', 'check-circle']" />

                      <font-awesome-icon v-else-if="orden.estados.despachado && orden.estados.entregado" class="entregada" :icon="['fa', 'check-circle']" />

                      <font-awesome-icon v-else-if="orden.estados.no_entregado && !orden.estados.entregado" class="no-entregado" :icon="['fa', 'times-circle']" />

                      <font-awesome-icon v-else-if="orden.estados.entregado_con_errores && !orden.estados.entregado" class="entregada-error" :icon="['fa', 'times-circle']" />

                      <font-awesome-icon v-else-if="orden.estados.mensaje_por_despachar" class="proximo-despacho" :icon="['fa', 'info-circle']" />

                      <font-awesome-icon v-if="orden.estados.no_entregado_cliente" class="no-entregada-cliente" :icon="['fa', 'minus-circle']" />

                      <font-awesome-icon v-if="orden.estados.entregado_con_errores_cliente" class="entregada-error-cliente" :icon="['fa', 'minus-circle']" />


                      {{orden.numero}} | {{orden.detalles.id_embarque}} | {{getFechaBodega(orden)}}
                      <font-awesome-icon v-if="getClassStockOrden(orden)" :class="getClassStockOrden(orden)" :icon="['fa', 'circle']" />
                      <span class="calendar__span--sin--completar" v-if="orden.estados.count_stock && orden.estados.count_stock > 0 && 'total_stock' in orden.estados"> {{orden.estados.total_stock}}%</span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <b-modal ref="my-modal" size="xl" hide-footer :title="`${this.infoModal.numero} - ${this.infoModal.region}`">
        <div class="card__buttons" style="align-item:center">
            <h4 class="card__subtitle mb-0">Nº Cotización:</h4>
            <span class="badge badge--primary">{{this.infoModal.numero_cotizacion}}</span>
            <h4 class="card__subtitle mb-0">Fecha Orden:</h4>
            <span class="badge badge--secondary">{{this.infoModal.fechaOrden}}</span>
        </div>
        <div class="d-flex flex-column">
            <div class="d-flex">
                <table class="table">
                    <thead class="thead">
                        <tr>
                            <th colspan="2">Detalle</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th class="card__subtitle" colspan="2">DATOS DE LA VENTA</th>
                        </tr>
                        <tr>
                            <td class="table__td">Cliente</td>
                            <td class="table__td">{{infoModal.detalles.numero_cliente}} - {{infoModal.detalles.nombre_cliente}}</td>
                        </tr>
                        <tr>
                            <td class="table__td">Correo Electrónico</td>
                            <td class="table__td">{{infoModal.detalles.email}}</td>
                        </tr>
                        <tr>
                            <td class="table__td">Tipo de Despacho</td>
                            <td class="table__td">{{infoModal.detalles.despacho}}</td>
                        </tr>
                        <tr>
                            <td class="table__td">Forma de Pago</td>
                            <td class="table__td">{{infoModal.detalles.forma_pago}}</td>
                        </tr>
                        <tr>
                            <td class="table__td">Vendedor</td>
                            <td class="table__td">{{infoModal.detalles.nombre_vendedor}}</td>
                        </tr>
                        <tr>
                            <td class="table__td">Orden Compra</td>
                            <td class="table__td">{{infoModal.detalles.orden_compra}}</td>
                        </tr>
                        <tr>
                            <td class="table__td">Factura</td>
                            <td class="table__td">{{infoModal.detalles.factura}}</td>
                        </tr>
                        <tr>
                            <th class="card__subtitle" colspan="2">DATOS DEL DESPACHO</th>
                        </tr>
                        <tr>
                            <td class="table__td">LAT, LNG</td>
                            <td class="table__td">{{infoModal.detalles.lat}}<b>,</b> {{infoModal.detalles.long}}</td>
                        </tr>
                        <tr>
                            <td class="table__td">ID de Embarque</td>
                            <td class="table__td">{{infoModal.detalles.id_embarque}}</td>
                        </tr>
                        <tr>
                            <td class="table__td">Región</td>
                            <td class="table__td">{{infoModal.detalles.region}}</td>
                        </tr>
                        <tr>
                            <td class="table__td">Comuna</td>
                            <td class="table__td">{{infoModal.detalles.comuna}}</td>
                        </tr>
                        <tr>
                            <td class="table__td">Ciudad</td>
                            <td class="table__td">{{infoModal.detalles.ciudad}}</td>
                        </tr>
                        <tr>
                            <td class="table__td">Dirección</td>
                            <td class="table__td">{{infoModal.detalles.direccion}}</td>
                        </tr>
                        <tr>
                            <td class="table__td">Observaciones</td>
                            <td class="table__td">{{infoModal.detalles.observaciones}}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="table">
                    <thead>
                        <tr class="thead">
                            <th colspan="6">Articulos</th>
                        </tr>
                        <tr>
                            <th class="thead__th" scope="col">Item</th>
                            <th class="thead__th" scope="col" v-if="!infoModal.fechaActualizada">Orden</th>
                            <th class="thead__th" scope="col" v-if="!infoModal.fechaActualizada">Stock</th>
                            <th class="thead__th" scope="col" v-if="!infoModal.fechaActualizada">Sin Stock</th>
                            <th class="thead__th" scope="col" v-if="!infoModal.fechaActualizada">Lista a Envío</th>
                            <th class="thead__th" scope="col" v-if="!infoModal.fechaActualizada">Despachada</th>
                            <th class="thead__th" scope="col" v-if="infoModal.fechaActualizada">Despachada</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(articulo, index) in infoModal.articulos" :key="index">
                            <td class="table__td">
                                <strong>{{articulo.codigo}}</strong> - {{articulo.descripcion}}
                            </td>
                            <td class="table__td" v-if="!infoModal.fechaActualizada">
                                {{articulo.orden}}
                            </td>
                            <td class="table__td" v-if="!infoModal.fechaActualizada">
                                {{articulo.stock}}
                            </td>
                            <td class="table__td" v-if="!infoModal.fechaActualizada">
                                {{articulo.sin_stock}}
                            </td>
                            <td class="table__td" v-if="!infoModal.fechaActualizada">
                                {{articulo.lista_envio}}
                            </td>
                            <td class="table__td" v-if="!infoModal.fechaActualizada">
                                {{articulo.despachada}}
                            </td>
                            <td class="table__td" v-if="infoModal.fechaActualizada">
                                {{articulo.cantidad_factura}} {{articulo.um}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </b-modal>
</div>
</template>

<script>
import axios from 'axios'
import Header from '@/components/Header.vue'

import { utils } from '@/logistica/utils.js'

let elementoArrastrandose = null

export default {
    mixins: [utils],
    components: { Header },
    data () {
        return {
            empresaId: this.$route.params.empresaId,
            empresaNombre: this.$route.params.empresaNombre,
            ordenes: [],
            ordenesNoEntregadas: [],
            ordenesConCamion: [],
            ordenesTabla: [],
            ordenesSeleccionadas: [],
            ordenesSeleccionadasProximas: [],
            fechaActual: new Date(new Date().setHours(0,0,0,0)),
            monday: this.getMonday(),
            semana: null,
            inicializados: false,
            infoModal: {
                registros: [],
                numero_cotizacion: null,
                numero: null,
                region: null,
                detalles: {},
                articulos: [],
            },
            regiones: [],
            regionesSeleccionadas: [],
            lastIndexCamion: 0,
            minIdCamion: 1,
        }
    },
    methods: {
        getOVConCamion () {
            axios.get('/logistica/api/ordenes_venta/', {params: { camion__isnull: false }}).then(res => {
                res.data.forEach(ov => {
                    if (!ov.estados.despachado) ov.seleccionable = false
                })
                this.ordenesConCamion = res.data
                // console.log('%c ordenes con camion', 'color:green', this.ordenesConCamion)
                this.getOrdenesTabla(res.data)
            })
        },
        getOrdenesTabla (ordenes) {
            this.ordenesTabla = ordenes.map(ov => {
                return {
                    id: ov.id,
                    numero: ov.numero,
                    diffDia: this.getDiffDays(ov),
                    despachado: ov.estados.despachado
                }
            })

            // this.ordenesTabla = this.ordenesTabla.filter(ov => ov.diffDia >= 0)
            this.ordenesTabla = this.ordenesTabla.sort((a, b) => a.diffDia - b.diffDia)
        },
        getMonday () {
            const date = new Date()
            const day = date.getDay()
            // last
            let diff = date.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is sunday
            return new Date(date.setDate(diff))
            // return this.$options.filters.formatDateTimeSlug(monday)
        },
        getSemana () {
            this.semana = {
                Lunes: { date: this.monday, formattedDate: this.$options.filters.formatDateTimeSlug(this.monday) },
                Martes: { date: this.nextDay(1), formattedDate: this.$options.filters.formatDateTimeSlug(this.nextDay(1)) },
                Miercoles: { date: this.nextDay(2), formattedDate: this.$options.filters.formatDateTimeSlug(this.nextDay(2)) },
                Jueves: { date: this.nextDay(3), formattedDate: this.$options.filters.formatDateTimeSlug(this.nextDay(3)) },
                Viernes: { date: this.nextDay(4), formattedDate: this.$options.filters.formatDateTimeSlug(this.nextDay(4)) },
                Sabado: { date: this.nextDay(5), formattedDate: this.$options.filters.formatDateTimeSlug(this.nextDay(5)) },
                Domingo: { date: this.nextDay(6), formattedDate: this.$options.filters.formatDateTimeSlug(this.nextDay(6)) },
            }
        },
        functionDblClick (event ) {
            let orden = null
            const id = +event.target.dataset.ovId

            orden = this.ordenesConCamion.find(ov => ov.id === id)
  

            if (orden) {
                // this.infoModal.registros = orden.registros ?? {}
                this.infoModal.id = id
                this.infoModal.numero_cotizacion = orden.numero_cotizacion ?? ''
                this.infoModal.fechaOrden = orden.fecha_orden
                this.infoModal.fechaActualizada = orden.fecha_actualizada
                this.infoModal.numero = orden.numero 
                this.infoModal.region = orden.region
                this.infoModal.detalles = orden.detalles ?? {}
                this.infoModal.estados = orden.estados ?? {}
                this.infoModal.articulos = orden.articulos?.map(a => {
                    return {
                        codigo: a.codigo,
                        descripcion: a.descripcion,
                        orden: a.cantidad_ordenada,
                        lista_envio: a.cantidad_facturar,
                        despachada: a.cantidad_fact,
                        stock: a.stock,
                        sin_stock: a.sin_stock,
                        cantidad_factura: a.cantidad_factura,
                        um: a.unidad_medida
                    }
                }) ?? []

                this.showModal()
                event.stopImmediatePropagation()
            }
        },
        nextDay (days) {
            const newDay = new Date(this.monday) // Hacemos una copia de monday
            newDay.setDate(newDay.getDate() + days)
            return newDay
        },
        getId (texto) {
            const start = texto.indexOf('data-ov-id="') + 12
            const end = texto.indexOf('"', start)
            return +texto.substring(start, end)
        },
        getIdCamionero (texto) {
            const start = texto.indexOf('data-camionero-id="') + 19
            const end = texto.indexOf('"', start)
            return +texto.substring(start, end)
        },
        getOVParaDia (fecha) {
            const fechaFormateada = fecha.split('T')[0]
            const ordenesFiltradas = this.ordenesConCamion.filter(orden => {
                if (orden.fechahora_logistica) {
                    const fechaOrden = orden.fechahora_logistica.split(" ")[0].split("/").reverse().join("-")
                    return fechaOrden === fechaFormateada
                }
            })
            return ordenesFiltradas
        },
        cambiarSemana (semana) {
            let diferencia = 0
            if (semana === 'proxima') { diferencia = 7 }
            else if (semana === 'anterior') { diferencia = -7 }

            const lunes = this.monday
            lunes.setDate(lunes.getDate() + diferencia)
            this.getSemana()
        },
        getClassRegion (region, estados) {
            var reg = 'region-'
            if (region === 'Región de Atacama') reg = reg + 'III'
            if (region === 'Región de Coquimbo') reg = reg + 'IV'
            if (region === 'Región de Valparaíso') reg = reg + 'V'
            if (region === 'Región Libertador Gral Bernardo O´Higgin') reg = reg + 'VI'
            if (region === 'Región del Maule') reg = reg + 'VII'
            if (region === 'Región del Biobío') reg = reg + 'VIII'
            if (region === 'Región de Los Lagos') reg = reg + 'X'
            if (region === 'Región Metropolitana de Santiago') reg = reg + 'RM'
            if (region === 'Región de Ñuble') reg = reg + 'XVI'
            if ('externa' in estados && estados['externa']) {
                return reg + ' background--externa'
            }
            return reg
        },
        getFechaBodega (orden) {
            const fechaBodega = orden.fechahora_bodega?.split(" ")
            return fechaBodega ? fechaBodega[0] : ''
        },
        fechaInvalida (fechaBodega, fechaLogistica) {
            const anioBodega = +fechaBodega[0]
            const mesBodega = +fechaBodega[1] - 1
            const diaBodega = +fechaBodega[2]

            const anioLogistica = +fechaLogistica[0]
            const mesLogistica = +fechaLogistica[1] - 1
            const diaLogistica = +fechaLogistica[2]

            const dateBodega = new Date(anioBodega, mesBodega, diaBodega)
            const dateLogistica = new Date(anioLogistica, mesLogistica, diaLogistica)

            return dateBodega > dateLogistica
        },
        getDiffDays (orden) {
            if (orden.fechahora_logistica === null) {
                console.log(orden)
                this.ordenError = orden
            }
            const fechaLogistica = orden.fechahora_logistica.split(" ")[0].split("/").reverse()
            const anioLogistica = +fechaLogistica[0]
            const mesLogistica = +fechaLogistica[1] - 1
            const diaLogistica = +fechaLogistica[2]

            const dateLogistica = new Date(anioLogistica, mesLogistica, diaLogistica)
            const diffTime = dateLogistica - this.fechaActual

            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
            return diffDays
        },
        getBgColor (fechaOV) {
            const cpFechaOV = new Date(fechaOV)
            cpFechaOV.setHours(0,0,0,0)
            
            if (cpFechaOV !== null && cpFechaOV > this.fechaActual) {
                return 'contenedor'
            } else if (cpFechaOV !== null && cpFechaOV.getTime() === this.fechaActual.getTime()) {
                return 'contenedor calendar__today'
            } else {
                return 'bg-light'
            }
        },
        inicializarOV () {
            // Inicializa las ordenes de venta
            const cardLogistico = document.getElementById('card-calendario-logistico')
            const ordenesVentaLogistico = [...cardLogistico.getElementsByClassName('calendar__ov')]
            ordenesVentaLogistico.forEach(ov => {
                ov.addEventListener('dblclick', this.functionDblClick, false)
            })
        },
        showModal () {
            this.$refs['my-modal'].show()
        }
    },
    mounted () {
        this.getPermisos().then(res => {
            this.getOVConCamion()
            this.getMonday()
            this.getSemana()
        })
    },
    updated () {
        this.inicializarOV()
    },
    computed: {
        isAdmin() {
            return this.$store.state.admin
        },
        isBodega() {
            return this.$store.state.bodega
        },
        isLogistica() {
            return this.$store.state.logistica
        }
    }

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
</style>