<!--COTIZACIÒN PRUEBA 1 + INFORMACIÓN ADICIONAL-->
<template>
  <div>
    <Header :empresa-nombre="empresaNombre" :empresa-id="empresaId"></Header>
    <!-- MODAL EDITAR COTIZACION -->
    <modal-cotizacion v-if="cotizacion" ref="modalEdit" @accept="getData()" :edit="true" title="Editar Cotización" modal="modal-edit-cotizacion" :cotizacion="cotizacion"></modal-cotizacion>
    <modal-presupuesto modal="modal-create-presupuesto" :edit="false" ref="modalCreate" :cambios="cambios" title="Agregar Presupuesto" @reset="getData();forceRerender();"></modal-presupuesto>
    <modal-confirmar-cotizacion v-if="cotizacion && cambios" :cotizacion="cotizacion" :cambios="cambios" ref="modalConfirmar" @accept="getData();forceRerender();"></modal-confirmar-cotizacion>
    <modal-confirmar-cambio v-if="cotizacion && cambios && user" :cotizacion="cotizacion" :cambios="cambios" :user="user" :edit="dataEditConfirmarCambio" ref="modalConfirmarCambio" @accept="getData();forceRerender();dataEditConfirmarCambio=null;"></modal-confirmar-cambio>
    <div class="container-fluid" v-if="cotizacion">
      <div class="row">
        <div class="col-md-9 col-sm-12 col-xs-12">
          <div class="card mb-3">
            <div class="card__header" :class="empresaNombre">
              <div class="d-flex">
                <p class="card__title">Cotización N° {{cotizacion.numero}}</p>
                <span class="badge badge--secondary" v-if="cotizacion.nombre_proyecto"> - {{cotizacion.nombre_proyecto}}</span>
                <span class="badge badge--primary" :class="empresaNombre" v-if="cotizacion.tipo">{{cotizacion.tipo}}</span>
              </div>
              <a @click="openModalEdit()" class="button button--primary" v-if="(permisoIngresar || permisoEditar) && !cotizacion.aprobado && !cotizacion.rechazado && !cotizacion.descartado && !cotizacion.revisado && !cotizacion.cerrado && !cotizacion.por_confirmar">
                <span class="button__text">Editar</span>
                <font-awesome-icon :icon="['fas', 'pencil-alt']" class="button__icon" />
              </a>
            </div>
            <div class="card__body">
              <p v-if="cotizacion.numero_proyecto">Número de Proyecto: {{cotizacion.numero_proyecto}}</p>
              <p>Cliente: {{cotizacion.cliente__numero}} - {{cotizacion.cliente__nombre}}</p>
              <p v-if="cotizacion.direccion && cotizacion.despacho === 'TRANSPORTE INTERNO'">{{cotizacion.direccion.id_embarque}} - {{cotizacion.direccion.direccion}}. {{cotizacion.direccion.comuna}}, {{cotizacion.direccion.region}}.</p>
              <p v-if="cotizacion.despacho">Método de despacho: {{cotizacion.despacho}}</p>
              <p v-if="cotizacion.cerrado">Forma de Pago: {{ cotizacion.cliente__forma_pago }}</p>
              <p v-if="cotizacion.cerrado">OC: {{ cotizacion.oc }}</p>
            </div>
          </div>
          <div class="card">
            <!-- <br v-if="!cotizacion.aprobado && !cotizacion.rechazado && !cotizacion.descartado || cotizacion.aprobado && permisoRevisar"> -->
            <div class="card__header">
              <!-- <a @click="openModalRespaldo()" class="btn btn-primary float-left" v-if="!cotizacion.aprobado && !cotizacion.rechazado && !cotizacion.descartado && !cotizacion.revisado">
                <font-awesome-icon :icon="['fas', 'upload']" />  Cargar Presupuesto (XLS)
              </a> -->
              <p class="card__title">Presupuestos</p>
              <div class="card__buttons">
                <a @click="openModalCreate()" class="button button--primary" v-if="!cotizacion.aprobado && !cotizacion.rechazado && !cotizacion.descartado && !cotizacion.cerrado">
                    <span class="button__text">Agregar Presupuesto</span>
                    <font-awesome-icon :icon="['fas', 'plus-circle']" class="button__icon" />
                </a>
              </div>
            </div>
            <!-- <br v-if="!cotizacion.aprobado && !cotizacion.rechazado && !cotizacion.descartado && !cotizacion.revisado && !cotizacion.cerrado"> -->
            <div class="card__body" v-if="cotizacion.presupuestos && cotizacion.presupuestos.length > 0">
              <presupuesto @accept="getData()" :key="key" :proyecto="proyecto" :aprobado="cotizacion.aprobado" :porConfirmar="cotizacion.por_confirmar" :cerrado="cotizacion.cerrado" :articulos="articulos"></presupuesto>
            </div>
          </div>           
        </div>

        <!--SECCIÓN COSTADO-->
        <div class="col-md-3 col-sm-12">
            <div class="card mb-3">
              <button class="card__header" v-b-toggle.collapse-1>
                <span>Información adicional</span>
                <font-awesome-icon :icon="['fas', 'chevron-down']" />
              </button>
              <b-collapse visible id="collapse-1">
                <div class="card__body">
                  <div v-if="cotizacion.tasa_cambio">
                    <small>Tasa de Cambio:</small>
                    <button class="button--sm button--primary m-0 mb-3" @click="setDataEditConfirmarCambio(true)" v-if="cotizacion.por_confirmar">
                        <font-awesome-icon class="button__icon--sm" :icon="['fas', 'pencil-alt']"/>
                    </button>
                    <br>
                    <span class="badge badge--primary" v-for="tasa in cotizacion.tasa_cambio" v-bind:key="tasa.moneda">
                      {{tasa.moneda}}
                      <vue-numeric style="width:60px" currency="$" separator="." v-model="tasa.tasa_cambio" disabled v-bind:precision="3"></vue-numeric>
                    </span>
                  </div>
                  <hr>
                  <span class="badge m-0 mb-3" :class="{'badge--danger': cotizacion.estado == 'Rechazada','badge--success': cotizacion.estado == 'Aprobada','badge--primary': cotizacion.estado == 'Revisada','badge--warning': cotizacion.estado == 'Por Revisar','badge--secondary': cotizacion.estado == 'Descartada', 'badge--info': cotizacion.estado == 'Cerrada', 'badge--purple': cotizacion.estado == 'Borrador'}" v-if="cotizacion.estado">
                      {{cotizacion.estado}}
                  </span>
                  <p class="m-0">Ingresada el {{cotizacion.fecha_ingreso}} por {{cotizacion.usuario_ingreso__get_nombre_completo}}</p>
                  <br v-if="cotizacion.aprobado && (permisoRevisar || permisoIngresar || permisoEditar)">
                  <!-- <div class="row" v-if="cotizacion.aprobado && (permisoRevisar || permisoIngresar || permisoEditar)">
                    <div class="col-md-6" v-for="cambio in cambios" v-bind:key="cambio.id">
                      <label>{{cambio.moneda}} </label> 
                      <vue-numeric currency="$" separator="." v-model="cambio.tasa_cambio" size="8rem" class="form-control" :min="1"  v-bind:precision="3"></vue-numeric> 
                    </div>
                  </div> -->
                  <div class="alert--sm alert--primary" role="alert" v-if="!cotizacion.tiene_detalles">
                      <font-awesome-icon :icon="['fas', 'info-circle']" class="alert__icon--sm" />
                      <span class="alert__text--sm">Agregue artículos para poder enviarla.</span>
                  </div>
                  <div class="button__group" v-if="permisoIngresar || permisoEditar || permisoRevisar">
                    <button class="button--sm button--primary m-0 mb-3" @click="revisarCotizacion(cotizacion.revisado)" v-if="!cotizacion.aprobado && !cotizacion.cerrado && !cotizacion.descartado && !cotizacion.rechazado && !cotizacion.revisado && !cotizacion.por_confirmar" :disabled="!cotizacion.tiene_detalles">
                      Enviar
                      <font-awesome-icon class="button__icon--sm" :icon="['fas', 'paper-plane']"/>
                    </button>
                    <button class="button--sm button--primary m-0 mb-3" @click="setDataEditConfirmarCambio(false)" v-if="cotizacion.aprobado && !cotizacion.por_confirmar">
                      Pre-Confirmar Venta
                      <font-awesome-icon class="button__icon--sm" :icon="['fas', 'user-check']"/>
                    </button>
                    <button class="button--sm button--primary-900 m-0 mb-3" @click="openModalConfirmar()" v-if="!cotizacion.aprobado && cotizacion.por_confirmar">
                      Confirmar Venta
                      <font-awesome-icon class="button__icon--sm" :icon="['fas', 'check']"/>
                    </button>
                    <button class="button--sm button--secondary m-0 mb-3" @click="descartarCotizacion(cotizacion.descartado)" v-if="(cotizacion.aprobado && !cotizacion.cerrado) || cotizacion.por_confirmar">
                      Descartar
                      <font-awesome-icon class="button__icon--sm" :icon="['fas', 'times']"/>
                    </button>
                    <button class="button--sm button--danger m-0 mb-3" @click="rechazarCotizacion(cotizacion.rechazado)" v-if="!cotizacion.cerrado && !cotizacion.descartado && cotizacion.aprobado || cotizacion.revisado || cotizacion.por_confirmar">
                      Rechazar
                      <font-awesome-icon class="button__icon--sm" :icon="['fas', 'times']"/>
                    </button>
                    <a @click="editarCotizacion()" style="cursor:pointer;" class="button--sm button--line-primary m-0 mb-3" v-if="cotizacion.aprobado && permisoRevisar && !isAdmin">
                        <span class="button__text"> Editar Cotización</span>
                        <font-awesome-icon :icon="['fas', 'pencil-alt']" class="button__icon" />
                    </a>
                  </div>
                  <div class="button__group" v-if="permisoAprobar">
                    <button class="button--sm button--success m-0 mb-3" @click="aprobarCotizacion(cotizacion.aprobado)" v-if="cotizacion.revisado && !cotizacion.aprobado && !cotizacion.cerrado && !cotizacion.descartado && !cotizacion.rechazado">
                      Aprobar
                      <font-awesome-icon class="button__icon--sm" :icon="['fas', 'check']"/>
                    </button>
                    <button class="button--sm button--warning m-0 mb-3" @click="cotizacion.por_revisar=!cotizacion.por_revisar;porRevisarCotizacion()" v-if="!cotizacion.por_revisar && (cotizacion.aprobado || cotizacion.revisado)">
                      Por Revisar
                      <font-awesome-icon class="button__icon--sm" :icon="['fas', 'pencil-alt']"/> </button>
                    <!-- <button class="button--sm button--danger m-0 mb-3" @click="rechazarCotizacion(cotizacion.rechazado)" v-if="!cotizacion.cerrado && !cotizacion.descartado && cotizacion.aprobado || cotizacion.revisado">
                      Rechazar
                      <font-awesome-icon class="button__icon--sm" :icon="['fas', 'times']"/>
                    </button> -->
                    <button class="button--sm button--primary m-0 mb-3" @click="editarCotizacion()" v-if="isAdmin && (cotizacion.cerrado || cotizacion.descartado || cotizacion.rechazado)">
                      Abrir Cotización
                      <font-awesome-icon class="button__icon--sm" :icon="['fas', 'check']"/>
                    </button>
                      <!-- <div class="form-check" v-if="permisoAprobar" v-b-tooltip.hover.bottom title="Cotización se encuentra aprobada.">
                          <input class="form-check-input" type="checkbox" v-model="cotizacion.aprobado" @click="aprobarCotizacion(cotizacion.aprobado)" :disabled="!permisoAprobar || cotizacion.rechazado || cotizacion.descartado || cotizacion.cerrado"/>
                          <label class="form-check-label">
                          Aprobar
                          </label>
                      </div>
                      <div class="form-check" v-if="permisoAprobar" v-b-tooltip.hover.bottom title="Cotización con errores, el vendedor debe corregir.">
                          <input class="form-check-input" type="checkbox" v-model="cotizacion.por_revisar" @click="cotizacion.por_revisar=!cotizacion.por_revisar;porRevisarCotizacion()" :disabled="cotizacion.rechazado || cotizacion.descartado || cotizacion.aprobado || cotizacion.cerrado"/>
                          <label class="form-check-label">Por Corregir</label>
                      </div>
                      <div class="form-check" v-if="permisoAprobar" v-b-tooltip.hover.bottom title="Cotización se ha rechazado.">
                          <input class="form-check-input" type="checkbox" v-model="cotizacion.rechazado" @click="rechazarCotizacion(cotizacion.rechazado)" :disabled="!permisoRechazar || cotizacion.aprobado || cotizacion.descartado || cotizacion.cerrado"/>
                          <label class="form-check-label">
                              Rechazar
                          </label>
                      </div> -->
                  </div>
                  <!-- <div class="alert--sm alert--danger" role="alert" v-if="cotizacion.aprobado && (cotizacion.oc !== null || cotizacion.oc !== '')">
                    <font-awesome-icon :icon="['fas', 'exclamation-circle']" class="alert__icon--sm" />
                    <span class="alert__text--sm">Para poder CERRAR una cotización, debes agregar una OC.</span>
                  </div> -->

                  <div v-if="cotizacion.aprobado || cotizacion.por_confirmar || cotizacion.cerrado">
                    <!-- <a @click="downloadPdf()" class="btn btn-sm btn-primary float-right"><font-awesome-icon :icon="['fas', 'file-download']" /></a> -->
                    <div v-b-tooltip.hover title="Observaciones a Orden de Venta">
                      <textarea class="form-control mb-3" maxlength="40" v-model="cotizacion.observaciones" placeholder="Observaciones a Orden de Venta" :disabled="cotizacion.cerrado"></textarea>
                    </div>
                    <!-- <div v-b-tooltip.hover title="OC">
                      <input class="form-control" type="text" v-model="cotizacion.oc" placeholder="OC" :disabled="cotizacion.cerrado"/>
                    </div> -->
                    <a @click="updateCotizacion()" v-if="!cotizacion.cerrado" class="button--sm button--primary w-100 m-0">
                      Guardar<font-awesome-icon class="button__icon--sm" :icon="['fas', 'save']" />
                    </a>
                  </div>
                </div>
              </b-collapse>
            </div>
            <div class="card mb-3" v-if="cotizacion.tiene_detalles">
                <button class="card__header" v-b-toggle.collapse-2>
                <span>Exportables</span>
                <font-awesome-icon :icon="['fas', 'chevron-down']" />
                </button>
                <b-collapse visible id="collapse-2">
                <div class="card__body">
                    <div class="alert--sm alert--primary" role="alert"  v-if="cotizacion.articulos_por_aprobar">
                    <font-awesome-icon :icon="['fas', 'info-circle']" class="alert__icon--sm" />
                    <span class="alert__text--sm">Existen artículos por aprobar</span>
                    </div>
                    <div class="alert--sm alert--primary" role="alert" v-if="!cotizacion.aprobado && !cotizacion.cerrado">
                    <font-awesome-icon :icon="['fas', 'info-circle']" class="alert__icon--sm" />
                    <span class="alert__text--sm">Podrá descargar la cotización una vez se encuentre aprobada.</span>
                    </div>
                    <div class="alert--sm alert--primary" role="alert" v-if="cotizacion.aprobado && !cotizacion.por_confirmar && !cotizacion.cerrado">
                    <font-awesome-icon :icon="['fas', 'info-circle']" class="alert__icon--sm" />
                    <span class="alert__text--sm">Podrá descargar la Orden de Venta (NO CONFIRMADA) una vez se encuentre pre-confirmada.</span>
                    </div>
                    <div class="button__group" v-if="cotizacion.monedas_disponibles && cotizacion.monedas_disponibles.length > 0">
                    <button class="button--sm button--primary m-0 mb-3" @click="downloadPdf('moneda')" :disabled="cotizacion.articulos_por_aprobar || !cotizacion.aprobado && !cotizacion.cerrado && !cotizacion.por_confirmar">Cotización<font-awesome-icon class="button__icon--sm" :icon="['fas', 'file-pdf']" />
                    </button>
                    <button @click="downloadPdf()" class="button--sm button--primary m-0" :disabled="cotizacion.articulos_por_aprobar || !cotizacion.cerrado && !cotizacion.por_confirmar">
                        Orden de Venta (No confirmada)
                        <font-awesome-icon class="button__icon--sm" :icon="['fas', 'file-pdf']" />
                    </button>
                    </div>
                </div>
                </b-collapse>
            </div>
            <div class="card mb-3" :class=" empresaNombre" v-if="cotizacion.get_sub_total">
                <button class="card__header" v-b-toggle.collapse-3>
                <span>Resumen Cotización</span>
                <font-awesome-icon :icon="['fas', 'chevron-down']" />
                </button>
                <b-collapse visible id="collapse-3">
                <div class="card__body">
                    <h6 class="" v-if="!cotizacion.aprobado && !cotizacion.rechazado && !cotizacion.descartado && !cotizacion.cerrado && empresaNombre ==='dripsa'"> Agregar descuento:</h6>
                    <percent-input class="form-control mb-3" v-bind="{ integerPlaces: 2, decimalPlaces: 2, suffix: true }" v-model="cotizacion.descuento" :key="keyPercent" v-if="!cotizacion.aprobado && !cotizacion.rechazado && !cotizacion.descartado && !cotizacion.cerrado && empresaNombre ==='dripsa'"></percent-input>
                    <span class="button__group" v-if="!cotizacion.aprobado && !cotizacion.rechazado && !cotizacion.descartado && !cotizacion.cerrado && empresaNombre ==='dripsa'">
                        <a class="button--sm button--success mb-3" @click="updateCotizacion()">Aplicar descuento
                            <font-awesome-icon class="button__icon--sm" :icon="['fas', 'check-circle']" />
                        </a>
                        <a class="button--sm button--secondary mb-3" @click="cotizacion.descuento=0;updateCotizacion();forceRerenderPercent()">Cancelar
                            <font-awesome-icon class="button__icon--sm" :icon="['fas', 'times-circle']" />
                        </a>
                        <!-- btn-restaurar-dripsa -->
                    </span>
                    <div v-if="cotizacion.get_sub_total_moneda && !cotizacion.cerrado && !cotizacion.por_confirmar">
                        <span class="resumen" v-for="data in cotizacion.get_sub_total_moneda" v-bind:key="data.moneda">
                            <h6>SUB-TOTAL {{data.moneda}}:</h6>
                            <vue-numeric size="11rem" currency="$" separator="." v-model="data.sub_total" disabled v-bind:precision="3"></vue-numeric>
                        </span>
                    </div>
                    <div class="resumen" v-if="cotizacion.get_sub_total && cotizacion.get_subtotal_descuento">
                        <h6>SUB-TOTAL:</h6>
                        <vue-numeric size="11rem" currency="$" separator="." v-model="cotizacion.get_sub_total" disabled v-bind:precision="3"></vue-numeric>
                    </div>
                    <div class="resumen" v-if="cotizacion.get_calculo_descuento">
                        <span class="badge badge--inf">DESC. {{cotizacion.descuento}}%: </span>
                        <vue-numeric size="11rem" currency="$" separator="." v-model="cotizacion.get_calculo_descuento" disabled  v-if="cotizacion.get_calculo_descuento" v-bind:precision="3"></vue-numeric>
                    </div>
                    <div class="resumen" v-if="cotizacion.get_subtotal_descuento">
                        <h6>SUB-TOTAL</h6>
                        <a class="badge badge--info" style=" cursor: pointer;" v-b-tooltip.hover.right title="CON DESCUENTO">(C/D): </a>
                        <vue-numeric size="11rem" currency="$" separator="." v-model="cotizacion.get_subtotal_descuento" disabled v-if="cotizacion.get_subtotal_descuento" v-bind:precision="3"></vue-numeric>
                    </div>
                    <div class="resumen" v-if="cotizacion.get_sub_total && !cotizacion.get_subtotal_descuento">
                        <h6>SUB-TOTAL CLP:</h6>
                        <vue-numeric size="11rem" currency="$" separator="." v-model="cotizacion.get_sub_total" disabled v-bind:precision="3"></vue-numeric>
                    </div>
                    <div class="resumen" v-if="cotizacion.get_iva_19 && (cotizacion.por_confirmar || cotizacion.cerrado)">
                        <h6>IVA 19% CLP:</h6>
                        <vue-numeric size="11rem" currency="$" separator="." v-model="cotizacion.get_iva_19" disabled v-bind:precision="3"></vue-numeric>
                    </div>
                    <br>
                    <div class="resumen" v-if="cotizacion.get_total && (cotizacion.por_confirmar || cotizacion.cerrado)">
                        <h6 class="resumen__total">TOTAL CLP:</h6>
                        <vue-numeric size="11rem" currency="$" separator="." v-model="cotizacion.get_total" disabled v-bind:precision="3"></vue-numeric>
                    </div>
                </div>
                </b-collapse>
            </div>
        </div>                    
      </div>
      <br><br><br><br>
      <!--FOOTER COTIZACIÓN-->
        <div class="card--resumen fixed-bottom" :class=" empresaNombre" v-if="cotizacion.get_sub_total">
            <!-- <button class="card__header" v-b-toggle.collapse-4>
            <span>Resumen Cotización</span>
            <font-awesome-icon :icon="['fas', 'chevron-down']" />
            </button>
            <b-collapse visible id="collapse-4"> -->
            <div class="card__body--resumen">
                <div class="d-flex">
                    <h6 class="resumen__dcto" v-if="!cotizacion.aprobado && !cotizacion.rechazado && !cotizacion.descartado && !cotizacion.cerrado && empresaNombre ==='dripsa'"> AGREGAR DCTO:</h6>
                    <percent-input class="form-control" size="4rem" v-bind="{ integerPlaces: 2, decimalPlaces: 2, suffix: true }" v-model="cotizacion.descuento" :key="keyPercent" v-if="!cotizacion.aprobado && !cotizacion.rechazado && !cotizacion.descartado && !cotizacion.cerrado && empresaNombre ==='dripsa'"></percent-input>
                    <span class="btn-group" v-if="!cotizacion.aprobado && !cotizacion.rechazado && !cotizacion.descartado && !cotizacion.cerrado && empresaNombre ==='dripsa'">
                        <a class="button button--success" @click="updateCotizacion()">
                            <font-awesome-icon :icon="['fas', 'check-circle']" />
                        </a>
                        <a class="button button--secondary" @click="cotizacion.descuento=0;updateCotizacion();forceRerenderPercent()">
                            <font-awesome-icon :icon="['fas', 'times-circle']" />
                        </a>
                    </span>
                </div>
                <div class="resumen__content">
                    <div style="display:flex; align-items:center;" v-if="cotizacion.get_sub_total_moneda && !cotizacion.aprobado && !cotizacion.cerrado">
                        <div class="resumen--footer" v-for="data in cotizacion.get_sub_total_moneda" v-bind:key="data.moneda">
                            <h6 v-bind:key="data.moneda">SUB-TOTAL {{data.moneda}}:</h6>
                            <vue-numeric size="6rem" currency="$" separator="." v-model="data.sub_total" disabled v-bind:precision="3"></vue-numeric>
                        </div>
                    </div>
                    <div class="resumen--footer" v-if="cotizacion.get_sub_total && cotizacion.get_subtotal_descuento">
                        <h6>SUB-TOTAL:</h6>
                        <vue-numeric size="11rem" currency="$" separator="." v-model="cotizacion.get_sub_total" disabled v-bind:precision="3"></vue-numeric>
                    </div>
                    <div class="resumen--footer" v-if="cotizacion.get_calculo_descuento">
                        <span class="badge badge--inf">DESC. {{cotizacion.descuento}}%: </span>
                        <vue-numeric size="11rem" currency="$" separator="." v-model="cotizacion.get_calculo_descuento" disabled  v-if="cotizacion.get_calculo_descuento" v-bind:precision="3"></vue-numeric>
                    </div>
                    <div class="resumen--footer" v-if="cotizacion.get_subtotal_descuento">
                        <h6>SUB-TOTAL</h6>
                        <a class="badge badge--info" style=" cursor: pointer;" v-b-tooltip.hover.right title="CON DESCUENTO">(C/D): </a>
                        <vue-numeric size="11rem" currency="$" separator="." v-model="cotizacion.               get_subtotal_descuento" disabled v-if="cotizacion.get_subtotal_descuento" v-bind:precision="3"></vue-numeric>
                    </div>
                    <div class="resumen--footer" v-if="cotizacion.get_sub_total && !cotizacion.get_subtotal_descuento">
                        <h6>SUB-TOTAL CLP:</h6>
                        <vue-numeric size="11rem" currency="$" separator="." v-model="cotizacion.get_sub_total" disabled v-bind:precision="3"></vue-numeric>
                    </div>
                    <div class="resumen--footer" v-if="cotizacion.get_iva_19 && (cotizacion.aprobado || cotizacion.por_confirmar || cotizacion.cerrado)">
                        <h6>IVA 19% CLP:</h6>
                        <vue-numeric size="8rem" currency="$" separator="." v-model="cotizacion.get_iva_19" disabled v-bind:precision="3"></vue-numeric>
                    </div>
                    <div class="resumen--footer" v-if="cotizacion.get_total && (cotizacion.aprobado || cotizacion.por_confirmar || cotizacion.cerrado)">
                        <h6 class="resumen__total">TOTAL CLP:</h6>
                        <vue-numeric size="11rem" currency="$" separator="." v-model="cotizacion.get_total" disabled v-bind:precision="3"></vue-numeric>
                    </div>
                </div>
            </div>
            <!-- </b-collapse> -->
        </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
import Header from '@/components/Header.vue'
import ModalPresupuesto from '@/cotizacion/ModalPresupuesto.vue'
import Presupuesto from '@/cotizacion/Presupuesto.vue'
import ModalCotizacion from '@/cotizacion/ModalCotizacion.vue'
import ModalConfirmarCotizacion from '@/cotizacion/ModalConfirmarCotizacion.vue'
import ModalConfirmarCambio from '@/cotizacion/ModalConfirmarCambio.vue'
// import ModalRespaldo from '@/modals/ModalRespaldo.vue'

const Cotizacion = resource('/cotizacion/api/gestor_cotizaciones', {
    update: (id, params) => axios.put(`/cotizacion/api/gestor_cotizaciones/${id}/`, { params }),
    habilitar:(id, params) => axios.put(`/cotizacion/api/gestor_cotizaciones/${id}/habilitar/`, { params }),
    por_revisar:(id, params) => axios.put(`/cotizacion/api/gestor_cotizaciones/${id}/por_revisar/`, { params }),
    revisar:(id, params) => axios.put(`/cotizacion/api/gestor_cotizaciones/${id}/revisar/`, { params }),
    aprobar:(id, params) => axios.put(`/cotizacion/api/gestor_cotizaciones/${id}/aprobar/`, { params }),
    rechazar:(id, params) => axios.put(`/cotizacion/api/gestor_cotizaciones/${id}/rechazar/`, { params }),
    descartar:(id, params) => axios.put(`/cotizacion/api/gestor_cotizaciones/${id}/descartar/`, { params }),
    cerrar:(id, params) => axios.put(`/cotizacion/api/gestor_cotizaciones/${id}/cerrar/`, { params }),
    actualizar_cambio:(id, params) => axios.put(`/cotizacion/api/gestor_cotizaciones/${id}/actualizar_cambio/`, { params }),
}, axios)
const Articulo = resource('/cotizacion/api/listar_articulos', axios)

const Cambio = resource('/base/api/listar_cambio/', axios)

export default {
    name: 'Cotizacion',
    components: {
        Header,
        ModalPresupuesto,
        Presupuesto,
        ModalCotizacion,
        ModalConfirmarCotizacion,
        ModalConfirmarCambio
        // ModalRespaldo
    },
    data () {
        return {
            empresaId: this.$route.params.empresaId,
            empresaNombre: this.$route.params.empresaNombre,
            cotizacionId: this.$route.params.cotizacionId,
            cotizacion: [],
            key: 0,
            keyPercent: 0,
            proyecto: false,
            articulos: [],
            cambios: [],
            count: 0,
            formCambio: {},
            dataEditConfirmarCambio: null
        }
    },
    methods: {
        getData () {
            Cotizacion.query({ id: this.cotizacionId }).then((res) => {
                this.cotizacion = res.data[0]
                this.cotizacion.tasa_cambio.forEach((item)=>{
                    item.tasa_cambio = parseFloat(item.tasa_cambio)
                })
                this.getArticulo()
                if (this.cotizacion.tipo === 'Proyecto')
                    this.proyecto = true

            })
        },
        openModalCreate () {
            this.$refs.modalCreate.openModalCreate()
        },
        openModalEdit () {
            this.$refs.modalEdit.openModalEdit()
        },
        openModalConfirmar () {
            this.$refs.modalConfirmar.openModal()
        },
        setDataEditConfirmarCambio (edit) {
            this.dataEditConfirmarCambio = edit
        },
        openModalConfirmarCambio () {
            this.$refs.modalConfirmarCambio.openModal()
        },
        updateCotizacion () {
            delete this.cotizacion.fecha_ingreso
            delete this.cotizacion.fechahora_actualizacion
            delete this.cotizacion.fecha_cierre
            Cotizacion.update(this.cotizacion.id, this.cotizacion).then((res) => {
                this.getData()
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Cotizacion editada con éxito'
                })
            })
                .catch((error) => {
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al editar la cotización'
                    })
                })

        },
        porRevisarCotizacion () {
            if (this.cotizacion.por_revisar) {
                this.cotizacion.revisado = false
                this.cotizacion.aprobado = false
                this.cotizacion.por_confirmar = false
                this.cotizacion.fecha_envio = null
            }
            delete this.cotizacion.fecha_ingreso
            delete this.cotizacion.fechahora_actualizacion
            delete this.cotizacion.fecha_cierre
            Cotizacion.por_revisar(this.cotizacion.id, this.cotizacion).then((res) => {
                this.getData()
                this.forceRerender()
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Cotizacion actualizada con éxito'
                })
            })
                .catch((error) => {
                    this.getData()
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al actualizar la cotización'
                    })
                })

        },
        revisarCotizacion (revisado) {
            this.cotizacion.revisado = !revisado
            this.cotizacion.usuario_revisor = this.user.id
            this.cotizacion.por_revisar = false
            this.cotizacion.fecha_envio = null
            if (!this.cotizacion.revisado)
                this.cotizacion.usuario_revisor = null
            delete this.cotizacion.fecha_ingreso
            delete this.cotizacion.fechahora_actualizacion
            delete this.cotizacion.fecha_cierre
            Cotizacion.revisar(this.cotizacion.id, this.cotizacion).then((res) => {
                this.forceRerender()
                this.getData()
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Cotizacion actualizada con éxito'
                })
            })
                .catch((error) => {
                    this.getData()
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al actualizar la cotización'
                    })
                })

        },
        aprobarCotizacion (aprobado) {
            this.cotizacion.aprobado = !aprobado
            this.cotizacion.usuario_aprobador = this.user.id
            this.cotizacion.por_revisar = false
            this.cotizacion.revisado = false
            if (!this.cotizacion.aprobado) {
                this.cotizacion.usuario_aprobador = null
                this.cotizacion.fecha_envio = null
            } else {
                const date = new Date()
                let day = date.getDate()
                let month = date.getMonth() + 1
                let year = date.getFullYear()
                this.cotizacion.fecha_envio = `${year}-${month}-${day}`
            }
            delete this.cotizacion.fecha_ingreso
            delete this.cotizacion.fechahora_actualizacion
            delete this.cotizacion.fecha_cierre
            // if (this.cotizacion.aprobado)
            //     this.forceRerender
            Cotizacion.aprobar(this.cotizacion.id, this.cotizacion).then((res) => {
                this.getData()
                this.forceRerender();
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Cotizacion actualizada con éxito'
                })
            })
                .catch((error) => {
                    this.getData()
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al actualizar la cotización'
                    })
                })

        },
        rechazarCotizacion (rechazado) {
            this.cotizacion.rechazado = !rechazado
            this.cotizacion.usuario_rechazo = this.user.id
            this.cotizacion.por_revisar = false
            this.cotizacion.revisado = false
            this.cotizacion.aprobado = false
            this.cotizacion.por_confirmar = false
            if (!this.cotizacion.rechazado)
                this.cotizacion.usuario_rechazo = null
            delete this.cotizacion.fecha_ingreso
            delete this.cotizacion.fechahora_actualizacion
            delete this.cotizacion.fecha_cierre
            Cotizacion.rechazar(this.cotizacion.id, this.cotizacion).then((res) => {
                this.getData()
                this.forceRerender()
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Cotizacion actualizada con éxito'
                })
            })
                .catch((error) => {
                    this.$emit('accept')
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al actualizar la cotización'
                    })
                })

        },
        descartarCotizacion (descartado) {
            this.cotizacion.descartado = !descartado
            this.cotizacion.usuario_rechazo = this.user.id
            this.cotizacion.por_revisar = false
            this.cotizacion.revisado = false
            this.cotizacion.aprobado = false
            this.cotizacion.por_confirmar = false
            if (!this.cotizacion.descartado)
                this.cotizacion.usuario_rechazo = null
            delete this.cotizacion.fecha_ingreso
            delete this.cotizacion.fechahora_actualizacion
            delete this.cotizacion.fecha_cierre
            Cotizacion.descartar(this.cotizacion.id, this.cotizacion).then((res) => {
                this.getData()
                this.forceRerender()
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Cotizacion actualizada con éxito'
                })
            })
                .catch((error) => {
                    this.$emit('accept')
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al actualizar la cotización'
                    })
                })

        },
        cerrarCotizacion (cerrado) {
            this.cotizacion.cerrado = !cerrado
            this.cotizacion.por_revisar = false
            this.cotizacion.revisado = false
            this.cotizacion.aprobado = false
            if (!this.cotizacion.cerrado)
                this.cotizacion.usuario_rechazo = null
            delete this.cotizacion.fecha_ingreso
            delete this.cotizacion.fechahora_actualizacion
            delete this.cotizacion.fecha_cierre
            this.actualizarCambio(this.cambios)
            Cotizacion.cerrar(this.cotizacion.id, this.cotizacion).then((res) => {
                this.getData()
                this.forceRerender()
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Cotizacion actualizada con éxito'
                })
            })
                .catch((error) => {
                    this.$emit('accept')
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al actualizar la cotización'
                    })
                })

        },
        downloadPdf (moneda) {
            var url
            var name = ''
            if (moneda) {
                url = '/cotizacion/api/gestor_cotizaciones/' + this.cotizacionId +'/pdf_cotizacion/?moneda=' + moneda
                name = 'Cotizacion - ' + this.cotizacion.cliente__numero
            } else {
                url = '/cotizacion/api/gestor_cotizaciones/' + this.cotizacionId +'/pdf_cotizacion/'
                name = 'Orden de Venta (NO CONFIRMADA) - ' + this.cotizacion.cliente__numero
            }
            axios({
                url: url,
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                this.count = 0
                var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))
                var fileLink = document.createElement('a')
                fileLink.download = name
                fileLink.href = fileURL
                document.body.appendChild(fileLink)
                fileLink.click()
                document.body.removeChild(fileLink)
                // window.open(fileURL)
            })
                .catch((error) => {
                    this.count++
                    if (error.response && error.response.status === 500) {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Ocurrió un error al descargar el documento'
                        })
                    }
                    else if (error.response && error.response.status === 401 && this.count < 5) {
                        this.getData()
                        this.downloadPdf(moneda)
                    }
                    else
                        this.$router.go()
                })
        },
        downloadXls (moneda) {
            axios({
                url: '/cotizacion/api/gestor_cotizaciones/' + this.cotizacionId +'/xls_cotizacion/?moneda=' + moneda,
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                this.count = 0
                var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}))
                var fileLink = document.createElement('a')

                fileLink.href = fileURL
                window.open(fileURL)
            })
                .catch((error) => {
                    this.count++
                    if (error.response && error.response.status === 500) {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Ocurrió un error al descargar el documento'
                        })
                    }
                    else if (error.response && error.response.status === 401 && this.count < 5) {
                        this.getData()
                        this.downloadXls(moneda)
                    }
                    else
                        this.$router.go()
                })
        },
        downloadXlsProyecto () {
            axios({
                url: '/cotizacion/api/gestor_cotizaciones/' + this.cotizacionId +'/xls_qad_proyecto/',
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                this.count = 0
                var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}))
                var fileLink = document.createElement('a')

                fileLink.href = fileURL
                window.open(fileURL)
            })
                .catch((error) => {
                    this.count++
                    if (error.response && error.response.status === 500) {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Ocurrió un error al descargar el documento'
                        })
                    }
                    else if (error.response && error.response.status === 401 && this.count < 5) {
                        this.getData()
                        this.downloadXlsProyecto()
                    }
                    else
                        this.$router.go()
                })
        },
        downloadXlsCotizacion () {
            axios({
                url: '/cotizacion/api/gestor_cotizaciones/' + this.cotizacionId +'/xls_qad_cotizacion/',
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                this.count = 0
                var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}))
                var fileLink = document.createElement('a')

                fileLink.href = fileURL
                window.open(fileURL)
            })
                .catch((error) => {
                    this.count++
                    if (error.response && error.response.status === 500) {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Ocurrió un error al descargar el documento'
                        })
                    }
                    else if (error.response && error.response.status === 401 && this.count < 5) {
                        this.getData()
                        this.downloadXlsCotizacion()
                    }
                    else
                        this.$router.go()
                })
        },
        forceRerender() {
            this.key += 1;
        },
        forceRerenderPercent() {
            this.keyPercent += 1;
        },
        openModalRespaldo () {
            this.$refs.modalRespaldo.openModal()
        },
        getArticulo () {
            Articulo.query({ empresa: this.empresaId, activo: true, manual: false }).then((res) => {
                this.articulos = res.data
            })
        },
        editarCotizacion () {
            this.cotizacion.aprobado = false
            this.cotizacion.aprobado = false
            this.cotizacion.revisado = false
            this.cotizacion.por_revisar = false
            this.cotizacion.descartado = false
            this.cotizacion.rechazado = false
            this.cotizacion.cerrado = false
            this.cotizacion.por_confirmar = false
            this.cotizacion.usuario_aprobador = null
            this.cotizacion.usuario_rechazo = null
            this.cotizacion.usuario_revisor = null
            this.cotizacion.fecha_envio = null
            delete this.cotizacion.fecha_ingreso
            delete this.cotizacion.fechahora_actualizacion
            delete this.cotizacion.fecha_cierre
            Cotizacion.revisar(this.cotizacion.id, this.cotizacion).then((res) => {
                this.getData()
                this.forceRerender()
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Cotizacion actualizada con éxito'
                })
            })
                .catch((error) => {
                    this.getData()
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al actualizar la cotización'
                    })
                })

        },
        actualizarCambio (cambios) {
            this.formCambio = cambios.map((c) => { return {'moneda': c.moneda, 'tasa_cambio': c.tasa_cambio} })
            console.log(this.formCambio)
            Cotizacion.actualizar_cambio(this.cotizacion.id, this.formCambio).then((res) => {
                this.getData()
                this.forceRerender()
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Cotizacion actualizada con éxito'
                })
            })
                .catch((error) => {
                    this.getData()
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al actualizar la cotización'
                    })
                })

        }
    },
    mounted () {
        this.getData()
        Cambio.query({}).then((res) => {
            this.cambios = res.data
        })
        this.$watch('dataEditConfirmarCambio', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null && this.dataEditConfirmarCambio !== null) {
                this.openModalConfirmarCambio()
            }
        }, { immediate: true })
    },
    computed: {
        user() {
            return this.$store.state.user
        },
        isAdmin() {
            return this.$store.state.admin
        },
        permisoRevisar() {
            return this.$store.state.permiso_revisar
        },
        permisoAprobar() {
            return this.$store.state.permiso_aprobar
        },
        permisoRechazar() {
            return this.$store.state.permiso_rechazar
        },
        permisoIngresar() {
            return this.$store.state.permiso_ingresar
        },
        permisoEditar() {
            return this.$store.state.permiso_editar
        }
    }
}
</script>
