<template>
  <div>
    <Header :empresa-nombre="empresaNombre" :empresa-id="empresaId"></Header>
    <modal-articulo modal="modal-create-articulo" :edit="false" ref="modalCreate" title="Crear Artículo" @accept="getData()"></modal-articulo>
    <modal-articulo modal="modal-edit-articulo" :articulo="modalEditData" :edit="true" ref="modalEdit" title="Editar Artículo" @accept="modalEditData=null;" v-if="modalEditData"></modal-articulo>
    <modal-documento :articulo="modalUploadData" ref="modalUpload" title="Cargar Documentos" @reset="modalUploadData=null;" v-if="modalUploadData"></modal-documento>
    <modal-respaldo v-if='empresaId' :empresa="empresaId" :update="dataUpdate" ref="modalRespaldo" @accept="getData();dataUpdate=null;"></modal-respaldo>
    <div class="container-fluid">
      <div class="card">
        <div class="card__header">
          <h2 class="card__title">Artículos</h2>
          <div class="dropdown">
            <a data-toggle="dropdown" class="button button--line-primary dropdown-toggle" style="cursor: pointer;" aria-haspopup="true" aria-expanded="false">
              <span class="button__text mr-3">Acciones</span>
              <font-awesome-icon :icon="['fas', 'ellipsis-v']" class="button__icon--left" />
            </a>
            <ul class="dropdown-menu dropstart" x-placement="top-start" aria-labelledby="dropdownMenuLink">
              <li class="nav-item">
                <a class="nav-link" style="cursor: pointer;" @click="downloadXls()">Descargar Artículos (XLS)</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" style="cursor: pointer;" @click="setDataUpdate()">Cargar Artículos para {{empresaNombre | capitalize}} (XLS)</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" style="cursor: pointer;" @click="setDataUpdate('activo')">Actualizar Artículos</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" style="cursor: pointer;" @click="setDataUpdate('inventario')">Inventario</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" style="cursor: pointer;" @click="openModalCreate()">Crear Artículo</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="card__body">
          <v-app v-if="articulos">
            <v-main>
              <v-data-table
              :headers="headers"
              :items="articulos"
              item-key="id"
              class="elevation-1 table-striped table-hover table-borderless"
              :hide-default-footer="true"
              :disable-sort="true"
              loading-text="Cargando Artículo"
              mobile-breakpoint="0">
                <template v-slot:no-data>
                    No existen artículos
                </template>
                <template v-slot:header="{ props }">
                  <tr class="grey lighten-3">
                      <th v-for="header in props.headers" :key="header.text">
                          <div v-if="filters.hasOwnProperty(header.value)">
                              <v-autocomplete
                                  flat
                                  hide-details
                                  multiple
                                  attach
                                  chips
                                  dense
                                  clearable
                                  :items="columnValueList(header.value)"
                                  v-model="filters[header.value]"
                                  header-fixed
                                  no-data-text="No hay información"
                              >
                                  <template v-slot:selection="{ item, index }">
                                      <v-chip v-if="index === 0">
                                          <span>
                                              +{{ filters[header.value].length }}
                                          </span>
                                      </v-chip>
                                  </template>
                              </v-autocomplete>
                          </div>
                      </th>
                  </tr>
                </template>
                <template v-slot:item.precio="{ item }">
                  <span v-if="getLastPrice(item.precio)">
                    <vue-numeric currency="$" separator="." v-model="getLastPrice(item.precio)[0]" disabled style="text-align:right;" v-bind:precision="3" size="8rem"></vue-numeric>
                  </span>
                </template>
                <template v-slot:item.variable="{ item }">
                    <span v-if="item.variable">
                        Sí
                    </span>
                    <span v-else>
                        No
                    </span>
                </template>
                <template v-slot:item.activo="{ item }">
                    <span v-if="item.activo">
                        Sí
                    </span>
                    <span v-else>
                        No
                    </span>
                </template>
                <template v-slot:item.accion="{ item }">
                  <div class="btn-group btn-group-sm" role="group" aria-label="Second group">
                    <a @click="setDataEdit(item)" class="button--sm button--primary" v-b-tooltip.hover title="Editar"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></a>
                    <a @click="setDataUpload(item)" class="button--sm button--secondary" v-b-tooltip.hover title="Cargar documentos" v-show="item.variable || item.documentos.length > 0 || item.codigo.includes('SIN CODIGO')"><font-awesome-icon :icon="['fas', 'file-upload']" /></a>
                    <!-- <a @click="getPrecios(item)"><font-awesome-icon :icon="['fas', 'dollar-sign']" /></a> -->
                    <!-- <a @click="deleteAlert(item)" class="btn btn-sm btn-danger" v-b-tooltip.hover title="Eliminar"><font-awesome-icon :icon="['fas', 'trash-alt']" /></a> -->
                    <a @click="activeArticulo(item)" id="active-articulo" class="button--sm" :class="{'button--danger': item.activo, 'button--success': !item.activo}">
                      <b-tooltip target="active-articulo">
                        <span v-if="item.activo">Deshabilitar</span>
                        <span v-if="!item.activo">Habilitar</span>
                      </b-tooltip>
                      <font-awesome-icon v-if="item.activo" :icon="['fas', 'ban']" />
                      <font-awesome-icon v-if="!item.activo" :icon="['fas', 'circle']" />
                    </a>
                  </div>
                </template>
              </v-data-table>
              <div class="text-center">
                  <v-pagination
                    v-model="page"
                    :length="numPages"
                    :total-visible="5"
                  ></v-pagination>
              </div>
            </v-main>
          </v-app>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
import Header from '@/components/Header.vue'
import ModalArticulo from '@/modals/ModalArticulo.vue'
import ModalDocumento from '@/modals/ModalDocumento.vue'
import ModalRespaldo from '@/modals/ModalRespaldo.vue'

const Articulo = resource('/cotizacion/api/gestor_articulos/', {
    update: (id, params) => axios.put(`/cotizacion/api/gestor_articulos/${id}/`, { params })
}, axios)
const ArticuloFiltro = resource('/cotizacion/api/filtro_articulos/', axios)

export default {
    name: 'GestorArticulos',
    components: {
        Header,
        ModalArticulo,
        ModalDocumento,
        ModalRespaldo
    },
    data () {
        return {
            empresaId: this.$route.params.empresaId,
            empresaNombre: this.$route.params.empresaNombre,
            articulos: [],
            currentPage: 1,
            perPage: 10,
            rows: null,
            page: 1,
            countPagination: null,
            numPages: null,
            formProducto: {},
            pagination: {
                page: 1
            },
            footerProps: {
                'disable-items-per-page': false,
                'items-per-page-options': [10, 20, 30, -1],
                'items-per-page-text': 'Artículos por página'
            },
            filters: {
                codigo: [],
                descripcion: [],
                // precio: [],
                // tipo__nombre: [],
                // unidad__abreviatura: [],
                // moneda: [],
                // costos: [],
                // variable: [],
                // activo: []
            },
            modalEditData: null,
            modalUploadData: null,
            count: 0,
            dataUpdate: null,
            articulosFiltro: []
        }
    },
    methods: {
        getData () {
            var params = {
                empresa: this.empresaId,
                page: this.page,
                per_page: this.perPage,
                codigo: this.filters.codigo,
                descripcion: this.filters.descripcion
            }
            Articulo.query(params).then((res) => {
                this.articulos = res.data.items
                this.countPagination = res.data.paginate.count
                this.numPages = res.data.paginate.num_pages
                this.currentPage = res.data.paginate.page
            })
        },
        getDataFiltro () {
            ArticuloFiltro.query({ empresa: this.empresaId }).then((res) => {
                this.articulosFiltro = res.data
            })
        },
        columnValueList (val) {
            var dict = []
            dict = this.articulosFiltro.map((d) => d[val])
            return dict.sort()
        },
        setDataEdit (item) {
            this.modalEditData = item
        },
        openModalEdit () {
            this.$refs.modalEdit.openModalEdit()
        },
        openModalCreate () {
            this.$refs.modalCreate.openModalCreate()
        },
        deleteAlert (articulo) {
            this.$swal({
                title: 'Vas a eliminar el artículo',
                text: '¿Estás seguro? Se perderá toda la información asociada',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteArticulo(articulo)
                }
            })
        },
        deleteArticulo(articulo) {
            Articulo.delete(articulo.id).then((res) => {
                this.getData()
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Artículo eliminado con exito'
                })
            })
                .catch((error) => {
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al eliminar el artículo'
                    })
                })
        },
        activeArticulo(articulo) {
            articulo.activo = !articulo.activo
            var title = 'Artículo deshabilitado con éxito'
            if (articulo.activo)
                title = 'Artículo habilitado con éxito'
            Articulo.update(articulo.id, articulo).then((res) => {
                this.getData()
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: title
                })
            })
                .catch((error) => {
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al actualizar el artículo'
                    })
                })
        },
        getLastPrice(precio) {
            var price = precio[precio.length - 1]
            if (price){
                let formatedPrice = parseFloat(price.precio)
                return [formatedPrice]
            }
            return ''
        },
        setDataUpload (item) {
            this.modalUploadData = item
        },
        openModalUpload () {
            this.$refs.modalUpload.openModal()
        },
        openModalRespaldo () {
            this.$refs.modalRespaldo.openModal()
        },
        setDataUpdate (item) {
            if (item)
                this.dataUpdate = item
            else
                this.dataUpdate = ''
        },
        downloadXls () {
            axios({
                url: `/cotizacion/api/gestor_articulos/xls_articulos/?empresa=${this.empresaId}`,
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                this.count = 0
                var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}))
                var fileLink = document.createElement('a')

                fileLink.href = fileURL
                window.open(fileURL)
            })
                .catch((error) => {
                    this.count++
                    if (error.response && error.response.status === 500) {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Ocurrió un error al descargar el documento'
                        })
                    }
                    else if (error.response && error.response.status === 401 && this.count < 5) {
                        this.getData()
                        this.downloadXls()
                    }
                    else
                        this.$router.go()
                })
        }
    },
    mounted () {
        this.getData()
        this.getDataFiltro()
        this.$watch('modalEditData', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null) {
                this.openModalEdit()
            }
        }, { immediate: true })
        this.$watch('modalUploadData', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null) {
                this.openModalUpload()
            }
        }, { immediate: true })
        this.$watch('dataUpdate', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null) {
                this.openModalRespaldo()
            }
        }, { immediate: true })
        this.$watch('page', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null) {
                this.getData()
            }
        }, { immediate: true })
        this.$watch('filters.codigo', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null && newLocale.length > 0) {
                this.getData()
            }
        }, { immediate: true })
        this.$watch('filters.descripcion', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null && newLocale.length > 0) {
                this.getData()
            }
        }, { immediate: true })
    },
    computed: {
        headers () {
            return [
                { text: 'Código', value: 'codigo', align: 'center' },
                { text: 'Descripción', value: 'descripcion', align: 'center' },
                { text: 'Tipo de Artículo', value: 'tipo__nombre', align: 'center' },
                { text: 'Unidad de Medida', value: 'unidad__abreviatura', align: 'center' },
                { text: 'Moneda', value: 'moneda', align: 'center' },
                { text: '¿Artículo sin precio?', value: 'variable', align: 'center' },
                { text: '¿Está habilitado?', value: 'activo', align: 'center' },
                { text: 'Último Precio Registrado', value: 'precio', align: 'center' },
                { text: 'Inventario Disponible', value: 'stock_disponible', align: 'center' },
                { text: 'Acción', value: 'accion', sortable: false, align: 'center'}
            ]
        },        
        filteredRows () {
            if (this.articulos) {
                return this.articulos.filter((d) => {
                    return Object.keys(this.filters).every((f) => {
                        return this.filters[f].length < 1 || this.filters[f].includes(d[f])
                    })
                })
            }
            return null
        }
    }
}
</script>
