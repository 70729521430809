<template>
    <div>
        <b-modal id="modal-cargar-documento" size="lg" title="Cargar Documentos" no-close-on-esc no-close-on-backdrop hide-header-close>
            <div class="row" v-if="!$parent.porConfirmar && !$parent.cerrado">
                <div class="form-group col-md-10">
                    <label>Documentos*</label>
                    <b-form-file multiple :accept="acceptedFiles" v-model="files" drop-placeholder="Deja aquí los documentos" placeholder="No hay documentos seleccionados" ref="files">
                       <template slot="file-name" slot-scope="{ names }">
                             <b-badge variant="light">{{ names[0] }}</b-badge>
                             <b-badge v-if="names.length > 1" variant="light" class="ml-1">
                               + {{ names.length - 1 }}
                             </b-badge>
                       </template>
                    </b-form-file>
                </div>
            </div>
            <div class="row" v-if="documentos.length>0">
                <div class="col-md-12">
                    <p>
                        <strong>Documentos disponibles</strong>
                    </p>
                    <ul class="files-list">
                        <li v-for="documento in documentos" v-bind:key="documento.id">
                            <a  class="file-name" @click="downloadFile(documento)" target="_blank" download>
                                <font-awesome-icon :icon="['fas', 'file']" />
                                <span class="file-name__text">
                                    {{ documento.nombre }}
                                    <br>
                                    <i>{{documento.user_create__get_nombre_completo}} — <strong>{{documento.fechahora_created}}</strong></i>
                                </span>
                            </a>
                            <a @click="deleteAlert(documento);" class="delete-file" v-if="isAdmin">
                                <font-awesome-icon :icon="['fas', 'trash-alt']" />
                            </a>
                        </li>
                    </ul>
                </div>
<!--                 <div class="col-xs-4">
                    <a  v-if="archivos.length > 0" class="btn btn-file pull-right" @click="descargarTodos()">
                        <font-awesome-icon :icon="['fas', 'file-archive']" /> Descargar Archivos
                    </a>
                </div> -->
            </div>
            <div else>
                <p>No hay documentos disponibles</p>
            </div>
            <template #modal-footer>
                <button class="button button--secondary" @click="closeModal()">
                    <span class="button__text">Cancelar</span>
                    <font-awesome-icon :icon="['fas', 'times-circle']" class="button__icon" />
                </button>
                <button class="button button--success" @click="uploadFile()" :disabled="files.length===0">
                    <span class="button__text">Guardar</span>
                    <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
                </button>
            </template>
        </b-modal>
    </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'

const Documento = resource('/cotizacion/api/gestor_documento_respaldo/', {
    download: (id) => axios.put(`/cotizacion/api/gestor_documento_respaldo/${id}/download/`),
    delete: (id) => axios.delete(`/cotizacion/api/gestor_documento_respaldo/${id}/`)
}, axios)

export default {
    name: 'ModalDocumento',
    props: ['articulo'],
    data () {
        return {
            documentos: [],
            files: [],
            acceptedFiles: 'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, application/x-rar-compressed,application/zip,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/png, image/jpeg'
        }
    },
    methods: {
        getData () {
            Documento.query({ articulo: this.articulo.id })
                .then((response) => {
                    this.documentos = response.data
                })
                .catch((response) => {
                    console.log(response)
                })
        },
        openModal () {
            this.$root.$emit('bv::show::modal', 'modal-cargar-documento')
        },
        closeModal () {
            this.$emit('reset')
            this.$root.$emit('bv::hide::modal', 'modal-cargar-documento')
        },
        uploadFile () {
            var formData = new FormData()
            for (var i = 0; i < this.files.length; i++) {
                var file = this.files[i]
                formData.append('files[' + i + ']', file)
            }
            formData.append('articulo', this.articulo.id)
            formData.append('cotizacion', this.$parent.cotizacionId)
            if (this.$parent.empresaId)
                formData.append('empresa', this.$parent.empresaId)
            else
                formData.append('empresa', this.$parent.$parent.empresaId)
            axios.post('/cotizacion/api/gestor_documento_respaldo/',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((response) => {
                    this.files = []
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Archivos cargados exitosamente'
                    })
                    this.closeModal()
                })
                .catch((response) => {
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al cargar los Archivos'
                    })
                })
        },
        downloadFile (documento) {
            Documento.download(documento.id)
                .then((response) => {
                    window.open(response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        deleteFile (documento) {
            Documento.delete(documento.id)
                .then((response) => {
                    this.getData()
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Documento eliminado exitosamente'
                    })
                })
                .catch((response) => {
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al eliminar el Documento'
                    })
                })
        },
        deleteAlert (documento) {
            this.$swal({
                title: 'Vas a eliminar este documento, ¿estás seguro?',
                text: 'Se perderá para siempre.',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteFile(documento)
                }
            })
        }
    },
    mounted () {
        this.getData()
    },
    computed: {
        isAdmin() {
            return this.$store.state.admin
        }
    }
}
</script>
