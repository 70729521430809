<template>
    <v-overlay :value="true">
        <v-progress-circular
            indeterminate
            size="100"
            width="10"
        ></v-progress-circular>
    </v-overlay>
</template>

<script>

export default {
    name: 'Loader',
    components: {
    },
    data () {
        return {
        }
    }
}
</script>