<template>
  <div>
    <Header :empresa-nombre="empresaNombre" :empresa-id="empresaId"></Header>
    <modal-respaldo v-if='empresaId' :empresa="empresaId" :calendar__ov="true" ref="modalRespaldo" @accept="reIniciarDatos()" :orden-venta="true"></modal-respaldo>
    <modal-nueva-orden modal="modal-create-orden" ref="modalOVManual" @accept="getOrdenesVenta();"></modal-nueva-orden>
    <div class="container-fluid">
      <div class="row">
        <div class="col-3">
          <div class="card mb-3" id="card-ovs-sinconfirmar-logistico">
            <button class="card__header" v-b-toggle.collapse-6>
              <span class="card__title">Sin Confirmar</span>
              <font-awesome-icon :icon="['fas', 'chevron-down']" />
            </button>
            <b-collapse visible id="collapse-6">
              <div class="card__body py-5" id="contenedor-ovs-sinconfirmar" @click="handleTargetClick($event, moverOvs)" @mouseover="overParaMultipleSelecccion($event)" @mouseleave="leaveParaMultipleSelecccion($event)">
                <div class="alert--sm alert--danger" role="alert" v-if="ordenesSinConfirmar.length === 0">
                  <font-awesome-icon :icon="['fas', 'exclamation-circle']" class="alert__icon--sm" />
                  <span class="alert__text--sm">No existen OV sin confirmar</span>
                </div>
                <button class="calendar__ov" v-for="orden in ordenesSinConfirmar" :key="orden.id" :draggable="isDraggableOV()" @click="handleSingleClick($event, isDraggableOV())" :class="getClassRegion(orden.region, orden.estados)" :data-ov-id="orden.id">
                    {{orden.numero}} | {{orden.detalles.id_embarque}}
                    <font-awesome-icon v-if="getClassStockOrden(orden)" :class="getClassStockOrden(orden)" :icon="['fa', 'circle']" />
                    <span class="calendar__span--sin--completar" v-if="orden.estados.count_stock && orden.estados.count_stock > 0 && 'total_stock' in orden.estados"> {{orden.estados.total_stock}}%</span>
                </button>
              </div>
            </b-collapse>
          </div>
          <div class="card" id="card-ovs-logistico">
            <button class="card__header" v-b-toggle.collapse-7>
              <span class="card__title">Ordenes de Venta</span>
              <font-awesome-icon :icon="['fas', 'chevron-down']" />
            </button>
            <b-collapse visible id="collapse-7">
              <div class="card__body card__scroll" id="contenedor-ovs" @click="handleTargetClick($event, moverOvs)" @mouseover="overParaMultipleSelecccion($event)" @mouseleave="leaveParaMultipleSelecccion($event)">
                <multiselect class="mb-3" v-model="regionesSeleccionadas" :options="regiones" :multiple="true" :close-on-select="true" :clear-on-select="true"
                  :preserve-search="true" placeholder="Elige una región" @input="getOrdenesVenta" v-show="regiones.length > 0">
                <template slot="tag" slot-scope="{ option, remove }">
                  <span class="multiselect__tag" :class="getClassRegion(option)">
                    <span class="mr-2">{{ option }}</span>
                    <span class="custom__remove" @click="remove(option)">
                      <font-awesome-icon :icon="['fa', 'minus-circle']" />
                    </span>
                  </span>
                </template>
                </multiselect>
                <multiselect class="m-0 mb-3" v-model="ubicacionesSeleccionadas" :options="ubicaciones" :multiple="true" :close-on-select="true" :clear-on-select="true"
                :preserve-search="true" placeholder="Elige una ubicación" @input="getOrdenesVenta" v-show="ubicaciones.length > 0">
                <template slot="tag" slot-scope="{ option, remove }">
                <span class="multiselect__tag">
                    <span class="mr-2">{{ option }}</span>
                    <span class="custom__remove" @click="remove(option)">
                    <font-awesome-icon :icon="['fa', 'minus-circle']" />
                    </span>
                </span>
                </template>
                </multiselect>
                <div class="alert--sm alert--primary" role="alert">
                  <font-awesome-icon :icon="['fas', 'info-circle']" class="alert__icon--sm" />
                  <span class="alert__text--sm">Puedes hacer doble click en las ordenes de venta para ver su información</span>
                </div>
                <div class="alert--sm alert--danger" role="alert" v-if="ordenes.length === 0">
                  <font-awesome-icon :icon="['fas', 'exclamation-circle']" class="alert__icon--sm" />
                  <span class="alert__text--sm">No existen OV por agendar</span>
                </div>
                <div v-for="(ovsByDespacho, despacho) in getOrdenesByDespacho(ordenes)" :key="despacho">
                  <h6 class="card__subtitle">{{despacho}}</h6>
                  <div v-if="despacho === 'TRANSPORTE INTERNO'"> 
                    <div v-for="(ovsByRegion, region) in getOrdenesByRegion(ovsByDespacho)" :key="region">
                      <h6>{{region}}</h6>
                      <div class="calendar__aside">
                        <button class="calendar__ov aside__ov" v-for="orden in ovsByRegion" :key="orden.id" :draggable="isDraggableOV()" @click="handleSingleClick($event, isDraggableOV())" :class="getClassRegion(orden.region, orden.estados)" :data-ov-id="orden.id">
                          {{orden.numero}} | {{orden.detalles.id_embarque}}
                          <font-awesome-icon v-if="getClassStockOrden(orden)" :class="getClassStockOrden(orden)" :icon="['fa', 'circle']" />
                          <span class="calendar__span--sin--completar" v-if="orden.estados.count_stock && orden.estados.count_stock > 0 && 'total_stock' in orden.estados"> {{orden.estados.total_stock}}%</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="calendar__aside" v-else>
                    <button class="calendar__ov aside__ov" v-for="orden in ovsByDespacho" :key="orden.id" :draggable="isDraggableOV()" @click="handleSingleClick($event, isDraggableOV())" :class="getClassRegion(orden.region, orden.estados)" :data-ov-id="orden.id">{{orden.numero}} | {{orden.detalles.id_embarque}}
                      <font-awesome-icon v-if="getClassStockOrden(orden)" :class="getClassStockOrden(orden)" :icon="['fa', 'circle']" />
                      <span class="calendar__span--sin--completar" v-if="orden.estados.count_stock && orden.estados.count_stock > 0 && 'total_stock' in orden.estados"> {{orden.estados.total_stock}}%</span>
                    </button>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
        <div class="col-9">
          <div class="card" id="card-calendario-bodega">
            <div class="card__header">
              <h4 class="card__title">Bodega</h4>
              <div class="dropdown">
                <a data-toggle="dropdown" class="button button--line-primary dropdown-toggle" style="cursor: pointer;" aria-haspopup="true" aria-expanded="false">
                  <span class="button__text mr-3">Acciones</span>
                  <font-awesome-icon :icon="['fas', 'ellipsis-v']" class="button__icon--left" />
                </a>
                <ul class="dropdown-menu dropstart" x-placement="top-start" aria-labelledby="dropdownMenuLink">
                  <li class="nav-item">
                    <a class="nav-link" style="cursor: pointer;" @click="openModalRespaldo()" v-if="isBodega || isLogistica || isAdmin">Cargar Ordenes de Venta (XLS)</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" style="cursor: pointer;" @click="openModalOVManual()" v-if="isBodega || isLogistica || isAdmin">Crear OV Manual</a>
                  </li>
                  <li class="nav-item">
                    <!-- &nbsp; -->
                    <router-link class="nav-link" style="cursor: pointer;" :to="`/${empresaId}/${empresaNombre}/logistica`" :class="empresaNombre">Ir a Logística</router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="card__body">
              <span class="calendar__legend">
                <span class="calendar__legend--span">
                  <h6>Región I</h6>
                  <font-awesome-icon class=" region-I" :icon="['fa', 'circle']" />
                </span>
                <span class="calendar__legend--span">
                  <h6>Región II</h6>
                  <font-awesome-icon class=" region-II" :icon="['fa', 'circle']" />
                </span>
                <span class="calendar__legend--span">
                  <h6>Región III</h6>
                  <font-awesome-icon class=" region-III" :icon="['fa', 'circle']" />
                </span>
                <span class="calendar__legend--span">
                  <h6>Región IV</h6>
                  <font-awesome-icon class=" region-IV" :icon="['fa', 'circle']" />
                </span>
                <span class="calendar__legend--span">
                  <h6>Región V</h6>
                  <font-awesome-icon class=" region-V" :icon="['fa', 'circle']" />
                </span>
                <span class="calendar__legend--span">
                  <h6>Región VI</h6>
                  <font-awesome-icon class=" region-VI" :icon="['fa', 'circle']" />
                </span>
                <span class="calendar__legend--span">
                  <h6>Región VII</h6>
                  <font-awesome-icon class=" region-VII" :icon="['fa', 'circle']" />
                </span>
                <span class="calendar__legend--span">
                  <h6>Región VIII</h6>
                  <font-awesome-icon class=" region-VIII" :icon="['fa', 'circle']" />
                </span>
                <span class="calendar__legend--span">
                  <h6>Región IX</h6>
                  <font-awesome-icon class=" region-IX" :icon="['fa', 'circle']" />
                </span>
                <span class="calendar__legend--span">
                  <h6>Región X</h6>
                  <font-awesome-icon class=" region-X" :icon="['fa', 'circle']" />
                </span>
                <span class="calendar__legend--span">
                  <h6>Región XI</h6>
                  <font-awesome-icon class=" region-XI" :icon="['fa', 'circle']" />
                </span>
                <span class="calendar__legend--span">
                  <h6>Región XII</h6>
                  <font-awesome-icon class=" region-XII" :icon="['fa', 'circle']" />
                </span>
                <span class="calendar__legend--span">
                  <h6>Región RM</h6>
                  <font-awesome-icon class=" region-RM" :icon="['fa', 'circle']" />
                </span>
                <span class="calendar__legend--span">
                  <h6>Región XIV</h6>
                  <font-awesome-icon class=" region-XIV" :icon="['fa', 'circle']" />
                </span>
                <span class="calendar__legend--span">
                  <h6>Región XV</h6>
                  <font-awesome-icon class=" region-XV" :icon="['fa', 'circle']" />
                </span>
                <span class="calendar__legend--span">
                  <h6>Región XVI</h6>
                  <font-awesome-icon class=" region-XVI" :icon="['fa', 'circle']" />
                </span>
                <span class="calendar__legend--span">
                  <h6>Sin región</h6>
                  <font-awesome-icon class=" region-" :icon="['fa', 'circle']" />
                </span>
              </span>
              <div class="custom-control custom-switch ml-3">
                <input type="checkbox" class="custom-control-input" id="switchSeleccion" v-model="seleccionGrupo" @change="limpiarSeleccionadasGrupal()">
                <label class="custom-control-label" for="switchSeleccion">Selección por Grupo</label>
              </div>
              <table v-if="semanas.length > 0" class="table table-bordered">
                <thead>
                  <tr>
                    <th colspan="7">
                      <div class="calendar__header">
                        <button @click="cambiarMes('anterior')" type="button" class="button button--primary">
                            <font-awesome-icon :icon="['fas', 'arrow-left']" />
                        </button>
                        <h6 class="calendar__title">{{this.month.name}} {{this.year}}</h6>
                        <button @click="cambiarMes('proximo')" type="button" class="button button--primary">
                            <font-awesome-icon :icon="['fas', 'arrow-right']" />
                        </button>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th class="calendar__th" scope="col" v-for="(dia, index) in diasSemana" :key="index">{{dia}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(semana, index) in semanas" :key="index" >
                    <td v-for="(dia, index) in getWeekForRender(semana)" :key="index" :ref="`td-${$options.filters.formatDateOnlyDay(dia.date)}`" :data-fecha="dia.formattedDate" data-todos-elementos="false" style=" padding:25px 5px 5px 5px;" :class="getBgColor(dia.date)" @click="handleTargetClick($event, moverOvs)" @mouseover="overParaMultipleSelecccion($event)" @mouseleave="leaveParaMultipleSelecccion($event)">
                      <div class="calendar__td" :data-fecha="dia.formattedDate">
                        <span>{{dia.date | formatDateOnlyDay}}</span>
                        <template v-for="(orden, idx) in getOVParaDia(dia.formattedDate)">
                          <button class="calendar__ov" v-show="canSeeElement($refs[`td-${$options.filters.formatDateOnlyDay(dia.date)}`], idx, cambioUnElementoTd)" :key="orden.id" :draggable="isDraggableOvInCalendar(orden)" @click="handleSingleClick($event, isDraggableOvInCalendar(orden))" :class="getClassRegion(orden.region, orden.estados)" :data-ov-id="orden.id">
                            {{orden.numero}} | {{orden.detalles.id_embarque}}
                            <font-awesome-icon v-if="getClassStockOrden(orden) && !orden.estados.despachado" :class="getClassStockOrden(orden)" :icon="['fa', 'circle']" />
                            <span class="calendar__span--sin--completar" v-if="orden.estados.count_stock && orden.estados.count_stock > 0 && 'total_stock' in orden.estados && !orden.estados.despachado"> {{orden.estados.total_stock}}%</span>
                          </button>
                        </template>
                        <button type="button" class="btn btn-link" v-if="canSeeButton($refs[`td-${$options.filters.formatDateOnlyDay(dia.date)}`], getOVParaDia(dia.formattedDate))" @click="mostrarOVOcultas($refs[`td-${$options.filters.formatDateOnlyDay(dia.date)}`])">...</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal ref="my-modal" size="xl" hide-footer :title="`${this.infoModal.numero} - ${this.infoModal.region}`">
      <div>
        <div class="card__buttons mb-3" style="align-item:center">
          <h4 class="card__subtitle mb-0">Nº Cotización:</h4>
          <span class="badge badge--primary">{{this.infoModal.numeroCotizacion}}</span>
          <h4 class="card__subtitle mb-0">Fecha Orden:</h4>
          <span class="badge badge--secondary">{{this.infoModal.fechaOrden}}</span>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="table-responsive">
              <table class="table">
                <thead class="thead">
                  <tr>
                    <th colspan="2">Detalle</th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th class="card__subtitle" colspan="2">Datos de la venta</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="table__td">Cliente</td>
                    <td class="table__td">{{infoModal.detalles.numero_cliente}} - {{infoModal.detalles.nombre_cliente}}</td>
                  </tr>
                  <tr>
                    <td class="table__td">Correo Electrónico</td>
                    <td class="table__td">{{infoModal.detalles.email}}</td>
                  </tr>
                  <tr>
                    <td class="table__td">Tipo de Despacho</td>
                    <td class="table__td">{{infoModal.detalles.despacho}}</td>
                  </tr>
                  <tr>
                    <td class="table__td">Forma de Pago</td>
                    <td class="table__td">{{infoModal.detalles.forma_pago}}</td>
                  </tr>
                  <tr>
                    <td class="table__td">Vendedor</td>
                    <td class="table__td">{{infoModal.detalles.nombre_vendedor}}</td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th class="card__subtitle" colspan="2">Datos de despacho</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="table__td">LAT, LNG</td>
                    <td class="table__td">{{infoModal.detalles.lat}}<b>,</b> {{infoModal.detalles.long}}</td>
                  </tr>
                  <tr>
                    <td class="table__td">ID de Embarque</td>
                    <td class="table__td">{{infoModal.detalles.id_embarque}}</td>
                  </tr>
                  <tr>
                    <td class="table__td">Región</td>
                    <td class="table__td">{{infoModal.detalles.region}}</td>
                  </tr>
                  <tr>
                    <td class="table__td">Comuna</td>
                    <td class="table__td">{{infoModal.detalles.comuna}}</td>
                  </tr>
                  <tr>
                    <td class="table__td">Ciudad</td>
                    <td class="table__td">{{infoModal.detalles.ciudad}}</td>
                  </tr>
                  <tr>
                    <td class="table__td">Dirección</td>
                    <td class="table__td">{{infoModal.detalles.direccion}}</td>
                  </tr>
                  <tr>
                    <td class="table__td">Observaciones</td>
                    <td class="table__td">{{infoModal.detalles.observaciones}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-md-6">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr class="thead">
                    <th colspan="6">Articulos</th>
                  </tr>
                  <tr>
                    <th class="thead__th" scope="col">Item</th>
                    <th class="thead__th" scope="col" v-if="!infoModal.fechaActualizada">Orden</th>
                    <th class="thead__th" scope="col" v-if="!infoModal.fechaActualizada">Stock</th>
                    <th class="thead__th" scope="col" v-if="!infoModal.fechaActualizada">Sin Stock</th>
                    <th class="thead__th" scope="col" v-if="!infoModal.fechaActualizada">Lista a Envío</th>
                    <th class="thead__th" scope="col" v-if="!infoModal.fechaActualizada">Despachada</th>
                    <th class="thead__th" scope="col" v-if="infoModal.fechaActualizada">Despachada</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(articulo, index) in infoModal.articulos" :key="index">
                    <td class="table__td">
                        <strong>{{articulo.codigo}}</strong> - {{articulo.descripcion}}
                    </td>
                    <td class="table__td" v-if="!infoModal.fechaActualizada">
                        {{articulo.orden}}
                    </td>
                    <td class="table__td" v-if="!infoModal.fechaActualizada">
                        {{articulo.stock}}
                    </td>
                    <td class="table__td" v-if="!infoModal.fechaActualizada">
                        {{articulo.sin_stock}}
                    </td>
                    <td class="table__td" v-if="!infoModal.fechaActualizada">
                        {{articulo.lista_envio}}
                    </td>
                    <td class="table__td" v-if="!infoModal.fechaActualizada">
                        {{articulo.despachada}}
                    </td>
                    <td class="table__td" v-if="infoModal.fechaActualizada">
                        {{articulo.cantidad_factura}} {{articulo.um}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import ModalRespaldo from '@/modals/ModalRespaldo.vue'
import Header from '@/components/Header.vue'
import ModalNuevaOrden from '@/logistica/ModalNuevaOrden.vue'

import { utils } from '@/logistica/utils.js'

let elementoArrastrandose = null

export default {
    name: 'CalendarioBodega',
    mixins: [utils],
    components: { Multiselect, ModalRespaldo, Header, ModalNuevaOrden },
    data () {
        return {
            empresaId: this.$route.params.empresaId,
            empresaNombre: this.$route.params.empresaNombre,
            diasSemana: ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'],
            dias: [],
            semanas: [],
            fecha: new Date(),
            fechaActual: new Date(new Date().setHours(0,0,0,0)),
            month: {},
            year: null,
            months: [
                'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
                'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
            ],
            ordenes: [],
            ordenesSinConfirmar: [],
            ordenesAgendadas: [],
            infoModal: {
                numeroCotizacion: null,
                numero: null,
                region: null,
                detalles: {},
                articulos: [],
            },
            regiones: [],
            regionesSeleccionadas: [],
            ubicaciones: [],
            ubicacionesSeleccionadas: [],
        }
    },
    methods: {
        getDays () {
            const y = this.fecha.getFullYear(), m = this.fecha.getMonth()
            const firstDay = new Date(y, m, 1)
            const lastDayNumber = new Date(y, m + 1, 0).getDate()

            const day = new Date(firstDay)
            this.dias = []
            for (let i = 1; i <= lastDayNumber; i++) {
                this.dias.push({
                    date: new Date(day),
                    formattedDate: this.$options.filters.formatDateTimeSlug(day)
                })
                day.setDate(day.getDate() + 1)
            }
            this.setAllWeeks(this.getFirstMonday())
            this.getFechaActiva(new Date())
        },
        getFirstMonday () {
            const y = this.fecha.getFullYear(), m = this.fecha.getMonth()
            const firstDay = new Date(y, m, 1)
            const day = firstDay.getDay()
            
            let diff = firstDay.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is sunday
            return new Date(firstDay.setDate(diff))
        },
        setAllWeeks (firstMonday) {
            const largoArray = this.dias.length

            const nextModay = new Date(firstMonday)
            nextModay.setDate(nextModay.getDate() + 7)

            this.semanas = []
            let semanaActual = []

            for (let i = 0; i < largoArray; i++) {
                    
                if (this.dias[i].date.getDate() === nextModay.getDate()) {
                    nextModay.setDate(nextModay.getDate() + 7)
                    this.semanas.push(semanaActual)
                    semanaActual = [this.dias[i]]
                } else { semanaActual.push(this.dias[i]) }

            }
            this.semanas.push(semanaActual)
        },
        getWeekForRender(week) {
            if (week.length == 7) {
                return week
            }

            const renderWeek = []
            if (week[0].date.getDay() === 1) {
                for (let i = 0; i < 7; i++) {
                    const dia = week[i]
                    dia ? renderWeek.push(dia) : renderWeek.push({date: null, formattedDate: null})
                }
            } else {
                for (let i = 0; i < 7; i++) {
                    const dia = week[i]
                    dia ? renderWeek.push(dia) : renderWeek.unshift({date: null, formattedDate: null})
                }
            }

            return renderWeek
        },
        getBgColor (fechaOV) {
            const cpFechaOV = new Date(fechaOV)
            cpFechaOV.setHours(0,0,0,0)
            // debugger
            if (cpFechaOV !== null && cpFechaOV > this.fechaActual) {
                return 'contenedor'
            } else if (cpFechaOV !== null && cpFechaOV.getTime() === this.fechaActual.getTime()) {
                return 'contenedor calendar__today'
            } else {
                return 'bg-light'
            }
        },
        isDraggableOvInCalendar (ov) {
            return !ov.estados.despachado && this.$store.state.permiso_editar_bodega
        },
        isDraggableOV () {
            return this.$store.state.permiso_editar_bodega
        },
        getFechaActiva () {
            const indexMonth = this.fecha.getMonth()
            this.month = { index: indexMonth, name:  this.months[indexMonth] }
            this.year = this.fecha.getFullYear()
        },
        cambiarMes (mes) {
            let diferencia = 0
            if (mes === 'proximo') { diferencia = 1 }
            else if (mes === 'anterior') { diferencia = -1 }

            const nextMonth = this.month.index + diferencia
            this.fecha = new Date(this.year, nextMonth, 1)
            this.getDays()
            this.getOVsAgendadas()
        },
        // Ordenes de Venta
        getRegiones () {
            const queryParams = { fechahora_bodega__isnull: true, empresa: this.empresaId }
            axios.get('/logistica/api/regiones/', { params: queryParams }).then(res => {
                this.regiones = res.data.results
            })
        },
        // getOrdenesVenta tiene otro queryparams, es decir, no es igual a la de logistica 
        getOrdenesVenta () {
            const queryParams = { fechahora_bodega__isnull: true, empresa: this.empresaId }
            if (this.regionesSeleccionadas.length >  0) {
                queryParams['region__in'] = this.regionesSeleccionadas.join(',')
            }
            if (this.ubicacionesSeleccionadas.length >  0) {
                queryParams['detalles__id_embarque__in'] = this.ubicacionesSeleccionadas
            }
            axios.get('/logistica/api/ordenes_venta/', {params: queryParams}).then(res => {
                this.ordenes = res.data.filter(ov => ov.estados.sin_confirmar === false)
                this.ordenesSinConfirmar = res.data.filter(ov => ov.estados.sin_confirmar === true)
                this.ubicaciones = [...new Set(res.data.map((res) => { return res.detalles.id_embarque }).sort())]
                // console.log('%c ordenes', 'color:green', this.ordenes)
            })
        },
        // getOVsAgendadas tiene otro queryparams, es decir, no es igual a la de logistica 
        getOVsAgendadas () {
            axios.get('/logistica/api/ordenes_venta/', {params: { fechahora_bodega__isnull: false, empresa: this.empresaId, fechahora_bodega__month: this.fecha.getMonth() + 1 }}).then(res => {
                this.ordenesAgendadas = res.data
                // console.log('%c ordenes agendadas', 'color:green', this.ordenesAgendadas)
            })
        },
        showModal () {
            this.$refs['my-modal'].show()
        },
        getClassRegion (region, estados) {
            var reg = 'region-'
            if (region === 'Región de Atacama') reg = reg + 'III'
            if (region === 'Región de Coquimbo') reg = reg + 'IV'
            if (region === 'Región de Valparaíso') reg = reg + 'V'
            if (region === 'Región Libertador Gral Bernardo O´Higgin') reg = reg + 'VI'
            if (region === 'Región del Maule') reg = reg + 'VII'
            if (region === 'Región del Biobío') reg = reg + 'VIII'
            if (region === 'Región de Los Lagos') reg = reg + 'X'
            if (region === 'Región Metropolitana de Santiago') reg = reg + 'RM'
            if (region === 'Región de Ñuble') reg = reg + 'XVI'
            if (estados && 'externa' in estados && estados['externa']) {
                return reg + ' background--externa'
            }
            return reg
        },
        inicializarOV () {
            // Inicializa los contenedores
            const contenedores = [...document.getElementsByClassName('contenedor')]
            const contenedorOVs = document.getElementById('contenedor-ovs')
            const contenedorOVsSinConfirmar = document.getElementById('contenedor-ovs-sinconfirmar')
            const contenedoresVacios = [...document.getElementsByClassName('bg-light')]
            contenedores.push(contenedorOVs)
            contenedores.push(contenedorOVsSinConfirmar)
            contenedores.forEach(c => {
                c.addEventListener('dragover', this.functionDragOver, false)
                c.addEventListener('dragleave', this.functionDragLeave, false)
                c.addEventListener('drop', this.functionDrop, false)
            })

            contenedoresVacios.forEach(cv => {
                cv.removeEventListener('dragover', this.functionDragOver)
                cv.removeEventListener('dragleave', this.functionDragLeave)
                cv.removeEventListener('drop', this.functionDrop)
            })

            // Inicializa las ordenes de venta
            const cardBodega = document.getElementById('card-calendario-bodega')
            const cardOVs = document.getElementById('card-ovs-logistico')
            const cardOVsSinConfirmar = document.getElementById('card-ovs-sinconfirmar-logistico')
            const ordenesVentaBodega = [...cardBodega.getElementsByClassName('calendar__ov')]
            const ordenesVenta = [...cardOVs.getElementsByClassName('calendar__ov')]
            const ordenesVentaSinConfirmar = [...cardOVsSinConfirmar.getElementsByClassName('calendar__ov')]
            ordenesVenta.forEach(ov => {
                ov.addEventListener('dragstart', this.functionDragStart, false)
                ov.addEventListener('dblclick', this.functionDblClick, false)
            })
            ordenesVentaBodega.forEach(ov => {
                ov.addEventListener('dragstart', this.functionDragStart, false)
                ov.addEventListener('dblclick', this.functionDblClick, false)
            })
            ordenesVentaSinConfirmar.forEach(ov => {
                ov.addEventListener('dragstart', this.functionDragStart, false)
                ov.addEventListener('dblclick', this.functionDblClick, false)
            })
        },
        functionDragOver (event) {
            event.preventDefault()

            const nodo = event.target

            const contenedorTD = this.parentNodeAccordingToCertainClassName(nodo, 'contenedor')

            const contenedorOVs = document.getElementById('contenedor-ovs')
            const isChildContenedorOVs = this.nodoIsChildOf(nodo, contenedorOVs)

            const contenedorOVsSinConfirmar = document.getElementById('contenedor-ovs-sinconfirmar')
            const isChildContenedorOVsSinConfirmar = this.nodoIsChildOf(nodo, contenedorOVsSinConfirmar)
            

            if (isChildContenedorOVs) {
                contenedorOVs.classList.add('over')
            } else if (isChildContenedorOVsSinConfirmar) {
                contenedorOVsSinConfirmar.classList.add('over')
            } else if (contenedorTD) {
                contenedorTD.classList.add('over')
            }

            return false
        },
        functionDragLeave (event) {
            const nodo = event.target

            const contenedorTD = this.parentNodeAccordingToCertainClassName(nodo, 'contenedor')

            const contenedorOVs = document.getElementById('contenedor-ovs')
            const isChildContenedorOVs = this.nodoIsChildOf(nodo, contenedorOVs)
            
            const contenedorOVsSinConfirmar = document.getElementById('contenedor-ovs-sinconfirmar')
            const isChildContenedorOVsSinConfirmar = this.nodoIsChildOf(nodo, contenedorOVsSinConfirmar)
            

            if (isChildContenedorOVs) {
                contenedorOVs.classList.remove('over')
            } else if (isChildContenedorOVsSinConfirmar) {
                contenedorOVsSinConfirmar.classList.remove('over')
            } else if (contenedorTD) {
                contenedorTD.classList.remove('over')
            }
        },
        moverOvs ({ nodoTarget = null, ovs = [] }) {
            if (!nodoTarget) {
                console.log('Ingrese el target')
                return
            }
            ovs.forEach(ov => {
                // Obtiene los contenedores habilitados donde soltar el elemento arrastrado
                const contenedorOVs = document.getElementById('contenedor-ovs')
                const contenedorOVsSinConfirmar = document.getElementById('contenedor-ovs-sinconfirmar')

                // Inicializa nodo Padre del target
                const nodoPadreDelTarget = this.getNodoPadreDelTarget({ nodoTarget: nodoTarget, padresPosibles:[contenedorOVs, contenedorOVsSinConfirmar] })

                const idOrden = ov.id

                // Encuentra la orden en el conjunto de ordenes existentes
                const orden = this.buscarOrdenEnConjunto({ idOrden: idOrden, conjunto: [this.ordenes, this.ordenesSinConfirmar, this.ordenesAgendadas] })
                
                // Encuentra el padre del elemento que se esta arrastrando o moviendo
                // Sirve para saber si el elemento se esta moviendo en el mismo lugar
                elementoArrastrandose = ov.nodo
                const padreDeELementoArrastrandose = this.nodoIsChildOf(elementoArrastrandose, contenedorOVs) ? contenedorOVs : elementoArrastrandose.parentNode

                // Condicion especial, no se puede mover una ov a un espacio de dias pasados
                if (nodoTarget.classList.contains("bg-light") || nodoPadreDelTarget.classList.contains("bg-light")) {
                    elementoArrastrandose.classList.remove("seleccionadoGrupal")
                    return
                }
                  
                // Si se cambia de mes se pierde el nodo padre, quedando este como nulo
                const moviendoEnElMismoLugar = padreDeELementoArrastrandose &&
                (this.nodoIsChildOf(padreDeELementoArrastrandose, nodoTarget) ||
                (this.nodoIsChildOf(padreDeELementoArrastrandose, nodoPadreDelTarget) && nodoPadreDelTarget.nodeName !== 'TR'))

                if (moviendoEnElMismoLugar) {
                    // Si el elemento se esta moviendo en el mismo lugar solo quita las clases que dan color o highlight
                    if (nodoPadreDelTarget.nodeName === 'TD' || nodoPadreDelTarget.id === 'contenedor-ovs' || nodoPadreDelTarget.id === 'contenedor-ovs-sinconfirmar') nodoPadreDelTarget.classList.remove('over')
                    else nodoTarget.classList.remove('over')
                } else if (nodoPadreDelTarget.nodeName === 'TD' || nodoPadreDelTarget.id === 'contenedor-ovs' || nodoPadreDelTarget.id === 'contenedor-ovs-sinconfirmar') {
                    // Si el nodo padre del target es uno de los contenedores habilitados guarda la orden en ese contenedor
                    nodoPadreDelTarget.classList.remove('over')
                    this.guardarOrdenVenta (orden, idOrden, nodoPadreDelTarget)
                } else {
                    // En este caso se considera que el target debe ser uno de los contenedores habilitados
                    nodoTarget.classList.remove('over')
                    this.guardarOrdenVenta (orden, idOrden, nodoTarget)
                }
                elementoArrastrandose.classList.remove("seleccionadoGrupal")
            })
            // Quita todos los elementos del array ovs
            ovs.splice(0)
        },
        functionDrop (event) {
            event.preventDefault()

            const datos = event.dataTransfer.getData('text')
            const ov = {
                id: this.getId(datos),
                nodo: elementoArrastrandose
            }
            this.moverOvs({ nodoTarget: event.target, ovs: [ov] })
            
            event.stopImmediatePropagation()
        },
        functionDragStart (event) {
            elementoArrastrandose = event.target
            const padre = document.createElement('div')
            const clon = event.target.cloneNode(true)
            padre.appendChild(clon)
            event.dataTransfer.setData('text', padre.innerHTML) // el nodo lo pasamos a una cadena
        },
        functionDblClick (event ) {
            debugger
            let orden = null
            const id = +event.target.dataset.ovId
            const nodoPadre = event.target.parentNode
            const fecha = nodoPadre.dataset.fecha

            if (fecha === undefined) {
                orden = this.ordenes.find(ov => ov.id === id)
            } else {
                orden = this.ordenesAgendadas.find(ov => ov.id === id)
            }

            if ( orden === undefined) {
                orden = this.ordenesSinConfirmar.find(ov => ov.id === id)
            }
            if (orden) {
                this.infoModal.numeroCotizacion = orden.numero_cotizacion ?? ''
                this.infoModal.fechaOrden = orden.fecha_orden
                this.infoModal.fechaActualizada = orden.fecha_actualizada
                this.infoModal.numero = orden.numero 
                this.infoModal.region = orden.region
                this.infoModal.detalles = orden.detalles ?? {}
                this.infoModal.articulos = orden.articulos?.map(a => {
                    return {
                        codigo: a.codigo,
                        descripcion: a.descripcion,
                        orden: a.cantidad_ordenada,
                        lista_envio: a.cantidad_facturar,
                        despachada: a.cantidad_fact,
                        stock: a.stock,
                        sin_stock: a.sin_stock,
                        cantidad_factura: a.cantidad_factura,
                        um: a.unidad_medida
                    }
                }) ?? []

                this.showModal()
                event.stopImmediatePropagation()
            }
        },
        getId (texto) {
            const start = texto.indexOf('data-ov-id="') + 12
            const end = texto.indexOf('"', start)
            return +texto.substring(start, end)
        },
        // guardarOrdenVenta ha sido editada, es decir, no es igual a la de logistica 
        guardarOrdenVenta (orden, id, nodo) {
            // debugger;
            // TODO EA: Revisar la hora que se guarda. Al parecer se guarda bien cuando recien se ingresa a la pagina
            if (orden !== undefined) {
                if (nodo.id === 'contenedor-ovs') {
                    if (orden.fechahora_bodega && orden.fechahora_logistica) {
                        const message = 'Error ya se encuentra agendada en logística'
                        this.$notify({ group: 'foo', type: 'error', title: 'Error', text: message })
                        return
                    }
                    orden.fechahora_bodega = null
                    orden.estados.sin_confirmar = false

                    this.modificarArraysOrdenes('Agendadas', orden, 'Confirmadas')
                    this.modificarArraysOrdenes('SinConfirmar', orden, 'Confirmadas')
                } else if (nodo.id === 'contenedor-ovs-sinconfirmar') {
                    if (orden.fechahora_bodega && orden.fechahora_logistica) {
                        const message = 'Error ya se encuentra agendada en logística'
                        this.$notify({ group: 'foo', type: 'error', title: 'Error', text: message })
                        return
                    }
                    orden.fechahora_bodega = null
                    orden.estados.sin_confirmar = true

                    this.modificarArraysOrdenes('Agendadas', orden, 'SinConfirmar')
                    this.modificarArraysOrdenes('Confirmadas', orden, 'SinConfirmar')

                } else if (!orden.estados.sin_confirmar) {
                    const fechaBodega = nodo.dataset.fecha.split('T')[0].split('-')
                    const fechaLogistica = orden.fechahora_logistica?.split(" ")[0].split("/").reverse()
                    
                    if (this.fechaInvalida(fechaBodega, fechaLogistica)) {
                        const message = 'Tienes una fecha en logistica que es inferior o igual a la fecha que deseas asignar'
                        this.$notify({ group: 'foo', type: 'error', title: 'Error', text: message })
                        return
                    }
                    orden.fechahora_bodega = nodo.dataset.fecha
                } else if (orden.estados.sin_confirmar) {
                    const message = 'La orden no esta confirmada'
                    this.$notify({ group: 'foo', type: 'error', title: 'Error', text: message })
                    return
                }

                const copyOrden = { ...orden }
                delete copyOrden.fecha_orden
                delete copyOrden.fecha_actualizada
                delete copyOrden.fechahora_fin
                if (orden.fechahora_logistica) {
                    delete copyOrden.fechahora_logistica
                }
                axios.put('/logistica/api/ordenes_venta/' + id + '/', copyOrden).then(res => {
                    if (nodo.id !== 'contenedor-ovs' && copyOrden.estados.sin_confirmar === false) {
                        this.ordenesAgendadas = this.ordenesAgendadas.filter(ov => ov.id !== orden.id)
                        this.ordenesAgendadas.push(res.data)
                        this.ordenes = this.ordenes.filter(ov => ov.id !== orden.id)
                    }
                })
            }
        },
        // getOVParaDia ha sido editada, es decir, no es igual a la de logistica 
        getOVParaDia (fecha) {
            if (fecha === null) return []

            const fechaFormateada = fecha.split('T')[0]
            const ordenesFiltradas = this.ordenesAgendadas.filter(orden => {
                const fechaOrden = orden.fechahora_bodega.split(" ")[0].split("/").reverse().join("-")
                return fechaOrden === fechaFormateada
            })
            return ordenesFiltradas
        },
        fechaInvalida (fechaBodega, fechaLogistica) {
            if (fechaLogistica === undefined) {
                return false
            } 

            const anioBodega = +fechaBodega[0]
            const mesBodega = +fechaBodega[1] - 1
            const diaBodega = +fechaBodega[2]

            const anioLogistica = +fechaLogistica[0]
            const mesLogistica = +fechaLogistica[1] - 1
            const diaLogistica = +fechaLogistica[2]

            const dateBodega = new Date(anioBodega, mesBodega, diaBodega)
            const dateLogistica = new Date(anioLogistica, mesLogistica, diaLogistica)

            return dateBodega >= dateLogistica
        },
        modificarArraysOrdenes (tipoOrdenes, orden, ordenesToModificar) {

            let ordenes = []
            if (tipoOrdenes === 'Agendadas') {
                ordenes = this.ordenesAgendadas
            } else if (tipoOrdenes === 'SinConfirmar') {
                ordenes = this.ordenesSinConfirmar
            } else if (tipoOrdenes === 'Confirmadas') {
                ordenes = this.ordenes
            }

            const index = ordenes.map(o => o.id).indexOf(orden.id)
            if (index > -1) {
                ordenes.splice(index, 1)
                // if (tipoOrdenes === 'Agendadas') this.getOrdenesTabla(ordenes)
                if (ordenesToModificar === 'SinConfirmar') {
                    this.ordenesSinConfirmar.unshift(orden)
                } else if (
                    ordenesToModificar === 'Confirmadas' && 
                    (this.regionesSeleccionadas.includes(orden.region) || this.regionesSeleccionadas.length === 0)
                ) { this.ordenes.unshift(orden) }
            }

        },
        openModalRespaldo () {
            this.$refs.modalRespaldo.openModal()
        },
        reIniciarDatos () {
            this.getDays()
            this.getRegiones()
            this.getOrdenesVenta()
            this.getOVsAgendadas()
        },
        openModalOVManual () {
            this.$refs.modalOVManual.openModalCreate()
        }
    },
    mounted () {
        this.getPermisos().then(res => {
            this.getDays()
            this.getRegiones()
            this.getOrdenesVenta()
            this.getOVsAgendadas()
        })
    },
    updated () {
        this.inicializarOV()
        this.updateClassNameMultipleOvs( {calendarId: 'card-calendario-bodega'})
    },
    computed: {
        isAdmin() {
            return this.$store.state.admin
        },
        isBodega() {
            return this.$store.state.bodega
        },
        isLogistica() {
            return this.$store.state.logistica
        }
    }

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
