<template>
  <div>
    <HeaderGeneral></HeaderGeneral>
    <div class="container-fluid">
        <div class="card">
          <div class="card__header">
            <p class="card__title">Usuarios</p>
            <modal-usuario modal="modal-create-usuario" :edit="false" ref="modalCreate" title="Crear Usuario" @accept="getData()"></modal-usuario>
            <modal-usuario modal="modal-edit-usuario" :usuario="modalEditData" :edit="true" ref="modalEdit" title="Editar Artículo" @accept="getData();modalEditData=null;" v-if="modalEditData"></modal-usuario>
            <modal-permisos :usuario="modalPermissionData" ref="modalPermission" v-if="modalPermissionData" @accept="modalPermissionData=null;"></modal-permisos>
            <a @click="openModalCreate()" class="button button--primary">
              <span class="button__text">Crear Usuario</span>
              <font-awesome-icon :icon="['fas', 'plus-circle']" class="button__icon" />
            </a>
          </div>
          <div class="card__body">
            <v-app v-if="usuarios">
              <v-main>
                <v-data-table 
                :headers="headers"
                :items="filteredRows"
                :items-per-page="3"
                v-bind:options.sync="pagination"
                item-key="codigo"
                class="elevation-1 table-striped"
                loading-text="Cargando Usuarios"
                :footer-props="footerProps"
                mobile-breakpoint="0">
                  <template v-slot:no-data>
                      No existen usuarios
                  </template>
                  <template v-slot:header="{ props }">
                      <tr class="grey lighten-3">
                          <th v-for="header in props.headers" :key="header.text">
                              <div v-if="filters.hasOwnProperty(header.value)">
                                  <v-autocomplete
                                      flat
                                      hide-details
                                      multiple
                                      attach
                                      chips
                                      dense
                                      clearable
                                      :items="columnValueList(header.value)"
                                      v-model="filters[header.value]"
                                      header-fixed
                                      no-data-text="No hay información"
                                  >
                                      <template v-slot:selection="{ item, index }">
                                          <v-chip v-if="index === 0">
                                              <span>
                                                  +{{ filters[header.value].length }}
                                              </span>
                                          </v-chip>
                                      </template>
                                  </v-autocomplete>
                              </div>
                          </th>
                      </tr>
                  </template>
                  <template v-slot:item.accion="{ item }">
                    <div class="btn-group" role="group" aria-label="Second group">
                      <a @click="setDataEdit(item)" class="button--sm button--primary" v-b-tooltip.hover title="Editar">
                        <font-awesome-icon :icon="['fas', 'pencil-alt']" />
                      </a>
                      <a v-if="item.rol != 'Chofer'" @click="setDataPermission(item)" class="button--sm button--secondary" v-b-tooltip.hover title="Permisos">
                        <font-awesome-icon :icon="['fas', 'user-lock']" />
                      </a>
                      <!-- <a @click="deleteAlert(item)" class="btn btn-sm btn-danger" v-b-tooltip.hover title="Eliminar"><font-awesome-icon :icon="['fas', 'trash-alt']" /></a> -->
                      <a @click="activeUsuario(item)" id="active-usuario" class="button--sm" :class="{'button--danger': item.is_active, 'button--success': !item.is_active}">
                        <b-tooltip target="active-usuario">
                          <span v-if="item.activo">Deshabilitar</span>
                          <span v-if="!item.activo">Habilitar</span>
                        </b-tooltip>
                        <font-awesome-icon v-if="item.is_active" :icon="['fas', 'ban']" />
                        <font-awesome-icon v-if="!item.is_active" :icon="['fas', 'circle']" />
                      </a>
                    </div>
                  </template>
                </v-data-table>
              </v-main>
            </v-app>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
import HeaderGeneral from '@/components/HeaderGeneral.vue'
import ModalUsuario from '@/modals/ModalUsuario.vue'
import ModalPermisos from '@/modals/ModalPermisos.vue'

const Usuario = resource('/base/api/gestor_usuarios', {
    update: (id, params) => axios.put(`/base/api/gestor_usuarios/${id}/`, { params })
}, axios)
const Empresa = resource('/base/api/listar_empresas', axios)

export default {
    name: 'GestorUsuarios',
    components: {
        HeaderGeneral,
        ModalUsuario,
        ModalPermisos
    },
    data () {
        return {
            usuarios: [],
            currentPage: 1,
            perPage: null,
            rows: null,
            page: null,
            formUsuario: {},
            pagination: {
                page: 1
            },
            footerProps: {
                'disable-items-per-page': false,
                'items-per-page-options': [10, 20, 30, -1],
                'items-per-page-text': 'Usuarios por página'
            },
            filters: {
                email: [],
                rol: [],
                empresas__nombre: [],
                get_nombre_completo: []
            },
            modalEditData: null,
            modalPermissionData: null,
            empresas__nombre: []
        }
    },
    methods: {
        getData () {
            Usuario.query({ }).then((res) => {
                this.usuarios = res.data
            })
        },
        columnValueList (val) {
            var dict = []
            if (val === 'empresas__nombre') return this.empresas__nombre
            dict = this.usuarios.map((d) => d[val])
            return dict.sort()
        },
        setDataEdit (item) {
            this.modalEditData = item
        },
        openModalEdit () {
            this.$refs.modalEdit.openModalEdit()
        },
        openModalCreate () {
            this.$refs.modalCreate.openModalCreate()
        },
        deleteAlert (usuario) {
            this.$swal({
                title: 'Vas a eliminar el usuario',
                text: '¿Estás seguro? Se perderá toda la información asociada',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteUsuario(usuario)
                }
            })
        },
        deleteUsuario(usuario) {
            Usuario.delete(usuario.id).then((res) => {
                this.getData()
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Usuario eliminado con exito'
                })
            })
                .catch((error) => {
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al eliminar el usuario'
                    })
                })
        },
        setDataPermission (item) {
            this.modalPermissionData = item
        },
        openModalPermission () {
            this.$refs.modalPermission.openModal()
        },
        activeUsuario(usuario) {
            usuario.is_active = !usuario.is_active
            var title = 'Usuario deshabilitado con éxito'
            if (usuario.is_active)
                title = 'Usuario habilitado con éxito'
            Usuario.update(usuario.id, usuario).then((res) => {
                this.getData()
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: title
                })
            })
                .catch((error) => {
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al actualizar el usuario'
                    })
                })
        }
    },
    mounted () {
        Empresa.query({ activo: true }).then((res) => {
            this.empresas__nombre = res.data.map(({ nombre }) => nombre)
        })    
        this.getData()
        this.$watch('modalEditData', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null) {
                this.openModalEdit()
            }
        }, { immediate: true })
        this.$watch('modalPermissionData', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null) {
                this.openModalPermission()
            }
        }, { immediate: true })
    },
    computed: {
        headers () {
            return [
                { text: 'Nombre Completo', value: 'get_nombre_completo' },
                { text: 'Correo Electrónico', value: 'email' },
                { text: 'Teléfono', value: 'telefono' },
                { text: 'Rol', value: 'rol' },
                { text: 'QAD', value: 'qad' },
                { text: 'Empresas', value: 'empresas__nombre' },
                { text: 'Acción', value: 'accion', sortable: false, align: 'center'}
            ]
        },        
        filteredRows () {
            if (this.usuarios) {
                return this.usuarios.filter((d) => {
                    return Object.keys(this.filters).every((f) => {
                        if (f === 'empresas__nombre' && this.filters[f].length >= 1)
                            return this.filters[f].length < 1 || d[f].includes(this.filters[f][0])
                        return this.filters[f].length < 1 || this.filters[f].includes(d[f])
                    })
                })
            }
            return null
        }
    }
}
</script>
