<template>
    <div class="container-fluid">
        <div class="card mt-3">
            <div class="card__header" v-if="!mensaje.esError">
                <h3 class="card__title">{{info_orden.patente}}</h3>
                <span class="badge badge--primary">Nº Orden: {{info_orden.numero}}</span>
            </div>
            <div class="card__body">
                <div v-if="this.markers.length > 0">
                    <gmap-map
                        :center="center"
                        :zoom="13"
                        style="width: 100%; height: 500px"
                    >
                        <gmap-marker
                            :key="index"
                            v-for="(m, index) in this.markers"
                            :position="m.position"
                            :title="m.title"
                            :icon="{ url: m.icon }"
                            :clickable="true"
                            @click="center=m.position"
                        />
                    </gmap-map>
                </div>
                <div class="alert alert--success" role="alert" v-else-if="this.markers.length === 0" :class="claseMensaje">
                    <font-awesome-icon :icon="['fas', 'check-circle']" class="alert__icon" />
                    <span class="alert__text">{{mensaje.valor}}</span>
                </div>
                <div class="alert alert--primary" role="alert" v-if="!mensaje.esError">
                    <font-awesome-icon :icon="['fas', 'info-circle']" class="alert__icon" />
                    <span class="alert__text">
                        Articulos que van en camino:
                        <ul>
                        <li v-for="(articulo, idx) in info_orden.articulos" :key="idx" style="list-style-type: none; padding-left: 10px">
                            <span class="text-primary">{{articulo.cantidad_factura}} {{articulo.unidad_medida}}</span> {{articulo.descripcion}}
                        </li>
                    </ul>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    props: {
        token: String,
    },
    data () {
        return {
            center: {},
            markers: [],
            paths: [],
            info_orden: {
                numero: null,
                patente: '',
                articulos: []
            },
            mensaje: {
                valor: '',
                esError: false
            }
        }
    },
    methods: {
        async getMarkers () {
            try {
                const res = await axios.get('/logistica/api/vehiculo_info/', { params: { token: this.token } })
                this.info_orden.patente = res.data.info_veh.patente
                this.info_orden.numero = res.data.info_veh.n_orden
                this.info_orden.articulos = res.data.info_veh.articulos

                const latitud = res.data.info_veh.lat
                const longitud = res.data.info_veh.lng
                if (latitud === null || longitud === null) {
                    this.mensaje.valor = 'Se notificará cuando se entregue su pedido'
                    this.mensaje.esError = false
                    return
                }
                this.center = { lat: latitud, lng: longitud }
                this.markers.push({
                    position: this.center,
                    title: 'Posición Vehículo',
                    icon: '/truck.png'
                })
                this.paths.push(this.center)
            } catch (error) {
                this.mensaje.valor = error.response.data[0]
                this.mensaje.esError = true
            }
        },
    },
    computed: {
        claseMensaje () {
            return this.mensaje.esError ? 'text-danger' : 'text-success' 
        }
    },
    mounted () {
        this.getMarkers()
    }

}
</script>

<style>

</style>