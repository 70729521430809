<template>
  <div>
    <b-modal :id="modal" size="lg" title="Camiones" no-close-on-esc no-close-on-backdrop hide-header-close>
      <div class="row">
        <div class="col-md-6">
          <label>Patente*</label>
          <input type="text" name="" class="form-control" v-model="camion.patente">
        </div>
        <div class="col-md-6">
          <label>M3</label>
          <input type="text" name="" class="form-control" v-model="camion.detalles.m3">
        </div>
        <div class="col-md-6">
          <label>KG</label>
          <input type="text" name="" class="form-control" v-model="camion.detalles.kg">
        </div>
        <div class="col-md-6">
          <label>Marca</label>
          <input type="text" name="" class="form-control" v-model="camion.detalles.marca">
        </div>
        <div class="col-md-6">
          <label>Modelo</label>
          <input type="text" name="" class="form-control" v-model="camion.detalles.modelo">
        </div>
        <div class="col-md-6">
          <label>Imagen*</label>
          <b-form-file
            v-model="file"
            placeholder="Selecciona una imagen"
            drop-placeholder="Deja aquí la imagen"
            accept="image/png,image/jpeg"
          ></b-form-file>
        </div>
      </div>
      <template #modal-footer>
        <button class="button button--secondary" @click="closeModal()">
          <span class="button__text">Cancelar</span>
          <font-awesome-icon :icon="['fas', 'times-circle']" class="button__icon" />
        </button>
        <button class="button button--success" @click="createCamion()" v-if="!edit">
          <span class="button__text">Guardar</span>
          <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
        </button>
        <button class="button button--success" @click="editCamion()" v-if="edit">
          <span class="button__text">Guardar</span>
          <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
        </button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios'

export default {
    name: 'ModalCamion',
    props: ['modal', 'data', 'edit'],
    data () {
        return {
            camion: { detalles: {} },
            file: null
        }
    },
    methods: {
        openModalCreate () {
            this.camion = { detalles: {} }
            this.file = null
            this.$root.$emit('bv::show::modal', 'modal-create-camion')
        },
        openModalEdit () {
            this.camion = this.data
            this.$root.$emit('bv::show::modal', 'modal-edit-camion')
        },
        closeModal () {
            this.$emit('close')
            this.$root.$emit('bv::hide::modal', this.modal)
        },
        createCamion () {
            var formData = new FormData()
            this.error = null
            formData.append('file', this.file)
            formData.append('patente', this.camion.patente)
            formData.append('is_active', true)
            formData.append('detalles', JSON.stringify(this.camion.detalles))
            axios.post('/logistica/api/camiones/',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((response) => {
                    this.files = null
                    this.closeModal()
                    this.$emit('accept')
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Camion creado con éxito'
                    })
                    this.closeModal()
                })
                .catch((response) => {
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al crear el camión'
                    })
                })
        },
        editCamion () {
            var formData = new FormData()
            this.error = null
            formData.append('file', this.file)
            formData.append('patente', this.camion.patente)
            formData.append('detalles', JSON.stringify(this.camion.detalles))
            formData.append('is_active', this.camion.is_active)
            axios.put('/logistica/api/camiones/' + this.camion.id + '/',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((response) => {
                    this.files = null
                    this.closeModal()
                    this.$emit('accept')
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Camion editado con éxito'
                    })
                    this.closeModal()
                })
                .catch((response) => {
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al ediar el camión'
                    })
                })
        }
    },
    mounted () {
    }
}
</script>
