<template>
  <div>
    <HeaderGeneral></HeaderGeneral>
    <div class="container-fluid">
      <div class="card">
        <div class="card__header">
          <h4 class="card__title">Tipos de Artículo</h4>
          <modal-tipo-articulo modal="modal-create-tipo-articulo" :edit="false" ref="modalCreate" title="Crear Tipo de Artículo" @accept="getData()"></modal-tipo-articulo>
          <modal-tipo-articulo modal="modal-edit-tipo-articulo" :tipo="modalEditData" :edit="true" ref="modalEdit" title="Editar Tipo Artículo" @accept="getData();modalEditData=null;" v-if="modalEditData"></modal-tipo-articulo>
          <a @click="openModalCreate()" class="button button--primary">
            <span class="button__text">Crear Tipo de Artículo</span>
            <font-awesome-icon :icon="['fas', 'plus-circle']" class="button__icon" />
          </a>
        </div>
        <div class="card__body">
          <v-app v-if="tipos">
            <v-main>
              <v-data-table 
              :headers="headers"
              :items="filteredRows"
              :items-per-page="3"
              v-bind:options.sync="pagination"
              item-key="codigo"
              class="elevation-1 table-striped"
              loading-text="Cargando Tipos de Artículo"
              :footer-props="footerProps"
              mobile-breakpoint="0">
                <template v-slot:no-data>
                    No existen Tipos
                </template>
                <template v-slot:header="{ props }">
                    <tr class="grey lighten-3">
                        <th v-for="header in props.headers" :key="header.text">
                            <div v-if="filters.hasOwnProperty(header.value)">
                                <v-autocomplete
                                    flat
                                    hide-details
                                    multiple
                                    attach
                                    chips
                                    dense
                                    clearable
                                    :items="columnValueList(header.value)"
                                    v-model="filters[header.value]"
                                    header-fixed
                                    no-data-text="No hay información"
                                >
                                    <template v-slot:selection="{ item, index }">
                                        <v-chip v-if="index === 0">
                                            <span>
                                                +{{ filters[header.value].length }}
                                            </span>
                                        </v-chip>
                                    </template>
                                </v-autocomplete>
                            </div>
                        </th>
                    </tr>
                </template>
                <template v-slot:item.accion="{ item }">
                  <div class="btn-group" role="group" aria-label="Second group">
                    <a class="button--sm button--primary" @click="setDataEdit(item)" v-b-tooltip.hover title="Editar">
                      <font-awesome-icon :icon="['fas', 'pencil-alt']" />
                    </a>
                    <a class="button--sm button--danger" @click="deleteAlert(item)" v-b-tooltip.hover title="Eliminar">
                      <font-awesome-icon :icon="['fas', 'trash-alt']" />
                    </a>
                  </div>
                </template>
              </v-data-table>
            </v-main>
          </v-app>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
import HeaderGeneral from '@/components/HeaderGeneral.vue'
import ModalTipoArticulo from '@/modals/ModalTipoArticulo.vue'

const TipoArticulo = resource('/base/api/gestor_tipos_articulos', axios)
const Empresa = resource('/base/api/listar_empresas', axios)

export default {
    name: 'GestorTipoArticulo',
    components: {
        HeaderGeneral,
        ModalTipoArticulo
    },
    data () {
        return {
            tipos: [],
            currentPage: 1,
            perPage: null,
            rows: null,
            page: null,
            formTipo: {},
            pagination: {
                page: 1
            },
            footerProps: {
                'disable-items-per-page': false,
                'items-per-page-options': [5, 10, 15, -1],
                'items-per-page-text': 'Tipos por página'
            },
            filters: {
                nombre: []
            },
            modalEditData: null
        }
    },
    methods: {
        getData () {
            TipoArticulo.query({ }).then((res) => {
                this.tipos = res.data
            })
        },
        columnValueList (val) {
            var dict = []
            dict = this.tipos.map((d) => d[val])
            return dict.sort()
        },
        setDataEdit (item) {
            this.modalEditData = item
        },
        openModalEdit () {
            this.$refs.modalEdit.openModalEdit()
        },
        openModalCreate () {
            this.$refs.modalCreate.openModalCreate()
        },
        deleteAlert (tipo) {
            this.$swal({
                title: 'Vas a eliminar el tipo de artículo y sus artículos asociados',
                text: '¿Estás seguro? Se perderá toda la información',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteTipo(tipo)
                }
            })
        },
        deleteTipo(tipo) {
            TipoArticulo.delete(tipo.id).then((res) => {
                this.getData()
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Tipo de artículo eliminado con exito'
                })
            })
                .catch((error) => {
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al eliminar el tipo de artículo'
                    })
                })
        }
    },
    mounted () {
        this.getData()
        this.$watch('modalEditData', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null) {
                this.openModalEdit()
            }
        }, { immediate: true })
    },
    computed: {
        headers () {
            return [
                { text: 'Nombre', value: 'nombre' },
                { text: 'Acción', value: 'accion', sortable: false, align: 'center'}
            ]
        },        
        filteredRows () {
            if (this.tipos) {
                return this.tipos.filter((d) => {
                    return Object.keys(this.filters).every((f) => {
                        return this.filters[f].length < 1 || this.filters[f].includes(d[f])
                    })
                })
            }
            return null
        }
    }
}
</script>
