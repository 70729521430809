<template>
  <div>
    <!-- <Breadcrumb></Breadcrumb> -->
    <HeaderGeneral ></HeaderGeneral>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <h4 class="bienvenido">Bienvenido(a), {{user.get_nombre_completo}}</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" v-if="isAdmin">
          <modal-cambio :cambios="cambios" ref="modalCambio" @accept="getData"></modal-cambio>
          <!-- <a @click="$refs.modalCambio.openModal()" v-if="cambios.length < 2" class="btn btn-sm btn-primary float-right" v-b-tooltip.hover title="Editar"><font-awesome-icon :icon="['fas', 'plus']" /> Agregar Cambio</a> -->

          <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-12" v-for="cambio in cambios" v-bind:key="cambio.id">
              <div class="card">
                <div class="card__body">
                  <div class="cambio__header">
                    <p class="cambio__icon"><span v-if="cambio.moneda === 'USD'">$</span><span v-if="cambio.moneda === 'EUR'">€</span></p>
                    <div class="card__buttons">
                      <a @click="setEdit(cambio)" v-if="!edit || edit && cambio.id !== cambioAnterior.id" class="button--sm button--primary" v-b-tooltip.hover title="Editar">
                        <font-awesome-icon :icon="['fas', 'pencil-alt']" />
                      </a>
                      <a v-if="edit && cambio.id === cambioAnterior.id" @click="editCambio(cambio)" class="button--sm button--success" v-b-tooltip.hover title="Actualizar">
                        <font-awesome-icon :icon="['fas', 'save']" />
                      </a>
                      <a v-if="edit && cambio.id === cambioAnterior.id" @click="unSetEdit(cambio)" class="button--sm button--danger" v-b-tooltip.hover title="Cancelar">
                        <font-awesome-icon :icon="['fas', 'times']" />
                      </a>
                    </div>
                  </div>
                  <h6 class="cambio__moneda">{{cambio.moneda}}</h6>
                  <h6 class="cambio__fecha" v-if="cambio">Últ. modificación: {{cambio.fechahora_actualizacion}}</h6> 
                  <strong>
                    <vue-numeric class="cambio__valor" currency="$" separator="," v-model="cambio.tasa_cambio" disabled size="8rem" v-if="cambio && cambio.tasa_cambio && (!edit || edit && cambio.id !== cambioAnterior.id)" v-bind:precision="3"></vue-numeric> 
                    <vue-numeric currency="$" separator="," class="form-control" v-model="cambio.tasa_cambio" size="8rem" v-if="edit && cambio.id === cambioAnterior.id" :min="1" v-bind:precision="3"></vue-numeric> 
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="user && (user.empresas && user.empresas.length >= 1 || user.is_superuser)">
        <div class="col-md-6 col-sm-12" v-for="empresa in empresas" v-bind:key="empresa.id">
          <div :class="{'card border--primary': empresa.nombre === 'Tagle', 'card border--warning': empresa.nombre === 'Dripsa'}">
          <!-- <div class="card border--warning">DRIPSA -->
            <div class="card__body">
              <router-link class="empresas" :to="createUrl(empresa)" :class="empresa.nombre.toLowerCase()">
                <img class="empresas__img" :src="`img/${empresa.nombre.toLowerCase()}.png`">
                <!-- <img class="empresas__img" src="img/tagle.png"> -->
                <!-- <h4 class="empresas__text">Ingresar a empresa {{empresa.nombre}}<font-awesome-icon :icon="['fas', 'arrow-right']" class="button__icon" /></h4>       -->                              
              </router-link>
            </div>
          </div>                 
        </div>
      </div>
      <div class="row" v-if="isAdmin">
        <div class="col-md-12">
          <h4 class="bienvenido">Panel de Administración</h4>
        </div>
      </div>
      <div class="row" v-if="isAdmin">
        <div class="col-md-4 col-sm-6">
          <div class="card">
            <div class="card__body">
              <router-link class="panel" to="/reportes">
                <font-awesome-icon class="panel__icon--warning" :icon="['fas', 'chart-line']" />
                <h4 class="panel__text"> Reportes</h4>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="card">
            <div class="card__body">
              <router-link class="panel" to="/gestor_usuarios">
                <font-awesome-icon class="panel__icon--purple" :icon="['fas', 'users']" />
                <h4 class="panel__text"> Gestor Usuarios</h4>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="card">
            <div class="card__body">
              <router-link class="panel" to="/gestor_tipos">
                <font-awesome-icon class="panel__icon--success" :icon="['fas', 'clipboard-list']" />
                <h4 class="panel__text"> Gestor tipos de Artículo</h4>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import resource from 'resource-axios'
import HeaderGeneral from '@/components/HeaderGeneral.vue'
import ModalCambio from '@/modals/ModalCambio.vue'

const Empresa = resource('/base/api/listar_empresas', axios)
const Cambio = resource('/base/api/listar_cambio/', {
    update: (id, params) => axios.put(`/base/api/listar_cambio/${id}/`, { params })
}, axios)

export default {
    name: 'Home',
    components: { HeaderGeneral, ModalCambio },
    async beforeCreate () {

    },
    data () {
        return {
            empresas: [],
            cambios: [],
            edit: false,
            nuevoCambio: {},
            cambioAnterior: null
        }
    },
    methods: {
        createUrl (empresa) {
            if (this.isBodega)
                return `/${empresa.id}/${empresa.nombre.toLowerCase()}/bodega`
            if (this.isLogistica)
                return `/${empresa.id}/${empresa.nombre.toLowerCase()}/bodega`
            return `/${empresa.id}/${empresa.nombre.toLowerCase()}`
        },
        getData () {
            Cambio.query({}).then((res) => {
                this.cambios = res.data
            })
        },
        setEdit (cambio) {
            this.edit = true
            this.cambioAnterior = Object.assign({}, cambio)
        },
        unSetEdit (cambio) {
            this.edit = false
            Object.assign(cambio, this.cambioAnterior)
        },
        editCambio (cambio) {
            delete cambio['fechahora_actualizacion']
            Cambio.update(cambio.id, cambio).then((res) => {
                this.getData()
                this.edit = false
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Cambio actualizado con exito'
                })
            })
                .catch((error) => {
                    this.formDetalle = {}
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al actualizar el cambio'
                    })
                })
        },
    },
    mounted () {
        this.$store.dispatch('loadUserFromLocal')
        Empresa.query({}).then((res) => {
            this.empresas = res.data
        })
        this.getData()
    },
    computed: {
        user () {
            return this.$store.state.user
        },
        isAdmin () {
            return this.$store.state.admin
        },
        isBodega () {
            return this.$store.state.bodega
        },
        isLogistica () {
            return this.$store.state.logistica
        }
    },
    watch: {
        user: {
            handler: function (newVal) {
                if (newVal.rol === 'Chofer') {
                    this.$router.push(`/ordenes/chofer/${newVal.id}`)
                }
            }
        }
    }
}
</script>
