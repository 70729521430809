<template>
  <div>
    <Header :empresa-nombre="empresaNombre" :empresa-id="empresaId" v-if="$route.name ==='DetalleOrdenAdmin'"></Header>
    <section v-if="ordenId">
      <div class="container-fluid" v-if="orden">
        <div class="row">
          <div class="col-md-2">
            <a class="button button--primary m-0 mb-3" v-if="orden" @click="$router.back()">
              <font-awesome-icon :icon="['fas', 'arrow-left']" class="button__icon--left" />
              <span class="button__text">Volver</span>
            </a>
          </div>
        </div>
        <div class="card">                  
          <div class="card__header">
            <h2 class="card__title" v-if="orden">{{orden.numero}}</h2>
            <span class="badge badge--primary" v-if="orden.fechahora_logistica">Fecha programada de entrega: {{orden.fechahora_logistica | formatDateTimeToDate}}</span>
          </div>
          <div class="card__body">
            <div class="row">
              <div class="col-md-4">
                <div class="card--outline">
                  <div class="card__header">
                    <h2 class="card__title">Detalles</h2>
                  </div>
                  <div class="card__body" v-if="orden">
                    <b-table responsive stacked v-if="orden.detalles" :items="[orden.detalles]" :fields="detalles">
                      <template #cell(coordenadas)="data">
                          <p class="text--ordenes"><a :href="`https://www.google.com/maps/place/${data.item.lat},${data.item.long}`">{{data.item.lat}}, {{data.item.long}}</a></p>
                      </template>
                    </b-table>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card--outline">
                  <div class="card__header">
                    <h2 class="card__title">Artículos</h2>
                  </div>
                  <div class="card__body" v-if="orden">
                    <b-table responsive stacked v-if="orden.articulos && !orden.articulos_cargados" :items="orden.articulos" :fields="articulos">
                    </b-table>
                    <b-table responsive stacked v-if="orden.articulos && orden.articulos_cargados" :items="orden.articulos" :fields="despachados">
                    </b-table>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card--outline">
                  <div class="card__header">
                    <h2 class="card__title">Información Adicional</h2>
                    <div class="d-flex">
                      <div class="btn-group">
                        <a class="button--sm button--primary" v-if="orden.fechahora_logistica && (!edit && orden.estado !== 'Entregado' || !edit && isAdmin || !edit && isBodega || !edit && isLogistica) && (isAdmin || isBodega || isLogistica)" @click="edit=!edit" v-b-tooltip.hover title="Editar">
                          <font-awesome-icon :icon="['fas', 'pencil-alt']" />
                        </a>

                        <a class="button--sm button--success" v-if="edit" @click="updateOrden()" v-b-tooltip.hover title="Guardar">
                          <font-awesome-icon :icon="['fas', 'save']" />
                        </a>
                        <a class="button--sm button--danger" v-if="edit" @click="edit=!edit" v-b-tooltip.hover title="Cancelar">
                          <font-awesome-icon :icon="['fas', 'times']" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="card__body" v-if="orden">
                    <div v-if="edit">
                      <div class="form-group">
                        <label class="title--ordenes bold">Estado</label>
                        <b-form-radio v-if="orden.estados" v-model="estado" value="Entregada">Entregada</b-form-radio>
                        <b-form-radio v-if="orden.estados" v-model="estado" value="Entregada con Errores">Entregada con errores</b-form-radio>
                        <b-form-radio v-if="orden.estados" v-model="estado" value="No Entregada">No entregada</b-form-radio>
                      </div>
                      <div class="form-group" v-if="orden.estados && estado==='Entregada con Errores'">
                        <label class="title--ordenes bold">Razón</label>
                        <v-select :options="razonesErrores" v-model="form.razon"></v-select>
                      </div>
                      <div class="form-group" v-if="orden.estados && estado==='No Entregada'">
                        <label class="title--ordenes bold">Razón</label>
                        <v-select :options="razones" v-model="form.razon"></v-select>
                      </div>
                      <div class="form-group">
                        <label class="title--ordenes bold">Observaciones</label>
                        <b-alert v-if="form.razon === 'Otros' && estado==='No Entregada'" show variant="danger">Explique por qué razón no se pudo entregar</b-alert>
                        <b-alert v-if="form.razon === 'Otros' && estado==='Entregada con Errores'" show variant="danger">Explique por qué se entregó con errores la orden</b-alert>
                        <textarea class="form-control" v-model="form.observaciones"></textarea>
                      </div>
                    </div>
                    <span v-if="orden && orden.documentos">
                      <h4 class="card__subtitle">Documentos</h4>
                      <hr>
                      <span v-for="documento in orden.documentos" v-bind:key="documento.id">
                        <button class="button__link" @click="descargar(documento.id)">{{ $dayjs(documento.fechahora_created).format('DD-MM-YYYY HH:mm:ss') }}</button>
                      </span>
                    </span>
                    <registros-anteriores v-if="orden.registros" :registros="orden.registros" />
                    <div class="alert alert--danger" role="alert"  v-else>
                      <font-awesome-icon :icon="['fas', 'exclamation-circle']" class="alert__icon" />
                      <span class="alert__text">Aún no hay registros disponibles</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import resource from 'resource-axios'
import RegistrosAnteriores from '@/ordenes/components/RegistrosAnteriores.vue'
import Header from '@/components/Header.vue'

const Ordenes = resource('/logistica/api/ordenes_venta', {
    update: (id, params) => axios.put(`/logistica/api/ordenes_venta/${id}/update_camion/`, { params }),
    download: (id) => axios.put(`/logistica/api/ordenes_venta/${id}/descargar_archivo/`),
}, axios)


export default {
    name: 'DetalleOrdenAdmin',
    components: { RegistrosAnteriores, Header },
    async beforeCreate () {

    },
    inject: ['$dayjs'],
    data () {
        return {
            empresaId: this.$route.params.empresaId,
            empresaNombre: this.$route.params.empresaNombre,
            ordenId: this.$route.params.ordenId,
            orden: {},
            form: { razon: '', observaciones: '' },
            edit: false,
            estado: null,
            detalles: ['nombre_vendedor', 'numero_cliente', 'nombre_cliente', 'email', 'telefono_cliente', 'despacho', 'forma_pago', 'id_embarque', 'direccion', 'ciudad','comuna','region','coordenadas', 'observaciones'],
            articulos: ['codigo', 'descripcion', 'cantidad_ordenada', 'cantidad_asignada', 'cantidad_picking', 'cantidad_fact', 'cantidad_facturar', 'stock', 'sin_stock', 'en_orden'],
            despachados: ['codigo', 'descripcion', 'cantidad_factura', 'unidad_medida'],
            registrosCliente: [],
            registrosChofer: []
        }
    },
    methods: {
        getOrden () {
            Ordenes.query({ id: this.ordenId }).then((res) => {
                this.orden = res.data[0]
                this.orden.registros = this.orden.registros
            })
                .catch((error) => {
                    console.log(error)
                })
        },
        updateOrden () {
            this.edit = false
            let registro = {
                'fecha_entrega_programada': this.orden.fechahora_logistica.split(' ')[0],
                'estado': this.estado,
                'razon': this.form.razon,
                'observaciones': this.form.observaciones,
                'usuario': this.user.get_nombre_completo,
                'chofer': this.orden.chofer__nombre_completo,
                'patente': this.orden.camion__patente
            }
            if (this.estado === 'Entregada') {
                this.orden.estados.entregado = true
                this.orden.estados.entregado_con_errores = false
                this.orden.estados.no_entregado = false
                this.orden.estados.despachado = true
                this.orden.estados.razon = ''
            } else if (this.estado === 'Entregada con Errores') {
                this.orden.estados.entregado = false
                this.orden.estados.entregado_con_errores = true
                this.orden.estados.no_entregado = false
                this.orden.estados.corregido = false
                this.orden.estados.despachado = true
            } else if (this.estado === 'No Entregada') {
                this.orden.estados.entregado = false
                this.orden.estados.entregado_con_errores = false
                this.orden.estados.no_entregado = true
                this.orden.estados.despachado = false
                this.orden.estados.mensaje_despachando = false

            }
            this.orden.registros = registro
            Ordenes.update(this.ordenId, this.orden).then((res) => {
                this.getOrden()
                this.form = {}
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Orden Actualizada'
                })
            })
                .catch((error) => {
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al actualizar'
                    })
                })

        },
        descargar (orden) {
            Ordenes.download(orden).then((res) => {
                location.href = res.data
            })
                .catch((error) => {
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error'
                    })
                })

        }
    },
    mounted () {
        this.getOrden()
        this.$watch('ordenId', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null) {
                this.getOrden()
            }
        }, { immediate: true })
    },
    computed: {
        user () {
            return this.$store.state.user
        },
        razones () {
            return ['Cliente no estaba', 'Cliente sin maquinaria para recibir', 'Hora de llegada muy tardía', 'Camino en mal estado', 'No se logró descargar completamente', 'Otros']
        },
        razonesErrores () {
            return ['Hay menos artículos que los indicados en factura', 'Hay artículos en mal estado', 'Faltó maquinaria para descarga completa', 'Otros']
        },
        isAdmin () {
            return  this.$store.state.admin
        },
        isBodega() {
            return this.$store.state.bodega
        },
        isLogistica() {
            return this.$store.state.logistica
        }
    }
}
</script>
