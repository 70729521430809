<template>
    <nav class="navbar navbar-expand-lg">
        <modal-password :user="user" ref="modalPassword"></modal-password>
        <div class="container-fluid">
            <router-link class="navbar-brand" to="/">
                <span class="navbar__title">Sistema de Cotizaciones</span>
            </router-link>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <font-awesome-icon :icon="['fas', 'bars']" />
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav ml-auto d-none d-md-flex">
                    <li class="nav-item">
                        <router-link to="/" class="nav-link">
                            <font-awesome-icon :icon="['fas', 'home']" class="button__icon--left" />Inicio
                        </router-link>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                           <font-awesome-icon :icon="['fas', 'briefcase']" class="button__icon--left" />Empresas
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li class="nav-item">
                                <router-link class="nav-link" v-for="empresa in empresas" v-bind:key="empresa.id" :to="createUrl(empresa)">{{empresa.nombre}}</router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown" v-if="isAdmin">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <font-awesome-icon :icon="['fas', 'cog']" class="button__icon--left" />Panel Administración
                        </a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown2">
                            <li class="nav-item">
                                <router-link class="nav-link" to="/gestor_usuarios">Gestor Usuarios</router-link>
                            </li>
                        </div>
                    </li>
                    <li class="nav-item" v-if="isAdmin">
                        <router-link class="nav-link" to="/reportes">
                        <font-awesome-icon :icon="['fas', 'chart-line']" class="button__icon--left" />Reportes
                        </router-link>
                    </li>
                    <li class="nav-item dropdown" >
                        <a data-toggle="dropdown" class="nav-link dropdown-toggle" style="cursor: pointer;" aria-haspopup="true" aria-expanded="false">
                            <font-awesome-icon :icon="['fas', 'user']" class="button__icon--left" />
                            <span v-if="user">{{user.get_nombre_completo}}</span>
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <li class="nav-item">
                                <a class="nav-link" @click="cambiarPassword()" style="cursor: pointer;">Cambiar Contraseña</a>
                                <router-link to='/login?logout' class="nav-link nav-link-exit" style="cursor: pointer;">Cerrar Sesión</router-link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
import ModalPassword from '@/modals/ModalPassword.vue'

const Empresa = resource('/base/api/listar_empresas', axios)

export default {
    name: 'HeaderGeneral',
    components: {
        ModalPassword
    },
    data () {
        return {
            activeClass: 'nav-link--active',
            empresas: []
        }
    },
    computed: {
        currentPage () {
            return this.$route.path
        },
        isAdmin () {
            return this.$store.state.admin
        },
        user () {
            return this.$store.state.user
        }
    },
    methods: {
        createUrl (empresa) {
            return '/' + empresa.id + '/' + empresa.nombre.toLowerCase()
        },
        cambiarPassword() {     
            this.$refs.modalPassword.openModal()
        }
    },
    mounted () {
        Empresa.query({}).then((res) => {
            this.empresas = res.data
        })
    }
}
</script>
