<template>
    <div class="login col-md-12">
        <div class="row justify-content-center">
            <div class="card--login">
                <div class="card__header--login">
                    <p class="card__header--text">Recupera tu contraseña</p>
                </div>
                <div class="card__body">
                    <form class="form">
                        <div class="form-group">
                            <label class="bold">Ingresa tu correo registrado en el sistema para reestablecer tu contraseña</label>
                            <input type="text" class="form-control" v-model="resetData.username" placeholder="Correo electrónico" required autocomplete="username">
                            <small class="form-text text-muted"></small>
                        </div>
                    </form>
                    <div class="alert alert--danger" role="alert" v-show="error">
                        <font-awesome-icon :icon="['fas', 'exclamation-circle']" class="alert__icon" />
                        <span class="alert__text">Usuario inválido</span>
                    </div>
                </div>
                <div class="card__footer--forgot">
                    <button type="button" class="button button--secondary m-0" @click="$router.go(-1)">
                        <font-awesome-icon :icon="['fas', 'arrow-left']" />
                    </button>
                    <button type="button" class="button button--primary" @click="resetPassword()">
                        <span class="button__text">Siguiente</span>
                        <font-awesome-icon :icon="['fas', 'sign-in-alt']" class="button__icon" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'ForgotPassword',
    components: {
    },
    data () {
        return {
            error: null,
            resetData: { username: null }
        }
    },
    mounted () {
    },

    methods: {
        resetPassword () {
            axios({
                method: 'put',
                url: '/base/api/user_reset_password/send_reset_email/',
                data: {
                    username: this.resetData.username
                }
            })
                .then((response) => {
                    this.$swal({
                        icon: 'success',
                        title: 'Se ha enviado un correo de recuperación de contraseña',
                        text: 'Revisa tu bandeja de entrada o de correo no deseado'
                    }).then((result) => {
                        this.$router.push('/')
                    })
                })
                .catch((response) => {
                    this.error = true
                })
        }

    },
    computed: {
    }
}
</script>
