<template>
  <div>
    <b-modal id="modal-restore-password" size="lg" title="Cambiar Contraseña">
      <div class="row">
        <div class="col-md-6">
          <label>Contraseña <strong>Nueva</strong>(Mínimo 8 carácteres)</label>
          <input class="form-control" type="password" minlength="8" maxlength="50" v-model="formUsuario.new_password">
        </div>
        <div class="col-md-6">
          <label>Repita la contraseña <strong>nueva</strong></label>
          <input class="form-control" type="password" minlength="8" maxlength="50" v-model="formUsuario.new_password2" v-on:blur="validate()">
        </div>
        <div class="col-md-12">
          <label>Contraseña <strong>Anterior</strong></label>
          <input class="form-control" type="password" v-model="formUsuario.old_password">
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="alert alert--danger" role="alert" v-if="errorOldPassword">
            <font-awesome-icon :icon="['fas', 'exclamation-circle']" class="alert__icon" />
            <span class="alert__text">La contraseña anterior no coincide</span>
          </div>
          <div class="alert alert--danger" role="alert" v-if="errorMessage">
            <font-awesome-icon :icon="['fas', 'exclamation-circle']" class="alert__icon" />
            <span class="alert__text">{{errorMessage}}</span>
          </div>
          <div class="alert alert--danger" role="alert" v-if="errorPassword">
            <font-awesome-icon :icon="['fas', 'exclamation-circle']" class="alert__icon" />
            <span class="alert__text">Las contraseñas no coinciden</span>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <button class="button button--secondary" @click="closeModal()">
          <span class="button__text">Cancelar</span>
          <font-awesome-icon :icon="['fas', 'times-circle']" class="button__icon" />
        </button>
        <button class="button button--success" @click="cambiarPassword()" v-if="(formUsuario.old_password && formUsuario.new_password && formUsuario.new_password2) && !errorPassword">
          <span class="button__text">Guardar</span>
          <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
        </button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios'

export default {
    name: 'ModalPassword',
    data () {
        return {
            formUsuario: {},
            user: this.$attrs.user,
            errorPassword: false,
            errorOldPassword: false,
            errorMessage: null
        }
    },
    methods: {
        openModal () {
            this.formUsuario = {}
            this.$root.$emit('bv::show::modal', 'modal-restore-password')
        },
        closeModal () {
            this.formUsuario = {}
            this.$root.$emit('bv::hide::modal', 'modal-restore-password')
        },
        validate () {
            this.errorPassword = false
            if (this.formUsuario.new_password !== this.formUsuario.new_password2) this.errorPassword = true
        },
        cambiarPassword () {
            if (!this.errorPassword) {
                // this.formUsuario.user = this.user
                this.show = true
                axios({
                    method: 'put',
                    url: '/base/api/user_reset_password/' + this.user.id + '/change_password/',
                    data: this.formUsuario
                })
                    .then((response) => {
                        this.show = false
                        if (response.status === 200) {
                            this.closeModal()
                            this.formUsuario = {}
                            this.$notify({
                                group: 'foo',
                                type: 'success',
                                title: 'Contraseña cambiada exitosamente'
                            })
                            this.$swal({
                                title: 'Se cambio su contraseña correctamente',
                                text: 'Ingresa nuevamente al sistema.',
                                icon: 'success',
                                showCancelButton: false,
                                showConfirmButton: true
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    this.$router.push('/login?logout')
                                }
                            })
                        } else if (response.status === 204) {
                            this.errorOldPassword = true
                            this.$notify({
                                group: 'foo',
                                type: 'error',
                                title: 'Error al cambiar la Contraseña'
                            })
                        }
                    })
                    .catch((error) => {
                        if (error.response.status === 400 && error.response.data.new_password.length > 0) {
                            this.errorMessage = error.response.data.new_password[0]
                        }
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Error al cambiar la Contraseña'
                        })
                    })
            }
        }
    },
    mounted () {
    }
}
</script>