<!--COTIZACIONES - CONFIRMAR CAMBIO-->

<template>
    <div>
        <b-modal id="modal-confirmar-cambio" size="lg" title="Confirmar Cambio" no-close-on-esc no-close-on-backdrop hide-header-close>
            <div class="row">
                <div class="col-md-6" v-for="cambio in cambios" v-bind:key="cambio.id">
                    <strong>{{cambio.moneda}} </strong> 
                    <strong>
                        <vue-numeric currency="$" separator="." v-model="cambio.tasa_cambio" size="8rem" class="form-control" :min="1"  v-bind:precision="3"></vue-numeric> 
                    </strong>
                </div>
            </div>
            <template #modal-footer="{ }">
                <button class="button button--secondary" @click="closeModal();">
                    <span class="button__text">Cancelar</span>
                    <font-awesome-icon :icon="['fas', 'times-circle']" class="button__icon" />
                </button>
                <button class="button button--primary" @click="actualizarCambio(cambios);porConfirmarCotizacion(cotizacion.por_confirmar);">
                    <span class="button__text">Pre-Confirmar</span>
                    <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
                </button>
            </template>
        </b-modal>
    </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'

const Cliente = resource('/base/api/listar_clientes/', axios)

const Cotizacion = resource('/cotizacion/api/gestor_cotizaciones', {
    update: (id, params) => axios.put(`/cotizacion/api/gestor_cotizaciones/${id}/`, { params }),
    por_confirmar:(id, params) => axios.put(`/cotizacion/api/gestor_cotizaciones/${id}/por_confirmar/`, { params }),
    actualizar_cambio:(id, params) => axios.put(`/cotizacion/api/gestor_cotizaciones/${id}/actualizar_cambio/`, { params }),
}, axios)

export default {
    name: 'ModalConfirmarCambio',
    props: ['cotizacion', 'cambios', 'user', 'edit'],
    components: {
    },
    data () {
        return {
            formCambio: {}
        }
    },
    methods: {
        openModal () {
            this.$root.$emit('bv::show::modal', 'modal-confirmar-cambio')
        },
        closeModal () {
            this.$root.$emit('bv::hide::modal', 'modal-confirmar-cambio')
        },
        porConfirmarCotizacion (por_confirmar) {
            if (!this.edit) {
                this.cotizacion.por_confirmar = !por_confirmar
            }
            this.cotizacion.aprobado = false
            var formCotizacion = this.cotizacion
            delete formCotizacion.fecha_ingreso
            delete formCotizacion.fechahora_actualizacion
            delete formCotizacion.fecha_cierre
            // this.actualizarCambio(this.cambios)
            Cotizacion.por_confirmar(this.cotizacion.id, formCotizacion).then((res) => {
                this.getData()
                this.forceRerender()
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Cotizacion actualizada con éxito'
                })
            })
                .catch((error) => {
                    this.$emit('accept')
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al actualizar la cotización'
                    })
                })

        },
        actualizarCambio (cambios) {
            this.formCambio = cambios.map((c) => { return {'moneda': c.moneda, 'tasa_cambio': c.tasa_cambio} })
            Cotizacion.actualizar_cambio(this.cotizacion.id, this.formCambio).then((res) => {
                this.$root.$emit('bv::hide::modal', 'modal-confirmar-cambio')
                this.$emit('accept')
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Cotizacion actualizada con éxito'
                })
            })
                .catch((error) => {
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al actualizar la cotización'
                    })
                })

        }
    },
    mounted () {
    }
}
</script>
