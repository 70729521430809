<template>
    <div>
        <HeaderOrdenes v-if="choferId"></HeaderOrdenes>
        <HeaderGeneral v-else></HeaderGeneral>
        <div class="container-fluid">
            <div class="card mb-3">
                <div class="card__header" style="border:none;">
                    <h4 class="card__title" v-if="ordenes.length>0 && 'ordenes' in ordenes[0]">{{ordenes[0]['ordenes'][0].chofer__nombre_completo}}</h4>
                </div>
            </div>
            <div class="card mb-3" v-for="orden in ordenes" v-bind:key="orden['detalles.id_embarque']">
                <div class="card__header">
                    <h4 class="card__title">ID de Embarque: {{orden['detalles.id_embarque']}}</h4>
                </div>
                <div class="card__body" v-if="orden.ordenes">
                    <div class="row">
                        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" v-for="ov in orden.ordenes" v-bind:key="ov.id">
                            <button class="ordenes__card" @click="pushOrden(ov.id)" :disabled="ov.fechahora_fin">
                                <div class="ordenes__card--header">
                                    <span class="badge badge--primary m-0 w-100">Fecha de reparto: {{ov.fechahora_logistica | formatDateTimeToDate }}</span>
                                    <span class="ordenes__title">{{ov.numero}}</span>
                                </div>
                                <div class="ordenes__card--body">
                                    <span class="badge m-0 mb-3" :class="{'badge--success': ov.estado==='En Despacho', 'badge--warning': ov.estado==='Entregado con errores'}">{{ov.estado}}</span>
                                    <span class="badge badge--info m-0 mb-3">Embarque: {{ov.detalles.id_embarque}}</span>
                                    <div class="d-flex">
                                        <font-awesome-icon class="button__icon--left" :icon="['fas', 'map-pin']" />
                                        <p v-if="ov.detalles">
                                            <strong>{{ov.region}}.</strong>
                                            {{ov.detalles.direccion}}, {{ov.detalles.ciudad}} - {{ov.detalles.comuna}}.
                                        </p>
                                    </div>
                                    <div class="d-flex">
                                        <font-awesome-icon class="button__icon--left" :icon="['fas', 'user']" />
                                        <p v-if="ov.detalles">{{ov.detalles.nombre_cliente}}</p>
                                    </div>
                                    <div class="d-flex" v-if="ov.detalles && ov.detalles.telefono_cliente">
                                        <font-awesome-icon class="button__icon--left" :icon="['fas', 'phone-alt']" />
                                        <p>{{ov.detalles.telefono_cliente}}</p>
                                    </div>
                                    <div class="d-flex" v-if="ov.detalles && ov.detalles.email">
                                        <font-awesome-icon class="button__icon--left" :icon="['fas', 'envelope']" />
                                        <a :href="`mailto:${ov.detalles.email}`">{{ov.detalles.email}}
                                        </a>
                                    </div>
                                </div>
                                <hr>
                                <p class="text-center mb-3" v-if="ov.detalles"><a :href="`https://www.google.com/maps/place/${ov.detalles.lat},${ov.detalles.long}`">{{ov.detalles.lat}}, {{ov.detalles.long}}</a></p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import resource from 'resource-axios'
import HeaderOrdenes from '@/components/HeaderOrdenes.vue'
import HeaderGeneral from '@/components/HeaderGeneral.vue'
import _ from 'lodash'

const Camion = resource('/logistica/api/camiones', axios)
const Ordenes = resource('/logistica/api/ordenes_venta', axios)

export default {
    name: 'Ordenes',
    components: { HeaderOrdenes, HeaderGeneral },
    async beforeCreate () {

    },
    data () {
        return {
            choferId: this.$route.params.choferId,
            ordenes: [],
            camion: {},
            orden: {},
            entregada: false,
            despachada: true,
            noEntregada:false,
            params: {}
        }
    },
    methods: {
        getOrdenes () {
            this.params = {}
            this.params.chofer = this.choferId
            Ordenes.query(this.params).then((res) => {
                this.ordenes = res.data
                this.ordenes = _.chain(this.ordenes)
                    .groupBy('detalles.id_embarque')
                    .toPairs()
                    .map(function (currentItem) {
                        return _.zipObject(['detalles.id_embarque', "ordenes"], currentItem)
                    })
                    .value()
            })
        },
        pushOrden(id) {
            this.$router.push({ name: 'DetalleOrden', params: { ordenId: id }})
        },
        verDespachadas() {
            this.entregada = null
            this.despachada = true
            this.noEntregada = null
            this.getOrdenes()
        },
        verEntregadas() {
            this.entregada = true
            this.despachada = null
            this.noEntregada = null
            this.getOrdenes()
        },
        verNoEntregadas() {
            this.entregada = null
            this.despachada = null
            this.noEntregada = true
            this.getOrdenes()
        }
    },
    mounted () {
        this.getOrdenes()
    },
    computed: {
        user () {
            return this.$store.state.user
        }
    }
}
</script>
