<template>
    <div>
        <Header :empresa-nombre="empresaNombre" :empresa-id="empresaId" :user="user"></Header>
        <!-- <panel-empresas :base-url="baseUrl"></panel-empresas> -->
        <gestor-cotizaciones v-if="!isBodega && !isLogistica"></gestor-cotizaciones>
        <!-- <Breadcrumb></Breadcrumb> -->
    </div>
</template>

<script>
import axios from 'axios'
import resource from 'resource-axios'
import Header from '@/components/Header.vue'
/* import PanelEmpresas from '@/components/PanelEmpresas.vue' */
import GestorCotizaciones from '@/cotizacion/GestorCotizaciones.vue'

export default {
    name: 'HomeEmpresa',
    // props: ['user', 'isAdmin'],
    components: {
        Header,
        /* PanelEmpresas, */
        GestorCotizaciones
    },
    async beforeCreate () {

    },
    data () {
        return {
            empresaId: this.$route.params.empresaId,
            empresaNombre: this.$route.params.empresaNombre
        }
    },
    mounted () {
    },
    methods: {
        permisoBodega () {
            return this.$store.state.permiso_ver_bodega
        },
        permisoLogistica () {
            return this.$store.state.permiso_ver_logistica
        },
    },
    computed: {
        baseUrl () {
            return '/' + this.empresaId + '/' + this.empresaNombre
        },
        user () {
            return this.$store.state.user
        },
        isAdmin () {
            return this.$store.state.admin
        },
        isDigitador () {
            return this.$store.state.digitador
        },
        // permisoBodega() {
        //     return this.$store.state.permiso_ver_bodega
        // },
        // permisoLogistica() {
        //     return this.$store.state.permiso_ver_logistica
        // },
        isBodega () {
            return this.$store.state.bodega
        },
        isLogistica () {
            return this.$store.state.logistica
        }
    }
}
</script>
