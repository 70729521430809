<template>
    <div class="container-fluid mt-3">
        <div class="card mb-3" v-if="$route.name==='ConfirmCliente'">
            <div class="card__body">
                <div class="alert alert--primary" role="alert"  v-if="orden && orden.numero && !error">
                    <font-awesome-icon :icon="['fas', 'info-circle']" class="alert__icon" />
                    <span class="alert__text">
                        ¡Gracias por su confirmación en la orden {{orden.numero}} !
                    </span>
                </div>
                <div class="alert alert--success" role="alert" v-if="orden && orden.numero && error">
                    <font-awesome-icon :icon="['fas', 'check-circle']" class="alert__icon" />
                    <span class="alert__text">
                        ¡Su orden {{orden.numero}} ya ha sido confirmada!
                    </span>
                </div>
                <div class="alert alert--danger" role="alert"  v-if="orden && !orden.numero && error">
                    <font-awesome-icon :icon="['fas', 'exclamation-circle']" class="alert__icon" />
                    <span class="alert__text">¡Ups! Ha ocurrido un error, vuelve a intentarlo.</span>
                </div>
            </div>
        </div>
        <div class="card" v-else>
            <div class="card__body">
                <div class="form-group">
                    <label class="title--ordenes bold">Estado</label>
                    <b-form-radio v-model="estado" value="Entregada">Entregada</b-form-radio>
                    <b-form-radio v-model="estado" value="Entregada con Errores">Entregada con errores</b-form-radio>
                    <b-form-radio v-model="estado" value="No Entregada">No entregada</b-form-radio>
                </div>
                <div class="form-group" v-if="estado==='Entregada con Errores'">
                    <label class="title--ordenes bold">Razón</label>
                    <v-select :options="razonesErrores" v-model="form.razon"></v-select>
                </div>
                <div class="form-group mb-3">
                    <label class="title--ordenes bold">Observaciones</label>
                    <div class="alert alert--primary" role="alert"  v-if="form.razon === 'Otros' && estado==='Entregada con Errores'">
                        <font-awesome-icon :icon="['fas', 'info-circle']" class="alert__icon" />
                        <span class="alert__text">Porfavor explique por qué se entregó con errores la orden.</span>
                    </div>
                    <textarea class="form-control" v-model="form.observaciones"></textarea>
                </div>
                <div class="alert alert--success" role="alert" v-if="orden && orden.numero && !error">
                    <font-awesome-icon :icon="['fas', 'check-circle']" class="alert__icon" />
                    <span class="alert__text">¡Sus comentarios sobre la orden {{orden.numero}} han sido enviados!</span>
                </div>
                <div class="alert alert--success" role="alert" v-if="orden && orden.numero && error">
                    <font-awesome-icon :icon="['fas', 'check-circle']" class="alert__icon" />
                    <span class="alert__text">¡Sus observaciones en la orden {{orden.numero}} ya han sido enviados!</span>
                </div>
                <div class="alert alert--danger" role="alert" v-if="orden && !orden.numero && error">
                    <font-awesome-icon :icon="['fas', 'exclamation-circle']" class="alert__icon" />
                    <span class="alert__text">¡Ups! Ha ocurrido un error, vuelve a intentarlo.</span>
                </div>
            </div>
            <div class="card__footer" v-if="estado !== null">
                <a @click="setData()" class="button button--primary">
                    <span class="button__text">Enviar</span>
                    <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import resource from 'resource-axios'

const Orden = resource('/logistica/api/ordenes_venta', {
    get: (id, params) => axios.get(`/logistica/api/ordenes_venta/${id}/set_orden_entregada/`, { params }),
    put: (id, params) => axios.put(`/logistica/api/ordenes_venta/${id}/set_form_orden/`, { params }),
}, axios)

export default {
    name: 'ConfirmCliente',
    async beforeCreate () {

    },
    data () {
        return {
            uid: this.$route.params.uid,
            ordenId: this.$route.params.ordenId,
            orden: {},
            error: false,
            form: { entregado_cliente: null, observaciones: ''},
            estado: null
        }
    },
    methods: {
        getData () {
            this.error = false
            Orden.get(this.ordenId, { uid: this.uid }).then((res) => {
                this.orden = res.data
            })
                .catch((error) => {
                    this.orden = error.response.data
                    this.error = true
                })
        },
        setData () {
            this.form['uid'] = this.uid
            if (this.estado === 'Entregada') {
                this.form.entregado = true
                this.form.entregado_con_errores = false
                this.form.no_entregado = false
                this.form.despachado = true
                this.form.razon = ''
            } else if (this.estado === 'Entregada con Errores') {
                this.form.entregado = false
                this.form.entregado_con_errores = true
                this.form.no_entregado = false
                this.form.corregido = false
                this.form.despachado = true
            } else if (this.estado === 'No Entregada') {
                this.form.entregado = false
                this.form.entregado_con_errores = false
                this.form.no_entregado = true
                this.form.despachado = false
                this.form.mensaje_despachando = false
            }
            this.form.estado = this.estado
            Orden.put(this.ordenId, { form: this.form }).then((res) => {
                this.orden = res.data
            })
                .catch((error) => {
                    this.orden = error.response.data
                    this.error = true
                })
        }
    },
    mounted () {
        if (this.$route.name == 'ConfirmCliente')
            this.getData()
    },
    computed: {
        razonesErrores () {
            return ['Hay menos artículos que los indicados en factura', 'Hay artículos en mal estado', 'Faltó maquinaria para descarga completa', 'Otros']
        }
    }
}
</script>
