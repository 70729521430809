<template>
    <nav class="navbar navbar-expand-lg">
        <modal-password :user="user" ref="modalPassword"></modal-password>
        <router-link class="navbar-brand" v-if="user" :to="`/ordenes/chofer/${user.id}`">
        <span class="navbar__title">Sistema de Órdenes</span>
        </router-link>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <font-awesome-icon :icon="['fas', 'bars']" />
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav ml-auto d-none d-md-flex">
                <li class="nav-item  dropdown" >
                    <a class="nav-link dropdown-toggle" data-toggle="dropdown" style="cursor: pointer;" aria-haspopup="true" aria-expanded="false">
                        <font-awesome-icon :icon="['fas', 'user']" class="button__icon--left"/>
                        <span v-if="user">{{user.get_nombre_completo}}</span>
                    </a>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink" style="left:-30px;">
                        <li class="nav-item">
                            <a class="nav-link" @click="cambiarPassword()" style="cursor: pointer;">Cambiar Contraseña</a>
                            <router-link class="nav-link nav-link-exit" to='/login?logout' style="cursor: pointer;">Cerrar Sesión</router-link>
                        </li>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
import ModalPassword from '@/modals/ModalPassword.vue'


export default {
    name: 'HeaderOrdenes',
    components: {
        ModalPassword
    },
    data () {
        return {
            activeClass: 'nav-link--active'
        }
    },
    computed: {
        currentPage () {
            return this.$route.path
        },
        user () {
            return this.$store.state.user
        }
    },
    methods: {
        cambiarPassword() {     
            this.$refs.modalPassword.openModal()
        }
    },
    mounted () {
    }
}
</script>
