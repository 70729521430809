<!--BOTON AGREGAR PRESUPUESTO-->
<template>
  <div>
    <b-modal :id="modal" size="md" :title="title" no-close-on-esc no-close-on-backdrop hide-header-close>
      <div class="row">
        <div class="form-group col-md-12">
          <label>Título* <b-badge show variant="danger" v-show="error && error.numero_equipo"> Título no puede estar vacío</b-badge></label>
          <input type="text" name="" class="form-control" v-model="formPresupuesto.numero_equipo">
        </div>
        <!-- <div class="form-group col-md-12" v-if="edit && formPresupuesto.tasa_cambio">
          <label>Tasa de Cambio</label>
          <br>
          <span v-for="tasa in formPresupuesto.tasa_cambio" v-bind:key="tasa.moneda">
            <small><label><strong>{{tasa.moneda}}</strong></label></small>
            <input type="number" name="" class="form-control" v-model="tasa.tasa_cambio" min="1">
          </span>
        </div> -->
      </div>
      <template #modal-footer="{ }">
        <button class="button button--secondary" @click="closeModal();">
          <span class="button__text">Cancelar</span>
          <font-awesome-icon :icon="['fas', 'times-circle']" class="button__icon" />
        </button>
        <button class="button button--success" v-if="!edit" @click="createPresupuesto()">
          <span class="button__text">Guardar</span>
          <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
        </button>
        <button class="button button--success" v-if="edit" @click="updatePresupuesto()">
          <span class="button__text">Actualizar</span>
          <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
        </button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
const Presupuesto = resource('/cotizacion/api/gestor_presupuestos/', {
    update: (id, params) => axios.put(`/cotizacion/api/gestor_presupuestos/${id}/`, { params })
}, axios)

export default {
    name: 'ModalPresupuesto',
    props: ['modal', 'presupuesto', 'edit', 'title', 'cambios'],
    components: {
    },
    data () {
        return {
            formPresupuesto: {
                numero_equipo: null,
                tasa_cambio: null
            },
            error: null
        }
    },
    methods: {
        openModalCreate () {
            this.formPresupuesto = {
                numero_equipo: null,
                tasa_cambio: null
            }
            this.$root.$emit('bv::show::modal', 'modal-create-presupuesto')
        },
        openModalEdit () {
            if (this.presupuesto) this.formPresupuesto = this.presupuesto
            this.$root.$emit('bv::show::modal', 'modal-edit-presupuesto')
        },
        closeModal () {
            this.$emit('reset')
            this.$root.$emit('bv::hide::modal', this.modal)
        },
        createPresupuesto () {
            this.error = null
            this.formPresupuesto.cotizacion = this.$parent.cotizacionId
            var c = this.cambios.map((c) => { return {'moneda': c.moneda, 'tasa_cambio': c.tasa_cambio}})
            this.formPresupuesto.tasa_cambio = c
            Presupuesto.save(this.formPresupuesto).then((res) => {
                this.closeModal()
                this.$emit('accept')
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Presupuesto creado con exito'
                })
            })
                .catch((error) => {
                    this.error = error.response.data
                    this.$emit('reset')
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al crear el presupuesto'
                    })
                })
        },
        updatePresupuesto () {
            this.error = null
            this.formPresupuesto.cotizacion = this.$parent.cotizacionId
            Presupuesto.update(this.formPresupuesto.id, this.formPresupuesto).then((res) => {
                this.closeModal()
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Presupuesto editado con exito'
                })
            })
                .catch((error) => {
                    this.error = error.response.data
                    this.$emit('reset')
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al editar el presupuesto'
                    })
                })

        }
    },
    mounted () {
    },
    computed: {
    }
}
</script>
