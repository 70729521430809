<!--COTIZACIONES - CREAR COTIZACION-->

<template>
  <div>
    <modal-cliente modal="modal-create-cliente" :edit="false" ref="modalCreateCliente" title="Crear Cliente" @confirm="getCliente" :cotizacion="true"></modal-cliente>
    <!-- <modal-direccion-despacho :cliente="modalAddressData" ref="modalAddress" :title="modalAddressData.numero" @accept="getDireccion();modalAddressData=null;" v-if="modalAddressData"></modal-direccion-despacho> -->
    <b-modal :id="modal" size="lg" :title="title" no-close-on-esc no-close-on-backdrop hide-header-close>
      <div class="row">
        <div class="col-md-6">
          <label>Número Cotización</label>
          <input type="text" name="" class="form-control" v-model="formCotizacion.numero" :disabled="$parent.empresaNombre === 'tagle'">
        </div>
        <div class="col-md-6">
          <label>Tipo de Cotización *</label>
          <v-select :options="tipos" v-model="formCotizacion.tipo" :disabled="$parent.empresaNombre==='tagle'">        
          </v-select>
        </div>
        <div class="col-md-6" v-if="$parent.empresaNombre === 'dripsa' && formCotizacion.tipo === 'Proyecto'">
          <label>Nombre Proyecto *</label>
          <input type="text" name="" class="form-control" v-model="formCotizacion.nombre_proyecto">
        </div>
        <div class="col-md-6" v-if="$parent.empresaNombre === 'dripsa' && formCotizacion.tipo === 'Proyecto'">
          <label>Número de Proyecto</label>
          <input type="text" name="" class="form-control" v-model="formCotizacion.numero_proyecto">
        </div>
        <div class="col-md-12">
          <label class="d-inline-bock">Cliente *</label>
          <div class="float-right">
            <a @click="openModalCliente()" class="button--sm button--primary">
            Crear Cliente <font-awesome-icon class="button__icon--sm" :icon="['fas', 'plus-circle']" />
            </a>
          </div>
          <v-select :options="clientes" v-model="formCotizacion.cliente" label="nombre" :filter-by="myFilter">
              <template slot="option" slot-scope="option">
                  {{ option.numero }} - {{option.nombre}}
              </template>
              <template slot="selected-option" slot-scope="option">
                  {{ option.numero }} - {{option.nombre}}
              </template>                        
              <span slot="no-options">
                  No se encontraron resultados.
              </span>
          </v-select>
          <div class="alert alert--danger" role="alert" v-show="error && error.cliente">
            <font-awesome-icon :icon="['fas', 'exclamation-circle']" class="alert__icon" />
            <span class="alert__text">Cliente no puede estar vacío</span>
          </div>
        </div>
        <!-- <div class="form-group col-md-7" v-if="formCotizacion.cliente">
            <label>Seleccione la dirección del Cliente * </label>
            <div class="float-right">
                <a @click="setDataAddress(formCotizacion.cliente)" class="button--sm button--primary">
                    Agregar Dirección<font-awesome-icon class="button__icon--sm" :icon="['fas', 'plus-circle']" />
                </a> 
            </div>
            <v-select :options="direcciones" v-model="formCotizacion.direccion" label="direccion" :filter-by="myFilterDireccion" v-if="direcciones.length > 0">
                <template slot="option" slot-scope="option">
                    {{option.tipo}} - {{option.id_embarque}}. {{option.direccion}}. {{option.comuna}}, {{option.region}}.
                </template>
                <template slot="selected-option" slot-scope="option">
                    {{option.tipo}} - {{option.id_embarque}}. {{option.direccion}}. {{option.comuna}}, {{option.region}}.
                </template>
                <div class="alert alert--warning" role="alert" slot="no-options">
                    <font-awesome-icon :icon="['fas', 'exclamation-circle']" class="alert__icon" />
                    <span class="alert__text">No se encontraron resultados.</span>
                </div>
            </v-select>
          </div>
          <div class="col-md-5">
            <label>Método de Despacho</label>
            <v-select :options="despachos" v-model="formCotizacion.despacho" label="despacho">
            </v-select>
          </div>-->
      </div>
      <template #modal-footer="{ }">
        <button class="button button--secondary" @click="closeModal();">
          <span class="button__text">Cancelar</span>
          <font-awesome-icon :icon="['fas', 'times-circle']" class="button__icon" />
        </button>
        <button class="button button--success" @click="createCotizacion()" v-if="!edit">
          <span class="button__text">Guardar</span>
          <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
        </button>
        <button class="button button--success" @click="updateCotizacion()" v-if="edit">
          <span class="button__text">Actualizar</span>
          <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
        </button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
import ModalCliente from '@/modals/ModalCliente.vue'
// import ModalDireccionDespacho from '@/modals/ModalDireccionDespacho.vue'

const Cliente = resource('/base/api/listar_clientes/', axios)
const Cotizacion = resource('/cotizacion/api/gestor_cotizaciones/', {
    update: (id, params) => axios.put(`/cotizacion/api/gestor_cotizaciones/${id}/`, { params }),
}, axios)

export default {
    name: 'ModalCotizacion',
    props: ['modal', 'cotizacion', 'edit', 'title'],
    components: {
        ModalCliente
    },
    data () {
        return {
            clientes: [],
            formCotizacion: {
                numero: null,
                nombre_proyecto: null,
                numero_proyecto: null,
                cliente: null,
                tipo: null,
                direccion: {}
            },
            tipos: [
                'Nota de Venta',
                'Proyecto'
            ],
            direcciones: [],
            error: null,
            // despachos: [
            //     'TRANSPORTE INTERNO',
            //     'TRANSPORTE EXTERNO ACC',
            //     'TRANSPORTE EXTERNO ACI',
            //     'RETIRO VENDEDOR',
            //     'RETIRO CLIENTE',
            //     'DESPACHO DIRECTO',
            //     'INSTALACION',
            //     'NO APLICA'
            // ],
            // modalAddressData: null
        }
    },
    methods: {
        getClientes () {
            Cliente.query({ empresa: this.$parent.empresaId }).then((res) => {
                this.clientes = res.data
            })
        },
        // getDireccion () {
        //     Cliente.query({ id: this.formCotizacion.cliente.id }).then((res) => {
        //         this.direcciones = res.data[0]['direccion']
        //     })
        // },
        openModalCreate () {
            this.formCotizacion = {
                numero: null,
                nombre_proyecto: null,
                numero_proyecto: null,
                cliente: null,
                tipo: 'Nota de Venta',
                direccion: {}
            }
            this.$root.$emit('bv::show::modal', 'modal-create-cotizacion')
        },
        openModalEdit () {
            this.formCotizacion = this.cotizacion
            this.$root.$emit('bv::show::modal', 'modal-edit-cotizacion')
        },
        closeModal () {
            this.$root.$emit('bv::hide::modal', this.modal)
        },
        createCotizacion () {
            this.error = null
            this.formCotizacion.empresa = parseInt(this.$parent.empresaId)
            if (this.formCotizacion.numero_proyecto === null) this.formCotizacion.numero_proyecto = ''
            this.formCotizacion.cliente = this.formCotizacion.cliente.id
            Cotizacion.save(this.formCotizacion).then((res) => {
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Cotización creada con éxito'
                })
                var params = this.$route.params
                params['cotizacionId'] = res.data['id']
                // console.log(params)
                this.closeModal()
                this.$router.push({ name: 'Cotizacion', params: params })

            })
                .catch((error) => {
                    // console.log(error)
                    // this.error = error.response.data
                    this.$emit('accept')
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al crear la cotización'
                    })
                })
        },
        updateCotizacion () {
            this.error = null
            this.formCotizacion.empresa = parseInt(this.$parent.empresaId)
            if (this.formCotizacion.numero_proyecto === null) this.formCotizacion.numero_proyecto = '   '
            this.formCotizacion.cliente = this.formCotizacion.cliente.id
            Cotizacion.update(this.cotizacion.id, this.formCotizacion).then((res) => {
                this.$emit('accept')
                this.closeModal()
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Cotización actualizada con éxito'
                })
            })
                .catch((error) => {
                    this.error = error.response.data
                    this.$emit('accept')
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al actualizar la cotización'
                    })
                })
        },
        openModalCliente () {
            this.$refs.modalCreateCliente.openModalCreate()
        },
        getCliente (value) {
            this.formCotizacion.cliente = value
            // this.modalAddressData = null
            this.getClientes()
        },
        // openModalAddress () {
        //     this.$refs.modalAddress.openModal()
        // },
        // setDataAddress (item) {
        //     this.modalAddressData = item
        // },
        // getClienteAddress (value) {
        //     this.setDataAddress(value)
        // },
        myFilter: (option, label, search) => {
            let temp = search.toUpperCase().toString()
            return option.numero.toUpperCase().indexOf(temp) > -1 || 
            option.nombre.toUpperCase().indexOf(temp) > -1
        }
        // myFilterDireccion: (option, label, search) => {
        //     let temp = search.toUpperCase().toString()
        //     return option.region.toUpperCase().indexOf(temp) > -1 || 
        //     option.comuna.toUpperCase().indexOf(temp) > -1 || 
        //     option.direccion.toUpperCase().indexOf(temp) > -1 
        //     // || 
        //     // option.id_embarque.toUpperCase().indexOf(temp) > -1 || 
        //     // option.tipo.toUpperCase().indexOf(temp) > -1
        // }
    },
    mounted () {
        this.getClientes()
        // this.$watch('formCotizacion.cliente', (newLocale, oldLocale) => {
        //     if (newLocale !== oldLocale && newLocale !== null) {
        //         this.getDireccion()
        //     }
        //     else if (newLocale !== oldLocale && newLocale === null)
        //         this.formCotizacion.direccion = null
        // }, { immediate: true })
        // this.$watch('modalAddressData', (newLocale, oldLocale) => {
        //     if (newLocale !== oldLocale && newLocale !== null) {
        //         this.openModalAddress()
        //     }
        // }, { immediate: true })
    }
}
</script>
