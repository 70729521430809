<template>
  <div>
    <b-modal id="modal-precio" size="lg" :title="title" no-close-on-esc no-close-on-backdrop hide-header-close>
      <div class="row" v-if="$parent.empresaNombre==='dripsa'">
          <div class="col-md-2">
              <label>
                  <small>Cantidad</small>
              </label>
              <input type="number" min="1" name="" class="form-control" v-model="formPrecio.cantidad">
          </div>
          <div class="col-md-5" v-if="!edit">
              <label><small>Costo</small></label>
              <vue-numeric currency="$" class="form-control" separator="," v-model="formPrecio.costo" v-bind:precision="3"></vue-numeric>
          </div>
          <div class="col-md-5">
              <label>
                  <small>
                      Precio mínimo: 
                  <vue-numeric currency="$" disabled separator="," v-model="formPrecio.precio_lista" v-bind:precision="3"></vue-numeric> 
              </small>
              <vue-numeric currency="$" separator="." v-model="formPrecio.precio" :min="precioMin" class="form-control" v-bind:precision="3"></vue-numeric> 
              </label>
              <br v-if="error">
              <small v-if="error" class="red">{{error}}</small>
          </div>
      </div>
      <div class="row" v-if="$parent.empresaNombre==='tagle'">
          <div class="col-md-12">
              <input class="mr-2" type="radio" id="precio" :value="true" v-model="precioDescuento" />
              <label for="precio">Precio</label>
              <input class="ml-5 mr-2" type="radio" id="Descuento" :value="false" v-model="precioDescuento" />
              <label for="Descuento">Descuento</label>
          </div>
          <div class="col-md-2">
              <label>
                  <small>Cantidad</small>
              </label>
              <input type="number" min="1" name="" class="form-control" v-model="formPrecio.cantidad">
          </div>
          <div class="col-md-5" v-if="!edit">
              <label><small>Costo</small></label>
              <vue-numeric currency="$" class="form-control" separator="," v-model="formPrecio.costo" v-bind:precision="3"></vue-numeric>
          </div>
          <div class="col-md-5">
              <label>
                  <small>
                      Precio lista: 
                  <vue-numeric currency="$" disabled separator="," v-model="formPrecio.precio_lista" v-bind:precision="3"></vue-numeric> 
                      Dcto. Tope: {{formPrecio.descuento_tope }}
                  <input v-model="formPrecio.descuento_tope" disabled hidden>
                  <!-- <a v-if="formPrecio.descuento && formPrecio.descuento > 0" @click="discountUp=!discountUp" class="btn btn-primary btn-sm">
                      <font-awesome-icon :icon="['fas', 'chevron-up']" v-if="!discountUp"/>
                      <font-awesome-icon :icon="['fas', 'chevron-down']" v-if="discountUp"/>
                  </a> -->
              </small>
              </label>
              <percent-input :key="keyPercent" v-model="descuento" class="form-control" v-bind="{ integerPlaces: 2, decimalPlaces: 2, suffix: true }" v-if="!precioDescuento"></percent-input>
              <!-- <percent-input class="form-control" v-bind="{ integerPlaces: 2, decimalPlaces: 2, suffix: true }" v-model="formPrecio.descuento"></percent-input> -->
              <small v-if="!precioDescuento">
                  <vue-numeric currency="$" disabled separator="," v-model="formPrecio.precio" v-bind:precision="3"></vue-numeric> 
              </small>
              <vue-numeric currency="$" separator="." v-model="precio" size="8rem" v-if="precioDescuento" v-bind:precision="3" class="form-control"></vue-numeric>
              <percent-input :key="keyDescuento" v-model="descuento" disabled v-bind="{ integerPlaces: 2, decimalPlaces: 2, suffix: true }" v-if="precioDescuento"></percent-input>
              <div class="alert alert--danger" role="alert" v-if="error">
                  <font-awesome-icon :icon="['fas', 'exclamation-circle']" class="alert__icon" />
                  <span class="alert__text">{{error}}</span>
              </div>
          </div>
      </div>
      <template #modal-footer="{ }">
        <button class="button button--secondary" @click="closeModal();$emit('cancel');">
          <span class="button__text">Cancelar</span>
          <font-awesome-icon :icon="['fas', 'times-circle']" class="button__icon" />
        </button>
        <button class="button button--success" @click="updatePrecio()" v-if="formPrecio.cantidad && formPrecio.precio && formPrecio.costo && !edit|| edit && formPrecio.cantidad && formPrecio.precio">
          <span class="button__text">Guardar</span>
          <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
        </button>
      </template>
    </b-modal>
  </div>
</template>
<script>

export default {
    name: 'ModalPrecio',
    props: ['articulo', 'edit'],
    components: {
    },
    data () {
        return {
            formPrecio: {},
            error: null,
            precioMin: 0,
            // discountUp: false,
            precioDescuento: false,
            keyPercent: 0,
            keyDescuento: 0,
            descuento: null,
            precio: null,
            title: 'Agregar Precio Nuevo'
        }
    },
    methods: {
        openModalCreate () {
            if (this.$parent.empresaNombre === 'tagle' && !this.edit)
                this.formPrecio.descuento_tope = 15
            if (this.$parent.empresaNombre === 'dripsa') {
                this.formPrecio.costos = 0
                this.formPrecio.precio_lista = 0
                this.formPrecio.precio = 0
            }
            if (this.edit) {
                this.formPrecio.id = this.articulo.id
                this.formPrecio.articulo = this.articulo.articulo
                this.formPrecio.moneda = this.articulo.moneda
                this.formPrecio.presupuesto = this.articulo.presupuesto
                this.formPrecio.cantidad = this.articulo.cantidad
                this.formPrecio.descuento = this.articulo.descuento
                this.formPrecio.descuento_tope = this.articulo.descuento_tope
                this.formPrecio.precio = this.articulo.precio
                this.descuento = this.articulo.descuento
                this.precio = this.articulo.precio
                this.formPrecio.precio_lista = this.articulo.precio_lista
                this.title = `${this.articulo.articulo__codigo} - ${this.articulo.articulo__descripcion}`
            }
            this.$root.$emit('bv::show::modal', 'modal-precio')
            this.forceRerenderDescuento()
            this.forceRerenderPercent()
        },
        closeModal () {
            this.error = null
            this.formPrecio.precio = null
            this.precio = null
            this.formPrecio.descuento = null
            this.descuento = null
            this.$root.$emit('bv::hide::modal', 'modal-precio')
        },
        updatePrecio (event) {
            if (!this.edit) {
                this.$emit('accept', this.formPrecio)
            } else if (this.edit) {
                console.log(this.formPrecio)
                this.$emit('acceptUpdate', this.formPrecio)
            }
            this.closeModal()
        },
        forceRerenderPercent() {
            this.keyPercent += 1;
        },
        forceRerenderDescuento() {
            this.keyDescuento += 1;
        }
    },
    watch: {
        descuento () {
            this.formPrecio.descuento = this.descuento
            if (this.formPrecio.descuento_tope < this.formPrecio.descuento)
                this.error = 'ATENCIÓN: el descuento es mayor al tope y necesita aprobación.'
            else this.error = null

            this.formPrecio.error = this.error
            var precioCalculado = this.formPrecio.precio_lista - (this.formPrecio.precio_lista * this.formPrecio.descuento/100)
            if (!this.precio || this.edit && !this.precio || this.edit && !this.precioDescuento) {
                if (this.articulo.moneda !== 'CLP') {
                    this.formPrecio.precio = precioCalculado.toFixed(3)
                }
                else
                    this.formPrecio.precio = Math.round(precioCalculado)
                if (this.formPrecio.descuento === 0)
                    this.formPrecio.precio = this.formPrecio.precio_lista
            }
        },
        precio () {
            this.formPrecio.precio = this.precio
            var precio_lista = this.formPrecio.precio_lista
            var dctoCalculado = Math.round((100 - ((100*this.formPrecio.precio)/precio_lista)))
            this.descuento = dctoCalculado
            this.forceRerenderDescuento()
        },

        precioDescuento () {
            this.error = null
            this.formPrecio.precio = null
            this.precio = null
            this.formPrecio.descuento = null
            this.descuento = null
            this.forceRerenderDescuento()
            this.forceRerenderPercent()
        }
    },
    mounted () {
        this.$watch('formPrecio.costo', (newLocale, oldLocale) => {
            this.discountUp = false
            if (newLocale !== oldLocale && newLocale !== undefined && newLocale !== null && this.formPrecio) {
                if (this.articulo.moneda !== 'CLP') {
                    this.formPrecio.precio_lista = (this.formPrecio.costo/0.7225).toFixed(3)
                    this.precioMin = this.formPrecio.costo/0.7225
                }
                else {
                    this.formPrecio.precio_lista = Math.round(this.formPrecio.costo/0.7225)
                    this.precioMin = Math.round(this.formPrecio.costo/0.7225)
                }

                if (this.formPrecio.descuento === 0 && this.$parent.empresaNombre === 'tagle')
                    this.formPrecio.precio = this.formPrecio.precio_lista
                
                if (this.$parent.empresaNombre === 'dripsa')
                    this.formPrecio.precio = this.formPrecio.precio_lista
            }
        }, { immediate: true })
        this.$watch('formPrecio.descuento', (newLocale, oldLocale) => {
            this.discountUp = false
            if (newLocale !== oldLocale && newLocale !== undefined && newLocale !== null && this.formPrecio && this.$parent.empresaNombre === 'tagle') {
                if (this.formPrecio.descuento_tope < this.formPrecio.descuento)
                    this.error = 'ATENCIÓN: el descuento es mayor al tope y necesita aprobación.'
                else this.error = null

                this.formPrecio.error = this.error
                var precioCalculado = this.formPrecio.precio_lista - (this.formPrecio.precio_lista * this.formPrecio.descuento/100)
                if (this.articulo.moneda !== 'CLP')
                    this.formPrecio.precio = precioCalculado.toFixed(3)
                else
                    this.formPrecio.precio = Math.round(precioCalculado)
                if (this.formPrecio.descuento === 0)
                    this.formPrecio.precio = this.formPrecio.precio_lista
            }
        }, { immediate: true })
        this.$watch('precioDescuento', (newLocale, oldLocale) => {
            this.discountUp = false
            if (newLocale !== oldLocale && newLocale !== undefined && newLocale !== null && this.formPrecio && this.$parent.empresaNombre === 'tagle') {    
                this.error = null
                this.formPrecio.precio = null
                this.precio = null
                this.formPrecio.descuento = null
                this.descuento = null
                this.forceRerenderDescuento()
                this.forceRerenderPercent()
            }
        }, { immediate: true })
        // this.$watch('discountUp', (newLocale, oldLocale) => {
        //     if (newLocale !== oldLocale && newLocale !== undefined && newLocale !== null && oldLocale !== undefined) {
        //         if (newLocale) {
        //             this.formPrecio.precio = parseFloat(this.formPrecio.precio_lista) + (parseFloat(this.formPrecio.precio_lista) * this.formPrecio.descuento/100)
        //         } else {
        //             this.formPrecio.precio = parseFloat(this.formPrecio.precio_lista) - (parseFloat(this.formPrecio.precio_lista) * this.formPrecio.descuento/100)
        //         }
        //     }
        // }, { immediate: true })
    }
}
</script>
