<template>
  <div>
    <b-modal id="modal-edit-direccion-despacho" size="lg" :title="'Direcciones - Cliente n°: ' + title" no-close-on-esc no-close-on-backdrop hide-header-close>
      <direccion-despacho @accept="getDireccion" :key="key" :count-direccion="countDir.length" :cliente="numero"></direccion-despacho>
      <div class="form-group" v-if="formCliente.direccion &&formCliente.direccion.length > 0">
        <h5 class="card__subtitle">Direcciones:</h5>
        <div class="card--outline" v-for="(dir, index) in formCliente.direccion" v-bind:key="dir.direccion">
          <div class="card__body">
            <div class="row">
              <div class="col-md-11">
                <p class="m-0"><strong>{{dir.tipo}}</strong> - {{dir.id_embarque}} <span v-if="dir.sucursal">|</span> {{dir.sucursal}} - {{dir.direccion}} - {{dir.ciudad}}. {{dir.comuna}}, {{dir.region}}.</p>
                <br v-if="dir.lat || dir.long">
                <p class="m-0" v-if="dir.lat">LAT {{dir.lat}}</p>
                <p class="m-0" v-if="dir.long">LONG {{dir.long}}</p>
                <p v-if="dir.lat && dir.long"><a :href="`https://www.google.com/maps/place/${dir.lat},${dir.long}`" target="_blank">Ver Mapa</a></p>
                <br v-if="dir.nombre_contacto || dir.telefono || dir.email">
                <p class="m-0"><strong>CONTACTO:</strong> 
                  <font-awesome-icon :icon="['fas', 'user']" v-if="dir.nombre_contacto"/>{{dir.nombre_contacto}} <font-awesome-icon :icon="['fas', 'phone']" v-if="dir.telefono"/>{{dir.telefono}}
                  <font-awesome-icon :icon="['fas', 'envelope']" v-if="dir.email"/>{{dir.email}}</p>
              </div>
              <div class="col-md-1">
                <a @click="removeDireccion(index)" role="button" class="button--sm button--danger">
                  <font-awesome-icon :icon="['fas', 'trash-alt']" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer="{ }">
        <button class="button button--secondary" @click="closeModal();$emit('accept');">
          <span class="button__text">Cancelar</span>
          <font-awesome-icon :icon="['fas', 'times-circle']" class="button__icon" />
        </button>
        <!-- <a @click="updateCliente()" class="btn btn-primary" :disabled="formCliente.direccion && formCliente.direccion.length===0"><font-awesome-icon :icon="['fas', 'check']" /> Guardar</a> -->
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
import DireccionDespacho from '@/components/DireccionDespacho.vue'

const Cliente = resource('/base/api/gestor_clientes/', {
    update: (id, params) => axios.put(`/base/api/gestor_clientes/${id}/`, { params })
}, axios)

export default {
    name: 'ModalDireccionDespacho',
    props: ['modal', 'cliente', 'title'],
    components: {
        DireccionDespacho
    },
    data () {
        return {
            formCliente: {
                direccion: []
            },
            key: 0,
            countDir: [],
            numero: null
        }
    },
    methods: {
        openModal () {
            if (this.cliente) this.formCliente = this.cliente
            this.numero = this.cliente.numero
            while (this.numero.length >= 8) {
                this.numero = this.numero.slice(0, -1)
            }
            if (this.cliente.direccion.length > 0) {
                this.countDir = this.cliente.direccion.filter((item) => {
                    return item.tipo === 'DESPACHO'
                })
            }
            this.$root.$emit('bv::show::modal', 'modal-edit-direccion-despacho')
        },
        closeModal () {
            this.$root.$emit('bv::hide::modal', 'modal-edit-direccion-despacho')
        },
        updateCliente () {
            var empresaId = this.$parent.empresaId
            if (!empresaId)
                empresaId = this.$parent.$parent.empresaId
            this.formCliente.empresa = parseInt(empresaId)
            Cliente.update(this.formCliente.id, this.formCliente).then((res) => {
                this.$emit('accept')
                this.closeModal()
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Cliente editado con exito'
                })
            })
                .catch((error) => {
                    this.$emit('accept')
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al editar el cliente'
                    })
                })

        },
        getDireccion (value) {
            this.formCliente.direccion.push(value)
            this.forceRerender()
            this.updateCliente()
        },
        forceRerender() {
            this.key += 1;
        },
        removeDireccion (index) {
            this.formCliente.direccion.splice(index, 1)
            this.updateCliente()
        }
    },
    mounted () {
    }
}
</script>
