import axios from 'axios'
import resource from 'resource-axios'
import Vue from 'vue'
import Vuex from 'vuex'
import { loader } from './loader'
const UsuarioActual = resource('/base/api/usuario_actual', axios)


Vue.use(Vuex)
const store = new Vuex.Store({
    modules: {
        loader
    },
    state: {
        user: {},
        admin: null,
        permiso_ingresar: false,
        permiso_editar: false,
        permiso_revisar: false,
        permiso_aprobar: false,
        permiso_rechazar: false,
        digitador: null,
        permiso_bodega: false,
        permiso_logistica: false,
        bodega: null,
        logistica: null,
        chofer: null
    },
    mutations: {
        set_user (state, user) {
            state.user = user
        },
        set_admin (state, admin) {
            state.admin = admin
        },
        set_permiso_ingresar (state, ingresar) {
            state.permiso_ingresar = ingresar
        },
        set_permiso_editar (state, editar) {
            state.permiso_editar = editar
        },
        set_permiso_revisar (state, revisar) {
            state.permiso_revisar = revisar
        },
        set_permiso_aprobar (state, aprobar) {
            state.permiso_aprobar = aprobar
        },
        set_permiso_rechazar (state, rechazar) {
            state.permiso_rechazar = rechazar
        },
        set_digitador (state, digitador) {
            state.digitador = digitador
        },
        set_permiso_ver_bodega (state, bodega) {
            state.permiso_ver_bodega = bodega
        },
        set_permiso_editar_bodega (state, bodega) {
            state.permiso_editar_bodega = bodega
        },
        set_permiso_ver_logistica (state, logistica) {
            state.permiso_ver_logistica = logistica
        },
        set_permiso_editar_logistica (state, logistica) {
            state.permiso_editar_logistica = logistica
        },
        set_bodega (state, bodega) {
            state.bodega = bodega
        },
        set_logistica (state, logistica) {
            state.logistica = logistica
        },
        set_chofer (state, chofer) {
            state.chofer = chofer
        }

    },
    actions: {
        loadUserFromLocal ({commit,state}) {
            if (localStorage.getItem('jwt.access') === null) return null
            else {
                UsuarioActual.query({}).then((res) => {
                    commit('set_user', res.data[0])
                    if (res.data[0].rol === 'Administrador' || res.data[0].is_superuser) commit('set_admin', true)
                    else commit('set_admin', false)
                    
                    if (res.data[0].rol === 'Digitador') commit('set_digitador', true)
                    else commit('set_digitador', false)

                    if (res.data[0].rol === 'Bodega') commit('set_bodega', true)
                    else commit('set_bodega', false)
                    if (res.data[0].rol === 'Logistica') commit('set_logistica', true)
                    else commit('set_logistica', false)
                    if (res.data[0].rol === 'Chofer') commit('set_chofer', true)
                    else commit('set_chofer', false)

                    var ingresar = false
                    var editar = false
                    var revisar = false
                    var aprobar = false
                    var rechazar = false
                    var ver_bodega = false
                    var ver_logistica = false
                    var editar_bodega = false
                    var editar_logistica = false
                    var permisos = res.data[0].permisos
                    for (var key in permisos) {
                        if (permisos[key]['tipo'] == 'ingresar' && permisos[key]['id'].length > 0)
                            ingresar = true
                        if (permisos[key]['tipo'] == 'editar' && permisos[key]['id'].length > 0)
                            editar = true
                        if (permisos[key]['tipo'] == 'revisar' && permisos[key]['id'].length > 0)
                            revisar = true
                        if (permisos[key]['tipo'] == 'aprobar' && permisos[key]['id'].length > 0)
                            aprobar = true
                        if (permisos[key]['tipo'] == 'rechazar' && permisos[key]['id'].length > 0)
                            rechazar = true
                        if (permisos[key]['tipo'] == 'ver_bodega' && permisos[key]['id'].length > 0)
                            ver_bodega = true
                        if (permisos[key]['tipo'] == 'editar_bodega' && permisos[key]['id'].length > 0)
                            editar_bodega = true
                        if (permisos[key]['tipo'] == 'ver_logistica' && permisos[key]['id'].length > 0)
                            ver_logistica = true
                        if (permisos[key]['tipo'] == 'editar_logistica' && permisos[key]['id'].length > 0)
                            editar_logistica = true
                    }
                    commit('set_permiso_ingresar', ingresar)
                    commit('set_permiso_editar', editar)
                    commit('set_permiso_revisar', revisar)
                    commit('set_permiso_aprobar', aprobar)
                    commit('set_permiso_rechazar', rechazar)
                    commit('set_permiso_ver_bodega', ver_bodega)
                    commit('set_permiso_editar_bodega', editar_bodega)
                    commit('set_permiso_ver_logistica', ver_logistica)
                    commit('set_permiso_editar_logistica', editar_logistica)
                }).catch(function (error) {
                    // handle error case here
                    console.log(error)
                })
            }
        }
    }
})
export default store