<template>
    <div v-if="registros && registros.length > 0">
        <h4 class="card__subtitle">Registros</h4>
        <hr>
        <h5 class="">Chofer</h5>
        <b-table v-if="registrosChofer && registrosChofer.length > 0" responsive stacked :items="registrosChofer" :fields="fields">
        </b-table>
        <br>
        <hr v-if="registrosCliente && registrosCliente.length > 0">
        <h5 v-if="registrosCliente && registrosCliente.length > 0" class="title--ordenes bold">Cliente</h5>
        <b-table v-if="registrosCliente && registrosCliente.length > 0" responsive stacked :items="registrosCliente" :fields="fieldsCliente">
        </b-table>
    </div>
</template>

<script>
export default {
    props: {
        registros: Array
    },
    data () {
        return {
            fields: ['fecha_ingreso_registro', 'fecha_entrega_programada', 'chofer', 'patente', 'usuario', 'estado', 'razon', 'observaciones'],
            fieldsCliente: ['fecha_ingreso_registro', 'estado', 'observaciones', 'cliente', 'email'],


        }
    },
    computed: {
        registrosCliente () {
            return this.registros.filter((item) => {
                if ('cliente' in item) 
                    return item
            }).sort((a,b) => {
                new Date(b.fecha_ingreso_registro.split('/')[2].split(' ')[0], parseInt(b.fecha_ingreso_registro.split('/')[1], 10)-1, b.fecha_ingreso_registro.split('/')[0], b.fecha_ingreso_registro.split('/')[2].split(' ')[1].split(':')[0], b.fecha_ingreso_registro.split('/')[2].split(' ')[1].split(':')[1], b.fecha_ingreso_registro.split('/')[2].split(' ')[1].split(':')[2]) - new Date(a.fecha_ingreso_registro.split('/')[2].split(' ')[0], parseInt(a.fecha_ingreso_registro.split('/')[1], 10)-1, a.fecha_ingreso_registro.split('/')[0], a.fecha_ingreso_registro.split('/')[2].split(' ')[1].split(':')[0], a.fecha_ingreso_registro.split('/')[2].split(' ')[1].split(':')[1], a.fecha_ingreso_registro.split('/')[2].split(' ')[1].split(':')[2])
            }).reverse()

        },
        registrosChofer () {
            return this.registros.filter((item) => {
                if ('usuario' in item) 
                    return item
            }).sort((a,b) => {
                new Date(b.fecha_ingreso_registro.split('/')[2].split(' ')[0], parseInt(b.fecha_ingreso_registro.split('/')[1], 10)-1, b.fecha_ingreso_registro.split('/')[0], b.fecha_ingreso_registro.split('/')[2].split(' ')[1].split(':')[0], b.fecha_ingreso_registro.split('/')[2].split(' ')[1].split(':')[1], b.fecha_ingreso_registro.split('/')[2].split(' ')[1].split(':')[2]) - new Date(a.fecha_ingreso_registro.split('/')[2].split(' ')[0], parseInt(a.fecha_ingreso_registro.split('/')[1], 10)-1, a.fecha_ingreso_registro.split('/')[0], a.fecha_ingreso_registro.split('/')[2].split(' ')[1].split(':')[0], a.fecha_ingreso_registro.split('/')[2].split(' ')[1].split(':')[1], a.fecha_ingreso_registro.split('/')[2].split(' ')[1].split(':')[2])
            }).reverse()

        }
    }

}
</script>

<style>

</style>