<template>
  <div>
    <Header :empresa-nombre="empresaNombre" :empresa-id="empresaId"></Header>
    <modal-respaldo v-if='empresaId' :empresa="empresaId" :despacho="true" ref="modalRespaldo" @accept="getOVConCamion()"></modal-respaldo>
    <modal-mail modal="modal-create-mail" :edit="true" ref="modalMail" @accept="correoDespachadas"></modal-mail>
    <modal-nueva-orden v-if="modalDataOV" modal="modal-create-orden"  ref="modalOVManual" @accept="modalDataOV=null;" :data="modalDataOV"></modal-nueva-orden>
    <modal-vaciar-camion modal="modal-vaciar-camion" :camiones="camiones" :date="fechaData" v-if="camiones" ref="modalVaciar" @accept="getOrdenesVenta();getOVConCamion();fechaData=null;" @close="fechaData=null;"></modal-vaciar-camion>
    <div class="container-fluid">
      <div class="card mb-3">
        <div class="card__header">
          <h4 class="card__title">Logística</h4>
          <div class="card__buttons">
            <button class="button button--primary" @click="marcarDespachadasOVsDeHoy()" type="button" v-if="ordenesSeleccionadas.length > 0 && (isBodega || isLogistica || isAdmin)" :disabled="seleccionGrupo">
              <span class="button__text">Despachar sin enviar correo</span>
              <font-awesome-icon :icon="['fas', 'envelope-open']" class="button__icon" />
            </button>
            <button class="button button--primary" @click="openModalMail()" type="button" v-if="ordenesSeleccionadas.length > 0 && (isBodega || isLogistica || isAdmin)" :disabled="seleccionGrupo">
              <span class="button__text">Despachar y enviar correo</span>
              <font-awesome-icon :icon="['fas', 'mail-bulk']" class="button__icon" />
            </button>
            <button class="button button--primary" @click="correoProximasADespachadar()" type="button" v-if="ordenesSeleccionadasProximas.length > 0 && (isBodega || isLogistica || isAdmin)" :disabled="seleccionGrupo">
              <span class="button__text">Enviar correo por despachar</span>
              <font-awesome-icon :icon="['fas', 'envelope']" class="button__icon" />
            </button>
            <router-link class="button button--line-success" :to="`/${empresaId}/${empresaNombre}/lista_ordenes`" :class="empresaNombre">
              <span class="button__text">Órdenes</span>
              <font-awesome-icon :icon="['fas', 'file']" class="button__icon" />
            </router-link>
            <router-link class="button button--line-warning" :to="`/${empresaId}/${empresaNombre}/bodega`" :class="empresaNombre">
              <span class="button__text">Bodega</span>
              <font-awesome-icon :icon="['fas', 'warehouse']" class="button__icon" />
            </router-link>
            <router-link class="button button--line-purple" to="/gestor_camiones" :class="empresaNombre" v-if="isBodega || isLogistica || isAdmin">
              <span class="button__text">Camiones</span>
              <font-awesome-icon :icon="['fas', 'truck']" class="button__icon" />
            </router-link>
            <div class="dropdown">
                <a data-toggle="dropdown" class="button button--line-primary dropdown-toggle" style="cursor: pointer;" aria-haspopup="true" aria-expanded="false">
                    <span class="button__text mr-3">Acciones</span>
                    <font-awesome-icon :icon="['fas', 'ellipsis-v']" class="button__icon--left" />
                </a>
                <ul class="dropdown-menu dropstart" x-placement="top-start" aria-labelledby="dropdownMenuLink">
                    <li class="nav-item">
                        <a class="nav-link" style="cursor: pointer;" @click="openModalRespaldo()" v-if="isBodega || isLogistica || isAdmin">Cargar OV del día</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="cursor: pointer;" @click="xlsAll()" v-if="isBodega || isLogistica || isAdmin">Descargar OV</a>
                    </li>
                    <li class="nav-item" v-if="ordenesSeleccionadas.length > 0 && (isBodega || isLogistica || isAdmin)">
                        <a class="nav-link" style="cursor: pointer;" @click="marcarDespachadasOVsDeHoy()">Despachar SIN enviar correo</a>
                    </li>
                    <li class="nav-item" v-if="ordenesSeleccionadas.length > 0 && (isBodega || isLogistica || isAdmin)">
                        <a class="nav-link" style="cursor: pointer;" @click="openModalMail()">Despachar y enviar correo</a>
                    </li>
                    <li class="nav-item" v-if="ordenesSeleccionadasProximas.length > 0 && (isBodega || isLogistica || isAdmin)">
                        <a class="nav-link" style="cursor: pointer;" @click="correoProximasADespachadar()">Enviar correo por despachar</a>
                    </li>
                </ul>
            </div>
          </div>
        </div>
        <div class="card__body" v-if="ordenError">
          <div class="alert alert--danger" role="alert" >
            <font-awesome-icon :icon="['fas', 'exclamation-circle']" class="alert__icon" />
            <span class="alert__text">
              La orden número <strong>{{ordenError.numero}}</strong> está con problemas, haga click <a @click="fixOrden()" style="cursor: pointer;">AQUÍ</a> para restablecerla.
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <div class="card mb-3" id="card-camioneros">
            <button class="card__header" v-b-toggle.collapse-5>
              <span class="card__title">Choferes</span>
              <font-awesome-icon :icon="['fas', 'chevron-down']" />
            </button>
            <b-collapse visible id="collapse-5">
              <div class="card__body" id="contenedor-camioneros">
                <div v-for="camionero in camioneros" :key="camionero.id" class="bodega__aside-content">
                  <button draggable="true" class="chofer badge badge--primary mx-0 my-0" :data-camionero-id="camionero.id">{{camionero.get_nombre_completo}}</button>
                </div>
              </div>
            </b-collapse>
          </div>
          <div class="card mb-3" id="card-ovs-logistico">
            <button class="card__header" v-b-toggle.collapse-7>
              <span class="card__title">Ordenes de Venta</span>
              <font-awesome-icon :icon="['fas', 'chevron-down']" />
            </button>
            <b-collapse visible id="collapse-7">
              <div class="card__body  card__scroll" id="contenedor-ovs" @click="handleTargetClick($event, moverOvs)" @mouseover="overParaMultipleSelecccion($event)" @mouseleave="leaveParaMultipleSelecccion($event)">    
                <a @click="getOrdenesVenta()" class="button button--primary m-0 mb-3">
                    <font-awesome-icon :icon="['fas', 'sync-alt']" />
                </a>
                <multiselect class="m-0 mb-3" v-model="regionesSeleccionadas" :options="regiones" :multiple="true" :close-on-select="true" :clear-on-select="true"
                :preserve-search="true" placeholder="Elige una región" @input="getOrdenesVenta" v-show="regiones.length > 0">
                <template slot="tag" slot-scope="{ option, remove }">
                <span class="multiselect__tag" :class="getClassRegion(option)">
                    <span class="mr-2">{{ option }}</span>
                    <span class="custom__remove" @click="remove(option)">
                    <font-awesome-icon :icon="['fa', 'minus-circle']" />
                    </span>
                </span>
                </template>
                </multiselect>
                <multiselect class="m-0 mb-3" v-model="ubicacionesSeleccionadas" :options="ubicaciones" :multiple="true" :close-on-select="true" :clear-on-select="true"
                :preserve-search="true" placeholder="Elige una ubicación" @input="getOrdenesVenta" v-show="ubicaciones.length > 0">
                <template slot="tag" slot-scope="{ option, remove }">
                <span class="multiselect__tag">
                    <span class="mr-2">{{ option }}</span>
                    <span class="custom__remove" @click="remove(option)">
                    <font-awesome-icon :icon="['fa', 'minus-circle']" />
                    </span>
                </span>
                </template>
                </multiselect>
                <v-date-picker v-model="fechasSeleccionadas" mode="date">
                    <template v-slot="{ inputValue, inputEvents }">
                        <input
                            :value="inputValue"
                            v-on="inputEvents"
                            class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300 col-md-6"
                            placeholder="Fecha Fin"
                        />
                    </template>
                </v-date-picker>
                <!-- <v-date-picker v-model="fechasSeleccionadas" is-range>
                    <template v-slot="{ inputValue, inputEvents }">
                        <div class="m-0 mb-3">
                        <input
                            :value="inputValue.start"
                            v-on="inputEvents.start"
                            class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300 col-md-6"
                            placeholder="Fecha Inicio"
                        />
                        <input
                            :value="inputValue.end"
                            v-on="inputEvents.end"
                            class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300 col-md-6"
                            placeholder="Fecha Fin"
                        />
                        </div>
                    </template>
                </v-date-picker> -->
                <div class="alert--sm alert--primary" role="alert">
                  <font-awesome-icon :icon="['fas', 'info-circle']" class="alert__icon--sm" />
                  <span class="alert__text--sm">Puedes hacer doble click en las ordenes de venta para ver su información</span>
                </div>
                <!-- <hr v-if="ordenes.length === 0"> -->
                <div class="alert--sm alert--danger" role="alert" v-if="ordenes.length === 0">
                  <font-awesome-icon :icon="['fas', 'exclamation-circle']" class="alert__icon--sm" />
                  <span class="alert__text--sm">No existen OV por agendar</span>
                </div>
                <div v-for="(ovsByDespacho, despacho) in getOrdenesByDespacho(ordenes)" :key="despacho">
                  <h6 class="card__subtitle mt-3">{{despacho}}</h6>
                  <div v-if="despacho === 'TRANSPORTE INTERNO'"> 
                    <div v-for="(ovsByRegion, region) in getOrdenesByRegion(ovsByDespacho)" :key="region">
                      <h6>{{region}}</h6>
                      <div class="calendar__aside">
                        <button class="calendar__ov aside__ov" v-for="orden in ovsByRegion" :key="orden.id" :draggable="isDraggableOV() && !seleccionGrupo"  @click="handleSingleClick($event, isDraggableOV())" :class="getClassRegion(orden.region, orden.estados)" :data-ov-id="orden.id">
                          {{orden.numero}} | {{orden.detalles.id_embarque}} | {{getFechaBodega(orden)}}
                          <font-awesome-icon v-if="getClassStockOrden(orden)" :class="getClassStockOrden(orden)" :icon="['fa', 'circle']" />
                          <span class="calendar__span--sin--completar" v-if="orden.estados.count_stock && orden.estados.count_stock > 0 && 'total_stock' in orden.estados"> {{orden.estados.total_stock}}%</span>
                        </button>
                        </div>
                      </div>
                    </div>
                    <div class="calendar__aside" v-else>
                      <button class="calendar__ov aside__ov" v-for="orden in ovsByDespacho" :key="orden.id" :draggable="isDraggableOV() && !seleccionGrupo" @click="handleSingleClick($event, isDraggableOV())" :class="getClassRegion(orden.region, orden.estados)" :data-ov-id="orden.id">
                        {{orden.numero}} | {{orden.detalles.id_embarque}} | {{getFechaBodega(orden)}}
                        <font-awesome-icon v-if="getClassStockOrden(orden)" :class="getClassStockOrden(orden)" :icon="['fa', 'circle']" />
                        <span class="calendar__span--sin--completar" v-if="orden.estados.count_stock && orden.estados.count_stock > 0 && 'total_stock' in orden.estados"> {{orden.estados.total_stock}}%</span>
                      </button>
                    </div>
                  </div>
              </div>
            </b-collapse>
          </div>
          <div class="card mb-3" id="card-ovs-no-entregadas">
            <button class="card__header" v-b-toggle.collapse-8>
              <span class="card__title">No entregadas</span>
              <font-awesome-icon :icon="['fas', 'chevron-down']" />
            </button>
            <b-collapse visible id="collapse-8">
              <div class="card__body" id="contenedor-ovs-no-entregadas">
                <a @click="getOVNoEntregadas()" class="button button--primary m-0 mb-3">
                  <font-awesome-icon :icon="['fas', 'sync-alt']" />
                </a>
                <div class="alert--sm alert--primary" role="alert">
                  <font-awesome-icon :icon="['fas', 'info-circle']" class="alert__icon--sm" />
                  <span class="alert__text--sm">Puedes hacer doble click en las ordenes de venta para ver su información</span>
                </div>
                <div class="alert--sm alert--danger" role="alert" v-if="ordenesNoEntregadas.length === 0">
                  <font-awesome-icon :icon="['fas', 'exclamation-circle']" class="alert__icon--sm" />
                  <span class="alert__text--sm">No existen OVs no entregadas</span>
                </div>
                <div v-for="(ovsByRegion, region) in getOrdenesByRegion(ordenesNoEntregadas)" :key="region">
                  <h6 class="card__subtitle">{{region}}</h6>
                  <div class="calendar__aside">
                    <button v-for="orden in ovsByRegion" :key="orden.id" :draggable="isDraggableOV() && !seleccionGrupo" @click="handleSingleClick($event, isDraggableOV())" class="calendar__ov aside__ov" :class="getClassRegion(orden.region, orden.estados)" :data-ov-id="orden.id">
                      {{orden.numero}} | {{orden.detalles.id_embarque}} | {{getFechaBodega(orden)}}
                      <font-awesome-icon v-if="getClassStockOrden(orden)" :class="getClassStockOrden(orden)" :icon="['fa', 'circle']" />
                      <span class="calendar__span--sin--completar" v-if="orden.estados.count_stock && orden.estados.count_stock > 0 && 'total_stock' in orden.estados"> {{orden.estados.total_stock}}%</span>
                    </button>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
        <div class="col-9">
          <div class="card" id="card-calendario-logistico">
            <div class="card__header">
              <div class="d-flex justify-content-certer align-items-center">
                <h4 class="card__title">Camiones</h4>
                <div class="custom-control custom-switch ml-3">
                  <input type="checkbox" class="custom-control-input" id="switchSeleccion" v-model="seleccionGrupo" @change="limpiarSeleccionadasGrupal()">
                  <label class="custom-control-label" for="switchSeleccion">Selección por Grupo</label>
                </div>
              </div>
              <router-link class="button button--primary float-right" :to="`/${empresaId}/${empresaNombre}/logisticaview`" target="_blank"><font-awesome-icon :icon="['fa', 'expand']" /></router-link>
            </div>
            <div class="card__body">
              <span class="calendar__legend">
                <span class="calendar__legend--span">
                  <h6>Despachada</h6>
                  <font-awesome-icon class="despachada" :icon="['fa', 'check-circle']" />
                </span>
                <span class="calendar__legend--span">
                  <h6>Entregada</h6>
                  <font-awesome-icon class="entregada" :icon="['fa', 'check-circle']" />
                </span>
                <span class="calendar__legend--span">
                  <h6>No entregada</h6>
                  <font-awesome-icon class="no-entregada" :icon="['fa', 'times-circle']" />
                </span>
                <span class="calendar__legend--span">
                  <h6>Entregada con Errores</h6>
                  <font-awesome-icon class="entregada-error" :icon="['fa', 'times-circle']" />
                </span>
                <span class="calendar__legend--span">
                  <h6>Noficado próximo a despacho</h6>
                  <font-awesome-icon class="proximo-despacho" :icon="['fa', 'info-circle']" />
                </span>
                <span class="calendar__legend--span">
                  <h6>No Entregada Cliente</h6>
                  <font-awesome-icon class="no-entregada-cliente" :icon="['fa', 'minus-circle']" />
                </span>
                <span class="calendar__legend--span">
                  <h6>Entregada con Errores Cliente</h6>
                  <font-awesome-icon class="entregada-error-cliente" :icon="['fa', 'minus-circle']" />
                </span>
              </span>
              <div class="table-responsive cabezeraFija">
                <table class="table table-bordered">
                  <thead class="thead">
                    <tr v-if="semana !== null">
                      <th class="thead__th" style="padding:5px !important;" scope="col">
                        <button class="button button--primary" @click="cambiarSemana('anterior')" type="button">
                          <font-awesome-icon :icon="['fas', 'arrow-left']" />
                        </button>
                      </th>
                      <th class="thead__th" style="padding:25px 5px 5px 5px !important; min-width: 150px;" scope="col" v-for="dia in Object.keys(semana)" :key="dia">
                        <div class="row">
                            <div class="col-md-12">
                                {{semana[dia].date | formatDatePersonalized}}
                            </div>
                            <div class="col-md-12 btn btn-group">
                                <a class="button--sm button--primary" @click="xlsDia(semana[dia].date)" v-if="isAdmin || isBodega || isLogistica">
                                <font-awesome-icon :icon="['fa', 'file-excel']" />
                                </a>
                                <a class="button--sm button--danger" @click="setFechaData(semana[dia].formattedDate)" v-if="isAdmin || isBodega || isLogistica">
                                <font-awesome-icon :icon="['fa', 'undo']" />
                                </a>
                            </div>
                        </div>
                      </th>
                      <th class="thead__th" style="padding:5px !important;" scope="col">
                        <button class="button button--primary" @click="cambiarSemana('proxima')" type="button">
                          <font-awesome-icon :icon="['fas', 'arrow-right']" />
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="camion in camiones" :key="camion.id">
                      <td class="camion calendar__td--camion" :data-camion="camion.id" >
                        <span class="calendar__td--log">{{camion.patente}}</span>
                        <button class="chofer badge badge--primary mx-0 my-0" v-if="camion.chofer" :draggable="isDraggableOV()" :data-camionero-id="camion.chofer" >
                            {{getNombreChofer(camion.chofer)}}
                        </button>
                      </td>
                      <td @click="handleTargetClick($event, moverOvs)" @mouseover="overParaMultipleSelecccion($event)" @mouseleave="leaveParaMultipleSelecccion($event)" style="min-width:150px; padding:25px 5px 5px 5px;" :ref="`td-${dia}-${camion.id}`" v-for="dia in Object.keys(semana)" :key="dia" :class="getBgColor(semana[dia].date)" :data-camion="camion.id" :data-fecha="semana[dia].formattedDate" data-todos-elementos="false">
                        <!-- <span class="calendar__date">{{semana[dia].date | formatDatePersonalized}}</span><br> -->
                        <div class="calendar__td" :data-camion="camion.id" :data-fecha="semana[dia].formattedDate">
                          <input type="checkbox" class="mr-1" v-if="dateGte(semana[dia].date) && getOVParaDia(camion.id, semana[dia].formattedDate).length>0" v-model="seleccionarTodas" :value="getOVParaDia(camion.id, semana[dia].formattedDate)" @change="ovTodas(semana[dia].date)" >

                          <template v-for="(orden, idx) in getOVParaDia(camion.id, semana[dia].formattedDate)">
                            <button class="calendar__ov" v-show="canSeeElement($refs[`td-${dia}-${camion.id}`], idx, cambioUnElementoTd)" :key="orden.id" :draggable="isDraggableOvInCalendar(orden) && !seleccionGrupo" @click="handleSingleClick($event, isDraggableOvInCalendar(orden))" :class="getClassRegion(orden.region, orden.estados)" :data-ov-id="orden.id">
                                <div style="display:flex; align-items:center;">
                                    <input v-if="habilitadoParaHoy(semana[dia].date, orden)" type="checkbox" v-model="orden.seleccionable" @change="ovSeleccionada(orden)" class="mr-1" :disabled="seleccionGrupo">
                                    <input v-if="habilitadoParaProxDias(orden)" type="checkbox" v-model="orden.seleccionable" @change="ovSeleccionadaProxima(orden)" class="mr-1" :disabled="seleccionGrupo">

                                    <font-awesome-icon v-if="orden.estados.despachado && !orden.estados.entregado && !orden.estados.entregado_con_errores && !orden.estados.no_entregado" class="despachada" :icon="['fa', 'check-circle']" />

                                    <font-awesome-icon v-else-if="orden.estados.despachado && orden.estados.entregado" class="entregada" :icon="['fa', 'check-circle']" />

                                    <font-awesome-icon v-else-if="orden.estados.no_entregado && !orden.estados.entregado" class="no-entregado" :icon="['fa', 'times-circle']" />

                                    <font-awesome-icon v-else-if="orden.estados.entregado_con_errores && !orden.estados.entregado" class="entregada-error" :icon="['fa', 'times-circle']" />

                                    <font-awesome-icon v-else-if="orden.estados.mensaje_por_despachar" class="proximo-despacho" :icon="['fa', 'info-circle']" />

                                    <font-awesome-icon v-if="orden.estados.no_entregado_cliente" class="no-entregada-cliente" :icon="['fa', 'minus-circle']" />

                                    <font-awesome-icon v-if="orden.estados.entregado_con_errores_cliente" class="entregada-error-cliente" :icon="['fa', 'minus-circle']" />

                                    {{orden.numero}} | {{orden.detalles.id_embarque}} | {{getFechaBodega(orden)}}
                                    <font-awesome-icon v-if="getClassStockOrden(orden) && !orden.estados.despachado" :class="getClassStockOrden(orden)" :icon="['fa', 'circle']" />
                                    <span class="calendar__span--sin--completar" v-if="orden.estados.count_stock && orden.estados.count_stock > 0 && 'total_stock' in orden.estados && !orden.estados.despachado"> {{orden.estados.total_stock}}%</span>
                                </div>
                            </button>
                          </template>
                          <button type="button" class="btn btn-link" v-if="canSeeButton($refs[`td-${dia}-${camion.id}`], getOVParaDia(camion.id, semana[dia].formattedDate))" @click="mostrarOVOcultas($refs[`td-${dia}-${camion.id}`])">...</button>
                        </div>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal ref="my-modal" size="xl" hide-footer :title="`${this.infoModal.numero} - ${this.infoModal.region}`">
      <div class="card__buttons" style="align-item:center">
        <h4 class="card__subtitle mb-0">Nº Cotización:</h4>
        <span class="badge badge--primary">{{this.infoModal.numero_cotizacion}}</span>
        <h4 class="card__subtitle mb-0">Fecha Orden:</h4>
        <span class="badge badge--secondary">{{this.infoModal.fechaOrden}}</span>
      </div>
      <div class="card__buttons">
        <button v-if="infoModal.estados && (infoModal.estados.no_entregado_cliente || infoModal.estados.entregado_con_errores || infoModal.estados.entregado_con_errores_cliente)" @click="entregadaCorrectamente(infoModal.id)" class="button--sm button--primary"> Entregada Correctamente</button>
        <button @click="marcarEntregado(infoModal.id);" v-if="infoModal.estados && infoModal.estados.despachado && !infoModal.estados.entregado && !infoModal.estados.no_entregado && !infoModal.estados.entregado_con_errores" class="button--sm button--primary" >Marcar como entregada</button>
        <button @click="setDataOVManual(infoModal);" v-if="infoModal.estados && (infoModal.estados.entregado_con_errores || infoModal.estados.entregado_con_errores_cliente)" class="button--sm button--primary" >Crear Nueva OV</button>
      </div>
      <div class="d-flex flex-column">
        <div class="d-flex">
            <table class="table">
              <thead class="thead">
                <tr>
                  <th colspan="2">Detalle</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th class="card__subtitle" colspan="2">DATOS DE LA VENTA</th>
                </tr>
                <tr>
                  <td class="table__td">Cliente</td>
                  <td class="table__td">{{infoModal.detalles.numero_cliente}} - {{infoModal.detalles.nombre_cliente}}</td>
                </tr>
                <tr>
                  <td class="table__td">Correo Electrónico</td>
                  <td class="table__td">{{infoModal.detalles.email}}</td>
                </tr>
                <tr>
                  <td class="table__td">Tipo de Despacho</td>
                  <td class="table__td">{{infoModal.detalles.despacho}}</td>
                </tr>
                <tr>
                  <td class="table__td">Forma de Pago</td>
                  <td class="table__td">{{infoModal.detalles.forma_pago}}</td>
                </tr>
                <tr>
                  <td class="table__td">Vendedor</td>
                  <td class="table__td">{{infoModal.detalles.nombre_vendedor}}</td>
                </tr>
                <tr>
                  <td class="table__td">Orden Compra</td>
                  <td class="table__td">{{infoModal.detalles.orden_compra}}</td>
                </tr>
                <tr>
                  <td class="table__td">Factura</td>
                  <td class="table__td">{{infoModal.detalles.factura}}</td>
                </tr>
                <tr>
                  <th class="card__subtitle" colspan="2">DATOS DEL DESPACHO</th>
                </tr>
                <tr>
                  <td class="table__td">LAT, LNG</td>
                  <td class="table__td">{{infoModal.detalles.lat}}<b>,</b> {{infoModal.detalles.long}}</td>
                </tr>
                <tr>
                  <td class="table__td">ID de Embarque</td>
                  <td class="table__td">{{infoModal.detalles.id_embarque}}</td>
                </tr>
                <tr>
                  <td class="table__td">Región</td>
                  <td class="table__td">{{infoModal.detalles.region}}</td>
                </tr>
                <tr>
                  <td class="table__td">Comuna</td>
                  <td class="table__td">{{infoModal.detalles.comuna}}</td>
                </tr>
                <tr>
                  <td class="table__td">Ciudad</td>
                  <td class="table__td">{{infoModal.detalles.ciudad}}</td>
                </tr>
                <tr>
                  <td class="table__td">Dirección</td>
                  <td class="table__td">{{infoModal.detalles.direccion}}</td>
                </tr>
                <tr>
                  <td class="table__td">Observaciones</td>
                  <td class="table__td">{{infoModal.detalles.observaciones}}</td>
                </tr>
              </tbody>
            </table>
            <table class="table">
              <thead>
                <tr class="thead">
                    <th colspan="6">Articulos</th>
                </tr>
                <tr>
                  <th class="thead__th" scope="col">Item</th>
                  <th class="thead__th" scope="col" v-if="!infoModal.fechaActualizada">Orden</th>
                  <th class="thead__th" scope="col" v-if="!infoModal.fechaActualizada">Stock</th>
                  <th class="thead__th" scope="col" v-if="!infoModal.fechaActualizada">Sin Stock</th>
                  <th class="thead__th" scope="col" v-if="!infoModal.fechaActualizada">Lista a Envío</th>
                  <th class="thead__th" scope="col" v-if="!infoModal.fechaActualizada">Despachada</th>
                  <th class="thead__th" scope="col" v-if="infoModal.fechaActualizada">Despachada</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(articulo, index) in infoModal.articulos" :key="index">
                    <td class="table__td">
                        <strong>{{articulo.codigo}}</strong> - {{articulo.descripcion}}
                    </td>
                    <td class="table__td" v-if="!infoModal.fechaActualizada">
                        {{articulo.orden}}
                    </td>
                    <td class="table__td" v-if="!infoModal.fechaActualizada">
                        {{articulo.stock}}
                    </td>
                    <td class="table__td" v-if="!infoModal.fechaActualizada">
                        {{articulo.sin_stock}}
                    </td>
                    <td class="table__td" v-if="!infoModal.fechaActualizada">
                        {{articulo.lista_envio}}
                    </td>
                    <td class="table__td" v-if="!infoModal.fechaActualizada">
                        {{articulo.despachada}}
                    </td>
                    <td class="table__td" v-if="infoModal.fechaActualizada">
                        {{articulo.cantidad_factura}} {{articulo.um}}
                    </td>
                </tr>
              </tbody>
            </table>
        </div>
      </div>
    </b-modal>
          <!-- Cards Camion -->
          <!-- <div class="card camionCard" v-for="camion in this.camiones" :key="camion.id" :id="'camionCard-' + camion.id">
            <button class="card__header" v-b-toggle.collapse-9>
              <span class="card__title">Transporte externo</span>
              <font-awesome-icon :icon="['fas', 'chevron-down']" />
            </button>
            <b-collapse id="collapse-9">
              <div class="card__body" >
                <div class="card--outline">
                  <div class="card__body">
                    <h6 class="card__subtitle">{{ camion.patente }}</h6>
                    <img class="card-img-top" :src="camion.detalles.ruta_img" alt="Imagen Camión">
                    <ul class="mb-0 mt-3" style="list-style-type: none;">
                    <li><span class="text-dark">m&sup3;:</span> {{ camion.detalles.m3 }}</li>
                    <li><span class="text-dark">kg:</span> {{ camion.detalles.kg }}</li>
                    <li><span class="text-dark">Marca:</span> {{ camion.detalles.marca }}</li>
                    <li><span class="text-dark">Modelo:</span> {{ camion.detalles.modelo }}</li>
                  </ul>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div> -->
    <div v-for="camion in this.camiones" :key="camion.id" class="card camionCard" :id="'camionCard-' + camion.id">
        <div class="card__header">
            <h5 class="card__title">{{ camion.patente }}</h5>
        </div>
        <img class="card-img-top" :src="camion.detalles.ruta_img" alt="Imagen Camión">
        <div class="card__body">
            <p class="m-0">m&sup3;: {{ camion.detalles.m3 }}</p>
            <p class="m-0">kg: {{ camion.detalles.kg }}</p>
            <p class="m-0">Marca: {{ camion.detalles.marca }}</p>
            <p class="m-0">Modelo: {{ camion.detalles.modelo }}</p>
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import Header from '@/components/Header.vue'
import ModalRespaldo from '@/modals/ModalRespaldo.vue'
import ModalMail from '@/logistica/ModalMail.vue'
import ModalNuevaOrden from '@/logistica/ModalNuevaOrden.vue'
import ModalVaciarCamion from '@/logistica/ModalVaciarCamion.vue'
// import RegistrosAnteriores from '@/ordenes/components/RegistrosAnteriores.vue'

import { utils } from '@/logistica/utils.js'

let elementoArrastrandose = null

export default {
    name: 'CalendarioLogistica',
    mixins: [utils],
    components: { Multiselect, Header, ModalRespaldo, ModalMail, ModalNuevaOrden, ModalVaciarCamion },
    inject: ['$dayjs'],
    data () {
        return {
            empresaId: this.$route.params.empresaId,
            empresaNombre: this.$route.params.empresaNombre,
            camiones: [],
            camioneros: [],
            camionerosConCamion: [],
            ordenes: [],
            ordenesNoEntregadas: [],
            ordenesConCamion: [],
            ordenesTabla: [],
            ordenesSeleccionadas: [],
            ordenesSeleccionadasProximas: [],
            fechaActual: new Date(new Date().setHours(0,0,0,0)),
            monday: this.getMonday(),
            semana: null,
            inicializados: false,
            infoModal: {
                registros: [],
                numero_cotizacion: null,
                numero: null,
                region: null,
                detalles: {},
                articulos: [],
            },
            regiones: [],
            regionesSeleccionadas: [],
            lastIndexCamion: 0,
            minIdCamion: 1,
            tipoElementoArrastrado: '',
            modalEditData: null,
            modalDataOV: null,
            ordenError: null,
            ubicaciones: [],
            ubicacionesSeleccionadas: [],
            fechaData: null,
            fechasSeleccionadas: null,
            start: null,
            end: null,
            seleccionarTodas: []
        }
    },
    methods: {
        getRegiones () {
            const queryParams = { fechahora_bodega__isnull: false, empresa: this.empresaId }
            axios.get('/logistica/api/regiones/', { params: queryParams }).then(res => {
                this.regiones = res.data.results
            })
        },
        getCamiones () {
            axios.get('/logistica/api/camiones/', { params: { is_active: true }}).then(res => {
                // NOTA EA: Es importante que los camiones vengan ordenados 
                //          por id para que funcione bien el codigo de las tarjetas de camiones
                this.camiones = res.data
                this.minIdCamion = this.camiones[0].id
            })
        },
        getOrdenesVenta () {
            const queryParams = { camion__isnull: true, fechahora_bodega__isnull: false, empresa: this.empresaId, no_entregado: false }
            if (this.regionesSeleccionadas.length >  0) {
                queryParams['region__in'] = this.regionesSeleccionadas.join(',')
            }
            if (this.ubicacionesSeleccionadas.length >  0) {
                queryParams['detalles__id_embarque__in'] = this.ubicacionesSeleccionadas
            }
            if (this.fechasSeleccionadas) {
                this.end = this.$dayjs(this.fechasSeleccionadas).format('YYYY-MM-DD')
                queryParams['end'] = this.end
            }
            // else if (!this.fechasSeleccionadas) {
            //     this.start = this.$dayjs(this.$options.filters.formatDateTimeSlug(this.monday)).format('YYYY-MM-DD')
            //     this.end = this.$dayjs(this.$options.filters.formatDateTimeSlug(this.nextDay(6))).format('YYYY-MM-DD')
            //     queryParams['start'] = this.start
            //     queryParams['end'] = this.end
            // }
            axios.get('/logistica/api/ordenes_venta/', {params: queryParams}).then(res => {
                this.ordenes = res.data
                this.ubicaciones = [...new Set(res.data.map((res) => { return res.detalles.id_embarque }).sort())]
            })
        },
        getOVNoEntregadas () {
            const queryParams = { camion__isnull: true, no_entregado: true }
            axios.get('/logistica/api/ordenes_venta/', {params: queryParams}).then(res => {
                this.ordenesNoEntregadas = res.data
            })
        },
        getOVConCamion () {
            const queryParams = { camion__isnull: false }
            if (!this.fechasSeleccionadas) {
                this.start = this.$dayjs(this.$options.filters.formatDateTimeSlug(this.monday)).format('YYYY-MM-DD')
                //ANTES ERA NEXTDAY(6)
                this.end = this.$dayjs(this.$options.filters.formatDateTimeSlug(this.nextDay(5))).format('YYYY-MM-DD')
                queryParams['start'] = this.start
                queryParams['end'] = this.end
            }
            axios.get('/logistica/api/ordenes_venta/', {params: queryParams}).then(res => {
                res.data.forEach(ov => {
                    if (!ov.estados.despachado) ov.seleccionable = false
                })
                this.ordenesConCamion = res.data
                this.getOrdenesTabla(res.data)
            })
        },
        getOrdenesTabla (ordenes) {
            this.ordenesTabla = ordenes.map(ov => {
                return {
                    id: ov.id,
                    numero: ov.numero,
                    diffDia: this.getDiffDays(ov),
                    despachado: ov.estados.despachado
                }
            })

            // this.ordenesTabla = this.ordenesTabla.filter(ov => ov.diffDia >= 0)
            this.ordenesTabla = this.ordenesTabla.sort((a, b) => a.diffDia - b.diffDia)
        },
        getMonday () {
            const date = new Date()
            const day = date.getDay()
            // last
            let diff = date.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is sunday
            return new Date(date.setDate(diff))
            // return this.$options.filters.formatDateTimeSlug(monday)
        },
        getSemana () {
            this.semana = {
                Lunes: { date: this.monday, formattedDate: this.$options.filters.formatDateTimeSlug(this.monday) },
                Martes: { date: this.nextDay(1), formattedDate: this.$options.filters.formatDateTimeSlug(this.nextDay(1)) },
                Miercoles: { date: this.nextDay(2), formattedDate: this.$options.filters.formatDateTimeSlug(this.nextDay(2)) },
                Jueves: { date: this.nextDay(3), formattedDate: this.$options.filters.formatDateTimeSlug(this.nextDay(3)) },
                Viernes: { date: this.nextDay(4), formattedDate: this.$options.filters.formatDateTimeSlug(this.nextDay(4)) },
                Sabado: { date: this.nextDay(5), formattedDate: this.$options.filters.formatDateTimeSlug(this.nextDay(5)) },
                Domingo: { date: this.nextDay(6), formattedDate: this.$options.filters.formatDateTimeSlug(this.nextDay(6)) },
            }
        },
        inicializarOV () {
            // Inicializa los contenedores
            const contenedores = [...document.getElementsByClassName('contenedor')]
            const contenedorOVs = document.getElementById('contenedor-ovs')
            const contenedorOVsNoEntregadas = document.getElementById('contenedor-ovs-no-entregadas')
            const contenedoresVacios = [...document.getElementsByClassName('bg-light')]
            contenedores.push(contenedorOVs)
            contenedores.push(contenedorOVsNoEntregadas)
            contenedores.forEach(c => {
                c.addEventListener('dragover', this.functionDragOver, false)
                c.addEventListener('dragleave', this.functionDragLeave, false)
                c.addEventListener('drop', this.functionDrop, false)
            })

            contenedoresVacios.forEach(cv => {
                cv.removeEventListener('dragover', this.functionDragOver)
                cv.removeEventListener('dragleave', this.functionDragLeave)
                cv.removeEventListener('drop', this.functionDrop)
            })

            // Inicializa los contenedores de camiones
            const contenedorCamioneros = document.getElementById('contenedor-camioneros')
            const contenedoresCamiones = [...document.getElementsByClassName('camion')]
            contenedoresCamiones.push(contenedorCamioneros)
            contenedoresCamiones.forEach(c => {
                c.addEventListener('dragover', this.functionDragOverForCamion, false)
                c.addEventListener('dragleave', this.functionDragLeaveForCamion, false)
                c.addEventListener('drop', this.functionDropForCamion, false)
            })

            // Inicializa las ordenes de venta
            const cardLogistico = document.getElementById('card-calendario-logistico')
            const cardOVs = document.getElementById('card-ovs-logistico')
            const cardOVsNoEntregadas = document.getElementById('card-ovs-no-entregadas')
            const ordenesVentaLogistico = [...cardLogistico.getElementsByClassName('calendar__ov')]
            const ordenesVenta = [...cardOVs.getElementsByClassName('calendar__ov')]
            const ordenesVentaNoEntregadas = [...cardOVsNoEntregadas.getElementsByClassName('calendar__ov')]
            ordenesVentaNoEntregadas.forEach(ov => {
                ov.addEventListener('dragstart', this.functionDragStart, false)
                ov.addEventListener('dblclick', this.functionDblClick, false)
            })
            ordenesVenta.forEach(ov => {
                ov.addEventListener('dragstart', this.functionDragStart, false)
                ov.addEventListener('dblclick', this.functionDblClick, false)
            })
            ordenesVentaLogistico.forEach(ov => {
                ov.addEventListener('dragstart', this.functionDragStart, false)
                ov.addEventListener('dblclick', this.functionDblClick, false)
            })
            
            const cardCamioneros = document.getElementById('card-camioneros')
            const camioneros = [...cardCamioneros.getElementsByClassName('chofer')]
            const camionerosEnCalendario = [...cardLogistico.getElementsByClassName('chofer')]
            camioneros.forEach(camionero => {
                camionero.addEventListener('dragstart', this.functionDragStart, false)
            })
            camionerosEnCalendario.forEach(camionero => {
                camionero.addEventListener('dragstart', this.functionDragStart, false)
            })
        },
        functionDragOver (event) {
            event.preventDefault()
            if (this.tipoElementoArrastrado === 'camionero') {
                return false
            }
            // const cardsCamion = [...document.getElementsByClassName('camionCard')]

            const nodo = event.target
            const contenedorTD = this.parentNodeAccordingToCertainClassName(nodo, 'contenedor')
            const contenedorOVs = document.getElementById('contenedor-ovs')
            const contenedorOVsNoEntregadas = document.getElementById('contenedor-ovs-no-entregadas')
            const isChildContenedorOVs = this.nodoIsChildOf(nodo, contenedorOVs)
            const isChildContenedorOVsNoEntregadas = this.nodoIsChildOf(nodo, contenedorOVsNoEntregadas)

            if (isChildContenedorOVs && contenedorOVs) {
                contenedorOVs.classList.add('over')
            } else if (isChildContenedorOVsNoEntregadas && contenedorOVsNoEntregadas) {
                contenedorOVsNoEntregadas.classList.add('over')
            } else if (contenedorTD) {
                contenedorTD.classList.add('over')

                const idCamion = contenedorTD.dataset.camion
                if (idCamion !== undefined) {
                    const indexCamion = idCamion - this.minIdCamion
                    // cardsCamion[indexCamion].classList.add('overCamion')
                    document.getElementById('camionCard-' + idCamion).classList.add('overCamion')
                    this.lastIndexCamion = idCamion
                }
            }

            return false
        },
        functionDragOverForCamion (event) {
            event.preventDefault()
            if (this.tipoElementoArrastrado === 'ov') {
                return false
            }

            const nodo = event.target
            const contenedorTD = this.parentNodeAccordingToCertainClassName(nodo, 'camion')
            const contenedorCamioneros = document.getElementById('contenedor-camioneros')

            const isChildContenedorCamioneros = this.nodoIsChildOf(nodo, contenedorCamioneros)

            if (isChildContenedorCamioneros) {
                contenedorCamioneros.classList.add('over')
            } else if (contenedorTD) {
                contenedorTD.classList.add('over')
            }
            
            return false
        },
        functionDragLeave (event) {
            if (this.tipoElementoArrastrado === 'camionero') {
                return 
            }
            // const cardsCamion = [...document.getElementsByClassName('camionCard')]

            const nodo = event.target
            const contenedorTD = this.parentNodeAccordingToCertainClassName(nodo, 'contenedor')
            const contenedorOVs = document.getElementById('contenedor-ovs')
            const contenedorOVsNoEntregadas = document.getElementById('contenedor-ovs-no-entregadas')

            const isChildContenedorOVs = this.nodoIsChildOf(nodo, contenedorOVs)
            const isChildContenedorOVsNoEntregadas = this.nodoIsChildOf(nodo, contenedorOVsNoEntregadas)

            if (isChildContenedorOVs) {
                contenedorOVs.classList.remove('over')
                if (document.getElementById('camionCard-' + this.lastIndexCamion)) {
                    document.getElementById('camionCard-' + this.lastIndexCamion).classList.remove('overCamion')
                }
            } else if (isChildContenedorOVsNoEntregadas) {
                contenedorOVsNoEntregadas.classList.remove('over')
                if (document.getElementById('camionCard-' + this.lastIndexCamion)) {
                    document.getElementById('camionCard-' + this.lastIndexCamion).classList.remove('overCamion')
                }
            } else if (contenedorTD) {
                contenedorTD.classList.remove('over')
                if (document.getElementById('camionCard-' + this.lastIndexCamion)) {
                    document.getElementById('camionCard-' + this.lastIndexCamion).classList.remove('overCamion')
                }
            }
        },
        functionDragLeaveForCamion (event) {
            if (this.tipoElementoArrastrado === 'ov') {
                return
            }

            const nodo = event.target
            const contenedorTD = this.parentNodeAccordingToCertainClassName(nodo, 'camion')
            const contenedorCamioneros = document.getElementById('contenedor-camioneros')

            const isChildContenedorCamioneros = this.nodoIsChildOf(nodo, contenedorCamioneros)

            if (isChildContenedorCamioneros) {
                contenedorCamioneros.classList.remove('over')
            } else if (contenedorTD) {
                contenedorTD.classList.remove('over')
            }
        },
        moverOvs ({ nodoTarget = null, ovs = [] }) {
            if (!nodoTarget) {
                console.log('Ingrese el target')
                return
            }

            ovs.forEach(ov => {

                if (ov.tipoElementoArrastrado === 'camionero') {
                    return 
                }
                
                // Obtiene los contenedores habilitados donde soltar el elemento arrastrado
                const contenedorOVs = document.getElementById('contenedor-ovs')
                const contenedorOVsNoEntregadas = document.getElementById('contenedor-ovs-no-entregadas')

                // Inicializa nodo Padre del target
                const nodoPadreDelTarget = this.getNodoPadreDelTarget({ 
                    nodoTarget: nodoTarget, padresPosibles:[contenedorOVs, contenedorOVsNoEntregadas], classNameLastOptionParent: 'contenedor' })

                const idOrden = ov.id

                // Encuentra la orden en el conjunto de ordenes existentes
                const orden = this.buscarOrdenEnConjunto({ idOrden: idOrden, conjunto: [this.ordenes, this.ordenesConCamion, this.ordenesNoEntregadas] })

                // Encuentra el padre del elemento que se esta arrastrando o moviendo
                // Sirve para saber si el elemento se esta moviendo en el mismo lugar
                elementoArrastrandose = ov.nodo
                const contenedorTDElementoArrastrandose = this.parentNodeAccordingToCertainClassName(elementoArrastrandose, 'contenedor')
                let padreDeELementoArrastrandose = elementoArrastrandose.parentNode
                if (this.nodoIsChildOf(elementoArrastrandose, contenedorOVs)) {
                    padreDeELementoArrastrandose = contenedorOVs
                } else if (this.nodoIsChildOf(elementoArrastrandose, contenedorOVsNoEntregadas)) {
                    padreDeELementoArrastrandose = contenedorOVsNoEntregadas
                } else if (contenedorTDElementoArrastrandose) {
                    padreDeELementoArrastrandose = contenedorTDElementoArrastrandose
                }
                
                // Condicion especial, no se puede mover una ov a un espacio de dias pasados
                if (nodoTarget.classList.contains("bg-light") || nodoPadreDelTarget.classList.contains("bg-light")) {
                    elementoArrastrandose.classList.remove("seleccionadoGrupal")
                    return
                }

                // Si se cambia de semana se pierde el nodo padre, quedando este como nulo
                const moviendoEnElMismoLugar = padreDeELementoArrastrandose && (padreDeELementoArrastrandose.isEqualNode(nodoTarget) || padreDeELementoArrastrandose.isEqualNode(nodoPadreDelTarget))

                if (moviendoEnElMismoLugar) {
                    // Si el elemento se esta moviendo en el mismo lugar solo quita las clases que dan color o highlight
                    if (nodoPadreDelTarget.nodeName === 'TD' || nodoPadreDelTarget.id === 'contenedor-ovs' || nodoPadreDelTarget.id === 'contenedor-ovs-no-entregadas') nodoPadreDelTarget.classList.remove('over')
                    else nodoTarget.classList.remove('over')
                } else if (nodoPadreDelTarget.nodeName === 'TD' || nodoPadreDelTarget.id === 'contenedor-ovs' || nodoPadreDelTarget.id === 'contenedor-ovs-no-entregadas') {
                    // Si el nodo padre del target es uno de los contenedores habilitados guarda la orden en ese contenedor
                    nodoPadreDelTarget.classList.remove('over')
                    this.guardarOrdenVenta (orden, idOrden, nodoPadreDelTarget)
                } else {
                    // En este caso se considera que el target debe ser uno de los contenedores habilitados
                    nodoTarget.classList.remove('over')
                    this.guardarOrdenVenta (orden, idOrden, nodoTarget)
                }
                elementoArrastrandose.classList.remove("seleccionadoGrupal")

            })
            // Quita todos los elementos del array ovs
            ovs.splice(0)
        },
        functionDrop (event) {
            event.preventDefault()
            
            // const cardsCamion = [...document.getElementsByClassName('camionCard')]
            if (document.getElementById('camionCard-' + this.lastIndexCamion)) {
                document.getElementById('camionCard-' + this.lastIndexCamion).classList.remove('overCamion')
            }

            const datos = event.dataTransfer.getData('text')
            const ov = {
                id: this.getId(datos),
                nodo: elementoArrastrandose,
                tipoElementoArrastrado: this.tipoElementoArrastrado
            }
            this.moverOvs({ nodoTarget: event.target, ovs: [ov] })

            event.stopImmediatePropagation()
        },
        functionDropForCamion (event) {
            event.preventDefault()
            if (this.tipoElementoArrastrado === 'ov') {
                return 
            }

            const datos = event.dataTransfer.getData('text')
            const nodo = event.target

            const contenedorCamioneros = document.getElementById('contenedor-camioneros')
            const contenedorTD = this.parentNodeAccordingToCertainClassName(nodo, 'camion')

            let nodoPadre = nodo.parentNode
            if (this.nodoIsChildOf(nodo, contenedorCamioneros)) {
                nodoPadre = contenedorCamioneros
            } else if (contenedorTD) {
                nodoPadre = contenedorTD
            }

            const idCamionero = this.getIdCamionero(datos)

            let camionero = this.camioneros.find(c => c.id === idCamionero)
            if ( camionero === undefined) {
                camionero = this.camionerosConCamion.find(c => c.id === idCamionero)
            }

            const contenedorTDElementoArrastrandose = this.parentNodeAccordingToCertainClassName(elementoArrastrandose, 'camion')
            let padreDeELementoArrastrandose = elementoArrastrandose.parentNode
            if (this.nodoIsChildOf(elementoArrastrandose, contenedorCamioneros)) {
                padreDeELementoArrastrandose = contenedorCamioneros
            } else if (contenedorTDElementoArrastrandose) {
                padreDeELementoArrastrandose = contenedorTDElementoArrastrandose
            }

            const moviendoEnElMismoLugar = padreDeELementoArrastrandose.isEqualNode(nodo) || padreDeELementoArrastrandose.isEqualNode(nodoPadre)

            if (moviendoEnElMismoLugar) {
                if (nodoPadre.nodeName === 'TD' || nodoPadre.id === 'contenedor-camioneros') nodoPadre.classList.remove('over')
                else nodo.classList.remove('over')
            } else if (nodoPadre.nodeName === 'TD' || nodoPadre.id === 'contenedor-camioneros') {
                nodoPadre.classList.remove('over')
                this.guardarCamionero (camionero, nodoPadre)
            } else {
                nodo.classList.remove('over')
                this.guardarCamionero (camionero, nodo)
            }

            event.stopImmediatePropagation()
        },
        functionDragStart (event) {
            if (event.target.dataset.camioneroId !== undefined) {
                this.tipoElementoArrastrado = 'camionero'
            } else {
                this.tipoElementoArrastrado = 'ov'
            }
            elementoArrastrandose = event.target
            const padre = document.createElement('div')
            const clon = event.target.cloneNode(true)
            padre.appendChild(clon)
            event.dataTransfer.setData('text', padre.innerHTML) // el nodo lo pasamos a una cadena
        },
        functionDblClick (event ) {
            let orden = null
            const id = +event.target.dataset.ovId
            const nodoPadre = event.target.parentNode
            const camion = nodoPadre.dataset.camion
            const idNodoPadre = event.target.parentNode.dataset.ovId
            if (camion === undefined) {
                orden = this.ordenes.find(ov => ov.id === id)
            } else {
                orden = this.ordenesConCamion.find(ov => ov.id === id)
            }

            if (orden === undefined) {
                orden = this.ordenesNoEntregadas.find(ov => ov.id === id)
            }
            if (orden === undefined && (!id || id === undefined || id === Nan)) {
                orden = this.ordenesConCamion.find(ov => ov.id === parseInt(idNodoPadre))
            }
            if (orden) {
                // this.infoModal.registros = orden.registros ?? {}
                this.infoModal.id = id
                this.infoModal.numero_cotizacion = orden.numero_cotizacion ?? ''
                this.infoModal.fechaOrden = orden.fecha_orden
                this.infoModal.fechaActualizada = orden.fecha_actualizada
                this.infoModal.numero = orden.numero 
                this.infoModal.region = orden.region
                this.infoModal.detalles = orden.detalles ?? {}
                this.infoModal.estados = orden.estados ?? {}
                this.infoModal.articulos = orden.articulos?.map(a => {
                    return {
                        codigo: a.codigo,
                        descripcion: a.descripcion,
                        orden: a.cantidad_ordenada,
                        lista_envio: a.cantidad_facturar,
                        despachada: a.cantidad_fact,
                        stock: a.stock,
                        sin_stock: a.sin_stock,
                        cantidad_factura: a.cantidad_factura,
                        um: a.unidad_medida
                    }
                }) ?? []

                this.showModal()
                event.stopImmediatePropagation()
            }
        },
        nextDay (days) {
            const newDay = new Date(this.monday) // Hacemos una copia de monday
            newDay.setDate(newDay.getDate() + days)
            return newDay
        },
        getId (texto) {
            // Nota EA: Se extendio este metodo para que se pudieran capturar multiples id,
            // Sin embargo a efectos practicos solo se usa para un solo id
            let startIndex = 0, index, indices = [], searchStr = 'data-ov-id="';
            const searchStrLen = searchStr.length;
            while ((index = texto.indexOf(searchStr, startIndex)) > -1) {
                indices.push(index);
                startIndex = index + searchStrLen;
            }

            const ids = indices.map(i => {
                const start = i + 12
                const end = texto.indexOf('"', start)
                return +texto.substring(start, end)
            })

            if (ids.length === 1) {
                return ids[0]
            }
            return ids

        },
        getIdCamionero (texto) {
            const start = texto.indexOf('data-camionero-id="') + 19
            const end = texto.indexOf('"', start)
            return +texto.substring(start, end)
        },
        getOVParaDia (camion, fecha) {
            const fechaFormateada = fecha.split('T')[0]
            const ordenesFiltradas = this.ordenesConCamion.filter(orden => {
                if (orden.fechahora_logistica) {
                    const fechaOrden = orden.fechahora_logistica.split(" ")[0].split("/").reverse().join("-")
                    return orden.camion === camion && fechaOrden === fechaFormateada
                }
            })
            return ordenesFiltradas
        },
        guardarOrdenVenta (orden, id, nodo) {
            // TODO EA: Revisar la hora que se guarda. Al parecer se guarda bien cuando recien se ingresa a la pagina

            if (orden !== undefined) {
                if (nodo.id === 'contenedor-ovs') {
                    if (orden.estados.no_entregado) {
                        const message = 'No puedes colocar una orden no entregada en este lugar'
                        this.$notify({ group: 'foo', type: 'error', title: 'Error', text: message })
                        return
                    }
                    orden.camion = null
                    orden.fechahora_logistica = null

                    this.modificarArraysOrdenes('Camion', orden, 'Confirmadas')

                } else if (nodo.id === 'contenedor-ovs-no-entregadas') {
                    if (!orden.estados.no_entregado) {
                        const message = 'En este lugar solo pueden ir las ordenes no entregadas'
                        this.$notify({ group: 'foo', type: 'error', title: 'Error', text: message })
                        return
                    }
                    orden.camion = null
                    orden.fechahora_logistica = null

                    this.modificarArraysOrdenes('Camion', orden, 'NoEntregadas')
                } else if (nodo.className?.includes('contenedor')) {
                    const fechaBodega = orden.fechahora_bodega.split(" ")[0].split("/").reverse()
                    const fechaLogistica = nodo.dataset.fecha.split('T')[0].split('-')
                    
                    if (this.fechaInvalida(fechaBodega, fechaLogistica)) {
                        const message = 'La fecha de bodega es superior a la fecha en la que desea agendar'
                        this.$notify({ group: 'foo', type: 'error', title: 'Error', text: message })
                        return
                    }
                    orden.camion = +nodo.dataset.camion
                    orden.fechahora_logistica = nodo.dataset.fecha
                }

                const copyOrden = { ...orden }
                delete copyOrden.fecha_orden
                delete copyOrden.fecha_actualizada
                delete copyOrden.fechahora_fin
                if (orden.fechahora_bodega) {
                    delete copyOrden.fechahora_bodega
                }
                
                axios.put('/logistica/api/ordenes_venta/' + id + '/', copyOrden).then(res => {
                    if (nodo.id !== 'contenedor-ovs' && nodo.id !== 'contenedor-ovs-no-entregadas') {
                        this.ordenesConCamion = this.ordenesConCamion.filter(ov => ov.id !== orden.id)
                        this.ordenesConCamion.push(res.data)
                        this.getOrdenesTabla(this.ordenesConCamion)
                        this.ordenes = this.ordenes.filter(ov => ov.id !== orden.id)
                        this.ordenesNoEntregadas = this.ordenesNoEntregadas.filter(ov => ov.id !== orden.id)
                    }
                })
            }
        },
        async guardarCamionero (camionero, nodo) {
            if (camionero !== undefined) {
                let camion = null
                let camionAnterior =  null
                let camioneroAnterior = null
                const idCamionero = camionero.id

                if (nodo.id === 'contenedor-camioneros') {
                    camion = this.camiones.find(c => c.chofer === idCamionero)
                    camion.chofer = null
                    
                    const index = this.camionerosConCamion.map(c => c.id).indexOf(idCamionero)
                    if (index > -1) {
                        this.camionerosConCamion.splice(index, 1)
                        this.camioneros.unshift(camionero)
                    }
                } else {
                    // NOTA EA: Entra aqui cuando se va a guardar en un camion
                    const idCamion = nodo.dataset.camion
                    if (idCamion === undefined) {
                        console.error("id de camion no encontrado")
                        return
                    }
                    
                    camion = this.camiones.find(c => c.id === +idCamion)
                    if (camion.chofer !== null) {
                        camioneroAnterior = this.camionerosConCamion.find(c => c.id === camion.chofer)
                    }

                    camionAnterior = this.camiones.find(c => c.chofer === idCamionero)

                    camion.chofer = idCamionero
                }

                if (camion === null) { return }
                const copyCamionero = JSON.parse(JSON.stringify(camionero))

                try {
                    if (camionAnterior) {
                        camionAnterior.chofer = null
                        await axios.put('/logistica/api/camiones/' + camionAnterior.id + '/', camionAnterior)
                    }
                    await axios.put('/logistica/api/camiones/' + camion.id + '/', camion)
                    if (nodo.id !== 'contenedor-camioneros') {
                        this.camionerosConCamion = this.camionerosConCamion.filter(c => c.id !== camionero.id)
                        this.camionerosConCamion.push(copyCamionero)
                        this.camioneros = this.camioneros.filter(c => c.id !== camionero.id)
                        if (camioneroAnterior) this.camioneros.unshift(camioneroAnterior)
                    }
                } catch (error) {
                    console.log(error)
                }
                

            }
        },
        cambiarSemana (semana) {
            let diferencia = 0
            if (semana === 'proxima') { diferencia = 7 }
            else if (semana === 'anterior') { diferencia = -7 }

            const lunes = this.monday
            lunes.setDate(lunes.getDate() + diferencia)
            this.getSemana()
            this.getOrdenesVenta()
            this.getOVConCamion()
        },
        showModal () {
            this.$refs['my-modal'].show()
        },
        getClassRegion (region, estados) {
            var reg = 'region-'
            if (region === 'Región de Atacama') reg = reg + 'III'
            if (region === 'Región de Coquimbo') reg = reg + 'IV'
            if (region === 'Región de Valparaíso') reg = reg + 'V'
            if (region === 'Región Libertador Gral Bernardo O´Higgin') reg = reg + 'VI'
            if (region === 'Región del Maule') reg = reg + 'VII'
            if (region === 'Región del Biobío') reg = reg + 'VIII'
            if (region === 'Región de Los Lagos') reg = reg + 'X'
            if (region === 'Región Metropolitana de Santiago') reg = reg + 'RM'
            if (region === 'Región de Ñuble') reg = reg + 'XVI'
            if (estados && 'externa' in estados && estados['externa']) {
                return reg + ' background--externa'
            }
            return reg
        },
        getFechaBodega (orden) {
            const fechaBodega = orden.fechahora_bodega?.split(" ")
            return fechaBodega ? fechaBodega[0] : ''
        },
        fechaInvalida (fechaBodega, fechaLogistica) {
            const anioBodega = +fechaBodega[0]
            const mesBodega = +fechaBodega[1] - 1
            const diaBodega = +fechaBodega[2]

            const anioLogistica = +fechaLogistica[0]
            const mesLogistica = +fechaLogistica[1] - 1
            const diaLogistica = +fechaLogistica[2]

            const dateBodega = new Date(anioBodega, mesBodega, diaBodega)
            const dateLogistica = new Date(anioLogistica, mesLogistica, diaLogistica)

            return dateBodega > dateLogistica
        },
        getDiffDays (orden) {
            if (orden.fechahora_logistica === null) {
                console.log(orden)
                this.ordenError = orden
            }
            const fechaLogistica = orden.fechahora_logistica.split(" ")[0].split("/").reverse()
            const anioLogistica = +fechaLogistica[0]
            const mesLogistica = +fechaLogistica[1] - 1
            const diaLogistica = +fechaLogistica[2]

            const dateLogistica = new Date(anioLogistica, mesLogistica, diaLogistica)
            const diffTime = dateLogistica - this.fechaActual

            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
            return diffDays
        },
        getBgColor (fechaOV) {
            const cpFechaOV = new Date(fechaOV)
            cpFechaOV.setHours(0,0,0,0)
            
            if (cpFechaOV !== null && cpFechaOV > this.fechaActual) {
                return 'contenedor'
            } else if (cpFechaOV !== null && cpFechaOV.getTime() === this.fechaActual.getTime()) {
                return 'contenedor calendar__today'
            } else {
                return 'bg-light'
            }
        },
        isDraggableOvInCalendar (ov) {
            return !ov.estados.despachado && !ov.seleccionable && this.$store.state.permiso_editar_logistica
            // return fechaOV !== null && fechaOV >= this.fechaActual
        },
        isDraggableOV () {
            return this.$store.state.permiso_editar_logistica
        },
        despacharOVsDeHoy () {
            const promesas = []
            this.ordenesTabla.forEach(ov => {
                if (ov.diffDia === 0 && !ov.despachado) {
                    promesas.push(axios.get('/logistica/api/ordenes_venta/' + ov.id + '/despachar/'))
                }
            })

            Promise.all(promesas).then(values => {
                // console.log(values)
                this.getOVConCamion()
            })
        },
        modificarArraysOrdenes (tipoOrdenes, orden, ordenesToModificar) {

            let ordenes = []
            if (tipoOrdenes === 'Camion') {
                ordenes = this.ordenesConCamion
            } else if (tipoOrdenes === 'NoEntregadas') {
                ordenes = this.ordenesNoEntregadas
            } else if (tipoOrdenes === 'Confirmadas') {
                ordenes = this.ordenes
            }

            const index = ordenes.map(o => o.id).indexOf(orden.id)
            if (index > -1) {
                ordenes.splice(index, 1)
                if (tipoOrdenes === 'Camion') this.getOrdenesTabla(ordenes)
                if (ordenesToModificar === 'NoEntregadas') {
                    this.ordenesNoEntregadas.unshift(orden)
                } else if (
                    ordenesToModificar === 'Confirmadas' && 
                    (this.regionesSeleccionadas.includes(orden.region) || this.regionesSeleccionadas.length === 0)
                ) { this.ordenes.unshift(orden) }
            }

        },
        ovSeleccionada (orden) {
            if (orden.seleccionable) {
                orden.diffDia = this.getDiffDays(orden)
                this.ordenesSeleccionadas.push(orden)
            } else {
                const index = this.ordenesSeleccionadas.map(o => o.id).indexOf(orden.id)
                if (index > -1) {
                    this.ordenesSeleccionadas.splice(index, 1)
                }
            }
        },
        ovSeleccionadaProxima (orden) {
            if (orden.seleccionable) {
                this.ordenesSeleccionadasProximas.push(orden)
            } else {
                const index = this.ordenesSeleccionadasProximas.map(o => o.id).indexOf(orden.id)
                if (index > -1) {
                    this.ordenesSeleccionadasProximas.splice(index, 1)
                }
            }
        },
        marcarDespachadasOVsDeHoy () {
            this.promiseAllOrdenes(
                this.ordenesSeleccionadas,
                ov => ov.diffDia === 0 && !ov.estados.despachado,
                ov => axios.get('/logistica/api/ordenes_venta/' + ov.id + '/despachar/')
            )
        },
        correoDespachadas (values) {
            this.promiseAllOrdenes(
                this.ordenesSeleccionadas,
                ov => ov.diffDia === 0 && !ov.estados.mensaje_despachando,
                ov => axios.get('/logistica/api/ordenes_venta/' + ov.id + '/correo_despachar/', { params: { observaciones: values.observaciones, url: values.url }})
            )
        },
        correoCustom () {
            this.promiseAllOrdenes(
                this.ordenesSeleccionadas,
                ov => ov.diffDia === 0 && !ov.estados.mensaje_despachando,
                ov => axios.get('/logistica/api/ordenes_venta/' + ov.id + '/correo_custom/')
            )
        },
        fechaValidaParaProximasOvs (orden) {
            const diffDia = this.getDiffDays(orden)

            if (diffDia >= 1 && diffDia <= 5) {
                return true
            }
            return false
        },
        correoProximasADespachadar () {
            this.promiseAllOrdenes(
                this.ordenesSeleccionadasProximas,
                ov => !ov.estados.mensaje_por_despachar,
                ov => axios.get('/logistica/api/ordenes_venta/' + ov.id + '/correo_proximas_a_despachar/')
            )
        },
        correoCustomProximasADespachadar () {
            this.promiseAllOrdenes(
                this.ordenesSeleccionadasProximas,
                ov => !ov.estados.mensaje_por_despachar,
                ov => axios.get('/logistica/api/ordenes_venta/' + ov.id + '/correo_custom_proximas_a_despachar/')
            )
        },
        promiseAllOrdenes (ordenes, condicion, peticion) {
            const promesas = []
            ordenes.forEach(ov => {
                if (condicion(ov)) {
                    promesas.push(peticion(ov))
                }
            })

            Promise.all(promesas).then(values => {
                // console.log(values)
                this.getOVConCamion()
            }).finally(() => { this.resetearOvsSeleccionables() })
        },
        resetearOvsSeleccionables () {
            this.ordenesSeleccionadas = []
            this.ordenesSeleccionadasProximas = []
        },
        tieneFechaActualizada (orden) {            
            let fecha = orden.fecha_actualizada
            if (fecha === null) {
                return false
            }

            fecha = fecha.split("/")
            const dia = +fecha[0]
            const mes = +fecha[1] - 1
            const anio = +fecha[2]

            const cpFechaOV = new Date(anio, mes, dia)
            cpFechaOV.setHours(0,0,0,0)
            
            if (cpFechaOV.getTime() <= this.fechaActual.getTime()) {
                return true
            }

            return false
        },
        getCamioneros () {
            axios.get('/logistica/api/camioneros/', {params: {camion__isnull: true}}).then(res => {
                this.camioneros = res.data
            })
            axios.get('/logistica/api/camioneros/', {params: {camion__isnull: false}}).then(res => {
                this.camionerosConCamion = res.data
            })
        },
        getNombreChofer (idChofer) {
            const chofer = this.camionerosConCamion.find(c => c.id === idChofer)
            if (chofer === undefined) {
                return ''
            }
            return chofer.get_nombre_completo
        },
        habilitadoParaHoy (fecha, orden) {
            return this.getBgColor(fecha) ==='contenedor calendar__today' && (
                (!orden.estados.mensaje_despachando && this.tieneFechaActualizada(orden) && !orden.estados.entregado) ||
                (orden.estados.no_entregado)
            )
        },
        habilitadoParaProxDias (orden) {
            return this.fechaValidaParaProximasOvs(orden) && (!orden.estados.mensaje_por_despachar || orden.estados.no_entregado)
        },
        openModalRespaldo () {
            this.$refs.modalRespaldo.openModal()
        },
        openModalMail () {
            this.$refs.modalMail.openModal()
        },
        xlsDia (date) {
            axios({
                url: '/logistica/api/ordenes_venta/xls_ov_dia/',
                params: {'fecha_logistica': date},
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                if (response.status === 200) {
                    this.count = 0
                    var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}))
                    var fileLink = document.createElement('a')
                    fileLink.href = fileURL
                    window.open(fileURL)
                }
            })
                .catch((error) => {
                    console.log(error)
                })
        },
        xlsAll () {
            axios({
                url: '/logistica/api/ordenes_venta/xls_ov_all/',
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                if (response.status === 200) {
                    this.count = 0
                    var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}))
                    var fileLink = document.createElement('a')
                    fileLink.href = fileURL
                    window.open(fileURL)
                }
            })
                .catch((error) => {
                    console.log(error)
                })
        },
        entregadaCorrectamente (orden) {
            axios({
                url: `/logistica/api/ordenes_venta/${orden}/corregir_orden/`,
                method: 'PUT'
            }).then((response) => {
                this.getOVConCamion()
            })
                .catch((error) => {
                    console.log(error)
                })
        },
        marcarEntregado (orden) {
            axios({
                url: `/logistica/api/ordenes_venta/${orden}/send_orden_entregada/`,
                method: 'PUT'
            }).then((response) => {
                this.getOVConCamion()
            })
                .catch((error) => {
                    console.log(error)
                })
        },
        setDataOVManual (data) {
            this.modalDataOV = data
        },
        openModalOVManual () {
            this.$refs.modalOVManual.openModalCreate()
        },
        fixOrden () {
            axios.put(`/logistica/api/ordenes_venta/${this.ordenError.id}/fix_orden_sin_fecha_con_camion/`).then(res => {
                this.ordenError = null
                this.getOrdenesVenta()
            })
        },
        setFechaData (data) {
            let dateSplited = data.split('-')
            this.fechaData = `${dateSplited[2].split('T')[0]}/${dateSplited[1]}/${dateSplited[0]}`
        },
        openModalVaciarCamion () {
            this.$refs.modalVaciar.openModal()
        },
        dateGte(date) {
            let today = new Date()
            today = this.$dayjs(today.getTime(), { utc: true }).format('YYYY-MM-DD')
            date = this.$dayjs(date, { utc: true }).format('YYYY-MM-DD')
            if (date >= today) {
                return true
            }
            return false
        },
        dateGteOrEqual (date) {
            let today = new Date()
            today = this.$dayjs(today.getTime(), { utc: true }).format('YYYY-MM-DD')
            date = this.$dayjs(date, { utc: true }).format('YYYY-MM-DD')
            if (date === today) {
                return true
            } else if (date > today) {
                return false
            }

        },
        ovTodas(date) {
            if (this.dateGteOrEqual(date) && this.seleccionarTodas.length > 0) {
                for (const ov of this.seleccionarTodas[0]) {
                    if (ov.fecha_actualizada && (!ov.estados.despachado || (ov.estados.despachado && !ov.estados.mensaje_despachando))) {
                        ov.seleccionable = true
                        this.ovSeleccionada(ov)
                    }
                }
            } else if (!this.dateGteOrEqual(date) && this.seleccionarTodas.length > 0) {
                for (const ov of this.seleccionarTodas[0]) {
                    if (!ov.estados.mensaje_despachando && !ov.estados.despachado) {
                        ov.seleccionable = true
                        this.ovSeleccionadaProxima(ov)
                    }
                }
            } else
                this.seleccionarTodas = []
            if (this.dateGteOrEqual(date) && this.ordenesSeleccionadas.length > 0  && this.seleccionarTodas.length === 0) {
                const ovTemp = [...this.ordenesSeleccionadas]
                for (const ov of ovTemp) {
                    ov.seleccionable = false
                    this.ovSeleccionada(ov)
                }
            } else if (!this.dateGteOrEqual(date) && this.ordenesSeleccionadasProximas.length > 0  && this.seleccionarTodas.length === 0) {
                const ovTemp = [...this.ordenesSeleccionadasProximas]
                for (const ov of ovTemp) {
                    ov.seleccionable = false
                    this.ovSeleccionadaProxima(ov)
                }
            } else
                this.seleccionarTodas = []
        }
    },
    mounted () {
        this.getPermisos().then(res => {
            this.getCamioneros()
            this.getRegiones()
            this.getCamiones()
            this.getOrdenesVenta()
            this.getOVNoEntregadas()
            this.getOVConCamion()
            this.getMonday()
            this.getSemana()
        })
        this.$watch('modalDataOV', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null) {
                this.openModalOVManual()
            }
        }, { immediate: true })
        this.$watch('fechaData', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null) {
                this.openModalVaciarCamion()
            }
        }, { immediate: true })
        this.$watch('fechasSeleccionadas', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null) {
                this.getOrdenesVenta()
            }
        }, { immediate: true })
    },
    updated () {
        this.inicializarOV()
        this.updateClassNameMultipleOvs( {calendarId: 'card-calendario-logistico'})
    },
    computed: {
        isAdmin() {
            return this.$store.state.admin
        },
        isBodega() {
            return this.$store.state.bodega
        },
        isLogistica() {
            return this.$store.state.logistica
        }
    }

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
</style>