
<!--PANEL DE ADMINISTRACIÓN-->
<!--GUIA COTIZACIONES-->
<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <button class="estado estado--purple" :class="{ 'estado--purple': !revisado && !porRevisar && !aprobado && !rechazado && !descartado && !cerrado && !porConfirmar}" @click="borrador()">
          <div class="estado__body">
            <p class="estado__number" v-if="estados">{{estados.borrador}}</p>
            <p class="estado__text">Borradores</p>
          </div>
        </button>
      </div>
      <div class="col">
        <button class="estado estado--primary" :class="{ 'bg-revisada--active': revisado }" @click="estadoRevisado()" >
          <div class="estado__body">
            <p class="estado__number" v-if="estados">{{estados.revisadas}}</p>
            <p class="estado__text">Revisar</p>
          </div>
        </button>
        <!-- <span class="span-badge"> (Por Aprobar)</span> -->
      </div>
      <div class="col">
        <button class="estado estado--warning" :class="{ 'bg-por-revisar--active': porRevisar }" @click="estadoPorRevisar()">
          <div class="estado__body">
            <p class="estado__number" v-if="estados">{{estados.por_revisar}}</p>
            <p class="estado__text">Corregir</p>
          </div>
        </button>
      </div>
      <div class="col">
        <button class="estado estado--danger" :class="{ 'estado--danger': rechazado }" @click="estadoRechazado()">
          <div class="estado__body">
            <p class="estado__number" v-if="estados">{{estados.rechazadas}}</p>
            <p class="estado__text"> Rechazadas</p>
          </div>
        </button>
      </div>
      <div class="col">
        <button class="estado estado--secondary" :class="{ 'estado--secondary': descartado }" @click="estadoDescartado()">
          <div class="estado__body">
            <p class="estado__number" v-if="estados">{{estados.descartadas}}</p>
            <p class="estado__text"> Descartadas</p>
          </div>
        </button>
      </div>
      <div class="col">
        <button class="estado estado--success" :class="{ 'estado--success': aprobado }" @click="estadoAprobado()">
          <div class="estado__body">
            <p class="estado__number" v-if="estados">{{estados.aprobadas}}</p>
            <p class="estado__text">Aprobadas</p>
          </div>
        </button>
      </div>
      <div class="col">
        <button class="estado estado--teal" :class="{ 'bg-revisada--active': porConfirmar }" @click="estadoPorConfirmar()" >
          <div class="estado__body">
            <p class="estado__number" v-if="estados">{{estados.por_confirmar}}</p>
            <p class="estado__text">Confirmar</p>
          </div>
        </button>
      </div>
      <div class="col">
        <button class="estado estado--info" :class="{ 'estado--info': cerrado }" @click="estadoCerrado()">
          <div class="estado__body">
            <p class="estado__number" v-if="estados">{{estados.cerradas}}</p>
            <p class="estado__text">Cerradas</p>
          </div>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card__header">
            <div class="d-flex">
              <h4 class="card__title">Cotizaciones</h4>
              <span class="badge badge--primary" v-if="revisado">Revisar</span>
              <span class="badge badge--warning" v-if="porRevisar">Corregir</span>
              <span class="badge badge--success" v-if="aprobado">Aprobadas</span>
              <span class="badge badge--danger" v-if="rechazado">Rechazadas</span>
              <span class="badge badge--secondary" v-if="descartado">Descartadas</span>
              <span class="badge badge--info" v-if="porConfirmar">Confirmar</span>
              <span class="badge badge--info" v-if="cerrado">Cerradas</span>
              <span class="badge badge--purple" v-if="!revisado && !porRevisar && !aprobado && !rechazado && !descartado && !cerrado && !porConfirmar">Borradores</span>
            </div>
            <modal-cotizacion class="" ref="modalCreate" @accept="getData()" :edit="false" modal="modal-create-cotizacion" title="Crear Cotización"></modal-cotizacion>
            <!-- QAD!!! DESCOMENTAR -->
            <br v-if="cerrado">
            <div class="card__buttons">
              <button class="button button--line-primary" v-if="cerrado && (isAdmin || isDigitador)" @click="xlsCerrado()" :disabled="porAprobar">
                <span class="button__text">Cotizaciones QAD</span>
                <font-awesome-icon :icon="['fas', 'download']" class="button__icon" />
              </button>
              <a class="button button--line-primary" v-if="cerrado && (isAdmin || isDigitador)" @click="xlsEmbarque()">
                <span class="button__text">ID embarque QAD</span>
                <font-awesome-icon :icon="['fas', 'download']" class="button__icon" />
              </a>
              <a class="button button--primary" v-if="cerrado && (isAdmin || isDigitador) && cotizacionesCerrar.length > 0" @click="ingresadasQAD()">
                <span class="button__text">Marca como ingresadas</span>
                <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
              </a>
              <!--BOTÓN CREAR COTIZACIÓN-->
              <a class="button button--primary" @click="openModalCreate()">
                <span class="button__text">Crear Cotización</span>
                <font-awesome-icon :icon="['fas', 'plus-circle']" class="button__icon" />
              </a>
            </div>
          </div>
          <div class="card__body">
            <div class="alert alert--primary" role="alert" v-if="cerrado && (isAdmin || isDigitador)">
              <font-awesome-icon :icon="['fas', 'info-circle']" class="alert__icon" />
              <span class="alert__text">Primero se debe descargar el documento ID Embarque QAD.</span>
            </div>
            <div class="alert alert--danger" role="alert" v-if="cerrado && porAprobar">
              <font-awesome-icon :icon="['fas', 'info-circle']" class="alert__icon" />
              <span class="alert__text">Existen artículos incorrectos.</span>
            </div>
            Filtros:
            <v-date-picker v-model="fechasSeleccionadas" is-range>
                <template v-slot="{ inputValue, inputEvents }">
                    <div class="m-0 mb-3">
                    <input
                        :value="inputValue.start"
                        v-on="inputEvents.start"
                        class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300 col-md-3"
                        placeholder="Fecha Inicio"
                    />
                    <input
                        :value="inputValue.end"
                        v-on="inputEvents.end"
                        class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300 col-md-3"
                        placeholder="Fecha Fin"
                    />
                    </div>
                </template>
            </v-date-picker>
            <v-app v-if="cotizaciones">
              <v-main>
                <div>
                  <v-data-table :headers="headers" :items="filteredRows" :items-per-page="10" v-bind:options.sync="pagination" item-key="codigo" class="elevation-1 table-striped" loading-text="Cargando Cotización" :footer-props="footerProps" mobile-breakpoint="0" :item-class="classRow">
                    <template v-slot:no-data>No existen cotizaciones</template>
                    <template v-slot:header="{ props }">
                      <tr class="grey lighten-3 bg-tr">
                        <th v-for="header in props.headers" :key="header.text">
                          <div v-if="filters.hasOwnProperty(header.value)">
                            <v-autocomplete flat hide-details multiple attach chips dense clearable :items="columnValueList(header.value)" v-model="filters[header.value]" header-fixed no-data-text="No hay información">
                              <template v-slot:selection="{ item, index }">
                                <v-chip v-if="index === 0">
                                  <span>+{{ filters[header.value].length }}</span>
                                </v-chip>
                              </template>
                            </v-autocomplete>
                          </div>
                        </th>
                      </tr>
                    </template>
                    <template v-slot:item.fecha_ingreso="{ item }">
                      <span v-if="item.fecha_ingreso">{{$dayjs(item.fecha_ingreso).format('DD/MM/YYYY')}}</span>
                    </template>
                    <template v-slot:item.fechahora_actualizacion="{ item }">
                      <span v-if="item.fechahora_actualizacion">{{$dayjs(item.fechahora_actualizacion).format('DD/MM/YYYY HH:mm:ss')}}</span>
                    </template>
                    <template v-slot:item.fecha_cierre="{ item }">
                      <span v-if="item.fecha_cierre">{{$dayjs(item.fecha_cierre).format('DD/MM/YYYY')}}</span>
                    </template>
                    <template v-slot:item.fecha_envio="{ item }">
                      <span v-if="item.fecha_envio">{{$dayjs(item.fecha_envio).format('DD/MM/YYYY')}}</span>
                    </template>
                    <template v-slot:item.estado="{ item }">
                      <span class="badge" :class="{'badge--danger': item.estado == 'Rechazada','badge--success': item.estado == 'Aprobada','badge--primary': item.estado == 'Revisada','badge--warning': item.estado == 'Por Revisar','badge--secondary': item.estado == 'Descartada', 'badge--info': item.estado == 'Cerrada', 'badge--purple': item.estado == 'Borrador'}" v-if="item.estado">
                        {{item.estado}}
                      </span>
                    </template>
                    <template v-slot:item.accion="{ item }">
                      <div class="btn-group btn-group-sm">
                        <router-link :to="createUrl(item)" class="button--sm button--primary">
                          <font-awesome-icon :icon="['fas', 'eye']" />
                        </router-link>
                        <a @click="deleteAlert(item)" class="button--sm button--danger" v-if="isAdmin">
                          <font-awesome-icon :icon="['fas', 'trash-alt']" />
                        </a>
                        <a @click="marcarIngresadaQAD(item)" class="button--sm button--success" v-if="item && item.estado == 'Cerrada' && (item.qad && item.qad.descargado && !item.qad.ingresado) && ((item.direccion && item.direccion.qad && item.direccion.qad.descargado && !item.direccion.qad.ingresado) || (item.direccion && item.direccion.qad && item.direccion.qad.descargado && item.direccion.qad.ingresado) || (item.direccion && !item.direccion.qad))">
                          <font-awesome-icon :icon="['fas', 'check']" />
                        </a>
                        <input class="form-check-input" type="checkbox" @click="appendCotizacion(item.id)" v-if="item && item.estado == 'Cerrada' && (item.qad && item.qad.descargado && !item.qad.ingresado)" />
                      </div>
                    </template>
                  </v-data-table>
                </div>
              </v-main>
            </v-app>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
import ModalCotizacion from '@/cotizacion/ModalCotizacion.vue'

const Cotizacion = resource('/cotizacion/api/gestor_cotizaciones', {
    get_estados: (params) => axios.get(`/cotizacion/api/gestor_cotizaciones/get_estados/`, { params }),
    ingresada_qad: (params) => axios.put('/cotizacion/api/gestor_cotizaciones/marcar_ingresada_qad/', { params })
},
axios)
const CotizacionList = resource('/cotizacion/api/listar_cotizaciones', {}, axios)

export default {
    name: 'GestorCotizaciones',
    components: {
        ModalCotizacion
    },
    inject: ['$dayjs'],
    data () {
        return {
            empresaId: this.$route.params.empresaId,
            empresaNombre: this.$route.params.empresaNombre,
            cotizaciones: [],
            currentPage: 1,
            perPage: null,
            rows: null,
            page: null,
            pagination: {
                page: 1
            },
            footerProps: {
                'disable-items-per-page': false,
                'items-per-page-options': [5, 10, 15, -1],
                'items-per-page-text': 'Cotizaciones por página'
            },
            filters: {
                anio: [],
                numero_table: [],
                // numero_proyecto_table: [],
                // nombre_proyecto_table: [],
                cliente__datos: [],
                // cliente__numero: [],
                // cliente__nombre: [],
                // fecha_ingreso: [],
                usuario_ingreso__get_nombre_completo: [],
                // revisado: [],
                // usuario_revisor__get_nombre_completo: [],
                // aprobado: [],
                // usuario_aprobador__get_nombre_completo: [],
                // rechazado: [],
                // descartado: [],
                // estado: [],
                tipo: []
                // activo: []
            },
            revisado: true,
            aprobado: false,
            rechazado: false,
            porRevisar: false,
            descartado: false,
            cerrado: false,
            porConfirmar: false,
            estados: [],
            cotizacionesCerrar: [],
            porAprobar: false,
            fechasSeleccionadas: null
        }
    },
    methods: {
        getData () {
            var params = {
                empresa: this.empresaId,
                revisado: this.revisado,
                aprobado: this.aprobado,
                rechazado: this.rechazado,
                por_revisar: this.porRevisar,
                descartado: this.descartado,
                cerrado:this.cerrado,
                por_confirmar: this.porConfirmar
            }
            if (this.fechasSeleccionadas) {
                params['start'] = this.$dayjs(this.fechasSeleccionadas.start).format('YYYY-MM-DD')
                params['end'] = this.$dayjs(this.fechasSeleccionadas.end).format('YYYY-MM-DD')
            }
            CotizacionList.query(params).then((res) => {
                this.cotizaciones = res.data
                res.data.filter((item) => {
                    if (item.articulos_por_aprobar) {
                        this.porAprobar = true
                    } else {
                        this.porAprobar = false
                    }
                })
            })
            Cotizacion.get_estados({ empresa: this.empresaId }).then((res) => {
                this.estados = res.data
            })
        },
        parseNull (value) {
            if (!value || value === undefined)
                return ''
            else
                return value
        },
        columnValueList (val) {
            var dict = []
            dict = this.cotizaciones.map((d) => d[val])
            return dict.sort()
        },
        // classTable (item) {
        //     if (item.por_revisar && !item.aprobado && !item.revisado && !item.rechazado)
        //         return 'por-revisar'
        //     else if (!item.aprobado && item.revisado && !item.rechazado)
        //         return 'revisada'
        //     else if (item.aprobado)
        //         return 'aprobada'
        //     else if (item.rechazado)
        //         return 'rechazada'
        //     else if (item.descartado)
        //         return 'descartada'
        //     return ''
        // },
        classRow (item) {
            if (item.estado === 'Cerrada' && item.articulos_por_aprobar)
                return 'sin-codigo'
            return ''
        },
        openModalCreate () {
            this.$refs.modalCreate.openModalCreate()
        },
        deleteAlert (cotizacion) {
            this.$swal({
                title: 'Vas a eliminar la cotización',
                text: '¿Estás seguro? Se perderá toda la información asociada',
                showCancel: true,
                confirmText: 'Aceptar',
                cancelext: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteCotización(cotizacion)
                }
            })
        },
        deleteCotización(cotizacion) {
            Cotizacion.delete(cotizacion.id).then((res) => {
                this.getData()
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Cotización eliminada con exito'
                })
            })
                .catch((error) => {
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al eliminar la cotización'
                    })
                })
        },
        createUrl (cotizacion) {
            return '/' + this.empresaId + '/' + this.empresaNombre + '/cotizacion/' + cotizacion.id
        },
        estadoRevisado () {
            this.cotizacionesCerrar = []
            this.revisado = !this.revisado
            this.aprobado = false
            this.rechazado = false
            this.porRevisar = false
            this.descartado = false
            this.cerrado = false
            this.porConfirmar = false
            this.getData()
        },
        estadoPorRevisar () {
            this.cotizacionesCerrar = []
            this.revisado = false
            this.aprobado = false
            this.rechazado = false
            this.porRevisar = !this.porRevisar
            this.descartado = false
            this.cerrado = false
            this.porConfirmar = false
            this.getData()
        },
        estadoAprobado () {
            this.cotizacionesCerrar = []
            this.revisado = false
            this.aprobado = !this.aprobado
            this.rechazado = false
            this.porRevisar = false
            this.descartado = false
            this.cerrado = false
            this.porConfirmar = false
            this.getData()
        },
        estadoRechazado () {
            this.cotizacionesCerrar = []
            this.revisado = false
            this.aprobado = false
            this.rechazado = !this.rechazado
            this.porRevisar = false
            this.descartado = false
            this.cerrado = false
            this.porConfirmar = false
            this.getData()
        },
        estadoDescartado () {
            this.cotizacionesCerrar = []
            this.revisado = false
            this.aprobado = false
            this.rechazado = false
            this.porRevisar = false
            this.descartado = !this.descartado
            this.cerrado = false
            this.porConfirmar = false
            this.getData()
        },
        estadoCerrado () {
            this.cotizacionesCerrar = []
            this.revisado = false
            this.aprobado = false
            this.rechazado = false
            this.porRevisar = false
            this.descartado = false
            this.cerrado = !this.cerrado
            this.porConfirmar = false
            this.getData()
        },
        borrador () {
            this.cotizacionesCerrar = []
            this.revisado = false
            this.aprobado = false
            this.rechazado = false
            this.porRevisar = false
            this.descartado = false
            this.cerrado = false
            this.porConfirmar = false
            this.getData()
        },
        estadoPorConfirmar () {
            this.cotizacionesCerrar = []
            this.revisado = false
            this.aprobado = false
            this.rechazado = false
            this.porRevisar = false
            this.descartado = false
            this.cerrado = false
            this.porConfirmar = !this.porConfirmar
            this.getData()
        },
        xlsCerrado () {
            axios({
                url: '/cotizacion/api/gestor_cotizaciones/xls_qad_cerradas/?empresa__id=' + this.empresaId,
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                this.count = 0
                var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}))
                var fileLink = document.createElement('a')

                fileLink.href = fileURL
                window.open(fileURL)
            })
                .catch((error) => {
                    this.count++
                    if (error.response && error.response.status === 500) {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Ocurrió un error al descargar el documento'
                        })
                    }
                    else if (error.response && error.response.status === 400) {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'No posees permisos para descargar este documento'
                        })

                    }
                    else if (error.response && error.response.status === 401 && this.count < 5) {
                        this.getData()
                        this.xlsCerrado()
                    }
                })

        },
        xlsEmbarque () {
            axios({
                url: '/cotizacion/api/gestor_cotizaciones/xls_qad_embarque/?empresa__id=' + this.empresaId,
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                this.count = 0
                var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}))
                var fileLink = document.createElement('a')

                fileLink.href = fileURL
                window.open(fileURL)
            })
                .catch((error) => {
                    this.count++
                    if (error.response && error.response.status === 500) {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Ocurrió un error al descargar el documento'
                        })
                    }
                    else if (error.response && error.response.status === 400) {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'No posees permisos para descargar este documento'
                        })

                    }
                    else if (error.response && error.response.status === 401 && this.count < 5) {
                        this.getData()
                        this.xlsEmbarque()
                    }
                })

        },
        marcarIngresadaQAD (cotizacion) {
            Cotizacion.ingresada_qad({ cotizacion: cotizacion.id }).then((res) => {
                this.getData()
            })
        },
        appendCotizacion(cotizacion) {
            if (this.cotizacionesCerrar.includes(cotizacion)) {
                this.cotizacionesCerrar.pop(cotizacion)
            } else {
                this.cotizacionesCerrar.push(cotizacion)
            }
        },
        ingresadasQAD () {
            Cotizacion.ingresada_qad({ cotizaciones: this.cotizacionesCerrar }).then((res) => {
                this.getData()
            })

        }
    },
    mounted () {
        this.getData()
        this.$watch('fechasSeleccionadas', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null) {
                this.getData()
            }
        }, { immediate: true })
    },
    computed: {
        headers () {
            return [
                { text: 'Año de Ingreso', value: 'anio', align: 'center' },
                { text: 'Número de Cotización', value: 'numero_table', align: 'center' },
                { text: 'Nombre del Proyecto', value: 'numero_proyecto_table', align: 'center' },
                { text: 'Número de Proyecto', value: 'nombre_proyecto_table', align: 'center' },
                { text: 'Tipo de Cotización', value: 'tipo' },
                { text: 'Cliente', value: 'cliente__datos', align: 'center' },
                // { text: 'Cliente Nombre', value: 'cliente__nombre', align: 'center' },
                { text: 'Fecha de Ingreso', value: 'fecha_ingreso', align: 'center' },
                { text: 'Fecha de Envío', value: 'fecha_envio', align: 'center' },
                // { text: 'Estado', value: 'estado', align: 'center' },
                { text: 'Usuario', value: 'usuario_ingreso__get_nombre_completo', align: 'center' },
                // { text: '¿Está revisada?', value: 'revisado' },
                // { text: 'Usuario Revisor', value: 'usuario_revisor__get_nombre_completo' },
                // { text: '¿Está aprobada?', value: 'aprobado' },
                // { text: 'Usuario Aprobador', value: 'usuario_aprobador__get_nombre_completo' },
                // { text: '¿Está rechazada?', value: 'rechazado' },
                // { text: 'Usuario Rechazo', value: 'usuario_rechazo__get_nombre_completo' },
                // { text: '¿Está descartada?', value: 'descartado' },
                { text: 'Fecha de Última Actualización', value: 'fechahora_actualizacion', align: 'center' },
                { text: 'Fecha de Cierre', value: 'fecha_cierre', align: 'center' },
                { text: 'Acción', value: 'accion', sortable: false, align: 'center'}
            ]
        },        
        filteredRows () {
            if (this.cotizaciones) {
                return this.cotizaciones.filter((d) => {
                    return Object.keys(this.filters).every((f) => {
                        return this.filters[f].length < 1 || this.filters[f].includes(d[f])
                    })
                })
            }
            return null
        },
        isAdmin() {
            return this.$store.state.admin
        },
        isDigitador() {
            return this.$store.state.digitador
        }
    }
}
</script>
