<template>
    <div class="login col-md-12">
        <div class="row justify-content-center">
            <div class="card--login">
                <div class="card__header--login">
                    <p class="card__header--text">Ingresar nueva contraseña</p>
                </div>
                <div class="card__body">
                    <form class="form">
                        <div class="form-group">
                            <label class="bold">Ingresa una nueva contraseña</label>
                            <input type="password" class="form-control" v-model="resetData.password" placeholder="Contraseña" required autocomplete="current-password">
                            <small class="form-text text-muted"></small>
                            <div class="alert alert--danger" role="alert" v-show="error">
                                <font-awesome-icon :icon="['fas', 'exclamation-circle']" class="alert__icon" />
                                <span class="alert__text">{{error}}</span>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card__footer--login">
                    <button type="button" class="button button--primary m-0 w-100" @click="resetPassword()">
                        <span class="button__text">Siguiente</span>
                        <font-awesome-icon :icon="['fas', 'arrow-right']" class="button__icon" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'ResetPassword',
    components: {
    },
    data () {
        return {
            userId: this.$route.params.userId,
            token: this.$route.params.token,
            name: this.$route.name,
            resetData: { password: null },
            error: null
        }
    },
    mounted () {
    },

    methods: {
        resetPassword () {
            if (this.name === 'ResetPassword')
                var url = '/base/api/user_reset_password/reset_password/'
            else if (this.name === 'SetPassword')
                var url = '/base/api/user_reset_password/set_password/'
            axios({
                method: 'put',
                url: url,
                data: {
                    id: this.userId,
                    token: this.token,
                    password: this.resetData.password
                }
            })
                .then((response) => {
                    this.$swal({
                        icon: 'success',
                        title: 'Contraseña configurada con éxito',
                        text: 'Inicie sesión nuevamente'
                    }).then((result) => {
                        this.$router.push('/')
                    })
                })
                .catch((response) => {
                    this.error = response.response.data
                })
        }

    },
    computed: {
    }
}
</script>
