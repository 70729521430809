import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import store from '../store'
/* VIEWS */
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import HomeEmpresa from '../views/HomeEmpresa.vue'
import GestorArticulos from '../views/GestorArticulos.vue'
import GestorUsuarios from '../views/GestorUsuarios.vue'
import GestorClientes from '../views/GestorClientes.vue'
import Cotizacion from '../cotizacion/Cotizacion.vue'
import Reportes from '../views/Reportes.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import GestorTipoArticulo from '../views/GestorTipoArticulo.vue'
import CalendarioLogistica from '../logistica/CalendarioLogistica.vue'
import CalendarioBodega from '../logistica/CalendarioBodega.vue'
import GestorCamiones from '../logistica/GestorCamiones.vue'
import ListaOrdenes from '../logistica/ListaOrdenes.vue'
import DetalleOrdenAdmin from '../logistica/DetalleOrdenAdmin.vue'
import ConfirmCliente from '../ordenes/ConfirmCliente.vue'
import Ordenes from '../ordenes/Ordenes.vue'
import DetalleOrden from '../ordenes/DetalleOrden.vue'
import MapaCamion from '../logistica/MapaCamion.vue'
import CalendarioLogisticaView from '../logistica/CalendarioLogisticaView.vue'
/* VIEWS */

Vue.use(VueRouter)

const routes = [
    { path: '/login', name: 'Login', component: Login, meta: { noAuth: true } },
    { path: '/', name: 'Home', component: Home, meta: { noAuth: false } },
    { path: '/:empresaId/:empresaNombre', name: 'HomeEmpresa', component: HomeEmpresa, meta: { noAuth: false } },
    { path: '/:empresaId/:empresaNombre/gestor_articulos', name: 'GestorArticulos', component: GestorArticulos, meta: { noAuth: false } },
    { path: '/gestor_usuarios', name: 'GestorUsuarios', component: GestorUsuarios, meta: { noAuth: false } },
    { path: '/:empresaId/:empresaNombre/gestor_clientes', name: 'GestorClientes', component: GestorClientes, meta: { noAuth: false } },
    { path: '/:empresaId/:empresaNombre/cotizacion/:cotizacionId', name: 'Cotizacion', component: Cotizacion, meta: { noAuth: false } },
    { path: '/reportes', name: 'Reportes', component: Reportes, meta: { noAuth: false } },
    { path: '/forgotpassword', name: 'ForgotPassword', component: ForgotPassword, meta: { noAuth: true } },
    { path: '/resetpassword/:userId/:token', name: 'ResetPassword', component: ResetPassword, meta: { noAuth: true } },
    { path: '/setpassword/:userId/:token', name: 'SetPassword', component: ResetPassword, meta: { noAuth: true } },
    { path: '/gestor_tipos', name: 'GestorTipoArticulo', component: GestorTipoArticulo, meta: { noAuth: false } },
    { path: '/:empresaId/:empresaNombre/logistica', name: 'CalendarioLogistica', component: CalendarioLogistica, meta: { noAuth: false } },
    { path: '/:empresaId/:empresaNombre/bodega', name: 'CalendarioBodega', component: CalendarioBodega, meta: { noAuth: false } },
    { path: '/map/posicion_vehiculo/:token', name: 'MapaCamion', component: MapaCamion, props: true, meta: { noAuth: true } },
    { path: '/ordenes/chofer/:choferId', name: 'Ordenes', component: Ordenes, meta: { noAuth: false }, props: true },
    { path: '/ordenes/detalle/:ordenId', name: 'DetalleOrden', component: DetalleOrden, meta: { noAuth: false }},
    { path: '/gestor_camiones', name: 'GestorCamiones', component: GestorCamiones, meta: { noAuth: false }},
    { path: '/confirmar_orden/:uid/:ordenId', name: 'ConfirmCliente', component: ConfirmCliente, meta: { noAuth: true }},
    { path: '/form_orden/:uid/:ordenId', name: 'FormCliente', component: ConfirmCliente, meta: { noAuth: true }},
    { path: '/:empresaId/:empresaNombre/lista_ordenes', name: 'ListaOrdenes', component: ListaOrdenes, meta: { noAuth: false }},
    { path: '/:empresaId/:empresaNombre/detalle_orden/:ordenId', name: 'DetalleOrdenAdmin', component: DetalleOrdenAdmin, meta: { noAuth: false }},
    { path: '/:empresaId/:empresaNombre/logisticaview', name: 'CalendarioLogisticaView', component: CalendarioLogisticaView, meta: { noAuth: false } },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: function (to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash
            }
        } else {
            return { x: 0, y: 0 }
        }
    }
    // linkActiveClass: 'nav-link--active',
    // linkExactActiveClass: 'nav-link--active'
})

router.beforeEach(function (to, from, next) {
    if (to.fullPath === '/login?logout') {
        localStorage.removeItem('jwt.access')
        localStorage.removeItem('jwt.refresh')
        next({
            path: '/login'
        })
        return
    }
    if (!to.meta.noAuth) {
        if (localStorage.getItem('jwt.access') === null) {
            next({
                path: '/login',
                params: { nextUrl: to.fullPath }
            })
            return
        }
    }
    next()
})
axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('jwt.access')
        // console.log(token)
        // console.log(config.url)

        if (config.url.search('http') !== 0) {
            config.url = process.env.VUE_APP_BASEURL + config.url
        }
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        // console.log(config.url)
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)
axios.interceptors.response.use((response) => {
    return response
}, function (error) {
    // console.log(error)
    // console.log(error.response)
    const originalRequest = error.config
    if (error.response.status === 401 && error.response.data && error.response.data.code === 'token_not_valid') {
        if (error.config.url.search('/api/token/refresh/') >= 0) {
            localStorage.removeItem('jwt.access')
            router.push('/login')
            location.reload()
            return Promise.reject(error)
        }
        axios.interceptors.response.eject()
        const token = localStorage.getItem('jwt.access')
        const refreshToken = localStorage.getItem('jwt.refresh')
        return axios.post(process.env.VUE_APP_BASEURL + '/api/token/refresh/', { access: token, refresh: refreshToken })
            .then(function (res) {
                if (res.status === 200) {
                    localStorage.setItem('jwt.access', res.data.access)
                    return axios(originalRequest)
                }
            })
    }
    return Promise.reject(error)
})
export default router
