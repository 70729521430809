<template>
    <div>
        <b-modal id="modal-cargar-respaldos" size="lg" title="Cargar Documentos de Respaldo" no-close-on-esc no-close-on-backdrop hide-header-close>
            <div class="row">
                <div class="col-md-12">
                    <a @click="downloadFile()" class="button button--primary">Descargar Planilla de Ejemplo</a>
                </div>
                <div class="form-group col-md-12">
                    <label>Documentos*</label>
                    <b-form-file :accept="acceptedFiles" v-model="files" drop-placeholder="Deja aquí los documentos" placeholder="No hay documentos seleccionados" ref="files">
                       <template slot="file-name" slot-scope="{ names }">
                            <span class="badge badge--primary">{{ names[0] }}</span>
                            <span class="badge badge--primary" v-if="names && names.length > 1"> + {{ names.length - 1 }}</span>
                       </template>
                    </b-form-file>
                    <div class="alert alert--danger" role="alert" v-show="error">
                        <font-awesome-icon :icon="['fas', 'exclamation-circle']" class="alert__icon" />
                        <span class="alert__text">{{error}}</span>
                    </div>
                </div>
            </div>
            <template #modal-footer>
                <button class="button button--secondary" @click="closeModal()">
                    <span class="button__text">Cancelar</span>
                    <font-awesome-icon :icon="['fas', 'times-circle']" class="button__icon" />
                </button>
                <button class="button button--success" @click="uploadFile()" :disabled="files && files.length===0">
                    <span class="button__text">Guardar</span>
                    <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
                </button>
            </template>
        </b-modal>
    </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'

const CargaRespaldo = resource('/base/api/cargar_respaldos/', {
    download_base_cotizacion: (id) => axios.put(`/base/api/cargar_respaldos/download_base_cotizacion/`),
    download_base_articulos: (id) => axios.put(`/base/api/cargar_respaldos/download_base_articulos/`),
    download_base_clientes: (id) => axios.put(`/base/api/cargar_respaldos/download_base_clientes/`),
    download_base_presupuesto: (id) => axios.put(`/base/api/cargar_respaldos/download_base_presupuesto/`),
    download_base_update: (id) => axios.put(`/base/api/cargar_respaldos/download_base_update/`),
    download_base_inventario: (id) => axios.put(`/base/api/cargar_respaldos/download_base_inventario/`),
    download_base_ov: (id) => axios.put(`/base/api/cargar_respaldos/download_base_ov/`),
    download_base_despacho: (id) => axios.put(`/base/api/cargar_respaldos/download_base_despacho/`)
    // delete: (id) => axios.delete(`/base/api/cargar_respaldos/${id}/`)
}, axios)

export default {
    name: 'ModalRespaldo',
    props: ['cotizacion', 'empresa', 'cliente', 'presupuesto', 'ordenVenta', 'update', 'despacho'],
    data () {
        return {
            files: [],
            error: null,
            acceptedFiles: 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }
    },
    methods: {
        // getData () {
        //     CargaRespaldo.query({ })
        //         .then((response) => {
        //             this.documentos = response.data
        //         })
        //         .catch((response) => {
        //             console.log(response)
        //         })
        // },
        openModal () {
            this.files = []
            this.$root.$emit('bv::show::modal', 'modal-cargar-respaldos')
        },
        closeModal () {
            this.$emit('close')
            this.$root.$emit('bv::hide::modal', 'modal-cargar-respaldos')
        },
        uploadFile () {
            var formData = new FormData()
            // for (var i = 0; i < this.files.length; i++) {
            //     var file = this.files[i]
            //     formData.append('files[' + i + ']', file)
            // }
            this.error = null
            formData.append('files', this.files)
            if (this.cotizacion) formData.append('cotizacion', this.cotizacion.id)
            if (this.empresa) formData.append('empresa', this.empresa)
            if (this.cliente) formData.append('cliente', true)
            if (this.presupuesto) formData.append('presupuesto', this.presupuesto.id)
            if (this.ordenVenta) formData.append('orden_venta', true)
            if (this.update === 'activo') formData.append('articulos_activos', true)
            if (this.update === 'inventario') formData.append('inventario', true)
            if (this.despacho) formData.append('despacho', true)
            axios.post('/base/api/cargar_respaldos/',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((response) => {
                    this.files = []
                    this.closeModal()
                    this.$emit('accept')
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Respaldos cargados exitosamente'
                    })
                    this.closeModal()
                })
                .catch((response) => {
                    this.error = response.response.data[0]
                    console.log(this.files)
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al cargar los Respaldos'
                    })
                })
        },
        downloadFile () {
            if (this.cotizacion) {
                CargaRespaldo.download_base_cotizacion()
                    .then((response) => {
                        window.open(response.data)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
            if (this.empresa && !this.cliente && !this.update && !this.ordenVenta && !this.despacho) {
                CargaRespaldo.download_base_articulos()
                    .then((response) => {
                        window.open(response.data)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
            if (this.empresa && this.cliente && !this.update && !this.ordenVenta && !this.despacho) {
                CargaRespaldo.download_base_clientes()
                    .then((response) => {
                        window.open(response.data)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
            if (this.presupuesto) {
                CargaRespaldo.download_base_presupuesto()
                    .then((response) => {
                        window.open(response.data)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
            if (this.update === 'activo') {
                CargaRespaldo.download_base_update()
                    .then((response) => {
                        window.open(response.data)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
            else if (this.update === 'inventario') {
                CargaRespaldo.download_base_inventario()
                    .then((response) => {
                        window.open(response.data)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
            else if (this.ordenVenta) {
                CargaRespaldo.download_base_ov()
                    .then((response) => {
                        window.open(response.data)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
            else if (this.despacho) {
                CargaRespaldo.download_base_despacho()
                    .then((response) => {
                        window.open(response.data)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        }
        // deleteFile (documento) {
        //     CargaRespaldo.delete(documento.id)
        //         .then((response) => {
        //             this.getData()
        //             this.$notify({
        //                 group: 'foo',
        //                 type: 'success',
        //                 title: 'Respaldo eliminado exitosamente'
        //             })
        //         })
        //         .catch((response) => {
        //             this.$notify({
        //                 group: 'foo',
        //                 type: 'error',
        //                 title: 'Error al eliminar el Respaldo'
        //             })
        //         })
        // },
        // deleteAlert (documento) {
        //     this.$swal({
        //         title: 'Vas a eliminar este respaldo, ¿estás seguro?',
        //         text: 'Se perderá para siempre.',
        //         showCancelButton: true,
        //         confirmButtonText: 'Aceptar',
        //         cancelButtonText: 'Cancelar'
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             this.deleteFile(documento)
        //         }
        //     })
        // }
    },
    mounted () {
    }
}
</script>
