<template>
  <div>
    <Header :empresa-nombre="empresaNombre" :empresa-id="empresaId"></Header>
    <modal-nueva-orden v-if="modalDataOV" modal="modal-create-orden"  ref="modalOVManual" @accept="getData();modalDataOV=null;" :data="modalDataOV"></modal-nueva-orden>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-2">
          <a @click="$router.back()" class="button button--primary m-0 mb-3">
            <font-awesome-icon :icon="['fas', 'arrow-left']" class="button__icon--left" />
            <span class="button__text">Volver</span>
          </a>
        </div>
      </div>
      <div class="card">
        <div class="card__header">
          <h2 class="card__title">Ordenes - {{ovTitle}}</h2>
            <a class="button button--primary" @click="marcarTodas();" v-if="despachada && ordenes.length > 0 && ovEntregadas.length === 0">
                <span class="button__text">Entregar todos los despachos</span>
                <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
            </a>
            <a class="button button--primary" @click="marcarTodas(ovEntregadas);" v-if="despachada && ovEntregadas.length > 0">
                <span class="button__text">Entregar seleccionadas</span>
                <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
            </a>
        </div>
        <div class="card__body">
            <div class="card__buttons">
                <button class="button button--line-primary" :class="empresaNombre" @click="limpiarEstado();despachada=true;getData();">
                <span class="button__text">En Despacho</span>
                <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
                </button>
                <button class="button button--line-success" :class="empresaNombre" @click="limpiarEstado();entregada=true;getData();">
                <span class="button__text">Entregadas</span>
                <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
                </button>
                <button class="button button--line-danger" :class="empresaNombre" @click="limpiarEstado();noEntregada=true;getData();">
                <span class="button__text">No entregadas</span>
                <font-awesome-icon :icon="['fas', 'times-circle']" class="button__icon" />
                </button>
                <button class="button button--line-purple" :class="empresaNombre" @click="limpiarEstado();entregadaConErrores=true;getData();">
                <span class="button__text">Entregadas con errores</span>
                <font-awesome-icon :icon="['fas', 'times-circle']" class="button__icon" />
                </button>
                <button class="button button--line-info" :class="empresaNombre" @click="limpiarEstado();sinEstado=true;getData();">
                <span class="button__text">Sin estado</span>
                <font-awesome-icon :icon="['fas', 'minus-circle']" class="button__icon" />
                </button>
            </div>
          <v-app v-if="ordenes">
            <v-main>
              <v-data-table
              :headers="headers"
              :items="filteredRows"
              :items-per-page="10"
              v-bind:options.sync="pagination"
              item-key="id"
              class="elevation-1 table-striped"
              loading-text="Cargando Ordenes"
              :footer-props="footerProps"
              mobile-breakpoint="0"
              >
                <template v-slot:no-data>
                    No existen ordenes
                </template>
                <template v-slot:header="{ props }">
                    <tr class="grey lighten-3 bg-tr">
                        <th v-for="header in props.headers" :key="header.text">
                            <div v-if="filters.hasOwnProperty(header.value)">
                                <v-autocomplete
                                    flat
                                    hide-details
                                    multiple
                                    attach
                                    chips
                                    dense
                                    clearable
                                    :items="columnValueList(header.value)"
                                    v-model="filters[header.value]"
                                    header-fixed
                                    no-data-text="No hay información"
                                >
                                    <template v-slot:selection="{ item, index }">
                                        <v-chip v-if="index === 0">
                                            <span> 
                                                +{{ filters[header.value].length }}
                                            </span>
                                        </v-chip>
                                    </template>
                                </v-autocomplete>
                            </div>
                        </th>
                    </tr>
                </template>
                <template v-slot:item.accion="{ item }">
                  <div class="btn-group">
                    <input class="form-check-input" type="checkbox" @click="appendOV(item.id)" v-if="item && item.estado == 'En Despacho'  && (isAdmin || isBodega || isLogistica)" />
                    <a class="button--sm button--primary" @click="setDataOVManual(item);" v-if="(item.estado ==='Entregado con errores' || item.estado_cliente === 'Entregado con errores') && item.estados__corregido === '' || item.estados__corregido === 'No' && (isAdmin || isBodega || isLogistica)" v-b-tooltip.hover title="Crear OV Manual">
                      <font-awesome-icon :icon="['fas', 'plus-circle']" />
                    </a>
                    <a class="button--sm button--success" @click="marcarEntregado(item);" v-if="item.estado ==='En Despacho' && (isAdmin || isBodega || isLogistica)" v-b-tooltip.hover title="Marcar como entregada">
                      <font-awesome-icon :icon="['fas', 'check-circle']" />
                    </a>
                    <a class="button--sm button--primary"  @click="pushOrden(item.id);" v-b-tooltip.hover title="Ver detalle">
                      <font-awesome-icon :icon="['fas', 'eye']" />
                    </a>
                  </div>
                </template>
              </v-data-table>
            </v-main>
          </v-app>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import resource from 'resource-axios'
import Header from '@/components/Header.vue'
import ModalNuevaOrden from '@/logistica/ModalNuevaOrden.vue'
const Ordenes = resource('/logistica/api/ordenes_venta', {
    update: (id) => axios.put(`/logistica/api/ordenes_venta/${id}/send_orden_entregada/`),
    marcar_entregada: (params) => axios.put('/logistica/api/ordenes_venta/marcar_entregada/', { params }),
}, axios)

export default {
    name: 'ListaOrdenes',
    components: { Header, ModalNuevaOrden },
    async beforeCreate () {

    },
    data () {
        return {
            empresaId: this.$route.params.empresaId,
            empresaNombre: this.$route.params.empresaNombre,
            ordenes: [],
            currentPage: 1,
            perPage: null,
            rows: null,
            page: null,
            pagination: {
                page: 1
            },
            footerProps: {
                'disable-items-per-page': false,
                'items-per-page-options': [5, 10, 15, -1],
                'items-per-page-text': 'Ordenes por página'
            },
            filters: {
                numero: [],
                numero_cotizacion: [],
                region: [],
                camion__patente: [],
                chofer__nombre_completo: [],
                fecha_orden: [],
                fechahora_bodega__read: [],
                fechahora_logistica__read: [],
                fechahora_fin__read: [],
                // estado: [],
                estado_cliente: [],
                estados__corregido: [],
                razon: []
            },
            modalDataOV: null,
            despachada: true,
            entregada: null,
            noEntregada: null,
            entregadaConErrores: null,
            sinEstado: null,
            ovEntregadas: []
        }
    },
    methods: {
        getData () {
            Ordenes.query({
                estados__despachado: this.despachada,
                estados__entregado: this.entregada,
                estados__no_entregado: this.noEntregada,
                estados__entregado_con_errores: this.entregadaConErrores,
                estados__sin_estado: this.sinEstado
            }).then((res) => {
                this.ordenes = res.data
            })
        },
        marcarEntregado (orden) {
            Ordenes.update(orden.id).then((res) => {
                this.getData()
            })
        },
        columnValueList (val) {
            var dict = []
            dict = this.ordenes.map((d) => d[val])
            return dict.sort()
        },
        limpiarEstado() {
            this.entregada = null
            this.despachada = null
            this.noEntregada = null
            this.entregadaConErrores = null
            this.sinEstado = null
        },
        pushOrden(id) {
            this.$router.push({ name: 'DetalleOrdenAdmin', params: { ordenId: id }})
        },
        setDataOVManual (data) {
            this.modalDataOV = data
        },
        openModalOVManual () {
            this.$refs.modalOVManual.openModalCreate()
        },
        appendOV(ov) {
            if (this.ovEntregadas.includes(ov)) {
                this.ovEntregadas.pop(ov)
            } else {
                this.ovEntregadas.push(ov)
            }
        },
        marcarTodas(ovs) {
            if (ovs) {
                Ordenes.marcar_entregada({ ovs: ovs }).then((res) => {
                    this.getData()
                    this.ovEntregadas = []
                })
            } else {
                Ordenes.marcar_entregada({ empresa: this.empresaId }).then((res) => {
                    this.getData()
                    this.ovEntregadas = []
                })
            }
        }
    },
    mounted () {
        this.getData()
        this.$watch('modalDataOV', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null) {
                this.openModalOVManual()
            }
        }, { immediate: true })
    },
    computed: {
        headers () {
            return [
                { text: 'Número', value: 'numero', align: 'center' },
                { text: 'Número de Cotización', value: 'numero_cotizacion', align: 'center' },
                { text: 'Región de Despacho', value: 'region', align: 'center' },
                { text: 'Camión', value: 'camion__patente', align: 'center' },
                { text: 'Chofer', value: 'chofer__nombre_completo' },
                { text: 'Fecha Ingreso', value: 'fecha_orden', align: 'center' },
                { text: 'Fecha programada Bodega', value: 'fechahora_bodega__read', align: 'center' },
                { text: 'Fecha programada Logística', value: 'fechahora_logistica__read', align: 'center' },
                { text: 'Fecha Entrega', value: 'fechahora_fin__read', align: 'center' },
                // { text: 'Estado', value: 'estado', align: 'center' },
                { text: 'Estado Cliente', value: 'estado_cliente', align: 'center' },
                { text: 'Razón', value: 'razon', align: 'center' },
                { text: 'Corregida', value: 'estados__corregido', align: 'center' },
                { text: 'Acción', value: 'accion', sortable: false, align: 'center'}
            ]
        },        
        filteredRows () {
            if (this.ordenes) {
                return this.ordenes.filter((d) => {
                    return Object.keys(this.filters).every((f) => {
                        return this.filters[f].length < 1 || this.filters[f].includes(d[f])
                    })
                })
            }
            return null
        },
        isAdmin () {
            return  this.$store.state.admin
        },
        isBodega() {
            return this.$store.state.bodega
        },
        isLogistica() {
            return this.$store.state.logistica
        },
        ovTitle () {
            if (this.despachada)
                return 'En Despacho'
            else if (this.entregada)
                return 'Entregadas'
            else if (this.entregadaConErrores)
                return 'Entregada con errores'
            else if (this.noEntregada)
                return 'No entregadas'
            return 'Sin estado'
        }
    }
}
</script>
