<template>
  <div>
    <b-modal id="modal-permisos-usuario" size="xl" no-close-on-esc no-close-on-backdrop hide-header-close>
      <template #modal-header>
          <h5 class="m-0">Editar Permisos: {{usuario.get_nombre_completo}} - {{usuario.email}}</h5>
      </template>
      <div v-for="empresa in usuario.empresas_serializer" v-bind:key="empresa.id">
        <h5 class="card__subtitle">{{empresa.nombre}}</h5>
        <div class="table-responsive">
          <table class="table">
            <thead class="thead">
              <tr>
                <th class="thead__th">Ver Cotización</th>
                <th class="thead__th">Ingresar Cotización</th>
                <th class="thead__th">Editar Cotización</th>
                <th class="thead__th">Revisar Cotización</th>
                <th class="thead__th">Aprobar Cotización</th>
                <th class="thead__th">Rechazar Cotización</th>
                <th class="thead__th">Ver Bodega</th>
                <th class="thead__th">Editar Bodega</th>
                <th class="thead__th">Ver Logistica</th>
                <th class="thead__th">Editar Logistica</th>
              </tr>
            </thead>
            <tbody class="tbody">
              <td class="table__td">
                <span v-if="!tienePermiso('ver', empresa.id)" @click="agregarPermiso('ver', empresa.id)">
                  <font-awesome-icon class="toggle" :icon="['fas', 'toggle-on']" :transform="{ rotate: 180 }"/>
                </span>
                <span v-if="tienePermiso('ver', empresa.id)" @click="quitarPermiso('ver', empresa.id);">
                  <font-awesome-icon class="toggle active" :icon="['fas', 'toggle-on']"/>
                </span>
              </td>
              <td class="table__td">
                <span v-if="!tienePermiso('ingresar', empresa.id)" @click="agregarPermiso('ingresar', empresa.id)">
                  <font-awesome-icon class="toggle" :icon="['fas', 'toggle-on']" :transform="{ rotate: 180 }"/>
                </span>
                <span v-if="tienePermiso('ingresar', empresa.id)" @click="quitarPermiso('ingresar', empresa.id);">
                  <font-awesome-icon class="toggle active" :icon="['fas', 'toggle-on']"/>
                </span>
              </td>
              <td class="table__td">
                <span v-if="!tienePermiso('editar', empresa.id)" @click="agregarPermiso('editar', empresa.id)">
                  <font-awesome-icon class="toggle" :icon="['fas', 'toggle-on']" :transform="{ rotate: 180 }"/>
                </span>
                <span v-if="tienePermiso('editar', empresa.id)" @click="quitarPermiso('editar', empresa.id);">
                  <font-awesome-icon class="toggle active" :icon="['fas', 'toggle-on']" />
                </span>
              </td>
              <td class="table__td">
                <span v-if="!tienePermiso('revisar', empresa.id)" @click="agregarPermiso('revisar', empresa.id)">
                  <font-awesome-icon class="toggle" :icon="['fas', 'toggle-on']" :transform="{ rotate: 180 }"/>
                </span>
                <span v-if="tienePermiso('revisar', empresa.id)" @click="quitarPermiso('revisar', empresa.id);">
                  <font-awesome-icon class="toggle active" :icon="['fas', 'toggle-on']"/>
                </span>
              </td>
              <td class="table__td">
                <span v-if="!tienePermiso('aprobar', empresa.id)" @click="agregarPermiso('aprobar', empresa.id)">
                  <font-awesome-icon class="toggle" :icon="['fas', 'toggle-on']" :transform="{ rotate: 180 }"/>
                </span>
                <span v-if="tienePermiso('aprobar', empresa.id)" @click="quitarPermiso('aprobar', empresa.id);">
                  <font-awesome-icon class="toggle active" :icon="['fas', 'toggle-on']" />
                </span>
              </td>
              <td class="table__td">
                <span v-if="!tienePermiso('rechazar', empresa.id)" @click="agregarPermiso('rechazar', empresa.id)">
                  <font-awesome-icon class="toggle" :icon="['fas', 'toggle-on']" :transform="{ rotate: 180 }"/>
                </span>
                <span v-if="tienePermiso('rechazar', empresa.id)" @click="quitarPermiso('rechazar', empresa.id);">
                  <font-awesome-icon class="toggle active" :icon="['fas', 'toggle-on']"/>
                </span>
              </td>
              <td class="table__td">
                <span v-if="!tienePermiso('ver_bodega', empresa.id)" @click="agregarPermiso('ver_bodega', empresa.id)">
                  <font-awesome-icon class="toggle" :icon="['fas', 'toggle-on']" :transform="{ rotate: 180 }"/>
                </span>
                <span v-if="tienePermiso('ver_bodega', empresa.id)" @click="quitarPermiso('ver_bodega', empresa.id);">
                  <font-awesome-icon class="toggle active" :icon="['fas', 'toggle-on']" />
                </span>
              </td>
              <td class="table__td">
                <span v-if="!tienePermiso('editar_bodega', empresa.id)" @click="agregarPermiso('editar_bodega', empresa.id)">
                  <font-awesome-icon class="toggle" :icon="['fas', 'toggle-on']" :transform="{ rotate: 180 }"/>
                </span>
                <span v-if="tienePermiso('editar_bodega', empresa.id)" @click="quitarPermiso('editar_bodega', empresa.id);">
                  <font-awesome-icon class="toggle active" :icon="['fas', 'toggle-on']" />
                </span>
              </td>
              <td class="table__td">
                <span v-if="!tienePermiso('ver_logistica', empresa.id)" @click="agregarPermiso('ver_logistica', empresa.id)">
                  <font-awesome-icon class="toggle" :icon="['fas', 'toggle-on']" :transform="{ rotate: 180 }"/>
                </span>
                <span v-if="tienePermiso('ver_logistica', empresa.id)" @click="quitarPermiso('ver_logistica', empresa.id);">
                  <font-awesome-icon class="toggle active" :icon="['fas', 'toggle-on']" />
                </span>
              </td>
              <td class="table__td">
                <span v-if="!tienePermiso('editar_logistica', empresa.id)" @click="agregarPermiso('editar_logistica', empresa.id)">
                  <font-awesome-icon class="toggle" :icon="['fas', 'toggle-on']" :transform="{ rotate: 180 }"/>
                </span>
                <span v-if="tienePermiso('editar_logistica', empresa.id)" @click="quitarPermiso('editar_logistica', empresa.id);">
                  <font-awesome-icon class="toggle active" :icon="['fas', 'toggle-on']"/>
                </span>
              </td>
            </tbody>
          </table>
        </div>
      </div>
      <template #modal-footer>
        <button class="button button--secondary" @click="closeModal();">
          <span class="button__text">Cancelar</span>
          <font-awesome-icon :icon="['fas', 'times-circle']" class="button__icon" />
        </button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
const Empresa = resource('/base/api/listar_empresas', axios)
const Usuario = resource('/base/api/gestor_usuarios/', {
    update: (id, params) => axios.put(`/base/api/gestor_usuarios/${id}/`, { params })
}, axios)

export default {
    name: 'ModalPermisos',
    props: ['usuario'],
    components: {
    },
    data () {
        return {
        }
    },
    methods: {
        openModal () {
            this.$root.$emit('bv::show::modal', 'modal-permisos-usuario')
        },
        closeModal () {
            this.$emit('accept')
            this.$root.$emit('bv::hide::modal', 'modal-permisos-usuario')
        },
        tienePermiso (tipo, id) {
            var temp = false
            if (this.usuario.permisos) {
                if (this.usuario.permisos.length > 0) {
                    this.usuario.permisos.filter((item) => {
                        if (item.tipo === tipo && item.id.includes(id)) {
                            temp = true
                        }
                    })
                }
            }
            return temp
        },
        agregarPermiso (tipo, id) {
            this.usuario.permisos.filter((item) => {
                if (item.tipo === tipo) {
                    if (!item.id.includes(id)){
                        item.id.push(id)
                    }
                }
            })
            this.updateUsuario()
        },
        quitarPermiso (tipo, id) {
            this.usuario.permisos.filter((item) => {
                if (item.tipo === tipo) {
                    var index = item.id.indexOf(id)
                    if (item.id.includes(id)) item.id.splice(index, 1)
                }
            })
            this.updateUsuario()
        },
        updateUsuario () {
            Usuario.update(this.usuario.id, this.usuario).then((res) => {
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Usuario editado con exito'
                })
            })
                .catch((error) => {
                    this.$emit('accept')
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al editar el usuario'
                    })
                })

        }
    },
    mounted () {
    }
}
</script>
