/* LIBRERÍAS */
import 'bootstrap'
import App from './App.vue'
import Vue from 'vue'
import router from './router'
import store from './store'
import { BootstrapVue } from 'bootstrap-vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
/* íconos sólidos font-awesome */
import {
    faHome, faSignInAlt, faCog, faBars, faTools, faPlus,
    faPencilAlt, faTrashAlt, faSave, faFile, faDollarSign,
    faUsers, faUserLock, faToggleOn, faFileUpload, faTimes, faTimesCircle,
    faCheck, faEye, faArrowRight, faFileDownload, faFileExcel,
    faFilePdf, faInfoCircle, faMapMarkerAlt, faUser, faClipboardList,
    faChartLine, faUpload, faInfo, faDownload, faArrowLeft, faEnvelope, faPhoneAlt,
    faBan, faCircle, faPaperPlane, faSyncAlt, faChevronUp, faChevronDown, faShippingFast,
    faWarehouse, faCheckCircle, faMinusCircle, faTruck, faExpand, faPhone, faBriefcase,
    faExclamationCircle, faFileInvoiceDollar, faEuroSign, faPlusCircle, faEllipsisV,
    faUserCheck, faEnvelopeOpen, faMailBulk, faMapPin, faUndo
} from '@fortawesome/free-solid-svg-icons'
/* íconos sólidos font-awesome */

/* íconos RRHH font-awesome
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
íconos RRHH font-awesome */

/* íconos regulares font-awesome
import { faSquare } from '@fortawesome/free-regular-svg-icons'
íconos regulares font-awesome */
import dayjs from 'dayjs'

import Vuetify, {
    VApp, VMain, VDataTable, VChip, VAutocomplete,
    VOverlay, VProgressCircular, VDataFooter, VPagination,
    VRow, VCol
} from 'vuetify/lib'
import vSelect from 'vue-select'
import Notifications from 'vue-notification'
import VueSweetalert2 from 'vue-sweetalert2'
import VueNumeric from 'vue-numeric'
import axios from 'axios'
import { mapState } from 'vuex'
import { rutFilter, rutInputDirective } from 'vue-dni'
import PercentInput from 'vue-percent-input'
import * as VueGoogleMaps from 'vue2-google-maps'
import Multiselect from 'vue-multiselect'
import VCalendar from 'v-calendar';
/* LIBRERÍAS */

/* CSS */
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vuetify/dist/vuetify.min.css'
import 'vue-select/dist/vue-select.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import './app.css'
/* CSS */

/* FILTERS */

Vue.filter('formatDateTimeSlug', function (date) {
    if (date) {
        return date.toISOString() //.split('T')[0]
    }
})
Vue.filter('formatDateTimeToDate', function (date) {
    if (date) {
        return date.split(' ')[0]
    }
})

Vue.filter('formatDatePersonalized', function (date) {
    const days = [
        'Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'
    ]
    const months = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto',
        'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ]
    if (date) {
        const indexDay = date.getDay()
        let arrayDate = date.toLocaleDateString() 
        arrayDate = arrayDate.includes('-') ? arrayDate.split('-') : arrayDate.split('/')
        const indexMonth = +arrayDate[1] - 1

        return `${days[indexDay]} ${arrayDate[0]} de ${months[indexMonth]} ${arrayDate[2]}`
    }
})

Vue.filter('formatDateOnlyDay', function (date) {
    
    if (date) {
        let arrayDate = date.toLocaleDateString() 
        arrayDate = arrayDate.includes('-') ? arrayDate.split('-') : arrayDate.split('/')

        return `${arrayDate[0]}`
    }
})

Vue.filter('capitalize', function (value) {
  	if (!value) return ''
  	value = value.toString()
  	return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('rutFilter', rutFilter)
/* FILTERS */

/* DIRECTIVES */
Vue.directive('rut', rutInputDirective)
Vue.directive( 'touppercase', {
    update (el) {
        el.value = el.value.toUpperCase()
    },
})
/* DIRECTIVES */

/* COMPONENTS */
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('v-select', vSelect)
Vue.component('multiselect', Multiselect)
/* COMPONENTS */

/* USE */
Vue.use(BootstrapVue)
Vue.use(Vuetify, {
    components: {
        VApp, VMain, VDataTable, VChip, VAutocomplete,
        VOverlay, VProgressCircular, VDataFooter, VPagination,
        VRow, VCol
    }
})
Vue.use(Notifications)
Vue.use(VueSweetalert2)
Vue.use(VueNumeric)
Vue.use(PercentInput)
Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_KEY_MAPS
    }
})
Vue.use(VCalendar)
/* USE */

/* OTROS */
Vue.config.productionTip = false
library.add(
    faHome, faSignInAlt, faCog, faBars, faTools, faPlus,
    faPencilAlt, faTrashAlt, faSave, faFile, faDollarSign,
    faUsers, faUserLock, faToggleOn, faFileUpload, faTimes, faTimesCircle,
    faCheck, faEye, faArrowRight, faFileDownload, faFileExcel,
    faFilePdf, faInfoCircle, faMapMarkerAlt, faUser, faClipboardList,
    faChartLine, faUpload, faInfo, faDownload, faArrowLeft, faEnvelope, faPhoneAlt,
    faBan, faCircle, faPaperPlane, faSyncAlt, faChevronUp, faChevronDown, faShippingFast,
    faWarehouse, faCheckCircle, faMinusCircle, faTruck, faExpand, faPhone, faBriefcase,
    faHome,faCog, faExclamationCircle, faFileInvoiceDollar, faEuroSign, faPlusCircle, faEllipsisV,
    faUserCheck, faTrashAlt, faEnvelopeOpen, faMailBulk, faMapPin, faUndo
)

const opts = {
    icons: {
        iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
    },
}
axios.defaults.showLoader = true;
/* OTROS */

/* VUE APP */
new Vue({
    router,
    store,
    vuetify: new Vuetify(opts),
    render: h => h(App),
    created() {
        axios.interceptors.request.use(
            (config) => {
                if (config.showLoader) {
                    store.dispatch('loader/pending');
                }
                return config;
            },
            (error) => {
                if (error.config.showLoader) {
                    store.dispatch('loader/done');
                }
                return Promise.reject(error);
            }
        );
        axios.interceptors.response.use(
            (response) => {
                if (response.config.showLoader) {
                    store.dispatch('loader/done');
                }

                return response;
            },
            (error) => {
                let response = error.response;

                if (response.config.showLoader) {
                    store.dispatch('loader/done');
                }

                return Promise.reject(error);
            }
        )
    },
    provide () {
        return {
            $dayjs: dayjs
        }
    },
    computed: {
        ...mapState('loader', ['loading'])
    }
}).$mount('#app')
/* VUE APP */