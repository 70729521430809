<template>
    <div>
        <HeaderOrdenes v-if="user && user.rol === 'Chofer'"></HeaderOrdenes>
        <div class="container-fluid mt-3" v-if="ordenId">
            <div class="row">
                <div class="col-md-2">
                    <a class="button button--primary m-0 mb-3" v-if="orden" @click="$router.back()">
                        <font-awesome-icon :icon="['fas', 'arrow-left']" class="button__icon--left" />
                        <span class="button__text">Volver</span>
                    </a>
                </div>
            </div>
            <div class="card" v-if="user">
                <div class="card__header"  v-if="orden">
                    <h4 class="card__title">{{orden.numero}} - Fecha de entrega: {{orden.fechahora_logistica | formatDateTimeToDate}}</h4>
                </div>
                <div class="card__body" v-if="orden">
                    <div class="form-group">
                        <label class="title--ordenes bold">Estado</label>
                        <b-form-radio v-if="orden.estados" v-model="estado" value="Entregada">Entregada</b-form-radio>
                        <b-form-radio v-if="orden.estados" v-model="estado" value="Entregada con Errores">Entregada con errores</b-form-radio>
                        <b-form-radio v-if="orden.estados" v-model="estado" value="No Entregada">No entregada</b-form-radio>
                    </div>
                    <div class="form-group" v-if="orden.estados && estado==='Entregada con Errores'">
                        <label class="title--ordenes bold">Razón</label>
                        <v-select :options="razonesErrores" v-model="form.razon"></v-select>
                    </div>
                    <div class="form-group" v-if="orden.estados && estado==='No Entregada'">
                        <label class="title--ordenes bold">Razón</label>
                        <v-select :options="razones" v-model="form.razon"></v-select>
                    </div>
                    <div class="form-group">
                        <label class="title--ordenes bold">Observaciones</label>
                        <b-alert v-if="form.razon === 'Otros' && estado==='No Entregada'" show variant="danger">Explique por qué razón no se pudo entregar</b-alert>
                        <b-alert v-if="form.razon === 'Otros' && estado==='Entregada con Errores'" show variant="danger">Explique por qué se entregó con errores la orden</b-alert>
                        <textarea class="form-control" v-model="form.observaciones"></textarea>
                    </div>
                    <div class="form-group">
                        <b-form-file :accept="acceptedFiles" v-model="files" drop-placeholder="Deja aquí los documentos" placeholder="No hay documentos seleccionados" ref="files">
                        <template slot="file-name" slot-scope="{ names }">
                                <span class="badge badge--primary">{{ names[0] }}</span>
                                <span class="badge badge--primary" v-if="names && names.length > 1"> + {{ names.length - 1 }}</span>
                        </template>
                        </b-form-file>
                    </div>
                </div>
                <div class="ordenes__card--footer">
                    <a @click="$router.back()" class="button button--secondary m-0 mb-3">
                        <font-awesome-icon :icon="['fas', 'arrow-left']" class="button__icon--left" />
                        <span class="button__text">Cancelar</span>
                    </a>
                    <a @click="updateOrden()" class="button button--primary m-0 mb-3">
                        <span class="button__text">Guardar cambios</span>
                        <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import resource from 'resource-axios'
import HeaderOrdenes from '@/components/HeaderOrdenes.vue'
// import RegistrosAnteriores from '@/ordenes/components/RegistrosAnteriores.vue'
import _ from 'lodash'

const Ordenes = resource('/logistica/api/ordenes_venta', {
    update: (id, params) => axios.put(`/logistica/api/ordenes_venta/${id}/update_camion/`, { params }),
}, axios)


export default {
    name: 'DetalleOrden',
    components: { HeaderOrdenes },
    async beforeCreate () {

    },
    data () {
        return {
            empresaId: this.$route.params.empresaId,
            empresaNombre: this.$route.params.empresaNombre,
            ordenId: this.$route.params.ordenId,
            orden: {},
            form: { razon: '', observaciones: '' },
            estado: null,
            files: [],
            error: null,
            acceptedFiles: 'image/jpeg, image/png'
        }
    },
    methods: {
        getOrden () {
            Ordenes.query({ id: this.ordenId }).then((res) => {
                this.orden = res.data[0]
                this.orden.registros = _.orderBy(this.orden.registros, 'fecha_ingreso_registro', 'desc')
            })
                .catch((error) => {
                    console.log(error)
                })
        },
        updateOrden () {
            let registro = {
                'fecha_entrega_programada': this.orden.fechahora_logistica.split(' ')[0],
                'estado': this.estado,
                'razon': this.form.razon,
                'observaciones': this.form.observaciones,
                'usuario': this.user.get_nombre_completo
            }
            if (this.estado === 'Entregada') {
                this.orden.estados.entregado = true
                this.orden.estados.entregado_con_errores = false
                this.orden.estados.no_entregado = false
                this.orden.estados.despachado = true
                this.orden.estados.razon = ''
            } else if (this.estado === 'Entregada con Errores') {
                this.orden.estados.entregado = false
                this.orden.estados.entregado_con_errores = true
                this.orden.estados.no_entregado = false
                this.orden.estados.corregido = false
                this.orden.estados.despachado = true
            } else if (this.estado === 'No Entregada') {
                this.orden.estados.entregado = false
                this.orden.estados.entregado_con_errores = false
                this.orden.estados.no_entregado = true
                this.orden.estados.despachado = false
                this.orden.estados.corregido = false
                this.orden.estados.mensaje_despachando = false

            }
            this.orden.registros = registro
            var formData = new FormData()
            formData.append('files', this.files)
            formData.append('estados', JSON.stringify(this.orden.estados))
            formData.append('registros', JSON.stringify(this.orden.registros))
            this.error = null
            axios.put(`/logistica/api/ordenes_venta/${this.orden.id}/update_camion/`,
                formData, { headers: { 'Content-Type': 'multipart/form-data'}})
                .then((response) => {
                    this.files = []
                    this.error = null
                })
                .catch((response) => {
                    this.error = response.response.data[0]
                    console.log(this.files)
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error'
                    })
                })
            // Ordenes.update(this.ordenId, this.orden).then((res) => {
            //     this.getOrden()
            //     this.form = {}
            //     this.$notify({
            //         group: 'foo',
            //         type: 'success',
            //         title: 'Orden Actualizada'
            //     })
            //     this.$router.back()
            // })
            //     .catch((error) => {
            //         this.$notify({
            //             group: 'foo',
            //             type: 'error',
            //             title: 'Error al actualizar'
            //         })
            //     })

        }
    },
    mounted () {
        this.getOrden()
        this.$watch('ordenId', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null) {
                this.getOrden()
            }
        }, { immediate: true })
    },
    computed: {
        user () {
            return this.$store.state.user
        },
        razones () {
            return ['Cliente no estaba', 'Cliente sin maquinaria para recibir', 'Hora de llegada muy tardía', 'Camino en mal estado', 'No se logró descargar completamente', 'Otros']
        },
        razonesErrores () {
            return ['Hay menos artículos que los indicados en factura', 'Hay artículos en mal estado', 'Faltó maquinaria para descarga completa', 'Otros']
        }
    }
}
</script>
