<template>
  <div>
    <b-modal id="modal-create-orden" size="xl" title="Nueva Orden Manual" no-close-on-esc no-close-on-backdrop hide-header-close>
      <div class="row">
        <div class="form-group col-md-3">
            <label>Orden previa</label>
            <v-app v-if="ordenes.length > 0 && !orden.orderless">
                <v-autocomplete
                    v-model="orden.object"
                    :items="ordenes"
                    item-text="numero"
                    solo
                    return-object
                >
                </v-autocomplete>
            </v-app>
            <div class="form-check" v-if="ordenes.length > 0">
                <input class="form-check-input" type="checkbox" v-model="orden.orderless">
                <label class="form-check-label">
                    ¿No tiene OV previa?
                </label>
            </div>
            <input type="text" name="" class="form-control" v-if="data && ordenes.length === 0" v-model="data.numero" disabled>
        </div>
        <div class="form-group col-md-3" v-if="show">
            <label>Número Cotización</label>
            <input type="text" name="" class="form-control" v-model="orden.numero_cotizacion" disabled>
        </div>
        <div class="form-group col-md-3" v-if="show">
            <label>Número OV</label>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{orden.prefijo}}</span>
                </div>
                <input type="text" name="" class="form-control col-md-10" v-model="orden.numero">
            </div>
        </div>
        <div class="form-group col-md-3" v-if="show">
            <label>Región</label>
            <input type="text" name="" class="form-control" v-model="orden.region">
        </div>
        <div class="form-group col-md-3" v-if="show">
            <label>Comuna</label>
            <input type="text" name="" class="form-control" v-model="orden.detalles.comuna">
        </div>
        <div class="form-group col-md-3" v-if="show">
            <label>Ciudad</label>
            <input type="text" name="" class="form-control" v-model="orden.detalles.ciudad">
        </div>
        <div class="form-group col-md-3" v-if="show">
            <label>Dirección</label>
            <input type="text" name="" class="form-control" v-model="orden.detalles.direccion">
        </div>
        <div class="form-group col-md-3" v-if="show">
            <label>ID Embarque</label>
            <input type="text" name="" class="form-control" v-model="orden.detalles.id_embarque">
        </div>
        <div class="form-group col-md-3" v-if="show">
            <label>Latitud</label>
            <input type="text" name="" class="form-control" v-model="orden.detalles.lat">
        </div>
        <div class="form-group col-md-3" v-if="show">
            <label>Longitud</label>
            <input type="text" name="" class="form-control" v-model="orden.detalles.long">
        </div>
        <div class="form-group col-md-3" v-if="show">
            <label>Forma de Pago</label>
            <input type="text" name="" class="form-control" v-model="orden.detalles.forma_pago">
        </div>
        <div class="form-group col-md-3" v-if="show">
            <label>Cliente</label>
            <input type="text" name="" class="form-control" v-model="orden.detalles.nombre_cliente">
        </div>
        <div class="form-group col-md-3" v-if="show">
            <label>Número Cliente</label>
            <input type="text" name="" class="form-control" v-model="orden.detalles.numero_cliente">
        </div>
        <div class="form-group col-md-3" v-if="show">
            <label>Correo Electrónico</label>
            <input type="text" name="" class="form-control" v-model="orden.detalles.email">
        </div>
        <div class="form-group col-md-3" v-if="show">
            <label>Teléfono</label>
            <input type="text" name="" class="form-control" v-model="orden.detalles.telefono_cliente">
        </div>
        <div class="form-group col-md-3" v-if="show">
            <label>Nombre Vendedor</label>
            <input type="text" name="" class="form-control" v-model="orden.detalles.nombre_vendedor">
        </div>
    </div>
    <div class="" v-if="show">
        <div class="row col-md-12">
            <a class="btn btn-primary float-right" @click="addArticulo()" style="cursor: pointer;"><font-awesome-icon :icon="['fas','plus']"/> Agregar artículo</a>
        </div>
        <div class="row">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Código</th>
                        <th scope="col">Descripción</th>
                        <th scope="col">Cantidad</th>
                        <th scope="col">Unidad Medida</th>
                        <th scope="col">Acción</th>
                    </tr>
                </thead>
                <tbody>
                <tr v-for="(articulo, index) in orden.articulos" v-bind:key="articulo.codigo">
                  <th><input type="text" name="" class="form-control" v-model="articulo.codigo"></th>
                  <td><input type="text" name="" class="form-control" v-model="articulo.descripcion"></td>
                  <td><input type="number" name="" class="form-control" v-model="articulo.cantidad_factura"></td>
                  <td><input type="text" name="" class="form-control" v-model="articulo.unidad_medida"></td>
                  <td><a @click="removeArticulo(index)" role="button" class="btn-icon-remove aling-left">Eliminar</a></td>
                </tr>
              </tbody>
            </table>
        </div>
      </div>
      <template #modal-footer>
        <button class="button button--secondary" @click="closeModal()">
          <span class="button__text">Cancelar</span>
          <font-awesome-icon :icon="['fas', 'times-circle']" class="button__icon" />
        </button>
        <button class="button button--success" @click="createOV()">
          <span class="button__text">Guardar</span>
          <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
        </button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
const Ordenes = resource('/logistica/api/ordenes_venta/', axios)

export default {
    name: 'ModalNuevaOrden',
    props: ['data'],
    data () {
        return {
            orden: { prefijo: 'N-', detalles: {}, articulos: [], estados: { en_orden: false, entregado: false, sin_stock: false, despachado: false, no_entregado: false, entregado_con_errores: false, sin_confirmar: false, mensaje_entregado: false, mensaje_despachando: false, mensaje_por_despachar: false, entregado_cliente: false, entregado_con_errores_cliente: false, no_entregado_cliente: false }, orderless: false },
            ordenes: [],
            show: false,
            empresaId: this.$route.params.empresaId,
        }
    },
    methods: {
        openModalCreate () {
            this.orden = { prefijo: 'N-', detalles: {}, articulos: [], estados: { en_orden: false, entregado: false, sin_stock: false, despachado: false, no_entregado: false, entregado_con_errores: false, sin_confirmar: false, mensaje_entregado: false, mensaje_despachando: false, mensaje_por_despachar: false, entregado_cliente: false, entregado_con_errores_cliente: false, no_entregado_cliente: false, corregido: false }, orderless: false }
            if (!this.data) {
                this.getOrdenes()
            } else {
                this.setOrden(this.data)
                this.show = true
            }
            this.$root.$emit('bv::show::modal', 'modal-create-orden')
        },
        closeModal () {
            this.$emit('accept')
            this.$root.$emit('bv::hide::modal', 'modal-create-orden')
        },
        createOV () {
            this.orden.numero = this.orden.prefijo + this.orden.numero
            this.orden.detalles.region = this.orden.region
            this.orden.empresa = this.empresaId
            Ordenes.save(this.orden).then((res) => {
                this.closeModal()
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'OV creada con exito'
                })
            })
        },
        getOrdenes () {
            Ordenes.query({ nueva: true }).then((res) => {
                this.ordenes = res.data
            })
        },
        removeArticulo (index) {
            this.orden.articulos.splice(index, 1)
        },
        addArticulo () {
            var artDict = {'codigo': '','descripcion': '', 'cantidad_factura': 0, 'unidad_medida': '' }
            if (!this.orden.articulos) {
                this.orden.articulos = []
                this.orden.articulos.push(artDict)
            } else {
                this.orden.articulos.push(artDict)
            }
        },
        setOrden (orden) {
            this.orden.base = orden.id
            this.orden.numero = orden.numero
            this.orden.region = orden.region
            this.orden.numero_cotizacion = orden.numero_cotizacion
            this.orden.detalles = orden.detalles
            this.orden.articulos = orden.articulos
        }
    },
    mounted () {
        this.$watch('orden.object', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null) {
                this.setOrden(this.orden.object)
                this.show = true
            }
        }, { immediate: true })
        this.$watch('orden.orderless', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null) {
                if (newLocale) {
                    this.show = true
                    this.orden = { prefijo: 'N-', detalles: {}, articulos: [], estados: { en_orden: false, entregado: false, sin_stock: false, despachado: false, no_entregado: false, entregado_con_errores: false, sin_confirmar: false, mensaje_entregado: false, mensaje_despachando: false, mensaje_por_despachar: false, entregado_cliente: false, entregado_con_errores_cliente: false, no_entregado_cliente: false, corregido: false }, orderless: true }
                } else if (!newLocale) {
                    this.show = false
                }
            }
        }, { immediate: true })
    },
    computed: {
    }
}
</script>
