<template>
    <div class="login col-md-12">
        <!-- <img src="img/login-ecologica-2.svg" alt="fondo login" class="login__img"> -->
        <div class="row justify-content-center">
            <div class="card--login">
                <div class="card__header--login">
                    <div class="card__header--img">
                        <img src="img/dripsa.png">
                        <img src="img/tagle.png">
                    </div>
                    <!-- <span>Sistema de cotizaciones</span> -->
                    <p class="card__header--text">Bienvenido</p>
                </div>
                <div class="card__body">
                    <div class="alert alert--danger" role="alert" v-show="loginError">
                        <font-awesome-icon :icon="['fas', 'exclamation-circle']" class="alert__icon" />
                        <span class="alert__text">Usuario o contraseña inválidos</span>
                    </div>
                    <form class="form">
                        <input type="text" class="form-control mb-3" v-model="loginData.username" placeholder="Ingrese su usuario o correo" required autocomplete="username">
                        <input type="password" class="form-control" v-model="loginData.password" placeholder="Contraseña" required  @keyup.enter="loginFuncionario" autocomplete="current-password">
                    </form>
                </div>
                <div class="card__footer--login">
                    <button type="button" class="button button--primary m-0 w-100" @click="loginFuncionario">
                        <span class="button__text">Ingresar</span>
                        <font-awesome-icon :icon="['fas', 'arrow-right']" class="button__icon" />
                    </button>
                    <router-link to='/forgotpassword' class="mt-3">¿Olvidó su contraseña?</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import resource from 'resource-axios'
const UsuarioActual = resource('/base/api/usuario_actual', axios)

export default {
    name: 'Login',
    components: {
    },
    data () {
        return {
            loginError: false,
            loginData: { username: null, password: null }
        }
    },
    mounted () {
        localStorage.removeItem('jwt.access')
        localStorage.removeItem('jwt.refresh')
    },

    methods: {
        loginFuncionario (e) {
            e.preventDefault()
            var url = '/api/token/'
            this.loginError = false
            axios({
                method: 'post',
                url: url,
                data: {
                    username: this.loginData.username,
                    password: this.loginData.password
                }
            })
                .then((response) => {
                    localStorage.setItem('jwt.access', response.data.access)
                    localStorage.setItem('jwt.refresh', response.data.refresh)
                    UsuarioActual.query({}).then((res) => {
                        if (res.data[0].rol === 'Chofer')
                            this.$router.push(`/ordenes/chofer/${res.data[0].id}`)
                        else
                            this.$router.push('/')
                    }).catch(function (error) {
                        console.log(error)
                    })
                    // location.reload()
                })
                .catch((response) => {
                    this.loginError = true
                    localStorage.removeItem('jwt.access')
                    localStorage.removeItem('jwt.refresh')
                })
        }

    }
}
</script>
