<template>
    <div>
        <b-modal :id="modal" size="lg" :title="title" no-close-on-esc no-close-on-backdrop hide-header-close>
            <div class="row">
                <div class="col-md-6">
                    <label>Código * <b-badge show variant="danger" v-show="error && error.codigo">Código no puede estar vacío</b-badge></label>
                    <input type="text" name="" class="form-control" v-model="formArticulo.codigo" :disabled="cotizacion" v-touppercase>
                 </div>
                <div class="col-md-6">
                    <label>Descripción * <b-badge show variant="danger" v-show="error && error.codigo">Descripción no puede estar vacía</b-badge></label>
                    <textarea class="form-control" v-model="formArticulo.descripcion" v-touppercase maxlength="48"></textarea>
                </div>
                <div class="col-md-6">
                    <label>Tipo de Artículo * <b-badge show variant="danger" v-show="error && error.codigo">Tipo de Artículo no puede estar vacío</b-badge></label>
                    <v-select :options="tipos" v-model="formArticulo.tipo" label="nombre" :reduce="nombre => nombre.id"></v-select>
                </div>
                <div class="col-md-6">
                    <label>Unidad de Medida</label>
                    <v-select :options="unidades" v-model="formArticulo.unidad" label="abreviatura" :reduce="abreviatura => abreviatura.id"></v-select>
                </div>
                <div class="col-md-6">
                    <label>Moneda *</label>
                    <v-select :options="monedas" v-model="formArticulo.moneda"></v-select>
                </div>
                <div class="col-md-6" v-if="!cotizacion">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" v-model="formArticulo.variable" @click="formArticulo.variable=!formArticulo.variable">
                        <label class="form-check-label">
                            ¿Artículo SIN precio?*
                        </label>
                    </div>
                </div>
<!--                 <div class="form-group col-md-6">
                    <label>Inventario Disponible</label>
                    <input type="number" min="0" name="" class="form-control" v-model="formArticulo.stock_disponible" max="2147483647">
                </div>   -->
                <div class="col-md-6" v-if="cotizacion">
                    <label>Cantidad *</label>
                    <input type="number" min="1" name="" class="form-control" v-model="formDetalle.cantidad">
                </div>     
                <div class="col-md-12 btn-group" v-if="!cotizacion">
                    <div class="col-md-6" v-if="formArticulo.precio && (formArticulo.precio.length === 0 && cotizacion) || !cotizacion">
                        <a class="button--sm button--primary float-right" @click="addPrecio()" style="cursor: pointer;">
                            Añadir precio
                            <font-awesome-icon class="button__icon--sm" :icon="['fas','plus']"/>
                        </a>
                    </div>
                    <div class="col-md-6" v-if="formArticulo.costos && (formArticulo.costos.length === 0 && cotizacion) || !cotizacion">
                        <a class="button--sm button--primary float-right" @click="addCostos()" style="cursor: pointer;">
                            Añadir costos
                            <font-awesome-icon class="button__icon--sm" :icon="['fas','plus']"/>
                        </a>
                    </div>
                </div>
                <div class="col-md-12" v-if="!cotizacion">
                    <span v-if="formArticulo.precio && formArticulo.precio.length > 0">
                        <label>Precios</label>
                        <div class="row  align-items-end" v-for="(precio, index) in formArticulo.precio" v-bind:key="precio.fechaactualizacion">
                            <div class="col-md-2">
                                <label><small>Fecha de Ingreso</small></label>
                                <input class="form-control" disabled type="text" v-model="precio.fechaactualizacion"/>
                            </div>
                            <div class="col-md-3">
                                <label>
                                    <small v-if="empresaNombre==='tagle' || empresaNombre==='tagle'">Precio lista</small>
                                    <small v-if="empresaNombre==='dripsa' || empresaNombre==='dripsa'">Precio mínimo</small>
                                </label>
                                <vue-numeric currency="$" class="form-control" separator="." v-model="precio.precio" v-bind="{ integerPlaces: 2, decimalPlaces: 2, suffix: true }" v-bind:precision="3"></vue-numeric>
                            </div>
                            <div class="col-md-3" v-if="empresaNombre === 'tagle'">
                                <label><small>Dcto. Agrícola tope</small></label>
                                <percent-input class="form-control" v-bind="{ integerPlaces: 2, decimalPlaces: 2, suffix: true }" v-model="precio.dcto_agricola"></percent-input>
                            </div>
                            <div class="col-md-3" v-if="empresaNombre === 'tagle'">
                                <label><small>Dcto. Distribuidor tope</small></label>
                                <percent-input class="form-control" v-bind="{ integerPlaces: 2, decimalPlaces: 2, suffix: true }" v-model="precio.dcto_distribuidor"></percent-input>
                            </div>
                            <div class="col-md-1">
                                <a @click="removePrecio(index)" role="button" class="button button--danger">
                                    <font-awesome-icon :icon="['fas','trash-alt']"/>
                                </a>
                            </div>
                        </div>
                    </span>
                </div>
                
                <div class="col-md-12" v-if="!cotizacion">
                    <span v-if="formArticulo.costos && formArticulo.costos.length > 0">
                        <label>Costos</label>
                        <div class="row  align-items-end" v-for="(costo, index) in formArticulo.costos" v-bind:key="costo.fechaactualizacion">
                            <div class="col-md-3">
                                <label><small>Fecha de Ingreso</small></label>
                                <input class="form-control" disabled type="text" v-model="costo.fechaactualizacion"/>
                            </div>
                            <div class="col-md-3">
                                <label><small>Costo</small></label>
                                <vue-numeric currency="$" class="form-control" separator="." v-model="costo.costo" v-bind="{ integerPlaces: 2, decimalPlaces: 2, suffix: true }" v-bind:precision="3"></vue-numeric>
                            </div>
                            <div class="col-md-1">
                                <a @click="removeCostos(index)" role="button" class="button button--danger">
                                    <font-awesome-icon :icon="['fas','trash-alt']"/>
                                </a>
                            </div>
                        </div>
                    </span>
                </div>
                <div class="col-md-12" v-if="cotizacion && empresaNombre==='tagle'">
                    <div class="row">
                        <!-- <div class="col-md-12">
                            <input class="mr-2" type="radio" id="precio" :value="true" v-model="precioDescuento" />
                            <label for="precio">Precio</label>
                            <input class="ml-5 mr-2" type="radio" id="Descuento" :value="false" v-model="precioDescuento" />
                            <label for="Descuento">Descuento</label>
                        </div> -->
                        <div class="col-md-6">
                            <label><small>Costo</small></label>
                            <vue-numeric currency="$" class="form-control" separator="." v-model="formDetalle.costos" v-bind:precision="3"></vue-numeric>
                        </div>
                        <div class="col-md-6">
                            <label>
                                Precio lista: 
                                <vue-numeric currency="$" disabled separator="." v-model="formDetalle.precio_lista" v-bind:precision="3"></vue-numeric> 
                                <!-- <small>
                                    Precio lista: 
                                <vue-numeric currency="$" disabled separator="." v-model="formDetalle.precio_lista" v-bind:precision="3"></vue-numeric> 
                                    Dcto. Tope: 15%
                                <input v-model="formDetalle.descuento_tope" disabled hidden> -->
                                <!-- <a v-if="formDetalle.descuento && formDetalle.descuento > 0" @click="discountUp=!discountUp" class="btn btn-primary btn-sm">
                                    <font-awesome-icon :icon="['fas', 'chevron-up']" v-if="!discountUp"/>
                                    <font-awesome-icon :icon="['fas', 'chevron-down']" v-if="discountUp"/>
                                </a> -->
                            <!-- </small> -->
                            </label>
                            <!-- <percent-input :key="keyPercent" v-model="descuento" class="form-control" v-bind="{ integerPlaces: 2, decimalPlaces: 2, suffix: true }" v-if="!precioDescuento"></percent-input> -->
                            <!-- <percent-input class="form-control" v-bind="{ integerPlaces: 2, decimalPlaces: 2, suffix: true }" v-model="formDetalle.descuento"></percent-input> -->
                            <!-- <small v-if="!precioDescuento">
                                <vue-numeric currency="$" disabled separator="." v-model="formDetalle.precio" v-bind:precision="3"></vue-numeric> 
                            </small> -->
                            <!-- <vue-numeric currency="$" separator="." v-model="precio" size="8rem" v-if="precioDescuento" v-bind:precision="3" class="form-control"></vue-numeric>
                            <percent-input :key="keyDescuento" v-model="descuento" disabled v-bind="{ integerPlaces: 2, decimalPlaces: 2, suffix: true }" v-if="precioDescuento"></percent-input>
                            <div class="alert alert--danger" role="alert" v-if="error">
                                <font-awesome-icon :icon="['fas', 'exclamation-circle']" class="alert__icon" />
                                <span class="alert__text">{{error}}</span>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <template #modal-footer="{ }">
                <button class="button button--secondary" @click="closeModal();$emit('accept');">
                    <span class="button__text">Cancelar</span>
                    <font-awesome-icon :icon="['fas', 'times-circle']" class="button__icon" />
                </button>
                <button class="button button--success" v-if="!edit" @click="createArticulo()">
                    <span class="button__text">Guardar</span>
                    <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
                </button>
                <button class="button button--success" v-if="cotizacion" @click="createArticulo(true)">
                    <span class="button__text">Guardar y agregar documento de respaldo</span>
                    <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
                </button>
                <button class="button button--success" v-if="edit" @click="updateArticulo()">
                    <span class="button__text">Guardar</span>
                    <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
                </button>
            </template>
        </b-modal>
    </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
const UnidadMedida = resource('/base/api/listar_unidad_medida', axios)
const TipoArticulo = resource('/base/api/listar_tipo_articulo', axios)
const Articulo = resource('/cotizacion/api/gestor_articulos/', {
    update: (id, params) => axios.put(`/cotizacion/api/gestor_articulos/${id}/`, { params })
}, axios)
const DetallePresupuesto = resource('/cotizacion/api/gestor_detalle_presupuestos/', axios)

export default {
    name: 'ModalArticulo',
    props: ['modal', 'articulo', 'edit', 'title', 'cotizacion', 'presupuestoId', 'cliente'],
    components: {
    },
    data () {
        return {
            monedas: ['CLP', 'USD', 'EUR'],
            tipos: [],
            unidades: [],
            formArticulo: {
                codigo: null,
                descripcion: null,
                tipo: null,
                unidad: null,
                moneda: 'CLP',
                costos: [],
                precio: []
            },
            dataReturned: null,
            error: null,
            formDetalle: {},
            empresaId: this.$route.params.empresaId,
            empresaNombre: this.$route.params.empresaNombre,
            // discountUp: false,
            precioDescuento: false,
            keyPercent: 0,
            keyDescuento: 0,
            descuento: null,
            precio: null
        }
    },
    methods: {
        openModalCreate () {
            var cod = null
            this.formArticulo = {
                codigo: null,
                descripcion: null,
                tipo: null,
                unidad: null,
                moneda: 'CLP',
                costos: [],
                precio: []
            }
            if (this.cotizacion) {
                let codCotizacion = ('0000'+this.$parent.cotizacionId).slice(-5)
                cod = `00000-${codCotizacion}`
                if (this.empresaNombre === 'tagle')
                    this.formDetalle.descuento_tope = 15
            }
            this.formArticulo.codigo = cod
            this.formArticulo.precio = []
            this.formArticulo.costos = []
            this.$root.$emit('bv::show::modal', 'modal-create-articulo')
        },
        openModalEdit () {
            if (this.articulo) {
                this.formArticulo = this.articulo
                this.formArticulo.precio.forEach((item)=>{
                    item.precio = parseFloat(item.precio)
                })
                this.formArticulo.costos.forEach((item)=>{
                    item.costo = parseFloat(item.costo)
                })
            }
            this.$root.$emit('bv::show::modal', 'modal-edit-articulo')
        },
        closeModal () {
            this.$root.$emit('bv::hide::modal', this.modal)
        },
        addPrecio () {
            var today = new Date()
            var month = today.getMonth() + 1
            var date = today.getDate() + '/' + month + '/' + today.getFullYear()
            var preciodict = {'fechaactualizacion': date,'precio': 0, 'dcto_agricola': 0, 'dcto_distribuidor': 0 }

            if (!this.formArticulo.precio) {
                this.formArticulo.precio = []
                this.formArticulo.precio.push(preciodict)
            } else {
                this.formArticulo.precio.push(preciodict)
            }
        },
        removePrecio (index) {
            this.formArticulo.precio.splice(index, 1)
        },
        addCostos () {
            var today = new Date()
            var month = today.getMonth() + 1
            var date = today.getDate() + '/' + month + '/' + today.getFullYear()
            var costodict = {'fechaactualizacion': date,'costo': 0}

            if (!this.formArticulo.costos) {
                this.formArticulo.costos = []
                this.formArticulo.costos.push(costodict)
            } else {
                this.formArticulo.costos.push(costodict)
            }
        },
        formatPrecioCosto () {
            var today = new Date()
            var month = today.getMonth() + 1
            var date = today.getDate() + '/' + month + '/' + today.getFullYear()
            var preciodict = {'fechaactualizacion': date,'precio': this.formDetalle.precio, 'dcto_agricola': this.formDetalle.descuento_tope, 'dcto_distribuidor': this.formDetalle.descuento_tope }
            var costodict = {'fechaactualizacion': date,'costo':this.formDetalle.costos}

            this.formArticulo.precio.push(preciodict)
            this.formArticulo.costos.push(costodict)
        },
        removeCostos (index) {
            this.formArticulo.costos.splice(index, 1)
        },
        createArticulo (doc) {
            this.error = null
            this.formArticulo.empresa = parseInt(this.empresaId)
            if (this.cotizacion) {
                this.formArticulo.variable = true
                this.formArticulo.por_aprobar = true
                this.formArticulo.manual = true
                this.formArticulo.codigo = `${this.formArticulo.codigo} ${this.formArticulo.descripcion}`
            }
            Articulo.save(this.formArticulo).then((res) => {
                if (this.cotizacion) {
                    this.formDetalle.articulo = res.data['id']
                    this.formDetalle.presupuesto = this.presupuestoId
                    this.formDetalle.moneda = this.formArticulo.moneda
                    if (res.data['precio'].length > 0)
                        this.formDetalle.precio = res.data['precio'][0]['precio']
                    if (res.data['costos'].length > 0)
                        this.formDetalle.costos = res.data['costos'][0]['costo']
                    this.createDetallePresupuesto()
                }
                else {
                    this.$emit('accept')
                }
                if (doc) {
                    this.dataReturned = res.data
                    this.returnData()
                }
                this.formArticulo = {
                    codigo: null,
                    descripcion: null,
                    tipo: null,
                    unidad: null,
                    moneda: 'CLP',
                    precio: []
                }
                this.closeModal()
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Artículo creado con exito'
                })
            })
                .catch((error) => {
                    console.log(error)
                    this.error = error.response.data
                    this.$emit('accept')
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al crear el artículo'
                    })
                })
        },
        returnData (event) {
            this.$emit('doc', this.dataReturned)
        },
        updateArticulo (articulo) {
            this.error = null
            this.formArticulo.empresa = parseInt(this.empresaId)
            if (this.formArticulo.manual && !this.formArticulo.codigo.includes('00000-')){
                this.formArticulo.manual = false
            }
            // this.formatPrecioCosto()
            Articulo.update(this.formArticulo.id, this.formArticulo).then((res) => {
                this.$emit('accept')
                this.closeModal()
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Artículo editado con exito'
                })
            })
                .catch((error) => {
                    this.error = error.response.data
                    this.$emit('accept')
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al editar el artículo'
                    })
                })

        },
        createDetallePresupuesto () {
            if (this.formArticulo.moneda !== 'CLP') {
                try {
                    this.formDetalle.precio = this.formDetalle.precio.toFixed(3)
                }
                catch (error) {
                    this.formDetalle.precio = this.formDetalle.precio
                }
                try {
                    this.formDetalle.precio_lista = this.formDetalle.precio_lista.toFixed(3)
                }
                catch (error) {
                    this.formDetalle.precio_lista = this.formDetalle.precio_lista
                }
                // this.formDetalle.precio = this.formDetalle.precio.toFixed(3)
                // this.formDetalle.precio_lista = this.formDetalle.precio_lista.toFixed(3)
                this.formDetalle.costos = this.formDetalle.costos.toFixed(3) 
            }
            else {
                this.formDetalle.precio = Math.round(this.formDetalle.precio)
                this.formDetalle.precio_lista = Math.round(this.formDetalle.precio_lista)
                this.formDetalle.costos = Math.round(this.formDetalle.costos)
            }
            if (!this.formDetalle.datos) {
                this.formDetalle.datos = {}
                this.formDetalle.datos.manual = true
            }
            DetallePresupuesto.save(this.formDetalle).then((res) => {
                this.formDetalle = {}
                this.$emit('accept')
                this.$parent.$emit('accept')
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Detalle creado con exito'
                })
            })
                .catch((error) => {
                    this.formDetalle = {}
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al crear el detalle'
                    })
                })
        },
        forceRerenderPercent() {
            this.keyPercent += 1;
        },
        forceRerenderDescuento() {
            this.keyDescuento += 1;
        }
    },
    watch: {
        descuento () {
            this.formDetalle.descuento = this.descuento
            if (this.formDetalle.descuento_tope < this.formDetalle.descuento)
                this.error = 'ATENCIÓN: el descuento es mayor al tope y necesita aprobación.'
            else this.error = null
            var precioCalculado = this.formDetalle.precio_lista - (this.formDetalle.precio_lista * this.formDetalle.descuento/100)
            if (!this.precio) {
                if (this.formArticulo.moneda !== 'CLP')
                    this.formDetalle.precio = precioCalculado.toFixed(3)
                else
                    this.formDetalle.precio = Math.round(precioCalculado)
                if (this.formDetalle.descuento === 0)
                    this.formDetalle.precio = this.formDetalle.precio_lista
            }
        },
        precio () {
            this.formDetalle.precio = this.precio
            var precio_lista = this.formDetalle.precio_lista
            var dctoCalculado = Math.round((100 - ((100*this.formDetalle.precio)/precio_lista)))
            this.descuento = dctoCalculado
            this.forceRerenderDescuento()
        },
        precioDescuento () {
            this.error = null
            this.formDetalle.precio = null
            this.precio = null
            this.formDetalle.descuento = null
            this.descuento = null
            this.forceRerenderDescuento()
            this.forceRerenderPercent()
        }
    },
    mounted () {
        TipoArticulo.query({}).then((res) => {
            this.tipos = res.data
        })
        UnidadMedida.query({}).then((res) => {
            this.unidades = res.data
        })
        this.$watch('formDetalle.costos', (newLocale, oldLocale) => {
            // this.discountUp = false
            if (newLocale !== oldLocale && newLocale !== undefined && newLocale !== null && this.formDetalle && this.empresaNombre === 'tagle') {
                if (this.formArticulo.moneda !== 'CLP') {
                    this.formDetalle.precio_lista = (this.formDetalle.costos/0.7225).toFixed(3)
                }
                else {
                    this.formDetalle.precio_lista = Math.round(this.formDetalle.costos/0.7225)
                }
                // this.formDetalle.precio_lista = this.formDetalle.costos/0.7225
                if (this.formDetalle.descuento === 0)
                    this.formDetalle.precio = this.formDetalle.precio_lista
            }
        }, { immediate: true })
        // this.$watch('formDetalle.descuento', (newLocale, oldLocale) => {
        //     this.discountUp = false
        //     if (newLocale !== oldLocale && newLocale !== undefined && newLocale !== null && this.formDetalle && this.empresaNombre === 'tagle') {
        //         if (this.formDetalle.descuento_tope < this.formDetalle.descuento)
        //             this.error = 'ATENCIÓN: el descuento es mayor al tope y necesita aprobación.'
        //         else this.error = null

        //         if (this.error)
        //             this.formDetalle.por_aprobar = true
        //         // this.formDetalle.precio = this.formDetalle.precio_lista - (this.formDetalle.precio_lista * this.formDetalle.descuento/100)
        //         let precioCalculado = this.formDetalle.precio_lista - (this.formDetalle.precio_lista * this.formDetalle.descuento/100)
        //         if (this.formArticulo.moneda !== 'CLP')
        //             this.formDetalle.precio = precioCalculado.toFixed(3)
        //         else
        //             this.formDetalle.precio = Math.round(precioCalculado)

        //         if (this.formDetalle.descuento === 0)
        //             this.formDetalle.precio = this.formDetalle.precio_lista
        //     }
        // }, { immediate: true })
        // this.$watch('discountUp', (newLocale, oldLocale) => {
        //     if (newLocale !== oldLocale && newLocale !== undefined && newLocale !== null && oldLocale !== undefined) {
        //         if (newLocale) {
        //             this.formDetalle.precio = parseFloat(this.formDetalle.precio_lista) + (parseFloat(this.formDetalle.precio_lista) * this.formDetalle.descuento/100)
        //         } else {
        //             this.formDetalle.precio = parseFloat(this.formDetalle.precio_lista) - (parseFloat(this.formDetalle.precio_lista) * this.formDetalle.descuento/100)
        //         }
        //     }
        // }, { immediate: true })
    }
}
</script>
