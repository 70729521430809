<template>
    <div>
        <b-modal id="modal-vaciar-camion" size="lg" :title="`Vaciar camiones para el día ${this.date}`" no-close-on-esc no-close-on-backdrop hide-header-close>
        <div class="row">
            <div class="form-group col-md-12">
            <div class="alert alert--danger" role="alert" >
                <font-awesome-icon :icon="['fas', 'exclamation-circle']" class="alert__icon" />
                <span class="alert__text">
                    Solo se descartarán las ordenes que no se encuentren despachadas
                </span>
            </div>
            <label>Camiones</label>
                <multiselect class="m-0 mb-3" v-model="camionesSeleccionados" :options="camiones" :multiple="true" :close-on-select="true" :clear-on-select="true"
                :preserve-search="true" placeholder="Elige los camiones a vaciar" v-show="camiones.length > 0" label="patente" track-by="patente">
                <template slot="tag" slot-scope="{ option, remove }">
                <span class="multiselect__tag">
                    <span class="mr-2">{{ option.patente }}</span>
                    <span class="custom__remove" @click="remove(option)">
                    <font-awesome-icon :icon="['fa', 'minus-circle']" />
                    </span>
                </span>
                </template>
                </multiselect>
            </div>
        </div>
        <template #modal-footer>
            <button class="button button--secondary" @click="closeModal()">
                <span class="button__text">Cancelar</span>
                <font-awesome-icon :icon="['fas', 'times-circle']" class="button__icon" />
            </button>
            <button class="button button--success"  @click="moverOrdenes();" v-if="camionesSeleccionados.length > 0">
                <span class="button__text">Vaciar</span>
                <font-awesome-icon :icon="['fas', 'undo']" class="button__icon" />
            </button>
        </template>
        </b-modal>
    </div>
</template>
<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'

export default {
    name: 'ModalVaciarCamion',
    props: ['date', 'camiones'],
    components: { Multiselect },
    data () {
        return {
            camionesSeleccionados: []
        }
    },
    methods: {
        openModal () {
            this.$root.$emit('bv::show::modal', 'modal-vaciar-camion')
        },
        closeModal () {
            this.$emit('close')
            this.$root.$emit('bv::hide::modal', 'modal-vaciar-camion')
        },
        moverOrdenes () {
            let camiones = this.camionesSeleccionados.map(item => { return item.id })
            axios.put('/logistica/api/ordenes_venta/vaciar_camion/', { params: {camiones: camiones, fecha: this.date }}).then(res => {
                this.$notify({ group: 'foo', type: 'success', title: 'Camión vaciado con éxito'})
                this.$emit('accept')
                this.closeModal()
            })
                .catch((error) => {
                    this.$notify({ group: 'foo', type: 'error', title: 'Ups, ocurrió un error'})
                    this.closeModal()
                })
        }
    },
    mounted () {
    }
}
</script>
