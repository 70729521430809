<template>
  <div>
    <h4 class="card__subtitle">DATOS DE DIRECCIÓN</h4>
    <div class="row">
        <div class="col-md-6">
            <label>ID Embarque</label>
            <input type="text" name="" class="form-control" v-model="id_embarque" disabled/>
        </div>
        <div class="col-md-6">
            <label>Tipo de Dirección</label>
            <v-select :options="tipos" v-model="tipo"></v-select>
        </div>
        <div class="col-md-6">
            <label>Nombre Sucursal</label>
            <input type="text" name="" class="form-control" v-model="sucursal" maxlength="36"/>
        </div>
        <div class="col-md-6">
            <label>Dirección*</label>
            <textarea class="form-control" v-model="direccion" maxlength="36"></textarea>
        </div>
        <div class="col-md-6">
            <label>Ciudad*</label>
            <input type="text" class="form-control" v-model="ciudad" maxlength="20">
        </div>
        <div class="col-md-6">
            <label>Región*</label>
            <v-select :options="regiones" v-model="region"></v-select>
        </div>
        <div class="col-md-6" v-if="region">
            <label>Comuna*</label>
            <v-select :options="comunas" v-model="comuna"></v-select>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <label>Latitud* (UTILIZAR DIGITOS ÚNICAMENTE, SIN GUIÓN, SIN PUNTOS)</label>
            <input type="text" name="" class="form-control" v-model="lat" @keypress="isNumber($event)"/>
        </div>
        <div class="col-md-6">
            <label>Longitud* (UTILIZAR DIGITOS ÚNICAMENTE, SIN GUIÓN, SIN PUNTOS)</label>
            <input type="text" name="" class="form-control" v-model="long" @keypress="isNumber($event)"/>
        </div>
        <div class="col-md-12" v-if="lat && long">
            <a :href="`https://www.google.com/maps/place/${lat},${long}`" target="_blank">Ver Mapa</a>
        </div>
    </div>
    <br>
    <div class="small alert alert--danger" role="alert" v-if="error">
        <font-awesome-icon :icon="['fas', 'exclamation-circle']" class="alert__icon" />
        <span class="small alert__text">{{ error }}</span>
    </div>
    <h4 class="card__subtitle">CONTACTO DE ENTREGA</h4>
    <div class="row">
        <div class="col-md-4">
          <label>Nombre*</label>
          <input type="text" name="" class="form-control" v-model="nombre_contacto" maxlength="20"/>
        </div>
        <div class="col-md-4">
          <label>Teléfono*</label>
          <input type="text" name="" class="form-control" v-model="telefono" maxlength="20"/>
        </div>
        <div class="col-md-4">
          <label>Correo electrónico*</label>
          <input type="email" name="" class="form-control" v-model="email" maxlength="50" @blur="validateEmail()"/>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
          <button class="button button--success float-right" @click="addDireccion()" :disabled="validate">
            <span class="button__text">Guardar</span>
            <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
          </button>
        </div>
    </div>
  </div>
</template>

<script>

export default {
    name: 'DireccionDespacho',
    components: {
    },
    props: ['countDireccion', 'cliente'],
    data () {
        return {
            comunas: [],
            regionesComunas: 
                [
                    {
                        "region": "Arica y Parinacota",
                        "comunas": ["Arica", "Camarones", "Putre", "General Lagos"]
                    },
                    {
                        "region": "Tarapacá",
                        "comunas": ["Iquique", "Alto Hospicio", "Pozo Almonte", "Camiña", "Colchane", "Huara", "Pica"]
                    },
                    {
                        "region": "Antofagasta",
                        "comunas": ["Antofagasta", "Mejillones", "Sierra Gorda", "Taltal", "Calama", "Ollagüe", "San Pedro de Atacama", "Tocopilla", "María Elena"]
                    },
                    {
                        "region": "Atacama",
                        "comunas": ["Copiapó", "Caldera", "Tierra Amarilla", "Chañaral", "Diego de Almagro", "Vallenar", "Alto del Carmen", "Freirina", "Huasco"]
                    },
                    {
                        "region": "Coquimbo",
                        "comunas": ["La Serena", "Coquimbo", "Andacollo", "La Higuera", "Paiguano", "Vicuña", "Illapel", "Canela", "Los Vilos", "Salamanca", "Ovalle", "Combarbalá", "Monte Patria", "Punitaqui", "Río Hurtado"]
                    },
                    {
                        "region": "Valparaíso",
                        "comunas": ["Valparaíso", "Casablanca", "Concón", "Juan Fernández", "Puchuncaví", "Quintero", "Viña del Mar", "Isla de Pascua", "Los Andes", "Calle Larga", "Rinconada", "San Esteban", "La Ligua", "Cabildo", "Papudo", "Petorca", "Zapallar", "Quillota", "Calera", "Hijuelas", "La Cruz", "Nogales", "San Antonio", "Algarrobo", "Cartagena", "El Quisco", "El Tabo", "Santo Domingo", "San Felipe", "Catemu", "Llaillay", "Panquehue", "Putaendo", "Santa María", "Quilpué", "Limache", "Olmué", "Villa Alemana"]
                    },
                    {
                        "region": "Región del Libertador Gral. Bernardo O’Higgins",
                        "comunas": ["Rancagua", "Codegua", "Coinco", "Coltauco", "Doñihue", "Graneros", "Las Cabras", "Machalí", "Malloa", "Mostazal", "Olivar", "Peumo", "Pichidegua", "Quinta de Tilcoco", "Rengo", "Requínoa", "San Vicente", "Pichilemu", "La Estrella", "Litueche", "Marchihue", "Navidad", "Paredones", "San Fernando", "Chépica", "Chimbarongo", "Lolol", "Nancagua", "Palmilla", "Peralillo", "Placilla", "Pumanque", "Santa Cruz"]
                    },
                    {
                        "region": "Región del Maule",
                        "comunas": ["Talca", "Constitución", "Curepto", "Empedrado", "Maule", "Pelarco", "Pencahue", "Río Claro", "San Clemente", "San Rafael", "Cauquenes", "Chanco", "Pelluhue", "Curicó", "Hualañé", "Licantén", "Molina", "Rauco", "Romeral", "Sagrada Familia", "Teno", "Vichuquén", "Linares", "Colbún", "Longaví", "Parral", "Retiro", "San Javier", "Villa Alegre", "Yerbas Buenas"]
                    },
                    {
                        "region": "Región del Biobío",
                        "comunas": ["Concepción", "Coronel", "Chiguayante", "Florida", "Hualqui", "Lota", "Penco", "San Pedro de la Paz", "Santa Juana", "Talcahuano", "Tomé", "Hualpén", "Lebu", "Arauco", "Cañete", "Contulmo", "Curanilahue", "Los Álamos", "Tirúa", "Los Ángeles", "Antuco", "Cabrero", "Laja", "Mulchén", "Nacimiento", "Negrete", "Quilaco", "Quilleco", "San Rosendo", "Santa Bárbara", "Tucapel", "Yumbel", "Alto Biobío", "Chillán", "Bulnes", "Cobquecura", "Coelemu", "Coihueco", "Chillán Viejo", "El Carmen", "Ninhue", "Ñiquén", "Pemuco", "Pinto", "Portezuelo", "Quillón", "Quirihue", "Ránquil", "San Carlos", "San Fabián", "San Ignacio", "San Nicolás", "Treguaco", "Yungay"]
                    },
                    {
                        "region": "Región de la Araucanía",
                        "comunas": ["Temuco", "Carahue", "Cunco", "Curarrehue", "Freire", "Galvarino", "Gorbea", "Lautaro", "Loncoche", "Melipeuco", "Nueva Imperial", "Padre las Casas", "Perquenco", "Pitrufquén", "Pucón", "Saavedra", "Teodoro Schmidt", "Toltén", "Vilcún", "Villarrica", "Cholchol", "Angol", "Collipulli", "Curacautín", "Ercilla", "Lonquimay", "Los Sauces", "Lumaco", "Purén", "Renaico", "Traiguén", "Victoria", ]
                    },
                    {
                        "region": "Región de Los Ríos",
                        "comunas": ["Valdivia", "Corral", "Lanco", "Los Lagos", "Máfil", "Mariquina", "Paillaco", "Panguipulli", "La Unión", "Futrono", "Lago Ranco", "Río Bueno"]
                    },
                    {
                        "region": "Región de Los Lagos",
                        "comunas": ["Puerto Montt", "Calbuco", "Cochamó", "Fresia", "Frutillar", "Los Muermos", "Llanquihue", "Maullín", "Puerto Varas", "Castro", "Ancud", "Chonchi", "Curaco de Vélez", "Dalcahue", "Puqueldón", "Queilén", "Quellón", "Quemchi", "Quinchao", "Osorno", "Puerto Octay", "Purranque", "Puyehue", "Río Negro", "San Juan de la Costa", "San Pablo", "Chaitén", "Futaleufú", "Hualaihué", "Palena"]
                    },
                    {
                        "region": "Región Aysén del Gral. Carlos Ibáñez del Campo",
                        "comunas": ["Coyhaique", "Lago Verde", "Aysén", "Cisnes", "Guaitecas", "Cochrane", "O’Higgins", "Tortel", "Chile Chico", "Río Ibáñez"]
                    },
                    {
                        "region": "Región de Magallanes y de la Antártica Chilena",
                        "comunas": ["Punta Arenas", "Laguna Blanca", "Río Verde", "San Gregorio", "Cabo de Hornos (Ex Navarino)", "Antártica", "Porvenir", "Primavera", "Timaukel", "Natales", "Torres del Paine"]
                    },
                    {
                        "region": "Región Metropolitana de Santiago",
                        "comunas": ["Cerrillos", "Cerro Navia", "Conchalí", "El Bosque", "Estación Central", "Huechuraba", "Independencia", "La Cisterna", "La Florida", "La Granja", "La Pintana", "La Reina", "Las Condes", "Lo Barnechea", "Lo Espejo", "Lo Prado", "Macul", "Maipú", "Ñuñoa", "Pedro Aguirre Cerda", "Peñalolén", "Providencia", "Pudahuel", "Quilicura", "Quinta Normal", "Recoleta", "Renca", "San Joaquín", "San Miguel", "San Ramón", "Vitacura", "Puente Alto", "Pirque", "San José de Maipo", "Colina", "Lampa", "Tiltil", "San Bernardo", "Buin", "Calera de Tango", "Paine", "Melipilla", "Alhué", "Curacaví", "María Pinto", "San Pedro", "Talagante", "El Monte", "Isla de Maipo", "Padre Hurtado", "Peñaflor"]
                    }
                ],
            region: null,
            comuna: null,
            ciudad: null,
            direccion: null,
            nombre_contacto: null,
            telefono: null,
            email: null,
            id_embarque: this.cliente + String.fromCharCode(this.countDireccion + 65),
            sucursal: null,
            tipo: 'DESPACHO',
            tipos: ['FACTURACIÓN', 'DESPACHO'],
            valuelat: "",
            valuelong: "",
            error: null,
            validate: false
        }
    },
    methods: {
        getComunas () {
            if (this.region) {
                var region = this.regionesComunas.filter(item => item.region === this.region)
                this.comunas = region.map(({ comunas }) => comunas)[0]
            }
        },
        addDireccion (event) {
            if (!this.direccion || !this.ciudad || !this.region ||
            !this.comuna || !this.lat || !this.long || !this.telefono || 
            !this.email || !this.nombre_contacto) {
                this.error = 'Complete los campos obligatorios'
            } else {
                var formCliente = {}
                formCliente.region = this.region
                formCliente.comuna = this.comuna
                formCliente.ciudad = this.ciudad
                formCliente.direccion = this.direccion
                formCliente.lat = this.lat
                formCliente.long = this.long
                formCliente.nombre_contacto = this.nombre_contacto
                formCliente.telefono = this.telefono
                formCliente.email = this.email
                formCliente.id_embarque = this.id_embarque
                formCliente.tipo = this.tipo
                formCliente.sucursal = this.sucursal
                formCliente.qad = {'ingresado': false, 'descargado': false}
                this.error = null
                this.$emit('accept', formCliente)
            }
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },
        validateEmail() {
            if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
                this.error = 'Formato de correo electrónico incorrecto'
                this.validate = true
            } else {
                this.error = null
                this.validate = false
            }
        }
    },
    computed: {
        regiones () {
            if (this.regionesComunas)
                return this.regionesComunas.map(({ region }) => region)
            return []
        },
        lat: {
            get: function() {
                return this.valuelat;
            },
            set: function(newValue) {
                if (newValue.length >= 3) {
                    newValue = newValue.replace("-", "");
                    newValue = newValue.replace(".", "");
                    newValue = newValue.replace(",", "");
                    newValue = newValue.replace(" ", "");
                    this.valuelat =
                        "-" +
                        newValue.substr(0, 2) +
                        "." +
                        newValue.substr(2, 4)
                } else {
                    this.valuelat = newValue;
                }
            }
        },
        long: {
            get: function() {
                return this.valuelong;
            },
            set: function(newValue) {
                if (newValue.length >= 3) {
                    newValue = newValue.replace("-", "");
                    newValue = newValue.replace(".", "");
                    newValue = newValue.replace(",", "");
                    newValue = newValue.replace(" ", "");
                    this.valuelong =
                        "-" +
                        newValue.substr(0, 2) +
                        "." +
                        newValue.substr(2, 4)
                } else {
                    this.valuelong = newValue;
                }
            }
        }
    },
    mounted () {
        this.$watch('region', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null) {
                this.getComunas()
            }
        }, { immediate: true })
    }
}
</script>