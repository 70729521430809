<template>
  <div>
    <HeaderGeneral></HeaderGeneral>
    <modal-camion modal="modal-create-camion" :edit="false" ref="modalCreate" @accept="getCamiones()"></modal-camion>
      <modal-camion modal="modal-edit-camion" :data="modalEditData" :edit="true" ref="modalEdit" @accept="modalEditData=null;getCamiones()" v-if="modalEditData"></modal-camion>
    <div class="container-fluid">
      <div class="card">
        <div class="card__header">                                  
          <h4 class="card__title">Camiones</h4>
          <a @click="openModalCreate()" class="button button--primary">
            <span class="button__text">Crear Camión</span>
            <font-awesome-icon :icon="['fas', 'plus-circle']" class="button__icon" />
          </a>
        </div>
        <div class="card__body">
          <v-app v-if="camiones">
            <v-main>
              <v-data-table
              :headers="headers"
              :items="filteredRows"
              :items-per-page="3"
              v-bind:options.sync="pagination"
              item-key="codigo"
              class="elevation-1 table-striped"
              loading-text="Cargando Camiones"
              :footer-props="footerProps"
              mobile-breakpoint="0">
                <template v-slot:no-data>
                    No existen camiones
                </template>
                <template v-slot:header="{ props }">
                  <tr class="grey lighten-3">
                    <th v-for="header in props.headers" :key="header.text">
                      <div v-if="filters.hasOwnProperty(header.value)">
                        <v-autocomplete
                            flat
                            hide-details
                            multiple
                            attach
                            chips
                            dense
                            clearable
                            :items="columnValueList(header.value)"
                            v-model="filters[header.value]"
                            header-fixed
                            no-data-text="No hay información"
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0">
                              <span>+{{ filters[header.value].length }}</span>
                            </v-chip>
                          </template>
                        </v-autocomplete>
                      </div>
                    </th>
                  </tr>
                </template>
                <template v-slot:item.is_active="{ item }">
                  <span v-if="item.is_active">Sí</span>
                  <span v-else>No</span>
                </template>
                <template v-slot:item.accion="{ item }">
                  <div class="btn-group btn-group-sm" role="group" aria-label="Second group">
                    <button class="button--sm button--primary" @click="setDataEdit(item)" type="button" v-b-tooltip.hover title="Editar">
                      <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                    </button>
                    <router-link class="button--sm button--secondary" :to="`/ordenes/camion_detalle/${item.id}`" v-b-tooltip.hover title="Ver Ordenes">
                    <font-awesome-icon :icon="['fas', 'file']"/>
                    </router-link>
                    <a @click="activeCamion(item)" class="button--sm" :class="{'button--danger': item.is_active, 'button--success': !item.is_active}">
                      <font-awesome-icon v-if="item.is_active" :icon="['fas', 'ban']" />
                      <font-awesome-icon v-if="!item.is_active" :icon="['fas', 'circle']" />
                    </a>
                  </div>
                </template>
              </v-data-table>
            </v-main>
          </v-app>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
import HeaderGeneral from '@/components/HeaderGeneral.vue'
import ModalCamion from '@/logistica/ModalCamion.vue'

const Camion = resource('/logistica/api/camiones', {
    active: (id, params) => axios.put(`/logistica/api/camiones/${id}/active_camion/`, { params }),
}, axios)

export default {
    name: 'GestorCamiones',
    components: {
        HeaderGeneral,
        ModalCamion
    },
    data () {
        return {
            camiones: [],
            currentPage: 1,
            perPage: null,
            rows: null,
            page: null,
            pagination: {
                page: 1
            },
            footerProps: {
                'disable-items-per-page': false,
                'items-per-page-options': [10, 20, 30, -1],
                'items-per-page-text': 'Camiones por página'
            },
            filters: {
                patente: [],
                is_active: []
            },
            modalEditData: null
        }
    },
    methods: {
        getCamiones () {
            Camion.query({}).then(res => {
                this.camiones = res.data
            })
        },
        openModalCreate () {
            this.$refs.modalCreate.openModalCreate()
        },
        setDataEdit (item) {
            this.modalEditData = item
        },
        openModalEdit () {
            this.$refs.modalEdit.openModalEdit()
        },
        columnValueList (val) {
            var dict = []
            dict = this.camiones.map((d) => d[val])
            return dict.sort()
        },
        activeCamion(camion) {
            Camion.active(camion.id).then((res) => {
                this.getCamiones()
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Actualizado con éxito'
                })
            })
                .catch((error) => {
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al actualizar el artículo'
                    })
                })
        }
    },
    mounted () {
        this.getCamiones()
        this.$watch('modalEditData', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null) {
                this.openModalEdit()
            }
        }, { immediate: true })
    },
    computed: {
        headers () {
            return [
                { text: 'Patente', value: 'patente' },
                { text: 'M3', value: 'detalles.m3' },
                { text: 'KG', value: 'detalles.kg' },
                { text: 'Marca', value: 'detalles.marca' },
                { text: 'Modelo', value: 'detalles.modelo' },
                { text: '¿Está activo?', value: 'is_active' },
                { text: 'Acción', value: 'accion', sortable: false, align: 'center'}
            ]
        },    
        filteredRows () {
            if (this.camiones) {
                return this.camiones.filter((d) => {
                    return Object.keys(this.filters).every((f) => {
                        return this.filters[f].length < 1 || this.filters[f].includes(d[f])
                    })
                })
            }
            return null
        }
    }
}
</script>
