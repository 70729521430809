<template>
  <div>
    <b-modal :id="modal" size="lg" :title="title" no-close-on-esc no-close-on-backdrop hide-header-close>
      <h5 class="card__subtitle">DATOS CLIENTE</h5>
      <div class="row">
        <div class="col-md-6">
            <label>Nombre Cliente* <b-badge show variant="danger" v-show="error && error.nombre"> Nombre no puede estar vacío</b-badge></label>
            <input type="text" name="" class="form-control" v-model="formCliente.nombre">
        </div>
        <div class="col-md-3">
            <label>R.U.T.* <b-badge show variant="danger" v-if="error && error.rut">{{error.rut[0]}}</b-badge></label>
            <ValidationProvider rules="rut" v-slot="{ errors }">
                <input v-model="formCliente.rut" type="text" name="rut" class='form-control' v-rut:live>
                <div class="invalid-error" v-show="errors[0]">
                    <b-badge show variant="danger">R.U.T. inválido</b-badge>
                </div>
            </ValidationProvider>
        </div>
        <div class="col-md-3">
            <label>Razón Social</label>
            <input type="text" name="" class="form-control" v-model="formCliente.razon_social">
        </div>
        <div class="col-md-6">
            <label>Tipo de Cliente* <b-badge show variant="danger" v-show="error && error.tipo_cliente">Tipo cliente no puede estar vacío</b-badge></label>
            <v-select :options="tipo_clientes" v-model="formCliente.tipo_cliente"></v-select>
        </div>
        <div class="col-md-6">
            <label>Forma de Pago*</label>
            <v-select :options="formas" v-model="formCliente.forma_pago"></v-select>
        </div>
        <div class="col-md-6">
            <label>Teléfono</label>
            <input type="text" name="" class="form-control" v-model="formCliente.telefono">
        </div>
        <div class="col-md-6">
            <label>Correo Electrónico</label>
            <input type="email" name="" class="form-control" v-model="formCliente.email">
        </div>
      </div>
<!--             <h5><strong>DATOS DE FACTURACIÓN</strong></h5>
      <direccion-facturacion :region="formCliente.direccion_facturacion.region" :comuna="formCliente.direccion_facturacion.comuna" @accept="getRegionComuna"></direccion-facturacion>
      <div class="row">
          <div class="form-group col-md-6">
              <label>Ciudad*</label>
              <input type="text" class="form-control" v-model="formCliente.direccion_facturacion.ciudad">
          </div>
          <div class="form-group col-md-6">
              <label>Dirección*</label>
              <textarea class="form-control" v-model="formCliente.direccion_facturacion.direccion"></textarea>
          </div>
          <div class="form-group col-md-6">
              <label>Teléfono de Facturación*</label>
              <input type="text" class="form-control" v-model="formCliente.direccion_facturacion.telefono">
          </div>
          <div class="form-group col-md-6">
              <label>Correo electrónico facturación*</label>
              <input class="form-control" v-model="formCliente.direccion_facturacion.email"/>
          </div>
      </div> -->
      <template #modal-footer="{ }">
        <button class="button button--secondary" @click="closeModal();$emit('accept');">
          <span class="button__text">Cancelar</span>
          <font-awesome-icon :icon="['fas', 'times-circle']" class="button__icon" />
        </button>

        <a v-if="!edit" @click="createCliente(false)" class="button button--success">
          <span class="button__text">Guardar</span>
          <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
        </a>
        <a v-if="!edit" @click="createCliente(true);" class="button button--success">
          <span class="button__text">Guardar y Agregar Direcciones de despacho</span>
          <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
        </a>
        <a v-if="edit" @click="updateCliente()" class="button button--success">
          <span class="button__text">Guardar</span>
          <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
        </a>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
// import DireccionFacturacion from '@/components/DireccionFacturacion.vue'
import { ValidationProvider, extend } from 'vee-validate'
import { rutValidator } from 'vue-dni'

const Cliente = resource('/base/api/gestor_clientes/', {
    update: (id, params) => axios.put(`/base/api/gestor_clientes/${id}/`, { params })
}, axios)

export default {
    name: 'ModalCliente',
    props: ['modal', 'cliente', 'edit', 'title', 'cotizacion'],
    components: {
        ValidationProvider,
        // DireccionFacturacion
    },
    data () {
        return {
            tipo_clientes: ['Distribuidor', 'Agrícola'],
            formas: ['Contado Contra Factura', 'Cta Cte a 30 días',
                'Cta Cte a 45 días', 'Cta Cte a 60 días', 'Cta Cte a 90 días',
                'Cheque 30 días', 'Cheque 60 días', 'Contado Depósito'],
            formCliente: {
                numero: null,
                rut: null,
                nombre: null,
                tipo_cliente: 'Agrícola',
                razon_social: null,
                direccion: [],
                telefono: null,
                email: null,
                direccion_facturacion: {},
                forma_pago: 'Contado Contra Factura'
            },
            res: null,
            address: null,
            key: 0,
            error: null
        }
    },
    methods: {
        openModalCreate () {
            this.formCliente = {
                numero: null,
                rut: null,
                nombre: null,
                tipo_cliente: 'Agrícola',
                razon_social: null,
                direccion: [],
                telefono: null,
                email: null,
                forma_pago: 'Contado Contra Factura'
            }
            this.$root.$emit('bv::show::modal', 'modal-create-cliente')
        },
        openModalEdit () {
            if (this.cliente) this.formCliente = this.cliente
            // if (!this.formCliente.direccion_facturacion) this.formCliente.direccion_facturacion = {}
            this.$root.$emit('bv::show::modal', 'modal-edit-cliente')
        },
        closeModal () {
            this.$root.$emit('bv::hide::modal', this.modal)
        },
        createCliente (address) {
            this.error = null
            try {
                var rut = this.formCliente.rut.replace(/\./g, '').toUpperCase()
            } catch (e) {
                this.error = {}
                this.error.rut = ['R.U.T. no puede estar vacío']
                return
            }
            var empresaId = this.$parent.empresaId
            if (!empresaId)
                empresaId = this.$parent.$parent.empresaId
            this.formCliente.empresa = parseInt(empresaId)
            // var rut = this.formCliente.rut.replace(/\./g, '').toUpperCase()
            while (rut.split('-')[0].length < 8) rut = '0' + rut
            this.formCliente.rut = rut
            this.formCliente.numero = rut.split('-')[0]
            Cliente.save(this.formCliente).then((res) => {
                this.$emit('accept')
                if (this.cotizacion)
                    this.res = res.data
                if (address)
                    this.address = res.data
                this.closeModal()
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Cliente creado con exito'
                })
            })
                .catch((error) => {
                    this.error = error.response.data
                    this.$emit('accept')
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al crear el cliente'
                    })
                })
        },
        updateCliente (cliente) {
            var empresaId = this.$parent.empresaId
            if (!empresaId)
                empresaId = this.$parent.$parent.empresaId
            this.formCliente.empresa = parseInt(empresaId)
            this.error = null
            try {
                var rut = this.formCliente.rut.replace(/\./g, '').toUpperCase()
            } catch (e) {
                this.error = {}
                this.error.rut = ['R.U.T. no puede estar vacío']
                return
            }
            while (rut.split('-')[0].length < 8) rut = '0' + rut
            this.formCliente.rut = rut
            this.formCliente.numero = rut.split('-')[0]
            Cliente.update(this.formCliente.id, this.formCliente).then((res) => {
                this.$emit('accept')
                this.closeModal()
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Cliente editado con exito'
                })
            })
                .catch((error) => {
                    this.error = error.response.data
                    this.$emit('accept')
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al editar el cliente'
                    })
                })

        },
        // getRegionComuna (value) {
        //     this.formCliente.direccion_facturacion.region = value.region
        //     this.formCliente.direccion_facturacion.comuna = value.comuna
        // },
        // getDireccion (value) {
        //     this.formCliente.direccion.push(value)
        //     this.forceRerender()
        // },
        returnData (event) {
            this.$emit('confirm', this.res)
        },
        returnAddress (event) {
            this.$emit('address', this.address)
        },
        forceRerender() {
            this.key += 1;
        },
        // removeDireccion (index) {
        //     this.formCliente.direccion.splice(index, 1)
        // }
    },
    created () {
        extend('rut', rutValidator)
    },
    mounted () {
        this.$watch('res', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null) {
                this.returnData()
            }
        }, { immediate: true })
        this.$watch('address', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null) {
                this.returnAddress()
            }
        }, { immediate: true })
    }
}
</script>
