<template>
  <div>
    <b-modal :id="modal" size="lg" :title="title" no-close-on-esc no-close-on-backdrop hide-header-close>
      <div class="row">
        <div class="form-group col-md-6">
          <label>Nombre*</label>
          <input type="text" name="" class="form-control" v-model="formUsuario.first_name">
        </div>
        <div class="form-group col-md-6">
          <label>Apellido*</label>
          <input type="text" name="" class="form-control" v-model="formUsuario.last_name">
        </div>
        <div class="form-group col-md-6">
          <label>Correo electrónico*</label>
          <input type="email" name="" class="form-control" v-model="formUsuario.email">
        </div>
        <div class="form-group col-md-6">
          <label>Rol*</label>
          <v-select :options="roles" v-model="formUsuario.rol"></v-select>
        </div>
        <div class="form-group col-md-6">
          <label>Teléfono</label>
          <input type="text" name="" class="form-control" v-model="formUsuario.telefono">
        </div>
        <div class="form-group col-md-6">
          <label>QAD</label>
          <input type="text" name="" class="form-control" v-model="formUsuario.qad">
        </div>
        <div class="form-group col-md-12" v-if="showSelector">
          <label>Empresas*</label>
          <v-select multiple :options="empresas" v-model="formUsuario.empresas" label="nombre" :reduce="nombre => nombre.id"></v-select>
        </div>
      </div>
      <template #modal-footer="{ }">
        <button class="button button--secondary" @click="closeModal();">
          <span class="button__text">Cancelar</span>
          <font-awesome-icon :icon="['fas', 'times-circle']" class="button__icon" />
        </button>
        <button class="button button--success" v-if="!edit" @click="createUsuario()">
          <span class="button__text">Guardar</span>
          <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
        </button>
        <button class="button button--success" v-if="edit" @click="updateUsuario()">
          <span class="button__text">Actualizar</span>
          <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
        </button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
const Empresa = resource('/base/api/listar_empresas', axios)
const Usuario = resource('/base/api/gestor_usuarios/', {
    update: (id, params) => axios.put(`/base/api/gestor_usuarios/${id}/`, { params })
}, axios)

export default {
    name: 'ModalUsuario',
    props: ['modal', 'usuario', 'edit', 'title'],
    components: {
    },
    data () {
        return {
            roles: ['Administrador', 'Solicitante', 'Revisor', 'Aprobador', 'Observador', 'Digitador', 'Bodega', 'Logistica', 'Chofer'],
            empresas: [],
            empresas__id: [],
            formUsuario: {
                first_name: null,
                last_name: null,
                email: null,
                rol: null,
                telefono: null,
                empresas: [],
                is_active: false
            },
            showSelector: false
        }
    },
    methods: {
        openModalCreate () {
            this.$root.$emit('bv::show::modal', 'modal-create-usuario')
        },
        openModalEdit () {
            if (this.usuario) this.formUsuario = this.usuario
            this.$root.$emit('bv::show::modal', 'modal-edit-usuario')
        },
        closeModal () {
            this.$emit('accept')
            this.$root.$emit('bv::hide::modal', this.modal)
        },
        createUsuario () {
            this.formUsuario.username = this.formUsuario.email
            this.formUsuario.is_active = false
            if (this.formUsuario.rol === 'Administrador') this.formUsuario.empresas = this.empresas__id
            Usuario.save(this.formUsuario).then((res) => {
                this.closeModal()
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Usuario creado con exito'
                })
                this.formUsuario = {
                    first_name: null,
                    last_name: null,
                    email: null,
                    rol: null,
                    telefono: null,
                    empresas: []
                }
            })
                .catch((error) => {
                    this.$emit('accept')
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al crear el usuario'
                    })
                })
        },
        updateUsuario () {
            this.formUsuario.username = this.formUsuario.email
            if (this.formUsuario.rol === 'Administrador') this.formUsuario.empresas = this.empresas__id
            Usuario.update(this.formUsuario.id, this.formUsuario).then((res) => {
                this.closeModal()
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Usuario editado con exito'
                })
            })
                .catch((error) => {
                    this.$emit('accept')
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al editar el usuario'
                    })
                })

        }
    },
    mounted () {
        Empresa.query({ activo: true }).then((res) => {
            this.empresas = res.data
            this.empresas__id = this.empresas.map(({ id }) => id)
        })
        this.$watch('formUsuario.rol', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale) {
                if (newLocale !== 'Administrador' && newLocale) {
                    this.showSelector = true
                } else if (newLocale === 'Administrador') {
                    this.showSelector = false
                }
            }
        }, { immediate: true })
    }
}
</script>
