<template>
    <div id="app">
        <!-- <Header v-if="currentPage !== 'Login' && usuario" :user="usuario" :is-admin="isAdmin"></Header> -->
        <loader v-if="loading"></loader>
        <router-view />
        <!-- <router-view style="margin-top:5rem;"/> -->
        <notifications group="foo" />
    </div>
</template>

<script>
// import axios from 'axios'
// import resource from 'resource-axios'
// import Header from '@/components/Header.vue'

// const UsuarioActual = resource('/base/api/usuario_actual', axios)
import Loader from '@/components/Loader.vue'

export default {
    name: 'App',
    components: {
        Loader
        // Header
    },
    data () {
        return {
            // usuario: {},
            // isAdmin: false
        }
    },
    mounted () {
        this.$store.dispatch('loadUserFromLocal')
        // UsuarioActual.query({}).then((res) => {
        //     this.usuario = res.data[0]
        //     if (this.usuario.rol === 'Administrador' || this.usuario.is_superuser) this.isAdmin = true
        // })
    },
    computed: {
        currentPage () {
            return this.$route.path
        },
        loading () {
            return this.$root.loading
        }
    }
}
</script>
