<!--BOTON AGREGAR PRESUPUESTO-->
<template>
    <div>
        <b-modal id="modal-create-cambio" size="sm" title="Crear Tasa de Cambio" no-close-on-esc no-close-on-backdrop hide-header-close>
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Moneda*</label>
                    <v-select :options="monedas" v-model="formCambio.moneda"></v-select>
                </div>
                <div class="form-group col-md-12">
                    <label>Tasa de Cambio</label>
                    <input type="number" name="" class="form-control" v-model="formCambio.tasa_cambio">
                </div>
            </div>
            <template #modal-footer="{ }">
                <button class="button button--secondary" @click="closeModal();">
                    <span class="button__text">Cancelar</span>
                    <font-awesome-icon :icon="['fas', 'times-circle']" class="button__icon" />
                </button>
                <button class="button button--success" @click="createCambio()">
                    <span class="button__text">Guardar</span>
                    <font-awesome-icon :icon="['fas', 'check-circle']" class="button__icon" />
                </button>
            </template>
        </b-modal>
    </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
const Cambio = resource('/base/api/listar_cambio/', {
    update: (id, params) => axios.put(`/base/api/listar_cambio/${id}/`, { params })
}, axios)

export default {
    name: 'ModalCambio',
    props: ['cambios'],
    components: {
    },
    data () {
        return {
            formCambio: {
                moneda: null,
                tasa_cambio: null
            },
            error: null,
            monedas: ['USD', 'EUR']
        }
    },
    methods: {
        openModal () {
            var currency
            currency = this.cambios.map((c) => { return c.moneda })
            currency.forEach((c) => {
                this.monedas.splice(this.monedas.indexOf(c), 1)
            })
            this.formCambio = {
                moneda: null,
                tasa_cambio: null
            }
            this.$root.$emit('bv::show::modal', 'modal-create-cambio')
        },
        closeModal () {
            this.$emit('reset')
            this.$root.$emit('bv::hide::modal', 'modal-create-cambio')
        },
        createCambio () {
            this.error = null
            Cambio.save(this.formCambio).then((res) => {
                this.closeModal()
                this.$emit('accept')
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Cambio creado con exito'
                })
            })
                .catch((error) => {
                    this.error = error.response.data
                    this.$emit('reset')
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al crear el cambio'
                    })
                })
        }
    },
    mounted () {
    },
    computed: {
    }
}
</script>
