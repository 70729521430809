<template>
  <div>
    <modal-presupuesto modal="modal-edit-presupuesto" :presupuesto="modalEditData" :edit="true" ref="modalEdit" title="Editar Presupuesto" @reset="getData();modalEditData=null;forceRerender();" v-if="modalEditData"></modal-presupuesto>
    <!-- MODAL CREAR ARTICULO -->
    <modal-articulo v-if="modalPresupuestoData" modal="modal-create-articulo" class="modal-z-index" :edit="false" ref="modalCreateArticulo" title="Crear Artículo" @accept="forceArticulo()" @doc="setDataUpload" :cotizacion="true" :presupuesto-id="modalPresupuestoData"></modal-articulo>
    <!-- MODAL CARGAR DOCUMENTO -->
    <modal-documento :articulo="modalUploadData" ref="modalUpload" title="Cargar Documentos" @reset="modalUploadData=null;" v-if="modalUploadData"></modal-documento>
    <modal-respaldo v-if='modalRespaldoData' :presupuesto="modalRespaldoData" ref="modalRespaldo" @accept="getData();forceRerender();modalRespaldoData=null;" @close="modalRespaldoData=null;"></modal-respaldo>
    <div class="card--outline" v-for="presupuesto in presupuestos" v-bind:key="presupuesto.id">
      <div class="card__header">
        <h4 class="card__title">{{presupuesto.numero_equipo}}</h4>
        <div class="card__buttons">
          <button class="button button--line-primary"  @click="setDataPresupuesto(presupuesto.id)" v-if="!$parent.cotizacion.cerrado">
            <span class="button__text">Agregar Artículo</span>
            <font-awesome-icon class="button__icon" :icon="['fas', 'plus-circle']" /> 
          </button>
          <div class="dropdown" v-if="!aprobado && !$parent.cotizacion.rechazado && !$parent.cotizacion.descartado && !$parent.cotizacion.cerrado">
            <a data-toggle="dropdown" class="button button--line-primary dropdown-toggle" style="cursor: pointer;" aria-haspopup="true" aria-expanded="false">
              <font-awesome-icon :icon="['fas', 'ellipsis-v']" class="button__icon--left" />
            </a>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <li class="nav-item">
                <a class="nav-link" style="cursor: pointer;" @click="setDataRespaldo(presupuesto)">Cargar Presupuesto (XLS)</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" style="cursor: pointer;" @click="setDataEdit(presupuesto)" v-if="(permisoIngresar || permisoEditar)">Editar</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" style="cursor: pointer;" @click="deleteAlert(presupuesto)" v-if="(permisoIngresar || permisoEditar)">Eliminar</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" style="cursor: pointer;" @click="downloadXlsProyecto(presupuesto)" v-if="proyecto && aprobado && permisoAprobar">QAD: Carga Proyecto</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" style="cursor: pointer;" @click="downloadXlsCotizacion(presupuesto)" v-if="!proyecto && aprobado && permisoAprobar">QAD: Carga Cotización</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card__body">
        <detalle-presupuesto :key="key" :presupuesto="presupuesto" :articulos="articulos" @accept="getData()" ref="detallePresupuesto" @reset="$emit('accept')"></detalle-presupuesto>
      </div>
      <div class="card__footer" v-if="$parent.empresaNombre === 'Dripsa'">
        <div class="row">
          <div class="col-md-12">
            <p class="table__title m-0">Resumen Cotización</p>
          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-md-6" v-if="presupuesto.sub_total_moneda.length > 0 && !aprobado && !$parent.cotizacion.cerrado">
            <div class="row" v-for="st in presupuesto.sub_total_moneda" v-bind:key="st.moneda">
              <div class="col-md-6">
                <p class="cotizacion--subtotal">SUB-TOTAL {{st.moneda}}:</p>
              </div>
              <div class="col-md-6">
                <vue-numeric class="w-100" currency="$" separator="." v-model="st.sub_total" disabled style="text-align: right;" v-bind:precision="3"></vue-numeric>
              </div>
            </div>
            <!--  <div v-if="presupuesto.sub_total_moneda.length > 0 && !aprobado && !$parent.cotizacion.cerrado">
              <p v-for="st in presupuesto.sub_total_moneda" v-bind:key="st.moneda" style="text-align: right">
                <span>
                    SUB-TOTAL {{st.moneda}}: <vue-numeric class="" currency="$" separator="." v-model="st.sub_total" disabled style="text-align: right;" v-bind:precision="3"></vue-numeric>
                    <br>
                </span>
              </p>
            </div>
            <p><strong class="float-right" v-if="presupuesto.sub_total">SUB-TOTAL CLP: <vue-numeric class="" currency="$" separator="." v-model="presupuesto.sub_total" disabled style="text-align: right;" v-bind:precision="3"></vue-numeric></strong>
            <br></p> -->
          </div>
        </div>
        <div class="row justify-content-end" v-if="presupuesto.sub_total">
          <div class="col-md-6">
            <div class="row" >
              <div class="col-md-6">
                <p class="cotizacion--subtotal">SUB-TOTAL CLP: </p>
              </div>
              <div class="col-md-6">
                <vue-numeric class="w-100" currency="$" separator="." v-model="presupuesto.sub_total" disabled style="text-align: right;" v-bind:precision="3"></vue-numeric>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
import ModalPresupuesto from '@/cotizacion/ModalPresupuesto.vue'
import DetallePresupuesto from '@/cotizacion/DetallePresupuesto.vue'
import ModalArticulo from '@/modals/ModalArticulo.vue'
import ModalDocumento from '@/modals/ModalDocumento.vue'
import ModalRespaldo from '@/modals/ModalRespaldo.vue'

const Presupuesto = resource('/cotizacion/api/gestor_presupuestos/', { 
    delete: (id) => axios.delete(`/cotizacion/api/gestor_presupuestos/${id}/`)
}, axios)

export default {
    name: 'Presupuesto',
    props: ['proyecto', 'aprobado', 'articulos', 'porConfirmar', 'cerrado'],
    components: {
        ModalPresupuesto,
        DetallePresupuesto,
        ModalArticulo,
        ModalDocumento,
        ModalRespaldo
    },
    data () {
        return {
            cotizacionId: this.$route.params.cotizacionId,
            presupuestos: [],
            modalEditData: null,
            key: 0,
            modalUploadData: null,
            modalPresupuestoData: null,
            modalRespaldoData: null
        }
    },
    methods: {
        getData () {
            Presupuesto.query({ cotizacion: this.cotizacionId }).then((res) => {
                this.presupuestos = res.data
                this.presupuestoId = this.presupuestos.id
            })
        },
        setDataEdit (item) {
            this.modalEditData = item
        },
        openModalEdit () {
            this.$refs.modalEdit.openModalEdit()
        },
        forceRerender() {
            this.key += 1;
        },
        deleteAlert (presupuesto) {
            this.$swal({
                title: 'Vas a eliminar el Presupuesto',
                text: '¿Estás seguro? Se perderá toda la información asociada',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deletePresupuesto(presupuesto)
                }
            })
        },
        deletePresupuesto(presupuesto) {
            Presupuesto.delete(presupuesto.id).then((res) => {
                this.getData()
                this.$emit('accept')
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: ' Presupuesto eliminado con exito'
                })
            })
                .catch((error) => {
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al eliminar el presupuesto'
                    })
                })
        },
        downloadXlsProyecto (presupuesto) {
            axios({
                url: '/cotizacion/api/gestor_cotizaciones/' + this.cotizacionId +'/xls_qad_proyecto/?presupuesto=' + presupuesto.id,
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}))
                var fileLink = document.createElement('a')

                fileLink.href = fileURL
                window.open(fileURL)
            })
                .catch((error) => {
                    if (error.response.status === 401) {
                        this.getData()
                        this.downloadXlsProyecto()
                    }
                })
        },
        downloadXlsCotizacion (presupuesto) {
            axios({
                url: '/cotizacion/api/gestor_cotizaciones/' + this.cotizacionId +'/xls_qad_cotizacion/?presupuesto=' + presupuesto.id,
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}))
                var fileLink = document.createElement('a')

                fileLink.href = fileURL
                window.open(fileURL)
            })
                .catch((error) => {
                    if (error.response.status === 401) {
                        this.getData()
                        this.downloadXlsCotizacion()
                    }
                })
        },
        setDataPresupuesto (item) {
            this.modalPresupuestoData = item
        },
        openModalCreateArticulo () {
            this.$refs.modalCreateArticulo.openModalCreate()
        },
        setDataUpload (value) {
            this.modalUploadData = value
        },
        openModalUpload () {
            this.$refs.modalUpload.openModal()
        },
        setDataRespaldo (value) {
            this.modalRespaldoData = value
        },
        openModalRespaldo () {
            this.$refs.modalRespaldo.openModal()
        },
        forceReset () {
            this.$emit('reset')
        },
        forceArticulo () {
            this.modalPresupuestoData = null
            this.forceRerender()
        }
    },
    mounted () {
        this.getData()
        this.$watch('modalEditData', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null) {
                this.openModalEdit()
            }
        }, { immediate: true })
        this.$watch('modalPresupuestoData', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null) {
                this.openModalCreateArticulo()
            }
        }, { immediate: true })
        this.$watch('modalUploadData', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null) {
                this.openModalUpload()
            }
        }, { immediate: true })
        this.$watch('modalRespaldoData', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale && newLocale !== null) {
                this.openModalRespaldo()
            }
        }, { immediate: true })
        
        
    },
    computed: {
        permisoAprobar () {
            return this.$store.state.permiso_aprobar
        },
        permisoIngresar() {
            return this.$store.state.permiso_ingresar
        },
        permisoEditar() {
            return this.$store.state.permiso_editar
        }
    }
}
</script>
